import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ResulActos from './PagoResulFolio/actosView';
import ResulGenerales from './PagoResulFolio/generalesView';
import ResulTitulares from './PagoResulFolio/titularesView';
import { Dialog } from 'primereact/dialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { useLocation, useNavigate } from "react-router-dom";
import { Messages } from 'primereact/messages';
import { Steps } from 'primereact/steps';
import Tabs from '../../../Components/Tabs/Tabs';
import Tab from '../../../Components/Tabs/Tab';
import TimeLine from '../../../Components/TimeLine/TimeLine';

const VenPagoFolioView = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const msgs = useRef<any>(null);

    const previousFolio: any = location.state.folios
    const previousPayments: any = location.state.pagos
    const preTramite: any = location.state.preTramite

    const [preTramiteDetail, setPreTramiteDetail] = useState<any>(null)
    const [preTramiteFolio, setPreTramiteFolio] = useState<any>()
    const [modalRechazar, setModalRechazar] = useState<boolean>(false);
    const [rechazarText, setRechazarText] = useState('')
    const [modalRechazado, setModalRechazado] = useState(false)
    const [modalSentToNotario, setModalSentToNotario] = useState(false)
    const [actosArray, setActosArray] = useState<any>([])
    const [actosList, setActosList] = useState([])
    const [escrituraNumber, setEscrituraNumber] = useState(preTramite ? preTramite.ptrm_escritura : '')
    const [modalBorradorGuardado, setModalBorradorGuardado] = useState(false)

    const getActosInformation = async () => {
        const idToSearch = responseData ? responseData.trm_id : preTramite ? preTramite.trm_id : null
        if (idToSearch) {
            let datos = {
                ptrm_id: 0,
                trm_id: idToSearch
            }
            await ApiServices.apiPost('getFormatosResp', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda.length > 0) {
                    const newArray: any = []

                    res.data.addenda.map(async (singleElement: any) => {
                        if (singleElement.trm_id === idToSearch) {
                            newArray.push(singleElement)
                        }
                    })
                    matchSavedActos(newArray)
                    /* setActosArray(newArray) */
                } else {
                    matchSavedActos(preTramiteDetail.actos)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    function findByActId(array: any, targetActId: any) {
        return array && array.find((item: any) => item.act_id == targetActId);
    }

    const [totalActos, setTotalActos] = useState(0)

    function mergeArraysByActId(array1: any, array2: any) {
        const mapArray2 = new Map(array2.map((obj: any) => [obj.act_id, obj]));
        const mergedArray = array1.map((obj1: any) => {
            const obj2 = mapArray2.get(obj1.act_id);
            return obj2 && typeof obj1 === 'object' && typeof obj2 === 'object' ? { ...obj1, ...obj2 } : obj1;
        });
        return mergedArray;
    }

    const matchSavedActos = (newActos: any) => {
        let newArray = []
        if (newActos && newActos.length > 0) {
            let total = 0;
            const matchedActos = newActos.map((singleActo: any) => {
                const match = findByActId(actosList, singleActo.act_id)
                if (match) {
                    singleActo.act_umas = match.act_umas
                    singleActo.act_dias = match.act_dias
                    singleActo.cnpg_id = match.cnpg_id
                    singleActo.area_id = match.area_id
                    singleActo.act_status = match.act_status
                    singleActo.act_update = match.act_update
                    singleActo.act_pesos = match.valormx

                    if (match.valormx) {
                        total = total + parseFloat(match.valormx)
                    }
                }

                return singleActo
            })

            if (actosArray.length > 0)
                newArray = mergeArraysByActId(actosArray, matchedActos)
            else
                newArray = matchedActos
            setTotalActos(total)
            setActosArray(newArray)
        }
    }

    const getActosList = async () => {
        let datos
        await ApiServices.apiPost('catActo', datos).then(async res => {
            setActosList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }



    const requestPreTramiteDetail = () => {
        if (preTramite) {
            let datos
            datos = { code: 204, trm_id: preTramite.trm_id }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    setEscrituraNumber(res.data.addenda.tramite.trm_escritura)
                    setRequestName(res.data.addenda.tramite.trm_solicitante)
                }
            }).catch(error => {
                console.log(error)
            })
        } else if (responseData) {
            let datos
            datos = { code: 204, trm_id: responseData.trm_id }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    setEscrituraNumber(res.data.addenda.tramite.trm_escritura)
                    setRequestName(res.data.addenda.tramite.trm_solicitante)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }


    /* OLD */

    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<any>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [modalSoliInter, ModalSoliInter] = useState<boolean>(false);
    const buttonEl = useRef<any>(null);

    const [timeLine, setTimeLine] = useState<any>()



    const events1 = [
        { label: 'Capturado' },
        { label: 'Asignado' },
        { label: 'Analizando' },
        { label: 'A-R-C' },
        { label: 'Firmado' }
    ];

    const events2 = [
        { label: 'Capturado', tooltip: timeLine ? timeLine.capturista : '', time: timeLine ? timeLine.trm_fecha : '', onlyTime: false },
        { label: 'Asignado', tooltip: timeLine ? timeLine.analista : '' },
        { label: 'Analizando', tooltip: '', time: timeLine ? timeLine.trm_analizado : '' },
        { label: 'A-R-C', tooltip: timeLine ? timeLine.statusARC : '', time: timeLine ? timeLine.fechahoraARC : '' },
        { label: 'Firmado', tooltip: timeLine ? timeLine.trm_firmador : '', time: timeLine ? timeLine.trm_firmado : '' }
    ];

    const accept = () => {
        ModalSetVisible(true)
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado tu trámite', life: 3000 });
    }

    const fileToBig = () => {
        toast.current.show({ severity: 'error', summary: 'Archivo muy grande', detail: 'El archivo excede los 5MB', life: 3000 });
    }

    const customizedMarker = (item: any) => {
        return (
            <div>
                {/* circulo vasio: se utilizara cuando no tiene un proceso todavia realizado */}
                <i className='pi pi-circle'></i>
                {/* circulo lleno: aparecera cuando el estatus se valla llenando, en cada punto tiene que aparecer un toolpin con el estatus de quien lo reviso y la hora de movimiento */}
                <Button className='p-button-text tooltip-view' type="button" icon="pi pi-circle-fill" tooltipOptions={{ position: 'bottom' }} />
                <Tooltip target=".tooltip-view" autoHide={false}>
                    <div className="p-3">
                        <h5>Capturado</h5>
                        <p>Juan Montes Torres <br />
                            <small>12 Marz 2022 12:00pm</small></p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const footerContent = (
        <div>
            <Button label="Cerrar" onClick={() => { { setVisible(false); showSuccess() } }} autoFocus />
        </div>
    );



    const [notaryList, setNotaryList] = useState([])
    const [selectedNotaryList, setSelectedNotaryList] = useState<any>(null)
    const [requestName, setRequestName] = useState(previousFolio ? previousFolio[0].proptitutales : '')


    const getNotaryList = async () => {
        let datos
        await ApiServices.apiPost('catNotarios', datos).then(async res => {
            setNotaryList(res.data.addenda)
            searchNotary(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const searchNotary = (notarys: any) => {
        if (notarys && notarys.length > 0) {
            notarys.map((element: any) => {
                if (element.not_id === user.not_id) {
                    setSelectedNotaryList(element)
                }
            })
        }
    }

    const getActosArray = () => {
        if (actosArray && actosArray.length > 0) {
            const ids = actosArray.map((element: any) => {
                return element.act_id
            })
            return ids
        }
    }

    const getPagosArray = () => {
        if (previousPayments && previousPayments.length > 0) {
            const ids = previousPayments.map((element: any) => {
                return element.pago_id
            })
            return ids
        }
    }

    function obtenerFechaYHoraActualEnFormato() {
        const mesesEnEspanol = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const fechaActual = new Date();
        const dia = fechaActual.getDate();
        const mes = mesesEnEspanol[fechaActual.getMonth()];
        const anio = fechaActual.getFullYear();
        let hora = fechaActual.getHours();
        const minutos = fechaActual.getMinutes();
        let periodo = "am";

        // Convertir la hora a formato de 12 horas y ajustar el periodo (am/pm)
        if (hora >= 12) {
            periodo = "pm";
            if (hora > 12) {
                hora -= 12;
            }
        }

        // Agregar un cero al principio si los minutos son menores a 10
        const minutosFormateados = (minutos < 10) ? "0" + minutos : minutos;

        const fechaYHoraFormateada = `${dia + 1} ${mes} ${anio} a las ${hora}:${minutosFormateados} ${periodo}`;
        return fechaYHoraFormateada;
    }

    /* CONFIRMED */

    const user = authGuardService.getUser()

    const handleAddPreTramite = () => {

        const actos = getActosArray()
        const pagos = getPagosArray()

        let datos: any = {
            code: 302,
            usu_id: user.usu_id,
            prop_id: previousFolio[0].prop_id,
            ptrm_solicitante: requestName,
            not_id: selectedNotaryList ? selectedNotaryList.not_id : user.not_id,
            ptrm_escritura: escrituraNumber,
            actos: actos || [],
            pagos: pagos || []
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido) {
                setActiveIndex(1)
                setResponseData(res.data.addenda)
                setModalNewScript(false)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleSaveTramite = () => {
        setModalBorradorGuardado(true)

        const actos = getActosArray()
        const pagos = getPagosArray()

        const propId = previousFolio && previousFolio[0].prop_id
        const requestNameFinal = selectedNotaryList ? `${selectedNotaryList ? selectedNotaryList.not_nom : ''} ${selectedNotaryList ? selectedNotaryList.not_mat : ''} ${selectedNotaryList ? selectedNotaryList.not_pat : ''}` : requestName

        let datos: any = {
            code: 203,
            usu_id: user.usu_id,
            prop_id: propId,
            trm_solicitante: requestNameFinal,
            not_id: selectedNotaryList ? selectedNotaryList.not_id : user.not_id,
            trm_escritura: escrituraNumber,
            actos: actos || [],
            pagos: pagos || [],
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido) {
                setModalBorradorGuardado(false)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleSaveSavedTramite = () => {
        setModalBorradorGuardado(true)

        const actos = getActosArray()
        const pagos = getPagosArray()

        const propId = previousFolio && previousFolio[0].prop_id
        const requestNameFinal = selectedNotaryList ? `${selectedNotaryList ? selectedNotaryList.not_nom : ''} ${selectedNotaryList ? selectedNotaryList.not_mat : ''} ${selectedNotaryList ? selectedNotaryList.not_pat : ''}` : requestName

        let datos: any = {
            code: 203,
            usu_id: user.usu_id,
            prop_id: propId,
            trm_solicitante: requestNameFinal,
            not_id: selectedNotaryList ? selectedNotaryList.not_id : user.not_id,
            trm_escritura: escrituraNumber,
            actos: actos || [],
            pagos: pagos || [],
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido) {
                setModalBorradorGuardado(false)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    function formatDate(date: any, onlyTime = false, item: any) {
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const newDate = new Date(date);
        const day = newDate.getUTCDate();
        const month = months[newDate.getUTCMonth()];
        const year = newDate.getUTCFullYear();
        const hour = newDate.getUTCHours();
        const minutes = newDate.getUTCMinutes();
        const fechaFormateada = `${day} ${month} ${year} a las ${hour}:${minutes}`;
        const timeFormateada = `${hour}:${minutes}`;
        if (onlyTime) {
            return timeFormateada
        } else {
            return fechaFormateada;
        }
    }

    const handleStatusPreTramite = (status: number) => {
        const data = preTramite || responseData
        let datos: any = {
            code: 304,
            ptrm_id: data.ptrm_id,
            usu_id: user.usu_id,
            ptrm_status: status,
            ptrm_nota: rechazarText || ''
        }

        if (status === 3) {
            setModalRechazar(false)
            datos.ptrm_nota = rechazarText;
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido) {
                if (status === 3) {
                    setModalRechazado(true)
                }
                if (status === 2) {
                    setModalSentToNotario(true)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const [modalNewScript, setModalNewScript] = useState(false)
    const [responseData, setResponseData] = useState<any>()

    const handlePreviousActos = () => {
        const arrayListSaved = preTramite.actos.split(', ')
        const newArray = JSON.parse(JSON.stringify(actosArray))

        if (arrayListSaved && arrayListSaved.length > 0) {
            arrayListSaved.map((singleAct: any) => {
                const foundItem = actosArray.find((item: any) => item.act_id == singleAct);
                if (!foundItem) {
                    const elementToPush = actosList.find((item: any) => item.act_clave == singleAct);
                    newArray.push(elementToPush)
                }
            })
        }

        matchSavedActos(newArray)
    }

    const searchPreTramiteDetail = async () => {
        if (preTramite) {
            let datos
            datos = { code: 303, ptrm_id: preTramite.ptrm_id }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            await ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    setEscrituraNumber(res.data.addenda.tramite.trm_escritura)
                    setRequestName(res.data.addenda.tramite.trm_solicitante)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    useEffect(() => {
        getActosList()
        getNotaryList()
        getActoHistory()
        if (preTramite && preTramite.trm_id)
            getTimeLine(preTramite.trm_id)
    }, [])

    const getTimeLine = (id: any) => {
        if (id) {
            let datos = {
                trm_id: id,
            }
            ApiServices.apiPost('getTimeLine', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                    setTimeLine(res.data.addenda[0])
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }


    useEffect(() => {
        if (preTramite && preTramite.actos && actosList && actosList.length > 0 && actosArray) {
            handlePreviousActos()
        }
    }, [actosList, preTramite])

    useEffect(() => {
        if (preTramite) {
            let datos2
            datos2 = { code: 204, trm_id: preTramite.trm_id }
            datos2 = EncryptService.encryptData(JSON.stringify(datos2));
            ApiServices.processRequestAdd(datos2).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    setEscrituraNumber(res.data.addenda.tramite.trm_escritura)
                    setRequestName(res.data.addenda.tramite.trm_solicitante)

                    if (preTramite.ptrm_status === 3 && res.data.addenda) {
                        msgs.current.show(
                            {
                                sticky: true,
                                severity: 'error',
                                summary: 'PreTramite Rechazado / ',
                                detail: `Motivo: ${res.data.addenda.ptrm_nota || 'Sin Motivo'}`,
                                closable: false,
                            }
                        );
                    } else if (preTramite.ptrm_status === 4 && res.data.addenda) {
                        msgs.current.show(
                            {
                                sticky: true,
                                severity: 'warn',
                                summary: 'PreTramite Rechazado / ',
                                detail: `Motivo: ${res.data.addenda.ptrm_nota || 'Sin Motivo'}`,
                                closable: false,
                            }
                        );
                    }
                }
            }).catch(error => {
                console.log(error)
            })

            if (preTramite.prop_folio) {
                let datos = {
                    prop_folio: preTramite.prop_folio,
                    prop_online: 0
                }
                ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                    setPreTramiteFolio(res.data.addenda)
                    setActiveIndex(0)
                }).catch(error => {
                    console.log(error)
                })
            } else {
                let datos = {
                    prop_id: preTramite.prop_id,
                }
                ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
                    let datos = {
                        prop_folio: res.data.addenda[0].prop_folio,
                        prop_online: 0
                    }
                    ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                        setPreTramiteFolio(res.data.addenda)
                        setActiveIndex(0)
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }, [preTramite])

    const [requester, setRequester] = useState(true)

    useEffect(() => {
        getActosInformation()
    }, [actosList])

    const controlInternoBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.trm_fecha);
        const horaUTC = new Date(rowData.trm_hora);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();
        const hours = horaUTC.getUTCHours().toString().padStart(2, '0');
        const minutes = horaUTC.getUTCMinutes().toString().padStart(2, '0');
        const seconds = horaUTC.getUTCSeconds().toString().padStart(2, '0');


        const fechaFormateada = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

        return (
            <div className="actions">
                <p>{`${rowData.trm_consecutivo} - ${fechaFormateada}`}</p>
            </div>
        )
    }


    function groupObjectsByTrmId(inputArray: any) {
        const groupedObjects: any = {};

        inputArray.forEach((obj: any) => {
            const trmId = obj.trm_id;

            if (!groupedObjects[trmId]) {
                groupedObjects[trmId] = {
                    trm_fecha: obj.trm_fecha,
                    trm_id: trmId,
                    trm_consecutivo: obj.trm_consecutivo,
                    history: []
                };
            }

            groupedObjects[trmId].history.push(obj);
        });

        return Object.values(groupedObjects);
    }

    function transformarArray(arr: any) {
        const result: any = [];

        arr.reverse().forEach((element: any) => {
            const firstDate = element.history[0].trm_fecha;

            element.history.forEach((historyElement: any) => {
                if (historyElement === element.history[0]) {
                    result.push({
                        firstDate,
                        trm_id: historyElement.trm_id,
                        act_id: historyElement.act_id,
                        act_clave: historyElement.act_clave,
                        act_nombre: historyElement.act_nombre,
                        pago_serie: historyElement.pago_serie,
                        pago_folio: historyElement.pago_folio,
                        usu_id: historyElement.usu_id,
                        not_id: historyElement.not_id,
                        trm_status: historyElement.trm_status,
                        analista: historyElement.analista,
                        calificador: historyElement.calificador,
                        trm_consecutivo: historyElement.trm_consecutivo,
                        trm_escritura: historyElement.trm_escritura,
                        trm_firmado: historyElement.trm_firmado,
                        ta_antecedente: historyElement.ta_antecedente
                    });
                } else {
                    result.push({
                        firstDate: undefined,
                        trm_fecha: historyElement.trm_fecha,
                        trm_id: historyElement.trm_id,
                        act_id: historyElement.act_id,
                        act_clave: historyElement.act_clave,
                        act_nombre: historyElement.act_nombre,
                        pago_serie: historyElement.pago_serie,
                        pago_folio: historyElement.pago_folio,
                        usu_id: historyElement.usu_id,
                        not_id: historyElement.not_id,
                        trm_status: historyElement.trm_status,
                        analista: historyElement.analista,
                        calificador: historyElement.calificador,
                        trm_consecutivo: historyElement.trm_consecutivo,
                        trm_escritura: historyElement.trm_escritura,
                        trm_firmado: historyElement.trm_firmado,
                        ta_antecedente: historyElement.ta_antecedente
                    });
                }
            });
        });

        return result;
    }
    const [actoHistory, setActoHistory] = useState()

    const getActoHistory = () => {
        if (preTramite) {
            let datos = {
                prop_id: preTramite.prop_id,
            }
            ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
                let datos2 = {
                    prop_folio: res.data.addenda[0].prop_folio
                }
                ApiServices.apiPost('getHistorial', datos2).then(async res => {
                    const groupedResult: any = groupObjectsByTrmId(res.data.addenda);
                    const newArray = transformarArray(groupedResult)
                    setActoHistory(newArray)
                }).catch(error => {
                    console.log(error)
                })
            }).catch(error => {
                console.log(error)
            })


        }
    }

    return (
        <section>
            <Toast ref={toast} />

            <div className='container-fluid'>
                <div className='flex justify-content-between'>
                    {/* cuando sean folios de calidad aparecera el color verde se mostrara como el div, si no. se negara el small y el div 0.1 siguiente */}

                    <div className='flex col-12 md:col-4'>
                        {/* negacion div 0.1 */}
                        {/* <div className='text-center'>
                            <i className='pi pi-check-circle verde'></i>
                            <div className='line'></div>
                        </div> */}

                        <div className='verde'>
                            {
                                previousFolio &&
                                <h3 className='verde'>{`Folio ${previousFolio && previousFolio[0] && previousFolio[0].prop_folio}`}</h3>
                            }
                            {
                                preTramiteFolio &&
                                <h3 className='verde'>{`Folio ${preTramiteFolio && preTramiteFolio[0] && preTramiteFolio[0].prop_folio}`}</h3>

                            }
                            {/* <small>Este es un folio de calidad</small> */}
                        </div>
                        <div className='col-12'><p><span className='font-bold'>Numero de Control: </span>{preTramiteDetail && preTramiteDetail.tramite && controlInternoBodyTemplate(preTramiteDetail.tramite)}</p></div>

                    </div>
                    <div className='col-12 md:col-6'>
                        {timeLine &&
                            <TimeLine timeLine={timeLine} />
                        }
                    </div>

                </div>

                <Messages ref={msgs} />

                <div className=''>
                    <Tabs>
                        <Tab label="Datos generales">
                            {previousFolio &&
                                <ResulGenerales
                                    folioDatos={previousFolio[0]}
                                    notaryList={notaryList}
                                    setNotaryList={setNotaryList}
                                    selectedNotaryList={selectedNotaryList}
                                    setSelectedNotaryList={setSelectedNotaryList}
                                    escrituraNumber={escrituraNumber}
                                    setEscrituraNumber={setEscrituraNumber}
                                    requestName={requestName}
                                    setRequestName={setRequestName}
                                    exist={responseData}
                                    preTramiteDetail={preTramiteDetail}
                                    requestPreTramiteDetail={requestPreTramiteDetail}
                                    fileToBig={fileToBig}
                                    requester={requester}
                                    setRequester={setRequester}
                                />
                            }{
                                preTramiteFolio &&
                                <ResulGenerales
                                    folioDatos={preTramiteFolio[0]}
                                    notaryList={notaryList}
                                    setNotaryList={setNotaryList}
                                    selectedNotaryList={selectedNotaryList}
                                    setSelectedNotaryList={setSelectedNotaryList}
                                    escrituraNumber={escrituraNumber}
                                    setEscrituraNumber={setEscrituraNumber}
                                    requestName={requestName}
                                    setRequestName={setRequestName}
                                    exist={preTramite}
                                    preTramiteDetail={preTramiteDetail}
                                    requestPreTramiteDetail={requestPreTramiteDetail}
                                    fileToBig={fileToBig}
                                    requester={requester}
                                    setRequester={setRequester}
                                />
                            }
                        </Tab>
                        {previousFolio &&
                            <Tab label="Actos">
                                <ResulActos
                                    previousFolio={previousFolio}
                                    previousPayments={previousPayments}
                                    actos={actosArray}
                                    actosList={actosList}
                                    actosFunction={setActosArray}
                                    responseData={responseData}
                                    preTramite={preTramite}
                                    requestActos={getActosInformation}
                                    total={totalActos}
                                    totalFunction={setTotalActos}
                                    preTramiteDetail={preTramiteDetail}
                                />
                            </Tab>
                        }
                        {preTramiteFolio &&
                            <Tab label="Actos">
                                <ResulActos
                                    previousFolio={previousFolio}
                                    previousPayments={previousPayments}
                                    actos={actosArray}
                                    actosList={actosList}
                                    actosFunction={setActosArray}
                                    responseData={responseData}
                                    preTramite={preTramite}
                                    requestActos={getActosInformation}
                                    total={totalActos}
                                    totalFunction={setTotalActos}
                                    preTramiteDetail={preTramiteDetail}

                                    actoHistory={actoHistory}
                                    propiedadDetail={null}
                                    tramiteDetail={preTramiteDetail}
                                />
                            </Tab>
                        }
                        <Tab header="Titulares">
                            <ResulTitulares
                                previousFolio={previousFolio}
                                previousPayments={previousPayments}
                                responseData={responseData}
                                preTramite={preTramite}
                                preTramiteDetail={preTramiteDetail}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div >
        </section >
    );

}

export default React.memo(VenPagoFolioView);


