import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../serviceExample/ProductService';
import ApiServices from '../../../../service/apiServices';
import { ConfirmPopup } from 'primereact/confirmpopup';
import configuration from '../../../../configurationFile';

const ResulGeneralesView = (props: any) => {
    const [selectedCity, setSelectedCity] = useState<any>(null);
    const [checked, setChecked] = useState<boolean>(false);

    const notario: any = [
        { name: 'notario n' },
        { name: 'notario n' },
        { name: 'notario n' },
    ];

    const dt = useRef<any>(null);
    const [products, setProducts] = useState<any>(null);

    useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []);

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.code}
            </>
        );
    }
    const IconDocumentTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <i className='pi pi-file' style={{ 'fontSize': '2em' }}></i>
                </div>
            </>
        );
    }


    const EliminarTemplate = (rowData: any) => {
        return (
            <>
                <div>
                    <a href={`${configuration.url}${rowData.ptrmd_url}`} target="_blank">
                        <Button
                            tooltip="Ver archivo subido"
                            className="p-button-text"
                            icon="pi pi-eye"
                        />
                    </a>
                </div>
            </>
        );
    }

    const [file, setFile] = useState<any>();
    const refFile: any = useRef()
    const [fileName, setFileName] = useState('')
    const handleFileClick = (e: any) => {
        refFile.current.click()
    }

    const readUploadFile = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    const handleUploadDocument = () => {
        const data = {
            "ptrm_id": props.preTramiteDetail.tramite.ptrm_id,
            "ptrmd_desc": fileName || file.name,
            "ptrmd_url": "media/doc/"
        }

        ApiServices.apiPostFile('addDocPre', file, data).then(async res => {
            if (res.data.valido === 1) {
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleDeleteDocument = (id: any) => {
        const data = {
            "ptrm_id": props.preTramiteDetail.ptrm_id,
            "ptrmd_id": id,
        }

        ApiServices.apiPost('deleteDocPre', data).then(async res => {
            if (res.data.valido === 1) {
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const [visible, setVisible] = useState(false);
    const buttonEl = useRef<any>(null);
    const [selectedFile, setSelectedFile] = useState<any>()

    const accept = () => {
    };

    return (
        <section className=''>
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <small className='black'>Folio</small>
                    <p className='result'>{props.folioDatos.prop_folio}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Clave catastral</small>
                    <p className='result'>{props.folioDatos.prop_clave}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Domicilio registral</h5>
                </div>
                <div className='col-12 md:col-12'>
                    <small className='black'>Domicilio registral</small>
                    <p className='result'>{props.folioDatos.prop_direccion}</p>
                </div>
                <div className='col-12'>
                    <h5>Domicilio convencional</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Codigo postal</small>
                    <p className='result'>{props.folioDatos.cp_cp}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Localidad</small>
                    <p className='result'>{props.folioDatos.cp_loc}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Municipio</small>
                    <p className='result'>{props.folioDatos.cp_mun}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Estado</small>
                    <p className='result'>{props.folioDatos.cp_edo}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Colonia</small>
                    <p className='result'>{props.folioDatos.cp_col}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. interior</small>
                    <p className='result'>{props.folioDatos.prop_int}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. exterior</small>
                    <p className='result'>{props.folioDatos.prop_ext}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Calle</small>
                    <p className='result'>{props.folioDatos.prop_calle}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Superficie del predio</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Medidas y Colindancias</small>
                    <p className='result'>{props.folioDatos.prop_medidas}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Construidas</small>
                    <p className='result'>{props.folioDatos.prop_constr}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Superficie total</small>
                    <p className='result'>{props.folioDatos.prop_suptotal}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <hr />
                    <h5>Solicitante</h5>
                </div>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <Dropdown
                            inputId="dd-city"
                            value={props.selectedNotaryList}
                            onChange={(e) => console.log('')}
                            options={props.notaryList}
                            optionLabel="not_fedata"
                            filter
                            showClear
                            filterBy="not_fedata"
                            className="p-inputtext-lg"
                            disabled
                            virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                        />
                        <label htmlFor="dd-city">Notario</label>
                    </span>
                </div>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <InputText
                            id="username"
                            className="p-inputtext-lg"
                            value={props?.preTramiteDetail?.tramite?.trm_id ? props?.preTramiteDetail?.tramite?.trm_escritura : props?.preTramiteDetail?.tramite?.ptrm_escritura || ''}
                            onChange={(e) => { props.setEscrituraNumber(e.target.value) }}
                            disabled
                        />
                    </span>
                </div>
                <div className='col-12 md:col-12'>
                    <p>El solicitante es el:</p>
                    <div className='grid'>
                        {/* <div className='col-2'>
                            <Checkbox inputId="ingredient1" checked={checked} />
                            <label htmlFor="ingredient1" className="ml-2">¿Dueño?</label>
                        </div>
                        <div className='col-2'>
                            <Checkbox inputId="ingredient1" checked={checked} />
                            <label htmlFor="ingredient1" className="ml-2">¿Notario?</label>
                        </div> */}
                        <div className='col-12 md:col-8'>
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    className="p-inputtext-lg"
                                    placeholder="Marta Maria"
                                    value={`${props.selectedNotaryList ? props.selectedNotaryList.not_nom : ''} ${props.selectedNotaryList ? props.selectedNotaryList.not_mat : ''} ${props.selectedNotaryList ? props.selectedNotaryList.not_pat : ''}`}
                                    onChange={(e) => { props.setRequestName(e.target.value) }}
                                    disabled
                                />
                                <label htmlFor="username">Nombre del solicitante</label>
                            </span>
                        </div>
                    </div>
                </div>
                {props.exist &&
                    <div className='col-12 md:col-12'>
                        <h5>Documentación</h5>
                        <p>Agrega los documentos necesarios para tu tramite</p>
                        <div className='grid'>
                            {
                                props.preTramiteDetail && props.preTramiteDetail.docs &&
                                <div className='col-12 md:col-12'>
                                    <ConfirmPopup
                                        target={buttonEl.current}
                                        visible={visible}
                                        onHide={() => setVisible(false)}
                                        message="Are you sure you want to proceed?"
                                        icon="pi pi-exclamation-triangle"
                                        accept={accept}
                                    />
                                    <DataTable value={props.preTramiteDetail.docs}
                                        dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        emptyMessage="No products found." responsiveLayout="scroll">
                                        <Column field="code" body={IconDocumentTemplate} ></Column>
                                        <Column field="ptrmd_desc" header="Nombre del documento"></Column>
                                        <Column field="name" style={{ width: '10rem' }} body={EliminarTemplate} ></Column>
                                    </DataTable>
                                </div>
                            }
                        </div>

                    </div>
                }
            </div>
        </section>
    );

}

export default React.memo(ResulGeneralesView);