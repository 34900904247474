import { RequestService } from '../utilities/request.component';
import eventEmitter from '../actions/timerActions';

export default class ApiServices {
  
  static apiPost(url: any, data?: any) {
    eventEmitter.emit('resetTimer'); // Emitir evento para reiniciar el temporizador
    return RequestService.sendPostRequestNew(`processrequest/apipost/${url}`, data)
  }

  static apiPostFile(url: any, file: any, json: any) {
    eventEmitter.emit('resetTimer'); // Emitir evento para reiniciar el temporizador
    return RequestService.sendPostFileRequestNew(
      `processrequest/apipostfile/${url}`,
      file,
      json
    );
  }

  static apiPost2Files(url: any, file1: any, file2: any, json: any) {
    eventEmitter.emit('resetTimer'); // Emitir evento para reiniciar el temporizador
    return RequestService.sendPost2FilesRequestNew(
      `processrequest/${url}`,
      file1,
      file2,
      json
    );
  }

  static processRequestAdd(data: any) {
    eventEmitter.emit('resetTimer'); // Emitir evento para reiniciar el temporizador
    return RequestService.sendPostRequestNew(`processrequest/processrequest-add/`, { "palabra": data })
  }

}
