import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import React, { useEffect, useState } from 'react';
import ProductService from '../../../../serviceExample/ProductService';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

interface City {
    name: string;
}


const FDuplicidad = () => {

    const productService = new ProductService();
    const [selectedCity, setSelectedCity] = useState<City | null>(null);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [rowClick, setRowClick] = useState(true);


    const estatus: City[] = [
        { name: 'Cerrado' },
        { name: 'Duplicado' },
    ];

    const statusBodyTemplate = (product: Product) => {
        return <Tag value={product.inventoryStatus} severity={getSeverity(product)}></Tag>;
    };

    const getSeverity = (product: Product) => {
        switch (product.inventoryStatus) { //EN ESTATUS SOLO APLICA DUPLICADO Y CERRADO
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            default:
                return null;
        }
    };

    useEffect(() => {
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" />
            </div>
        );
    }

    return (
        <section className='container-fluid'>
            <div className='grid'>
                <div className='col-12 md:col-6'>
                    <h3>Duplicidad</h3>
                    <p>Aqui encontraras los folios duplicados y que se pidieron cerrar por duplicidad</p>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='grid'>
                        <div className='col-6'>
                            <span className="p-float-label">
                                <Dropdown inputId="dd-city" value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={estatus} optionLabel="name" className="p-inputtext-lg" virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }} />
                                <label htmlFor="dd-city">Estatus</label>
                            </span>
                        </div>
                        <div className='col-6'>
                            <span className="p-input-icon-right">
                                <i className="pi pi-search" />
                                <span className='p-float-label '>
                                    <InputText id="username" className="p-inputtext-lg" />
                                    <label htmlFor="username">Buscador</label>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <DataTable value={products} paginator rows={10}
                        onSelectionChange={(e) => {
                            const value = e.value as Product[];
                            setSelectedProducts(value);
                        }} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                        <Column sortable field="code" header="Folio electronico"></Column>
                        <Column sortable field="name" header="Clave catastral"></Column>
                        <Column sortable field="name" header="Estatus" body={statusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </section>
    );

}

export default React.memo(FDuplicidad);