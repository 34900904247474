import { Button } from 'primereact/button';
import { Checkbox } from "primereact/checkbox";
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';

const PreCaptura = (props: any) => {

    const requestTypes = [
        { name: 'Solicitud de inscripción de Propiedad', code: 'propiedad' },
        { name: 'Solicitud de inscripción Personas Morales', code: 'personas' },
        { name: 'Solicitud de trámite de certificaciones', code: 'certificaciones' }
    ];
    const [selectedRequestType, setSelectedRequestType] = useState<any>(requestTypes[0]);
    const requestTypesTemplate = (option: any) => {
        return (
            <div className='requestedTemplate' style={{ color: selectedRequestType && option.code == selectedRequestType.code ? 'white' : '#313131' }}> {option.name}</div >
        );
    };

    const SearchTypes = [
        { name: 'Notaría', code: 'notaria' },
        { name: 'Documento de juzgado', code: 'documento' },
        { name: 'Inmatriculación', code: 'inmatriculacion' },
        { name: 'Contrato privado', code: 'contrato' },
        { name: 'Título del RAN', code: 'titulo' }

    ];
    const [selectedSearchType, setSelectedSearchType] = useState<any>(SearchTypes[0]);
    const SearchTypesTemplate = (option: any) => {
        return (
            <div className='requestedTemplate' style={{ color: selectedSearchType && option.code == selectedSearchType.code ? 'white' : '#313131' }}> {option.name}</div >
        );
    };

    const SearchTypesPersonas = [
        { name: 'Notaría', code: 'notaria' },
        { name: 'Particular', code: 'particular' }

    ];
    const [selectedSearchTypePersonas, setSelectedSearchTypePersonas] = useState<any>(SearchTypes[0]);
    const SearchTypesTemplatePersonas = (option: any) => {
        return (
            <div className='requestedTemplate' style={{ color: selectedSearchTypePersonas && option.code == selectedSearchTypePersonas.code ? 'white' : '#313131' }}> {option.name}</div >
        );
    };


    return (
        <div className="flex flex-column divPreCaptura">

            <label className="titlePreCaptura">Nueva pre-captura</label>

            <label className='subtitlePreCaptura'>Ayúdanos a generar tu comprobante de pago y pre-captura para agilizar tu tramite.</label>

            <Divider />

            <label className='subtitlePreCaptura'>Selecciona el tipo de solicitud para iniciar con la captura.</label>

            <Dropdown
                value={selectedRequestType}
                onChange={(e) => setSelectedRequestType(e.value)}
                options={requestTypes}
                optionLabel="name"
                placeholder="Tipo de Solicitud"
                itemTemplate={requestTypesTemplate}
                panelStyle={{ background: '#FFF' }}
                className='dropDownRequestType'
                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
            />

            {
                selectedRequestType && selectedRequestType.code == 'propiedad' &&
                <>
                    <div className='divRequest'>
                        <label className='requestInnerTitle'>Estado de Morelos</label>
                        <label className='requestInnerTitle requestInnerTitleBold'>SOLICITUD DE INSCRIPCIÓN DE PROPIEDAD</label>
                        <label className='requestInnerTitle'>Dirección de registro Solicito llevar a cabo el trámite de inscripción de:</label>
                    </div>

                    <label className='labelImportantMessage'>
                        Esta solicitud deberá presentarse por duplicado con firmas autógrafas del notario público que autorizo o de quien tenga interés legítimo en el derecho que se va a inscribir, con fundamento en el Art. 38 de la ley de registro público de la propiedad y del comercio del Estado de Morelos.IMPORTANTE: El Instituto de Servicios Registrales y Catastrales del Estado De Morelos NO se hace responsable de los documentos que no sean recogidos en un lapso mayor a 3 MESES.
                    </label>

                    <label className='labelData'>Ingresa los siguientes datos para enviar la solicitud requerida.</label>
                    <div className='divGeneralDataPreCapture'>
                        <div className='divTwoInputs'>
                            <InputText placeholder="Folio real electronico" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                            <Dropdown
                                value={selectedSearchType}
                                onChange={(e) => setSelectedSearchType(e.value)}
                                options={SearchTypes}
                                optionLabel="name"
                                placeholder="Tipo de Solicitud"
                                itemTemplate={SearchTypesTemplate}
                                panelStyle={{ background: '#FFF' }}
                                className='dropDownSearchedType divSingleTwoInput'
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                        </div>
                    </div>

                    {selectedSearchType && selectedSearchType.code == 'notaria' &&
                        <>
                            <label className='subtitle'>Notaria</label>
                            <div className='divGeneralData'>
                                <div className='divTwoInputs'>
                                    <InputText placeholder="Número notaría *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                                    <InputText placeholder="Número escritura *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                                </div>
                            </div>
                        </>
                    }

                    {selectedSearchType && selectedSearchType.code == 'documento' &&
                        <>
                            <label className='subtitle'>Documento de juzgado</label>
                            <div className='divGeneralData'>
                                <div className='divTwoInputs'>
                                    <InputText placeholder="Oficio o expediente *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                                    <InputText placeholder="Distrito *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                                </div>
                            </div>
                        </>
                    }

                    {selectedSearchType && selectedSearchType.code == 'inmatriculacion' &&
                        <>
                            <label className='subtitle'>Inmatriculacion</label>
                            <div className='divGeneralData'>
                                <div className='divTwoInputs'>
                                    <InputText placeholder="Número de resolucion" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                                </div>
                            </div>
                        </>
                    }

                    {selectedSearchType && selectedSearchType.code == 'titulo' &&
                        <>
                            <label className='subtitle'>Título del RAN</label>
                            <div className='divGeneralData'>
                                <div className='divTwoInputs'>
                                    <InputText placeholder="Número" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                                </div>
                            </div>
                        </>
                    }


                    <label className='labelData'>Datos de la propiedad actual</label>
                    <div className='divGeneralDataPreCapture'>
                        <div className='divGeneralDataRowPreCapture'>
                            <div className='inputTwoThree'>
                                <InputText placeholder="Nombre del propietario actual *" className='generalDataInputPreCapture inputTwoThree' type="text" />
                            </div>
                            <InputText placeholder="Clave catastral *" className='generalDataInputPreCapture' type="text" />
                        </div>
                    </div>

                    <label className='subtitle'>Datos de registro</label>
                    <div className='divGeneralData'>
                        <div className='divGeneralDataRow'>
                            <InputText placeholder="Registro *" className='generalDataInput' type="text" />
                            <InputText placeholder="Foja" className='generalDataInput' type="text" />
                            <InputText placeholder="Libro/Tomo" className='generalDataInput' type="text" />
                        </div>
                        <div className='divGeneralDataRow'>
                            <InputText placeholder="Volumen *" className='generalDataInput' type="text" />
                            <InputText placeholder="Sección" className='generalDataInput' type="text" />
                            <InputText placeholder="Serie" className='generalDataInput' type="text" />
                        </div>
                        <div className='divTwoInputs'>
                            <InputText placeholder="Fecha de registro" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                        </div>
                    </div>

                    <label className='subtitle'>Datos del solicitante</label>
                    <div className='divGeneralData'>
                        <div className='divGeneralDataRow'>
                            <InputText placeholder="Nombre *" className='generalDataInput' type="text" />
                            <InputText placeholder="Primer Apellido *" className='generalDataInput' type="text" />
                            <InputText placeholder="Segundo Apellido *" className='generalDataInput' type="text" />
                        </div>
                        <div className='divTwoInputs'>
                            <InputText placeholder="Telefono *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                            <InputText placeholder="Correo Electronico *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                        </div>
                    </div>

                    <div className='divAccept'>
                        <Checkbox className='checkbox' checked={false}></Checkbox>
                        <p className='labelAccept'>Acepto que bajo protesta de decir verdad manifiesto que la información que proporciono, así como las pólizas de pago de derechos son autenticas.</p>
                    </div>
                </>
            }

            {
                selectedRequestType && selectedRequestType.code == 'personas' &&
                <>
                    <div className='divRequest'>
                        <label className='requestInnerTitle'>Estado de Morelos</label>
                        <label className='requestInnerTitle requestInnerTitleBold'>SOLICITUD DE INSCRIPCIÓN DE PERSONAS MORALES</label>
                        <label className='requestInnerTitle'>Dirección de registro Solicito llevar a cabo el trámite de inscripción de:</label>
                    </div>

                    <label className='labelImportantMessage'>
                        Esta solicitud deberá presentarse por duplicado con firmas autógrafas del notario público que autorizo o de quien tenga interés legítimo en el derecho que se va a inscribir, con fundamento en el Art. 38 de la ley de registro público de la propiedad y del comercio del Estado de Morelos.IMPORTANTE: El Instituto de Servicios Registrales y Catastrales del Estado De Morelos NO se hace responsable de los documentos que no sean recogidos en un lapso mayor a 3 MESES.
                    </label>

                    <label className='labelData'>Ingresa los siguientes datos para enviar la solicitud requerida.</label>
                    <div className='divGeneralDataPreCapture'>
                        <div className='divTwoInputs'>
                            <InputText placeholder="Folio real electronico" className='generalDataInputPreCapture divSingleFour' type="text" />
                            <div className='divSingleFour divAcceptFour'>
                                <Checkbox className='checkbox' checked={false}></Checkbox>
                                <p className='labelAccept'>Asociación civil</p>
                            </div>
                            <div className='divSingleFour divAcceptFour'>
                                <Checkbox className='checkbox' checked={false}></Checkbox>
                                <p className='labelAccept'>Sociedad civil</p>
                            </div>
                            <Dropdown
                                value={selectedSearchTypePersonas}
                                onChange={(e) => setSelectedSearchTypePersonas(e.value)}
                                options={SearchTypesPersonas}
                                optionLabel="name"
                                placeholder="Tipo de Solicitud"
                                itemTemplate={SearchTypesTemplatePersonas}
                                panelStyle={{ background: '#FFF' }}
                                className='dropDownSearchedType divSingleTwoInput divSingleFour'
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                        </div>
                    </div>
                    <InputText className='inputBig' placeholder='Nombre de la sociedad o asociación' type="text" />

                    <label className='subtitle'>Notaria</label>
                    <div className='divGeneralData'>
                        <div className='divTwoInputs'>
                            <InputText placeholder="Numero notaria *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                            <InputText placeholder="Numero Escritura *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                        </div>
                    </div>

                    <label className='subtitle'>Datos de registro</label>
                    <div className='divGeneralData'>
                        <div className='divGeneralDataRow'>
                            <InputText placeholder="Registro *" className='generalDataInput' type="text" />
                            <InputText placeholder="Foja" className='generalDataInput' type="text" />
                            <InputText placeholder="Libro/Tomo" className='generalDataInput' type="text" />
                        </div>
                        <div className='divGeneralDataRow'>
                            <InputText placeholder="Volumen *" className='generalDataInput' type="text" />
                            <InputText placeholder="Sección" className='generalDataInput' type="text" />
                            <InputText placeholder="Serie" className='generalDataInput' type="text" />
                        </div>
                        <div className='divTwoInputs'>
                            <InputText placeholder="Fecha de registro" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                        </div>
                    </div>

                    <label className='subtitle'>Datos del solicitante</label>
                    <div className='divGeneralData'>
                        <div className='divGeneralDataRow'>
                            <InputText placeholder="Nombre *" className='generalDataInput' type="text" />
                            <InputText placeholder="Primer Apellido *" className='generalDataInput' type="text" />
                            <InputText placeholder="Segundo Apellido *" className='generalDataInput' type="text" />
                        </div>
                        <div className='divTwoInputs'>
                            <InputText placeholder="Telefono *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                            <InputText placeholder="Correo Electronico *" className='generalDataInputPreCapture divSingleTwoInput' type="text" />
                        </div>
                    </div>

                    <div className='divAccept'>
                        <Checkbox className='checkbox' checked={false}></Checkbox>
                        <p className='labelAccept'>Acepto que bajo protesta de decir verdad manifiesto que la información que proporciono, así como las pólizas de pago de derechos son autenticas.</p>
                    </div>
                </>
            }

            <div className='divFooterPreCapture'>
                <div className='divInsideFooterPreCapture'>
                    <Button label='Continuar' className='footerButtonPreCapture' />
                </div>
            </div>
        </div >
    );

}

export default React.memo(PreCaptura);