import Axios from 'axios';
import configFile from '../configurationFile'

export class RequestService {

    static sendPostRequestNew(url: string, information: any) {
        const qs = require('qs');
        let data = qs.stringify(information);

        let config: object = {
            method: 'post',
            maxBodyLength: Infinity,
            url: configFile.url + url,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        return Axios.request(config);
    } // sendPostRequest

    static sendPostFileRequestNew(url: string, file: any, json: any) {
        let information = new FormData();
        information.append("file", file, file.name);
        information.append("objeto", JSON.stringify(json));

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: configFile.url + url,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: information,
        };

        return Axios.request(config);
    } // sendPostRequestFile

    static sendPost2FilesRequestNew(url: string, file1: any, file2: any, json: any) {
        let information = new FormData();
        information.append("file1", file1, file1.name);
        information.append("file2", file2, file2.name);
        information.append("dataEnc", JSON.stringify(json));

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: configFile.url + url,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: information,
        };

        return Axios.request(config);
    } // sendPostRequestFile

    static sendPostRequest(aEnviar: any, url: string, token?: string) {
        let json = JSON.stringify(aEnviar);
        let config = token ? { headers: { 'Content-Type': 'application/json ', 'Authorization': token } } : { headers: { 'Content-Type': 'application/json ' } };
        return Axios.post(configFile.url + url, json, config);
    } // sendPostRequest


    static sendGetRequest(url: string, token?: any) {
        let config = token ? { headers: { 'Content-Type': 'application/json ', 'Authorization': 'Bearer ' + token } } : { headers: { 'Content-Type': 'application/json ' } };
        return Axios.get(configFile.url + url, config);
    } // sendGetRequest

    /*     public sendFilePostRequest(aEnviar: any, url: string, file): Observable<any> {
            const formData: FormData = new FormData();
            formData.append('file', file); 
            formData.append('objeto', JSON.stringify(aEnviar));
            //let json = JSON.stringify(aEnviar);
            let headers = new HttpHeaders();
            headers.set('Content-Type', null);
            headers.set('Accept', "multipart/form-data");
            return this._http.post(this.url + url, formData, { headers: headers });
        } // sendPostRequest
    
        public sendGetRequestShopify( url: string , token?: string): Observable<any> {
            let headers = token ? new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': ': ' + token + '' }) : 
                                new HttpHeaders({ 'Content-Type': 'application/json', 
                                                    'Access-Control-Allow-Origin': '*', 
                                                    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                                                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token' }) 
            return this._http.get<RespuestaDto>(url, { headers: headers });
        } // sendGetRequest */
}