// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import ProductService from '../../../../serviceExample/ProductService';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { useLocation, useNavigate } from 'react-router-dom';
import EncryptService from '../../../../service/encrypt.service';
import ApiServices from '../../../../service/apiServices';

interface City {
    name: string;
}

const DupFolio = () => {

    const location = useLocation();

    const [folios, setFolios] = useState(location.state.folios)
    const [buttonsActive, setButtonsActive] = useState(false)



    const rowHeadersProps: rowHeaderInterface = {
        "": "usu_status",
        "": "chk",
        "Folio": "prop_folio",
        "Clave Catastral": "prop_clave",
        "Dueño": "proptitutales",
        "Domicilio Catastral": "prop_direccion",
        "C.P": "cp_cp",
        "Localidad": "cp_loc",
        "Municipio": "cp_mun",
        "Estado": "cp_edo",
        "Cruzamiento 1 y 2": "prop_cruzamientos",
        "Colonia": "cp_col",
        "N° Interior": "prop_int",
        "Calle": "prop_calle",
        "Medidas": "prop_medidas",
        "Construida": "prop_constr",
        "Superficie Total": "prop_suptotal"
    };

    const rowHeaders = Object.keys(rowHeadersProps).filter((f) => f !== "Carb");


    const columnKeys = Object.keys(folios[0]).filter((f) => f !== "Carb");

    function buscarPorId(array: any, trmId: any) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].trm_id === trmId) {
                return i;
            }
        }
        return null;
    }

    function existeSeleccionado(array: any) {
        const objetoSeleccionado = array.find((item: any) => item.selected === true);
        return objetoSeleccionado !== undefined;
    }

    const changeSelected = (data: any) => {
        const copyArray: any = JSON.parse(JSON.stringify(folios))
        const index: any = buscarPorId(copyArray, data.trm_id)
        copyArray[index].selected = copyArray[index].selected ? !(copyArray[index].selected) : true
        setFolios(copyArray)
        setButtonsActive(existeSeleccionado(copyArray))
    }

    function obtenerPropIdsSeleccionados(array: any) {
        const objetosSeleccionados = array.filter((item: any) => item.selected === true);
        const propIdsSeleccionados = objetosSeleccionados.map((item: any) => item.prop_id);
        return propIdsSeleccionados;
    }

    function agruparPorTrmId(data) {
        const resultadosAgrupados = {};
        data.forEach((elemento) => {
            const { prop_id, historial } = elemento;
            if (!resultadosAgrupados[prop_id]) {
                resultadosAgrupados[prop_id] = [];
            }
            historial.forEach((registro) => {
                const { trm_id } = registro;
                const registroExistente = resultadosAgrupados[prop_id].find(
                    (r) => r.trm_id === trm_id
                );
                if (!registroExistente) {
                    const nuevoRegistro = {
                        trm_fecha: registro.trm_fecha,
                        trm_id: trm_id,
                        actos: [registro]
                    };
                    resultadosAgrupados[prop_id].push(nuevoRegistro);
                } else {
                    registroExistente.actos.push(registro);
                }
            });
        });
        const resultadoFinal = Object.entries(resultadosAgrupados).flatMap(
            ([prop_id, registros]) =>
                registros.map((registro) => ({ prop_id, ...registro }))
        );
        return resultadoFinal;
    }

    const handleRequestActos = () => {
        let datos
        datos = { "code": 502, prop_id: obtenerPropIdsSeleccionados(folios) }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            const datosFormatted = agruparPorTrmId(res.data.historiales)
            navigate('/interno/com-actos', {
                state: {
                    actos: datosFormatted
                }
            })
        }).catch(error => {
            console.log(error)
        })
    }




    const productService = new ProductService();
    const [products, setProducts] = useState([]);
    const [checked, setChecked] = useState<any>(false);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [selectedCity, setSelectedCity] = useState<City | null>(null);
    const [selectedCities, setSelectedCities] = useState(null);
    const navigate = useNavigate();

    const motivo: City[] = [
        { name: 'Duplicidad de folio' },
        { name: 'Folio conflictivo' },
    ];

    const foliosSelect: City[] = [
        { name: 'folio 1' },
        { name: 'folio 2' },
    ];

    const areas: City[] = [
        { name: 'Certificaciones' },
        { name: 'Juridico' },
        { name: 'Registro' },
    ];

    useEffect(() => {
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => ModalSetVisible(false)} severity="secondary" outlined />
            <Button label="Guardar" onClick={() => { { ModalSetVisible(false) } }} autoFocus />
        </div>
    );

    const checkTemplateBody = (text: any, header: string) => {
        return (header == "Folio") ? (
            <div>
                <a style={{ textDecoration: 'underline', cursor: 'pointer' }}>{text}</a>
            </div>
        ) : (
            <div>
                {text}
            </div>
        );
    };

    const checkTemplate = (product: any, generalData: any) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Checkbox inputId={product} onChange={e => {
                    let checkedEx = [...checkeds];
                    if (!checkedEx.includes(product))
                        checkedEx.push(product)
                    else
                        checkedEx = checkedEx.filter((c: any) => c !== product);
                    setChecked(checkedEx)
                }} checked={checkeds.includes(product)} />
                {generalData && generalData.prop_status == 2 && <p>Folio pendiente de validación</p>}
            </div>
        );
    };

    return (
        <section className='container-fluid'>
            <div className='col-12 md:col-12 pb-5'>
                <h3 className='color-violet'>Búsqueda de coincidencias</h3>
                <p>Se encontraros los resultados con los siguientes datos.</p>
            </div>
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText id="username" className="p-inputtext-lg" value={folios[0].prop_folio} />
                        <label htmlFor="username">Folio real</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText id="username" className="p-inputtext-lg" value={folios[0].prop_clave} />
                        <label htmlFor="username">Clave catastral</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText id="username" className="p-inputtext-lg" value={folios[0].proptitutales} />
                        <label htmlFor="username">Nombre del propietario</label>
                    </span>
                </div>
            </div>
            <div className='col-12 md:col-12 py-5'>
                <h3 className='color-violet'>Resultados</h3>
                <div className='flex justify-content-between'>
                    <div>
                        <p>Selecciona los folios para comparar las precodificadas, posteriormente selecciona los folios para generar una o varias observaciones o alertas.</p>
                    </div>
                    <div>
                        <p className='azul'><i className="pi pi-circle-fill"></i> Coincidencias encontradas</p>
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <table>
                        <tbody>
                            {rowHeaders.map((header, index) => (
                                <tr key={header} >
                                    <th style={{
                                        display: 'flex',
                                        padding: '10px',
                                        alignItems: 'center',
                                        marginRight: '50px',
                                        background: '#EEE'
                                    }}>{header}</th>
                                    {folios && folios.map((individualFolio: any) => {
                                        return (
                                            index > 0 ? (
                                                <td
                                                    key={individualFolio.id}
                                                    style={{
                                                        padding: '10px',
                                                        alignItems: 'center',
                                                        marginRight: '50px',
                                                        background: '#EEE'
                                                    }}
                                                >{checkTemplateBody(individualFolio[rowHeadersProps[header]], header)}</td>
                                            ) : (
                                                <td style={{
                                                    padding: '10px',
                                                    alignItems: 'right',
                                                    justifyContent: 'center',
                                                    marginRight: '50px',
                                                    background: '#EEE'
                                                }}><Checkbox onChange={() => changeSelected(individualFolio)} checked={individualFolio.selected}></Checkbox>                                                </td>
                                            )
                                        )
                                    }
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='footer-folio flex justify-content-end'>
                <div className='flex col-6'>
                    <Button
                        label="Generar una observación ó alerta"
                        className='p-button-outlined mr-4'
                        onClick={() => { { ModalSetVisible(true) } }}
                        disabled={!buttonsActive}
                    />
                    <Button
                        label="Comparar actos"
                        onClick={handleRequestActos}
                        disabled={!buttonsActive}
                    />
                </div>
            </div>

            {/*inicia dialog para generar una observacion o alerta*/}
            <Dialog header="Genera una observación ó alerta" visible={modalVisible} style={{ width: '50vw' }} onHide={() => ModalSetVisible(false)} footer={footerContent}>
                <p>Selecciona los resultados a los cuales deseas colocarle una alerta</p>

                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown inputId="dd-city" value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={motivo} optionLabel="name" className="w-100 p-inputtext-lg" virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }} />
                            <label htmlFor="dd-city">Motivo</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <MultiSelect value={selectedCities} onChange={(e) => setSelectedCities(e.value)} options={foliosSelect} optionLabel="name"
                                maxSelectedLabels={3} className="w-100 p-inputtext-lg" />
                            <label htmlFor="dd-city">Folios a cerrar</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown inputId="dd-city" value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={areas} optionLabel="name" className="w-100 p-inputtext-lg" virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }} />
                            <label htmlFor="dd-city">Area</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea id="username" rows={5} cols={30} />
                            <label htmlFor="username">Nota</label>
                        </span>
                    </div>
                </div>

            </Dialog>

        </section>

    );

}

export default React.memo(DupFolio);