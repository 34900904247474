import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import { Toast } from 'primereact/toast';

const BuscaFolio = () => {

    const navigate = useNavigate();
    const toast = useRef<any>(null);

    const [realFolio, setRealFolio] = useState('')
    const [claveCatastral, setClaveCatastral] = useState('')
    const [name, setName] = useState('')
    const [notFound, setNotFound] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)

    const valueExist = () => {
        return (
            (claveCatastral && claveCatastral.length > 0) ||
            (realFolio && realFolio.length > 0) ||
            (name && name.length > 0) ||
            false
        )
    }

    const searchFolio = () => {
        if (valueExist()) {
            let datos
            datos = {
                code: 208,
                prop_clave: claveCatastral,
                prop_folio: realFolio,
                ptit_nom: name,
                ptit_pat: '',
                ptit_mat: '',
                prop_direccion: '',
                prop_calle: '',
                prop_ext: '',
                prop_int: ''
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    if (res.data.addenda && res.data.addenda.length > 0) {
                        navigate('/interno/dup-folio', {
                            state: {
                                folios: res.data.addenda
                            }
                        })
                    } else {
                        setNotFound(true)
                    }
                } else {
                    setNotFound(true)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            setButtonDisabled(true)
        }
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='col-12 md:col-12 pb-5'>
                <h3 className='color-violet'>Buscar folio</h3>
                <p>Agrega los campos necesarios para buscar folio.</p>
            </div>

            {notFound &&
                <div className='col-12 md:col-12 pb-5'>
                    <div className="grid grid-nogutter" style={{ background: '#F4CBB4', borderRadius: 10 }}>
                        <div className="col">
                        </div>
                        <div className="col-10" style={{ display: 'flex' }}>
                            <div style={{ width: '1%', height: '100%', background: '#D34C00', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }} />
                            <div style={{ width: '85%', height: '8rem', marginLeft: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <span style={{ color: '#D34C00', fontSize: 24, fontFamily: 'Cabin', fontWeight: '700', wordWrap: 'break-word' }}>
                                    Datos no encontrados
                                </span>
                                <span style={{ color: '#5B5B5F', fontSize: 16, fontFamily: 'Cabin', fontWeight: '400', wordWrap: 'break-word' }}>
                                    No se encontraron coincidencias en el sistema. rectifica si existe en otro lugar dentro del instituto.
                                </span>
                            </div>
                        </div>
                        <div className="col-2">
                            <div style={{ width: '90%', height: '8rem', justifyContent: 'flex-end', alignItems: 'center', display: 'flex', marginRight: '1rem' }}>


                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className='flex justify-content-center align-items-center'>
                <div className='col-12 md:col-4'>
                    <div className='grid'>
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    className="p-inputtext-lg"
                                    value={realFolio}
                                    onChange={(e) => {
                                        setRealFolio(e.target.value)
                                        setButtonDisabled(false)
                                    }}
                                />
                                <label htmlFor="username">Folio real</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    className="p-inputtext-lg"
                                    value={claveCatastral}
                                    onChange={(e) => {
                                        setClaveCatastral(e.target.value)
                                        setButtonDisabled(false)
                                    }}
                                />
                                <label htmlFor="username">Clave catastral</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    className="p-inputtext-lg"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setButtonDisabled(false)
                                    }}
                                />
                                <label htmlFor="username">Nombre del propietario</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-12 pb-5'>
                            <Button
                                label="Buscar"
                                disabled={buttonDisabled}
                                onClick={() => {
                                    searchFolio()
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </section>

    );

}

export default React.memo(BuscaFolio);