import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../serviceExample/ProductService';
import { InputText } from 'primereact/inputtext';
import authGuardService from '../../../../service/authGuardService';
import EncryptService from '../../../../service/encrypt.service';
import ApiServices from '../../../../service/apiServices';
import { useNavigate } from 'react-router-dom';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

const TraRecibidos = (props: any) => {
    const navigate = useNavigate();

    const dt = useRef<any>(null);
    const productService = new ProductService();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);

    const user = authGuardService.getUser()

    useEffect(() => {
        getList()
    }, []);

    const getList = () => {
        let datos
        datos = {
            code: 305,
            usu_id: user.usu_id,
            status: 1
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            setProducts(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const statusBodyTemplate = (tramite: any) => {
        const statusType = getStatusType(tramite.ptrm_status)
        return <Tag value={statusType} severity={getSeverity(tramite.ptrm_status)}></Tag>;
    };

    const getStatusType = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'Eliminado';
            case 1:
                return 'Borrador';
            case 2:
                return 'En Revision';
            case 3:
                return 'Rechazado';
            case 4:
                return 'Subsanado';
            case 5:
                return 'Aprobado';
            default:
                return null;
        }
    };

    const getSeverity = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'info'
            case 2:
                return 'warning';
            case 3:
                return 'danger';
            case 4:
                return 'warning';
            case 5:
                return 'success';
            default:
                return null;
        }
    };

    const analistNameTemplate = (rowData: any) => {
        const nombre = (rowData && rowData.worker && rowData.worker.usu_nom) ? rowData.worker.usu_nom : ''
        const apellido = (rowData && rowData.worker && rowData.worker.usu_pat) ? rowData.worker.usu_pat : ''
        const name = `${nombre} ${apellido}`
        return (
            <div className="actions">
                <p>{name}</p>
            </div>
        );
    }

    const navigateToScreen = (data: any) => {
        const navigateOptions = {
            state: {
                preTramite: data
            }
        };

        switch (data.ptrm_status) {
            case 1:
                navigate('/notarios/pago-folio', navigateOptions)
                break;
            case 2:
                navigate('/notarios/pago-folio-view', navigateOptions)
                break;
            case 3:
                navigate('/notarios/pago-folio', navigateOptions)
                break;
            case 4:
                navigate('/notarios/pago-folio', navigateOptions)
                break;
            case 5:
                navigate('/notarios/pago-folio-view', navigateOptions)
                break;
            default:
                break;
        }
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => navigateToScreen(rowData)} />
            </div>
        );
    }


    const header = (
        <div className='flex justify-content-end'>
            <div className='col-12 md:col-4'>
                <span className="p-float-label p-input-icon-left w-100">
                    <i className="pi pi-search" />
                    <InputText className=' p-inputtext-lg' type="search" onChange={(e) => { searchActos(e.target.value) }} />
                    <label htmlFor="username">Buscador general</label>
                </span>
            </div>
        </div>
    );

    function buscarCoincidencias(texto: any, array: any) {
        const textoMinusculas = texto.toLowerCase();
        const coincidencias = array.filter((objeto: any) => {
            for (const propiedad in objeto) {
                if (objeto[propiedad] && objeto[propiedad].toString().toLowerCase().includes(textoMinusculas)) {
                    return true;
                }
            }
            return false;
        });

        return coincidencias;
    }

    const [tramites, setTramites] = useState(props.tramites)

    const searchActos = (e: any) => {
        const clone = JSON.parse(JSON.stringify(props.tramites))
        setTramites(buscarCoincidencias(e, clone))
    }

    return (
        <section>
            <DataTable
                value={tramites}
                dataKey="ptrm_id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} pre-trámites"
                globalFilter={globalFilter}
                emptyMessage="No products found."
                header={header}
                loading={props.allPreTramitesLoading}
            >
                <Column sortable field="ptrm_consecutivo" header="Control Interno Notaria"></Column>
                <Column sortable field="prop_folio" header="Folio electrónico"></Column>
                <Column sortable field="prop_clave" header="Clave catastral"></Column>
                <Column sortable field="name" header="Analista" body={analistNameTemplate}></Column>
                <Column sortable field="actos" header="Actos"></Column>
                <Column sortable field="ptrm_status" header="Estatus" body={statusBodyTemplate}></Column>
                <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
            </DataTable>
        </section>
    );

}

export default React.memo(TraRecibidos);