import React from "react";
import './tab.css'

const Tab = props => {
    if (props.children) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        )
    } else {
        return (<></>)
    }
}

export default Tab;