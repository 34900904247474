import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import validateEmail from '../../../utilities/emailValidation';


const NotAccesos = () => {

    const toast = useRef<Toast>(null);
    const [selectedCity, setSelectedCity] = useState<any>();
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleedit, setVisibleedit] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);
    const [Nusuarios, setNusuarios] = useState([]);
    const [Nperfiles, setNperfiles] = useState([]);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [SelectedNusuario, setSelectedNusuario] = useState<any>({
        not_id: { value: '', valid: true },
        usu_nom: { value: '', valid: true },
        usu_pat: { value: '', valid: true },
        usu_mat: { value: '', valid: true },
        prf_id: { value: '', valid: true },
        usu_activo: { value: '', valid: true },
        usu_email: { value: '', valid: true }
    });

    /* useEffect(() => {
        validateFields()
    }, [SelectedNusuario]) */

    useEffect(() => {
        getNusuarios()
        getNperfiles()
    }, []);

    const showSuccess = () => {
        toast.current?.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se han guardado los cambios exitosamente', life: 3000 });
    }

    const footerContent = (
        <div className='flex justify-content-between'>
            <div className='pr-5'>
                <Checkbox onChange={e => setChecked(!checked)} checked={checked} />
                <label className="ml-2">Activar usuario</label>
            </div>
            <div>
                <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
                <Button label="Guardar" onClick={() => { saveNusuario() }} autoFocus />
            </div>
        </div>
    );

    const footerContentEdit = (
        <div className='flex justify-content-between'>
            <div className='pr-5'>
                <Checkbox onChange={e => setChecked(!checked)} checked={checked} />
                <label htmlFor="ingredient1" className="ml-2">{`${checked ? 'Desactivar' : 'Activar'} Usuario`}</label>
            </div>
            <div>
                <Button label="Cancelar" onClick={() => setVisibleedit(false)} severity="secondary" outlined />
                <Button label="Actualizar" onClick={() => { setVisibleedit(false); updateNusuario() }} autoFocus />
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" rounded text severity="secondary" onClick={() => HandleActoDetail(rowData)} />
            </div>
        );
    }

    function obtenerJsonPorCodigo(array: any, codigo: any, searchType: any = 'code') {
        if (codigo) {
            for (var i = 0; i < array.length; i++) {
                const tempArray = array[i]
                if (tempArray[searchType] === codigo) {
                    return array[i];
                }
            }
            return null;
        } else {
            return null
        }
    }

    const HandleActoDetail = (rowData: any) => {
        setVisibleedit(true)
        setSelectedNusuario(rowData)
        setChecked(rowData.usu_activo === 1)
        setSelectedCity(obtenerJsonPorCodigo(Nperfiles, rowData.prf_id, 'prf_id'))
    }

    const TipoBodyTemplate = (rowData: any) => {
        return <p>{getTipo(rowData)}</p>;
    }

    const getTipo = (usuario: any) => {
        switch (usuario.prf_id) { //EN ESTATUS SOLO APLICA  ACTIVADO, DESACTIVADO
            case 9:
                return 'Analista';

            case 10:
                return 'Buscador';

            default:
                return null;
        }
    };

    const StatusBodyTemplate = (rowData: any) => {
        return <p>{getStatus(rowData)}</p>;
    }

    const getStatus = (usuario: any) => {
        switch (usuario.usu_activo) { //EN ESTATUS SOLO APLICA  ACTIVADO, DESACTIVADO
            case 1:
                return 'Activado';

            case 0:
                return 'Desactivado';

            default:
                return null;
        }
    };

    const handleActData = (value: any, jsonId: any) => {
        const temporalData = JSON.parse(JSON.stringify(SelectedNusuario));
        if (temporalData[jsonId].value)
            temporalData[jsonId].value = value;
        else
            temporalData[jsonId] = value
        /* if (temporalData[jsonId].valid)
            temporalData[jsonId].valid = value && value.length > 1 */
        setSelectedNusuario(temporalData);
    };

    const handleActDataNew = (value: any, jsonId: any) => {
        const temporalData = JSON.parse(JSON.stringify(SelectedNusuario));
        if (temporalData[jsonId].value)
            temporalData[jsonId].value = value;
        else
            temporalData[jsonId] = { value: value, valid: true }
        /* if (temporalData[jsonId].valid)
            temporalData[jsonId].valid = value && value.length > 1 */
        setSelectedNusuario(temporalData);
    };

    const getNusuarios = () => {
        const usu = authGuardService.getUser()
        let datos = { "not_id": usu.not_id }
        ApiServices.apiPost('getUsuarioNotario', datos).then(async resp => {
            setNusuarios(resp.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getNperfiles = () => {
        let datos
        ApiServices.apiPost('getPerfilNotario', datos).then(async resp => {
            setNperfiles(resp.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const validateFields = () => {
        const newUser = JSON.parse(JSON.stringify(SelectedNusuario))
        for (let clave in newUser) {
            if (newUser.hasOwnProperty(clave)) {
                if (clave === 'usu_email') {
                    const isEmailValid = validateEmail(newUser[clave].value)
                    newUser[clave].valid = isEmailValid
                } else {
                    newUser[clave].valid = newUser[clave].value.length > 1
                }
            }
        }

        setSelectedNusuario(newUser)

        return (
            newUser.usu_nom.valid &&
            newUser.usu_pat.valid &&
            newUser.usu_mat.valid &&
            newUser.usu_email.valid &&
            selectedCity && selectedCity.prf_id
        )
    }

    const saveNusuario = async () => {
        const usu = authGuardService.getUser()
        const isEmailValid = validateEmail(SelectedNusuario.usu_email.value)

        if (usu.not_id && SelectedNusuario.usu_nom && selectedCity && SelectedNusuario.usu_email && isEmailValid) {
            let datos: any = {
                "code": 301,
                "not_id": usu.not_id,
                "usu_nom": SelectedNusuario.usu_nom.value,
                "usu_pat": SelectedNusuario.usu_pat.value,
                "usu_mat": SelectedNusuario.usu_mat.value,
                "prf_id": selectedCity.prf_id,
                "usu_activo": 1,
                "usu_email": SelectedNusuario.usu_email.value
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            const res = await ApiServices.processRequestAdd(datos);
            const { valido } = res.data;
            if (valido === 0) {
            } else {
                setVisible(false)
                getNusuarios();
                showSuccess();
                setSelectedNusuario({
                    not_id: { value: '', valid: true },
                    usu_nom: { value: '', valid: true },
                    usu_pat: { value: '', valid: true },
                    usu_mat: { value: '', valid: true },
                    prf_id: { value: '', valid: true },
                    usu_activo: { value: '', valid: true },
                    usu_email: { value: '', valid: true }
                })
                setSelectedCity(null)
            }
        } else {
            toast.current?.show({ severity: 'error', summary: 'Datos Incompletos', detail: 'Revisa todos los datos ingresados', life: 3000 });
        }
    }

    const updateNusuario = async () => {
        let datosStatus
        datosStatus = {
            "code": 107,
            "usu_id": SelectedNusuario.usu_id,
            "usu_activo": checked ? 1 : 0
        }
        datosStatus = EncryptService.encryptData(JSON.stringify(datosStatus));
        ApiServices.processRequestAdd(datosStatus).then(async res => {
            getNusuarios()
        }).catch(error => {
            console.log(error)
        })

        let datos = {
            "usu_id": SelectedNusuario.usu_id,
            "usu_activo": checked ? 1 : 0,
            "prf_id": selectedCity.prf_id,
            "usu_nom": SelectedNusuario.usu_nom,
            "usu_pat": SelectedNusuario.usu_pat,
            "usu_mat": SelectedNusuario.usu_mat,
        }

        ApiServices.apiPost('updateUsuarioNotario', datos).then(async resp => {
            showSuccess();
            setNusuarios(resp.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const header = (
        <div className="grid">
            <div className='col-12 md:col-8'>
            </div>
            <div className='col-12 md:col-4'>
                <span className="p-float-label p-input-icon-left w-100">
                    <i className="pi pi-search" />
                    <InputText
                        className='p-inputtext-lg'
                        type="search"
                        onChange={(e) => {
                            setGlobalFilter(e.target.value)
                        }}
                    />
                    <label htmlFor="username">Buscador general</label>
                </span>
            </div>
        </div>
    );

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-10'>
                    <h3>Usuarios</h3>
                    <p>Genera los perfiles necesarios.</p>
                </div>
                <div className='col-12 md:col-2'><Button label="Nuevo usuario" size="large" onClick={() => setVisible(true)} /></div>
                <div className='col-12'>
                    <DataTable
                        value={Nusuarios}
                        paginator
                        rows={10}
                        dataKey="id"
                        tableStyle={{ minWidth: '50rem' }}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron tramites."
                        header={header}
                    >
                        <Column sortable field="usu_email" header="Usuario"></Column>
                        <Column sortable field='prf_id' body={TipoBodyTemplate} header="Tipo"></Column>
                        <Column sortable field="usu_activo" header="Estatus" body={StatusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog header="Usuario" visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <p>Se generará una contraseña genérica para que inicie sesión y pueda hacer el cambio</p>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="usu_nom"
                                value={SelectedNusuario.usu_nom.value}
                                onChange={(e) => { handleActDataNew(e.target.value, e.target.id) }}
                                className={`p-inputtext-lg ${!SelectedNusuario.usu_nom.valid && 'p-invalid'}`}
                            />
                            <label htmlFor="usu_nom">Nombre</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="usu_pat"
                                value={SelectedNusuario.usu_pat.value}
                                onChange={(e) => { handleActDataNew(e.target.value, e.target.id) }}
                                className={`p-inputtext-lg ${!SelectedNusuario.usu_pat.valid && 'p-invalid'}`}
                            />
                            <label htmlFor="usu_pat">Primer apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="usu_mat"
                                value={SelectedNusuario.usu_mat.value}
                                onChange={(e) => { handleActDataNew(e.target.value, e.target.id) }}
                                className={`p-inputtext-lg ${!SelectedNusuario.usu_mat.valid && 'p-invalid'}`}
                            />
                            <label htmlFor="usu_mat">Segundo apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-8'>
                        <span className="p-float-label">
                            <InputText
                                id="usu_email"
                                value={SelectedNusuario.usu_email.value}
                                onChange={(e) => { handleActDataNew(e.target.value, e.target.id) }}
                                className={`p-inputtext-lg ${!SelectedNusuario.usu_email.valid && 'p-invalid'}`}
                            />
                            <label htmlFor="usu_email">Correo electrónico</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-puesto"
                                showClear={true}
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.value)}
                                options={Nperfiles}
                                optionLabel="prf_desc"
                                className={`p-inputtext-lg w-100 ${!selectedCity && 'p-invalid'}`}
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-puesto">Puesto a realizar</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-8'>
                        {
                            selectedCity && selectedCity.prf_id == 9 &&
                            <p>Analista: realiza trámites</p>
                        }
                        {
                            selectedCity && selectedCity.prf_id == 10 &&
                            <p>Visualizador: realiza solo búsquedas</p>
                        }
                    </div>
                </div>
            </Dialog>

            {/* setVisibleedit */}
            <Dialog header="Usuario" visible={visibleedit} maximizable style={{ width: '50vw' }} onHide={() => setVisibleedit(false)} footer={footerContentEdit}>
                <p>Se generará una contraseña genérica para que inicie sesión y pueda hacer el cambio</p>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText id="usu_nom" value={SelectedNusuario.usu_nom} onChange={(e) => { handleActData(e.target.value, e.target.id) }} className="p-inputtext-lg" />
                            <label htmlFor="usu_nom">Nombre</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText id="usu_pat" value={SelectedNusuario.usu_pat} onChange={(e) => { handleActData(e.target.value, e.target.id) }} className="p-inputtext-lg" />
                            <label htmlFor="usu_pat">Primer apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText id="usu_mat" value={SelectedNusuario.usu_mat} onChange={(e) => { handleActData(e.target.value, e.target.id) }} className="p-inputtext-lg" />
                            <label htmlFor="usu_mat">Segundo apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-8'>
                        <span className="p-float-label">
                            <InputText id="usu_email" value={SelectedNusuario.usu_email} onChange={(e) => { handleActData(e.target.value, e.target.id) }} className="p-inputtext-lg" />
                            <label htmlFor="usu_email">Correo electrónico</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown inputId="dd-puesto" showClear={true} value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={Nperfiles} optionLabel="prf_desc" className="p-inputtext-lg w-100" virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }} />
                            <label htmlFor="dd-puesto">Puesto a realizar</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-8'>
                        {
                            selectedCity && selectedCity.prf_id === 10 &&
                            <p>Visualizador: Realiza solo búsquedas</p>
                        }
                        {
                            selectedCity && selectedCity.prf_id === 9 &&
                            <p>Analista: Realiza trámites</p>
                        }
                    </div>
                </div>
            </Dialog>
        </section >
    );
}

export default React.memo(NotAccesos);