const configuration = {
  theme: 'light', // light | dark
  url: 'https://morelos.mob-tk.com/api/',
  menu: 'horizontal', // horizontal | sidebar | slim | overlay
  landing: true // true | false
}

export default configuration

//url: 'http://localhost:5014/', 
// http://sire.morelos.gob.mx:4443/api/
// url: 'https://morelos.mob-tk.com/api/',
