import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import authGuardService from '../../../../service/authGuardService';
import EncryptService from '../../../../service/encrypt.service';
import DynamicForm from '../../../actos/DynamicForm';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import { TreeSelect } from 'primereact/treeselect';
import { ConfirmPopup } from 'primereact/confirmpopup';

interface City {
    name: string;
}

const ResulActos = (props: any) => {
    const user = authGuardService.getUser()

    const previousPayments = props.previousPayments
    /* const previousFolio = props.previousFolio[0] */
    const responseData = props.responseData
    const preTramite = props.preTramite

    const [selectedCity, setSelectedCity] = useState<City | null>(null);
    const dt = useRef<any>(null);
    const [products, setProducts] = useState<any>(null);
    const [pagos, setPagos] = useState<any>(null);
    const menu = useRef<any>(null);
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        //   const productService = new ProductService();
        //   productService.getProducts().then(data => setProducts(data));
        setProducts([
            {
                "id": "1",
                "code": "I33",
                "name": "Segundo aviso preventivo",
                "price": 337,
            },
            {
                "id": "2",
                "code": "I39",
                "name": "Certificado de libertad o existencia de gravámenes con efectos de aviso preventivo",
                "price": 500,
            },
        ])

        setPagos([
            {
                "id": "1",
                "pago": "G: 30759042",
                "date": "21 Enero 2022",
                "monto": 337,
            },
            {
                "id": "2",
                "pago": "G: 30759042",
                "date": "21 Enero 2022",
                "monto": 337,
            },
        ])
    }, []);

    const montoBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Monto de derechos</span>
                ${rowData.pago_monto}.°°
            </>
        );
    }

    const buttonConfirm2 = useRef<any>(null);
    const [confirmButton, setConfirmButton] = useState(false)

    const deletePaymentTemplate = (rowData: any) => {
        return (
            < div className='flex'>
                <ConfirmPopup
                    target={buttonConfirm2.current}
                    visible={confirmButton}
                    onHide={() => setConfirmButton(false)}
                    message="¿Estas seguro de eliminar este pago ?"
                    icon="pi pi-exclamation-triangle"
                    accept={() => { deletePayment(rowData) }}
                    reject={() => setConfirmButton(false)}
                    acceptLabel={'Si'}
                    rejectLabel={'No'}
                    acceptClassName={'w-3'}
                />
                <Button ref={buttonConfirm2}
                    icon="pi pi-trash" tooltip="Eliminar pago" className="p-button-rounded p-button-text p-button-danger" onClick={() => setConfirmButton(true)} />
            </div >
        );
    }

    const deletePayment = async (payment: any) => {
        if (previousPayments && previousPayments.length > 0) {
            props.eliminarPagoPorId(payment.pago_id)
        } else {
            let datos = {
                trm_id: preTramite ? preTramite.trm_id : responseData && responseData.trm_id,
                pago_id: payment.pago_id,
                usu_id: user.usu_id
            }
            await ApiServices.apiPost('deletePago', datos).then(async res => {
                window.location.reload();
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const totalTitularesBodyTemplate = (rowData: any) => {
        const titulares = rowData.proptitutales ? rowData.proptitutales.split(',') : '0'
        return (
            <p>{titulares ? titulares.length : 0}</p>
        )
    }

    const titularesBodyTemplate = (rowData: any) => {
        const titulares = rowData.proptitutales ? rowData.proptitutales : rowData.trm_solicitante
        return (
            <p>{titulares ? titulares : ''}</p>
        )
    }


    const [selectedTitular, setSelectedTitular] = useState<any>()
    const [selectedTitulares, setSelectedTitulares] = useState()

    const callSelectedTitular = (rowData: any) => {
        setSelectedTitular(rowData)
        setModalTitulares(true)
        getTitulares(rowData.prop_id)
    }

    const getTitulares = (propId: any) => {
        let datos = {
            prop_id: propId
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setSelectedTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }


    const iconsTitularesBodyTemplate = (rowData: any, index: any) => {
        return (
            <div style={{ justifyContent: 'space-around', display: 'flex' }}>
                <i className="pi pi-eye" style={{ fontSize: '1.2rem' }} onClick={() => { callSelectedTitular(rowData) }}
                ></i>
                <i className="pi pi-trash" style={{ fontSize: '1.2rem' }} onClick={() => { deleteRow(index.rowIndex) }}></i>
            </div>
        )
    }

    function eliminarElementoPorIndice(index: any) {
        if (index > -1 && index < props.totalFolios.length) {
            const cloneFolios = JSON.parse(JSON.stringify(props.totalFolios))
            const deleted = delete cloneFolios[index]
            return limpiarArray(cloneFolios)
        }
    }

    function limpiarArray(array: any) {
        return array.filter(function (element: any) {
            return element !== null && element !== undefined && element !== '';
        });
    }


    const deleteRow = (index: any) => {
        const newArray = eliminarElementoPorIndice(index)
        props.setTotalFolios(newArray)
    }

    const [selected, setSelected] = useState<any>('')

    const items = [
        {
            items: [
                {
                    label: 'Ver Acto',
                    icon: 'pi pi-eye',
                    command: () => {
                        getActoInformation()
                    },
                },
                {
                    label: 'Eliminar',
                    icon: 'pi pi-trash',
                    command: () => {
                        deleteActo()
                    }
                }
            ]
        },
    ];

    const [dynamicFormInformation, setDynamicFormInformation] = useState()

    const getActoInformation = () => {
        let datos = {
            act_id: selected.act_id
        }
        ApiServices.apiPost('getCampos', datos).then(async res => {
            setDynamicFormInformation(res.data.addenda)
            setVisible(true)
        }).catch(error => {
            console.log(error)
        })
    }

    const EliminarTemplate = (e: any) => {
        return (
            <>
                <div className='text-center'>
                    {/* {(e.act_clave === 'I33' || e.act_clave === 'I39') &&
                        <> */}
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <Button aria-controls="popup_menu" aria-haspopup onClick={(event) => {
                        setSelected(e)
                        menu.current.toggle(event)
                    }} className="p-button-text p-button-plain" icon="pi pi-ellipsis-v" />
                    {/* </>

                    } */}

                </div>
            </>
        );
    }

    const [sumaMonto, setSumaMonto] = useState(0)

    useEffect(() => {
        calcularMonto()
    }, [props.previousPayments])

    const formatCurrency = (amount: any): any => {
        return amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });
    };

    const calcularMonto = () => {
        const sumaMonto = props.previousPayments ? props.previousPayments.reduce((acumulador: any, elemento: any) => {
            return acumulador + elemento.pago_monto;
        }, 0) : props.preTramiteDetail.pagos && props.preTramiteDetail.pagos.reduce((acumulador: any, elemento: any) => {
            return acumulador + elemento.pago_monto;
        }, 0)
        setSumaMonto(formatCurrency(parseFloat(sumaMonto)))
    }


    const footerActos = (
        <ColumnGroup>
            <Row>
                <Column className='text-right' footer="Precio total:" colSpan={2} />
                <Column footer={`$ ${(props && props.total) ? parseFloat(props.total).toFixed(0) : '$0.00'} MXN`} />
                <Column />
            </Row>
        </ColumnGroup>
    );
    const footerPagos = (
        <ColumnGroup>
            <Row>
                <Column className='text-right' footer="Precio total:" colSpan={4} />
                <Column footer={`${sumaMonto}`} />
            </Row>
        </ColumnGroup>
    );

    const [dynamicInformationParent, setDynamicInformationParent] = useState()

    const formatInformation = (informationToFormat: any) => {
        const flattenedArray = informationToFormat.flat();
        const filteredObjects = flattenedArray.filter((obj: any) => obj.cmp_tipo !== "L" && obj.cmp_tipo !== 'vacio');
        const result = filteredObjects.map(({ cmp_id, frm_id, cmp_tipo, value }: any) => ({
            id: cmp_id,
            val: value || ''
        }));

        return result;
    }

    const handleSave = (selectedForm: any) => {
        let datos: any
        const formatResponse = formatInformation(dynamicInformationParent)

        if (selected.frmu_id) {
            datos = {
                code: 402,
                frmu_id: selected.frmu_id,
                usu_id: user.usu_id,
                campos: formatResponse,
                trm_id: 0,
                //ptrm_id: responseData ? responseData.ptrm_id : preTramite.ptrm_id
            }
        } else {
            datos = {
                code: 401,
                usu_id: user.usu_id,
                act_id: selected.act_id,
                campos: formatResponse,
                trm_id: 0,
                // ptrm_id: responseData ? responseData.ptrm_id : preTramite.ptrm_id
            }
        }
        setVisible(false)
    }

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <p>{`${option.act_clave} ${option.act_nombre}`}</p>
                {/* <p>{`${option.frm_desc} `}</p> */}
            </div>
        );
    };

    const handleAddToActosArray = (e: any) => {
        const newArray = JSON.parse(JSON.stringify(props.actos))
        newArray.push(e)
        props.actosFunction(newArray)

        const newTotal = props.total + e.valormx
        props.totalFunction(newTotal)
    }

    function eliminarObjeto(array: any, objetoAEliminar: any) {
        const nuevoArray = array.filter((objeto: any) => {
            return objeto.act_id !== objetoAEliminar.act_id;
        });

        return nuevoArray;
    }

    const deleteActo = () => {
        let datos = {
            frmu_id: selected.frmu_id
        }
        if (selected.frmu_id) {
            ApiServices.apiPost('deleteRespuestas', datos).then(async res => {
                if (res.data.valido === 1) {
                }

            }).catch(error => {
                console.log(error)
                props.requestActos()
            })
        }
        const newArray = JSON.parse(JSON.stringify(props.actos))
        const deletedArray = eliminarObjeto(newArray, selected)
        props.actosFunction(deletedArray)
        const sumaTotal = sumarValores(deletedArray);
        props.totalFunction(sumaTotal)
    }

    function sumarValores(array: any) {
        let suma = 0.0;
        for (let i = 0; i < array.length; i++) {
            suma += parseFloat(array[i].valormx);
        }
        return suma;
    }

    const pagoBodyTemplate = (payment: any) => {
        return (
            <p>$ {parseFloat(payment.act_pesos).toFixed(0)} MXN</p>
        )
    }
    const fechaBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.pago_fecha);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();

        const fechaFormateada = `${day}/${month}/${year}`;

        return (
            <div className="actions">
                <p>{`${fechaFormateada}`}</p>
            </div>
        )
    }

    const footerContent = (
        <div>
        </div>
    );

    const [selectedNodeKey, setSelectedNodeKey] = useState(null);

    function buscarObjetoPorActId(array: any, actId: any) {
        for (const item of array) {
            if (item.children) {
                const resultado: any = buscarObjetoPorActId(item.children, actId);
                if (resultado) {
                    return resultado;
                }
            }
            if (item.data && item.data.act_id === actId) {
                return item;
            }
        }
        return null;
    }

    function buscarObjetoPorActClave(array: any, actClave: any) {
        for (const item of array) {
            if (item.children) {
                const resultado: any = buscarObjetoPorActClave(item.children, actClave);
                if (resultado) {
                    return resultado;
                }
            }
            if (item.data && item.data.act_clave == actClave) {
                return item;
            }
        }
        return null;
    }

    function existeActId(array: any, id: any) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].act_id === id) {
                return true;
            }
        }
        return false;
    }


    const handleAddToActosArray2 = (e: any) => {
        const newArray = JSON.parse(JSON.stringify(props.actos))
        if (!existeActId(newArray, e)) {
            const objetoEncontrado = buscarObjetoPorActId(props.actosList, e);
            if (objetoEncontrado.data.act_clave == "I39") {
                const founded = buscarObjetoPorActClave(props.actosList, "CI1")
                founded.data.act_pesos = 0
                newArray.push(founded.data)
            }
            if (objetoEncontrado) {
                newArray.push(objetoEncontrado.data)
            }
            props.actosFunction(newArray)
            const newTotal = props.total + objetoEncontrado.data.act_pesos
            props.totalFunction(newTotal)
        }
    }

    const [multipleFolio, setMultipleFolio] = useState(false)
    const [folioSearch, setFolioSearch] = useState('')

    const searchFolio = () => {
        if (folioSearch) {
            let datos
            datos = {
                code: 208,
                prop_folio: folioSearch,
                prop_clave: '',
                ptit_nom: '',
                ptit_pat: '',
                ptit_mat: '',
                prop_direccion: '',
                prop_calle: '',
                prop_ext: '',
                prop_int: '',
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido == 1) {
                    const newFolios = JSON.parse(JSON.stringify(props.totalFolios))
                    if (res.data.addenda && res.data.addenda.length > 0) {
                        setFolioSearch('')
                        const newArray = newFolios.concat(res.data.addenda)
                        props.setTotalFolios(newArray)
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: 'Sin Resultados', detail: res.data.mensaje, life: 4000 });
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const [modalTitulares, setModalTitulares] = useState(false)

    const descriptionBodyTemplate = (rowData: any) => {
        const json: any = rowData.pago_validacion && JSON.parse(rowData.pago_validacion)
        if (json instanceof Array) {
            return (
                <div>
                    {
                        json.map((singleElement) => {
                            return (
                                <p>{`${singleElement && singleElement.$ && singleElement.$.Descripcion ? `${singleElement.$.Descripcion} ${singleElement.$.Monto && '- $ '} ${singleElement.$.Monto}` : ''}`}</p>)
                        })
                    }

                </div>
            )
        }
        else
            return (
                <div>
                    <p>{`${json && json.$ && json.$.Descripcion ? json.$.Descripcion : ''}`}</p>
                </div>
            )
    }

    const reORderActos = (newValues: any) => {
        const localArray = []
        const ordered = newValues && newValues.map((singleValue, index) => {
            singleValue.ta_consecutivo = index + 1
            localArray.push(singleValue)
            return {
                act_id: singleValue.act_id,
                ta_consecutivo: index + 1
            }
        })

        let datos
        datos = { code: 214, trm_id: preTramite ? preTramite.trm_id : responseData && responseData.trm_id, actos: ordered }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                toast.current.show({ severity: 'success', summary: 'Actos Ordenados con Exito', detail: res.data.mensaje, life: 4000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error al Ordenar los Actos', detail: res.data.mensaje, life: 4000 });
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error al Ordenar los Actos', life: 4000 });
        })


        props.actosFunction(localArray)
    }

    return (
        <section>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-6'>
                    <h3 className='color-violet'>Nuevo acto</h3>
                    <p>Ingresa en los siguientes campos para encontrar el registro</p>
                </div>
                <div className='col-12 md:col-6' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <Checkbox onChange={e => setMultipleFolio(!multipleFolio)} checked={multipleFolio} style={{ marginRight: 10 }}></Checkbox>
                        <p>Afecta multiples Folios</p>
                    </div>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <TreeSelect
                            value={selectedNodeKey}
                            onChange={(e) => handleAddToActosArray2(e.value)}
                            options={props.actosList}
                            filter
                            className="w-full"
                            placeholder="Selecciona un Acto para agregar"
                        ></TreeSelect>
                        <label htmlFor="dd-city">Acto</label>
                    </span>
                </div>
            </div>

            <div className='pt-4'>
                <DataTable
                    sortField="ta_consecutivo"
                    sortOrder={1}
                    reorderableColumns reorderableRows
                    ref={dt} value={props.actos} footerColumnGroup={footerActos}
                    dataKey="id" paginator rows={5} className="datatable-responsive"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                    emptyMessage="No se encontraron Registros." responsiveLayout="scroll"
                    onRowReorder={(e) => reORderActos(e.value)}
                >
                    {
                        user.prf_id == 12 &&
                        <Column rowReorder style={{ width: '3rem' }} />
                    }
                    <Column field="ta_consecutivo" header="Consecutivo" style={{ width: '10%' }}></Column>
                    <Column field="act_clave" header="Clave" sortable /* body={codeBodyTemplate} */ ></Column>
                    <Column field="act_nombre" header="Acto" sortable /* body={nameBodyTemplate} */ ></Column>
                    <Column field="act_pesos" header="Derechos" body={pagoBodyTemplate} ></Column>
                    <Column field="price" style={{ width: '10rem' }} body={(e) => EliminarTemplate(e)} ></Column>
                </DataTable>
            </div>

            {multipleFolio &&
                <div className='grid' style={{ marginTop: 20 }}>
                    <div className='col-12 md:col-6'>
                        <h3 className='color-violet'>Múltiples folios</h3>
                        <p>En caso de que afecte múltiples folios, ingresa los folios necesarios para su registro.</p>
                    </div>
                    <div className='col-12 md:col-6' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, width: '100%', justifyContent: 'space-between' }} >
                            <InputText value={folioSearch} onChange={(e) => setFolioSearch(e.target.value)} style={{ width: '75%' }} />
                            <Button label="Agregar" style={{ width: '20%' }} onClick={searchFolio} />
                        </div>
                    </div>
                    <div className='col-12 md:col-12'>
                        <DataTable
                            ref={dt}
                            value={props.totalFolios}
                            dataKey="prop_id" paginator rows={5} className="datatable-responsive"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                            emptyMessage="No products found." responsiveLayout="scroll">
                            <Column field="prop_folio" header="Folio" sortable ></Column>
                            <Column field="proptitutales" header="Titulares" sortable body={titularesBodyTemplate}></Column>
                            <Column field="price" header="Total Titulares" body={totalTitularesBodyTemplate}></Column>
                            <Column field="icons" header="" body={iconsTitularesBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            }


            {previousPayments && previousPayments.length > 0 &&
                < div >
                    <div className='col-12 md:col-12'>
                        <h3 className='color-violet'>Pagos realizados</h3>
                        <p>Podrá visualizar los pagos</p>

                        <div>
                            <DataTable ref={dt} value={previousPayments} footerColumnGroup={footerPagos}
                                dataKey="id" paginator rows={5} className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                                emptyMessage="No products found." responsiveLayout="scroll">
                                <Column field="pago_serie" header="Serie" sortable ></Column>
                                <Column field="pago_folio" header="Pago" sortable ></Column>
                                <Column header="Descripción" body={descriptionBodyTemplate} ></Column>
                                <Column field="pago_update" header="Fecha de pago" sortable body={fechaBodyTemplate} ></Column>
                                <Column field="price" header="Monto de derechos" body={montoBodyTemplate}></Column>
                                <Column field="delete" header="" body={deletePaymentTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

            }
            {props && props.preTramiteDetail && props.preTramiteDetail.pagos && props.preTramiteDetail.pagos.length > 0 &&
                < div >
                    <div className='col-12 md:col-12'>
                        <h3 className='color-violet'>Pagos realizados</h3>
                        <p>Podrá visualizar los pagos</p>

                        <div>
                            <DataTable ref={dt} value={props.preTramiteDetail.pagos} footerColumnGroup={footerPagos}
                                dataKey="id" paginator rows={5} className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                                emptyMessage="No products found." responsiveLayout="scroll">
                                <Column field="pago_serie" header="Serie" sortable ></Column>
                                <Column field="pago_folio" header="Pago" sortable /* body={pagoBodyTemplate} */ ></Column>
                                <Column header="Descripción" body={descriptionBodyTemplate} ></Column>
                                <Column field="pago_update" header="Fecha de pago" sortable body={fechaBodyTemplate} ></Column>
                                <Column field="pago_monto" header="Monto de derechos" body={montoBodyTemplate}></Column>
                                <Column field="delete" header="" body={deletePaymentTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            }

            {/* <div>
                <HistorialActo />
            </div> */}



            {/*inicia modal de actos*/}
            <Dialog header={`${(selected && selected.act_nombre) || ''}`} maximizable visible={visible} style={{ width: '50%' }} onHide={() => setVisible(false)} footer={footerContent}>
                {/* {
                    selected && selected.act_clave === "I33" ?
                        <I33Acto /> : <I39Acto />
                } */}
                <DynamicForm
                    previousFolio={props.previousFolio}
                    previousPayments={props.previousPayments}
                    responseData={props.responseData}
                    preTramite={props.preTramite}
                    preTramiteDetail={props.preTramiteDetail}
                    informationForm={dynamicFormInformation}
                    information={selected}
                    preTramiteFolio={props.preTramiteFolio}
                    setDynamicInformationParent={setDynamicInformationParent}
                    titulares={props.titulares}
                    tramite={props.preTramiteDetail || { tramite: props.selectedNotaryList }}
                />

            </Dialog>

            <Dialog header="Titulares" visible={modalTitulares} style={{ width: '70%' }} onHide={() => setModalTitulares(false)}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 30 }}>
                    <div className="flex flex-column gap-2" style={{ width: '30%', marginRight: 20 }}>
                        <label htmlFor="username">Folio</label>
                        <InputText id="username" disabled value={selectedTitular && selectedTitular.prop_folio} />
                    </div>
                    <div className="flex flex-column gap-2" style={{ width: '30%' }}>
                        <label htmlFor="username">Clave Catastral</label>
                        <InputText id="username" disabled value={selectedTitular && selectedTitular.prop_clave} />
                    </div>
                </div>
                <p className="m-0" style={{ marginTop: 30 }}>
                    <DataTable ref={dt} value={selectedTitulares}
                        dataKey="ptit_id" paginator rows={5} className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                        emptyMessage="No products found." responsiveLayout="scroll">
                        <Column field="ptit_nom" header="Nombre" sortable ></Column>
                        <Column field="ptit_pat" header="Primer Apellido" sortable ></Column>
                        <Column field="ptit_nom" header="Segundo Apellido" ></Column>
                        <Column field="ptit_rfc" header="RFC" ></Column>
                        <Column field="ptit_nuda" header="Nuda %" ></Column>
                        <Column field="ptit_usufructo" header="Usofructo %" ></Column>

                    </DataTable>
                </p>
            </Dialog>
        </section >
    );

}

export default React.memo(ResulActos);