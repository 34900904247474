import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { ColorPicker } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../serviceExample/ProductService';
import ApiServices from '../../../../service/apiServices';
import { Toast } from 'primereact/toast';
import configuration from '../../../../configurationFile';

const Configurador = () => {

    const [text, setText] = useState('');
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [products, setProducts] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const dt = useRef<any>(null);
    const toast = useRef<any>(null);

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []);

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.code}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Volumen</span>
                {rowData.name}
            </>
        );
    }
    const DeleteTemplate = (rowData: any) => {
        return (
            <>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" />
            </>
        );
    }

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Cambios exitoso', detail: 'Se ha guardado el cambio', life: 3000 });
    }

    function esMayorQue5MB(bytes: any) {
        const megabytes = bytes / (1024 * 1024); // Convertir a megabytes
        return megabytes > 5;
    }

    const [colorButtons, setColorButtons] = useState()
    const [colorTitles, setColorTitles] = useState()
    const [image1, setImage1] = useState<any>()
    const [image2, setImage2] = useState<any>()
    const [image3, setImage3] = useState<any>()

    const [imagePreview1, setImagePreview1] = useState<any>()
    const [imagePreview2, setImagePreview2] = useState<any>()
    const [imagePreview3, setImagePreview3] = useState<any>()

    const refFile1: any = useRef()
    const refFile2: any = useRef()
    const refFile3: any = useRef()

    const handleFileClick1 = (e: any) => {
        refFile1.current.click()
    }

    const handleFileClick2 = (e: any) => {
        refFile2.current.click()
    }

    const handleFileClick3 = (e: any) => {
        refFile3.current.click()
    }

    const readUploadFile1 = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            if (esMayorQue5MB(e.target.files[0].size)) {
                console.log('Archivo muy grande')
            } else {
                setImage1(e.target.files[0]);
                setImagePreview1(URL.createObjectURL(e.target.files[0]))
            }
        }
    }

    const readUploadFile2 = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            if (esMayorQue5MB(e.target.files[0].size)) {
                console.log('Archivo muy grande')
            } else {
                setImage2(e.target.files[0]);
                setImagePreview2(URL.createObjectURL(e.target.files[0]))
            }
        }
    }

    const readUploadFile3 = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            if (esMayorQue5MB(e.target.files[0].size)) {
                console.log('Archivo muy grande')
            } else {
                setImage3(e.target.files[0]);
                setImagePreview3(URL.createObjectURL(e.target.files[0]))
            }
        }
    }


    useEffect(() => {
        getSettings()
    }, [])

    const getSettings = () => {
        ApiServices.apiPost('getUMA', { "setting_id": 4 }).then(async (res: any) => { setColorButtons(res.data.addenda[0].setting_valor) })
        ApiServices.apiPost('getUMA', { "setting_id": 5 }).then(async (res: any) => { setColorTitles(res.data.addenda[0].setting_valor) })
        ApiServices.apiPost('getUMA', { "setting_id": 6 }).then(async (res: any) => { setImage1(res.data.addenda[0].setting_valor) })
        ApiServices.apiPost('getUMA', { "setting_id": 7 }).then(async (res: any) => { setImage2(res.data.addenda[0].setting_valor) })
        ApiServices.apiPost('getUMA', { "setting_id": 8 }).then(async (res: any) => { setImage3(res.data.addenda[0].setting_valor) })
    }

    const updateAll = () => {
        ApiServices.apiPost('updateUMA', { "setting_id": 4, "setting_valor": colorButtons })
        ApiServices.apiPost('updateUMA', { "setting_id": 5, "setting_valor": colorTitles })
        {
            imagePreview1 &&
                ApiServices.apiPostFile('setSettingImagen', image1, { "setting_id": 6, "setting_valor": "media/settings/" })
        }
        {
            imagePreview2 &&
                ApiServices.apiPostFile('setSettingImagen', image2, { "setting_id": 7, "setting_valor": "media/settings/" })
        }
        {
            imagePreview3 &&
                ApiServices.apiPostFile('setSettingImagen', image3, { "setting_id":8, "setting_valor": "media/settings/" })
        }

        showSuccess()
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 pb-4'>
                    <h3>Configurador del sistema</h3>
                    <p>Aqui podras cambiar el diseño del sistema.</p>
                </div>
            </div>
            <div className='grid'>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <InputText
                                className="p-inputtext-lg"
                                id="username"
                                placeholder="#00000"
                                value={colorButtons}
                                onChange={(e: any) => { setColorButtons(e.target.value) }}
                            />
                            <ColorPicker value={colorButtons} onChange={(e: any) => setColorButtons(e.value)} style={{ position: 'absolute', left: '85%', width: '10%' }} />
                            <label htmlFor="username">Color botones</label>
                        </div>
                    </span>
                    <small id="username1-help" className="p-d-block">ingresa el color en tipo hexadecimal</small>
                </div>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <InputText
                                className="p-inputtext-lg"
                                id="username"
                                placeholder="#00000"
                                value={colorTitles}
                                onChange={(e: any) => { setColorTitles(e.target.value) }}
                            />
                            <ColorPicker value={colorTitles} onChange={(e: any) => setColorTitles(e.value)} style={{ position: 'absolute', left: '85%', width: '10%' }} />
                            <label htmlFor="username">Color titulos</label>
                        </div>
                    </span>
                    <small id="username1-help" className="p-d-block">ingresa el color en tipo hexadecimal</small>
                </div>
                <div className='col-12 md:col-4'>
                    <div className='pt-2 text-center'>
                        <p>Logo principal</p>
                    </div>
                    {image1 ?
                        <div className='pt-2 document-ok' style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            widows: '33%',
                            justifyContent: "center",
                            alignItems: "center"
                        }} onClick={handleFileClick1}>
                            <input
                                ref={refFile1}
                                type="file"
                                hidden
                                onChange={readUploadFile1}
                            />
                            {image1 && image1.name ?
                                <img src={imagePreview1} alt="profile" style={{ width: '80%' }} />
                                :
                                <img src={`${configuration.url}/${image1}`} alt="profile" style={{ width: '80%' }} />
                            }
                        </div>
                        :
                        <div className='pt-2 text-center'>
                            <input
                                ref={refFile1}
                                type="file"
                                hidden
                                onChange={readUploadFile1}
                            />
                            <p className='document' onClick={handleFileClick1}>
                                <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }} />
                                Selecciona para subir Logo principal
                            </p>
                        </div>
                    }
                </div>
                <div className='col-12 md:col-4'>
                    <div className='pt-2 text-center'>
                        <p>Logo intermedio</p>
                    </div>
                    {image2 ?
                        <div className='pt-2 document-ok' style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            widows: '33%',
                            justifyContent: "center",
                            alignItems: "center"
                        }} onClick={handleFileClick2}>
                            <input
                                ref={refFile2}
                                type="file"
                                hidden
                                onChange={readUploadFile2}
                            />
                            {image2 && image2.name ?
                                <img src={imagePreview2} alt="profile" style={{ width: '80%' }} />
                                :
                                <img src={`${configuration.url}/${image2}`} alt="profile" style={{ width: '80%' }} />
                            }
                        </div>
                        :
                        <div className='pt-2 text-center'>
                            <input
                                ref={refFile2}
                                type="file"
                                hidden
                                onChange={readUploadFile2}
                            />
                            <p className='document' onClick={handleFileClick2}>
                                <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }} />
                                Selecciona para subir Logo principal
                            </p>
                        </div>
                    }
                </div>
                <div className='col-12 md:col-4'>
                    <div className='pt-2 text-center'>
                        <p>Logo casual</p>
                    </div>
                    {image3 ?
                        <div className='pt-2 document-ok' style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            widows: '33%',
                            justifyContent: "center",
                            alignItems: "center"
                        }} onClick={handleFileClick3}>
                            <input
                                ref={refFile3}
                                type="file"
                                hidden
                                onChange={readUploadFile3}
                            />
                            {image3 && image3.name ?
                                <img src={imagePreview3} alt="profile" style={{ width: '80%' }} />
                                :
                                <img src={`${configuration.url}/${image3}`} alt="profile" style={{ width: '80%' }} />
                            }
                        </div>
                        :
                        <div className='pt-2 text-center'>
                            <input
                                ref={refFile3}
                                type="file"
                                hidden
                                onChange={readUploadFile3}
                            />
                            <p className='document' onClick={handleFileClick3}>
                                <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }} />
                                Selecciona para subir Logo principal
                            </p>
                        </div>
                    }
                </div>
            </div>
            <div className='col-12 md:col-12' style={{ marginTop: 30 }}>
                <Button label="Guardar cambios" onClick={updateAll} />
            </div>



        </section >
    );

}

export default React.memo(Configurador);