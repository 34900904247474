import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { TimerContext } from '../../../context/TimerContext';

export const LoginVentanilla = (props: any) => {
	const { resetTimer } = useContext(TimerContext);

	const navigate = useNavigate();
	const toast = useRef<any>();
	const permisos: { label: any; }[] = []

	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)

	const permisosMenu = async (menu: any[]) => {
		menu.forEach(menuItem => {
			let permiso = { label: menuItem.label }
			if (menuItem.items && menuItem.items.length > 0) {
				permisosMenu(menuItem.items);
			} else {
				permisos.push(permiso);
			}
		});
		return permisos;
	}

	const userLogin = async () => {
		try {
			setLoading(true);
			if (email.length <= 0 || password.length <= 0) {
				setLoading(false);
				toast.current.show({ severity: 'error', summary: 'Campos incompletos', detail: 'Ingresa tu correo y tu contraseña', life: 4000 });
			} else {
				let datos: any = {
					code: 101,
					usu_email: email,
					usu_psw: password
				};
				datos = EncryptService.encryptData(JSON.stringify(datos));
				const res = await ApiServices.processRequestAdd(datos);
				const { valido, addenda } = res.data;
				if (valido === 0) {
					setLoading(false);
					toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Email y/o Contraseña Incorrectos', life: 100000000 });
				} else {
					resetTimer(); // Establece el tiempo de inicio de sesión
					authGuardService.sendUser(addenda);
					navigate("/");
				}
			}
		} catch (error) {
			setLoading(false);
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Intentelo de Nuevo más tarde.', life: 4000 });
		}
	};

	return (
		<section className='flex justify-content-center align-items-center'>
			<Toast ref={toast} />
			<div className='col-12 md:col-4 login'>
				<div className='grid'>
					<div className='col-12 md:col-12 pb-5'>
						<h1>Iniciar sesión</h1>
						<p>Ingresa los siguientes datos</p>
					</div>
					<div className='col-12 md:col-12'>
						<div className='pb-3'>
							<InputText
								placeholder="Usuario"
								className='p-inputtext-lg'
								value={email}
								type="text"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className='pb-5'>
							<Password
								placeholder="Contraseña"
								className='p-inputtext-lg'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								feedback={false}
								toggleMask
							/>
						</div>
					</div>
					<div className='col-12 md:col-12 pb-5'>
						<Button
							label="Comenzar"
							onClick={userLogin}
							loading={loading}
						/>
					</div>
					<div className='col-12 md:col-12'>
						<div className='pb-5'>
							<Button
								label="Registrate"
								className="p-button-link"
								onClick={() => navigate('/registro')}
							/>
						</div>
						<div className='pb-5'>
							<Button
								label="¿Olvidaste la contraseña?"
								className="p-button-text p-button-secondary"
								onClick={() => navigate('/olvideContrasena')}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}