import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import formatDate from '../../../utilities/formatDate';
import './chartStyles.css'
import configuration from '../../../configurationFile';

let horizontalOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
};

const GenInicio = () => {

    const navigate = useNavigate();

    const user: any = authGuardService.getUser()
    const toast = useRef<any>(null);

    const [visible, setVisible] = useState<boolean>(false);
    const [analistData, setAnalistData] = useState<any>()
    const [notarioWorkers, setNotariosWorkers] = useState([])
    const [preTramites, setPreTramites] = useState([])
    const [tramiteData, setTramiteData] = useState<any>([])
    const [loadingTable, setLoadingTable] = useState(true)
    const [listNotice, setListNotice] = useState([])
    const [folio, setFolio] = useState('')

    useEffect(() => {
        getNoticeList()
        getNotariosWorkers()
    }, [])

    useEffect(() => {
        if (notarioWorkers && notarioWorkers.length > 0) {
            getList()
            getAnilistaData()
            getTramiteesData()
        }
    }, [notarioWorkers]);

    const getNoticeList = () => {
        let datos
        ApiServices.apiPost('listAvisos', datos).then(async res => {
            if (res.data.valido === 1) {
                setListNotice(res.data.addenda)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const getNotariosWorkers = () => {
        const usu = authGuardService.getUser()
        let datos = { "not_id": usu.not_id }
        ApiServices.apiPost('getUsuarioNotario', datos).then(async resp => {
            const clone = JSON.parse(JSON.stringify(resp.data.addenda))
            clone.push({ usu_id: user.usu_id, not_id: user.not_id })
            setNotariosWorkers(clone)
        }).catch(error => {
            console.log(error)
        })
    }

    const getList = async () => {
        let tramites: any = []
        if (notarioWorkers) {
            for (const singleWorker of notarioWorkers as any) {
                if (singleWorker.not_id === user.not_id) {
                    let datos: any = {
                        code: 305,
                        usu_id: singleWorker.usu_id,
                        status: 2
                    }
                    datos = EncryptService.encryptData(JSON.stringify(datos));
                    await ApiServices.processRequestAdd(datos).then(async res => {
                        if (res.data && res.data.addenda && res.data.addenda.length > 0) {
                            res.data.addenda.map((singlePreTramite: any) => {
                                tramites.push(singlePreTramite)
                            })
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }
        }
        setPreTramites(tramites)
        setLoadingTable(false)
    }

    const getAnilistaData = () => {
        let datos = {
            not_id: user.not_id
        }
        ApiServices.apiPost('actividadAnalista', datos).then(async res => {
            setAnalistData(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getTramiteesData = () => {
        let datos = {
            not_id: user.not_id
        }
        ApiServices.apiPost('graficaNotarios', datos).then(async res => {
            formatTramiteData(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const formatTramiteData = (data: any) => {
        const newData = JSON.parse(JSON.stringify(outputObject))
        data.forEach((item: any) => {
            const dataset = newData.datasets.find((dataset: any) => dataset.id === item.ptrm_status);
            if (dataset) {
                dataset.quantity += item.cuenta;
            }
        });
        setTramiteData(newData)
    }

    const actionBodyTemplate = (rowData: any) => {
        const navigateOptions = {
            state: {
                preTramite: rowData
            }
        };

        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => { navigate('/notarios/pago-folio', navigateOptions) }} />
            </div>
        );
    }

    const actosBodyTemplate = (rowData: any) => {
        let arrays = ' - '
        if (rowData && rowData.actos) {
            arrays = rowData.actos
        }
        return (
            <div className="actions">
                <p>{arrays}</p>
            </div>
        );
    }

    const statusBodyTemplate = (tramite: any) => {
        const statusType = getStatusType(tramite.ptrm_status)
        const statusColor = getSeverity(tramite.ptrm_status)
        return <Tag value={statusType} style={{ color: statusColor }}></Tag>;
    };

    const getStatusType = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'Eliminado';
            case 1:
                return 'Borrador';
            case 2:
                return 'Ingresado';
            case 3:
                return 'Rechazado';
            case 4:
                return 'Subsanado';
            case 5:
                return 'Enviado';
            default:
                return null;
        }
    };

    const getSeverity = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'red'
            case 1:
                return 'red'
            case 2:
                return '#7A7A7A';
            case 3:
                return '#D72323';
            case 4:
                return 'red';
            case 5:
                return '#3D7B0C';
            default:
                return 'black';
        }
    };

    const searchFolio = () => {
        let datos = {
            prop_folio: folio,
            prop_online: 0
        }
        ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
            if (res.data.valido === 1) {
                if (res.data.addenda && res.data.addenda.length > 0) {
                    const navigateOptions = {
                        state: {
                            folio: res.data.addenda[0]
                        }
                    };
                    navigate('/notarios/view-folio', navigateOptions)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Folio no Encontrado', detail: 'No se encontro el folio que buscas.' });
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Folio no Encontrado', detail: 'No se encontro el folio que buscas.' });
            }
        }).catch(error => {
            console.log(error)
        })
    }

    function convertToNewFormat(originalArray: any) {
        const resultMap: any = {};

        originalArray.forEach((item: any) => {
            const { usu_id, ptrm_status, usuarioNombre, ...rest } = item;

            if (!resultMap[usu_id]) {
                resultMap[usu_id] = { usu_id, information: [], name: usuarioNombre };
            }

            resultMap[usu_id].information.push({ id: ptrm_status, quantity: rest[''] });
        });

        const newArray = Object.values(resultMap);
        setAnalistData(newArray)
    }

    const fechaBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.ptrm_fecha);
        const hoursUTC = new Date(rowData.ptrm_hora);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();
        const hours = hoursUTC.getUTCHours().toString().padStart(2, '0');
        const minutes = hoursUTC.getUTCMinutes().toString().padStart(2, '0');
        const seconds = hoursUTC.getUTCSeconds().toString().padStart(2, '0');

        const fechaFormateada = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

        return (
            <div className="actions">
                <p>{`${fechaFormateada}`}</p>
            </div>
        )
    }

    const preTramiteIdTemplate = (rowData: any) => {
        return (
            <p>{`${rowData && rowData.ptrm_consecutivo && parseInt(rowData.ptrm_consecutivo)}`}</p>
        )
    }

    const footerContent = (
        <div className='text-center'>
            <Button className='w-10' label="Buscar" onClick={searchFolio} />
        </div>
    );

    const outputObject = {
        labels: ['Cantidad'],
        datasets: [
            {
                id: 0,
                label: 'Eliminado',
                quantity: 0
            },
            {
                id: 1,
                label: 'Borrador',
                backgroundColor: '#FFA726',
                quantity: 0
            },
            {
                id: 2,
                label: 'En Revisión',
                backgroundColor: '#fcf25d',
                quantity: 0
            },
            {
                id: 3,
                label: 'Rechazado',
                backgroundColor: '#4d9be3',
                quantity: 0
            },
            {
                id: 4,
                label: 'Subsanado',
                backgroundColor: '#5dfce9',
                quantity: 0
            },
            {
                id: 5,
                label: 'Aprobado',
                backgroundColor: '#aafc5d',
                quantity: 0
            }
        ]
    }



    const [data] = useState([
        { name: 'Eliminado', value: 20, color: '#e5e5e5' },
        { name: 'Borrador', value: 40, color: '#ffa726' },
        { name: 'En Revisión', value: 35, color: '#fff238' },
        { name: 'Rechazado', value: 50, color: '#4d9be3' },
        { name: 'Subsanado', value: 55, color: '#5dfce9' },
        { name: 'Aprovado', value: 40, color: '#98ff38' },
    ]);

    const [containerWidth, setContainerWidth] = useState(800);
    const containerRef = useRef(null);

    const updateDimensions = () => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const barHeight = 30;
    const scaleFactor = containerWidth / 100;

    const BarGroups = ({ tramiteData, scaleFactor, barHeight }) => {
        if (tramiteData && tramiteData.datasets && tramiteData.datasets.length > 0) {
            return (
                <>
                    {tramiteData.datasets.map((singleTramite: any, index: any) => {
                        let qValue: any = singleTramite.quantity
                        let barPadding = 2;
                        let widthScale = qValue => qValue * scaleFactor; // Utilizamos singleTramite.quantity

                        let width = widthScale(singleTramite.quantity);
                        let yMid = barHeight * 0.5;

                        return (
                            <g transform={`translate(0, ${index * barHeight})`} key={index}>
                                <g className="bar-group">
                                    <text className="name-label" x="-20" y={yMid} alignmentBaseline="middle">{singleTramite.label}</text>
                                    <rect y={barPadding * 0.5} width={width} height={barHeight - barPadding} fill={singleTramite.backgroundColor} />
                                    {singleTramite.quantity > 0 &&
                                        <text className="value-label" x={width - 8} y={yMid} alignmentBaseline="middle">{singleTramite.quantity}</text>
                                    }
                                </g>
                            </g>
                        );
                    })}
                </>
            );
        } else {
            return null;
        }
    };


    return (
        <section className='container-fluid'>
            <Toast ref={toast} />

            <div className='grid'>
                <div className='col-12 md:col-8'>
                    <h3 className='color-violet'>Bienvenido</h3>
                    <p>Podrá ver las actulizaciones y comportamiento de la notaría</p>
                </div>
                <div className='col-12 md:col-4'>
                    <h5 className='pb-4'>¿Qué actividad quieres realizar?</h5>
                    <div className='grid'>
                        <div className='col-12 md:col-6'>
                            <Button label="Buscar folio" className='p-button-outlined' onClick={() => navigate('/notarios/buscador')} />
                        </div>
                        <div className='col-12 md:col-6'>
                            <Button label="Nuevo trámite" onClick={() => { navigate('/notarios/not-pagos') }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12 md:col-5'>
                    <div className='dashboard'>
                        <h4 className='color-violet pb-3'>Actividad de analistas</h4>
                        <div className='grid'>
                            <table className='w-100'>
                                <thead>
                                    <tr>
                                        <th className='p-2' scope="col"></th>
                                        <th className='p-2' scope="col">Borrador</th>
                                        <th className='p-2' scope="col">En Revisión</th>
                                        <th className='p-2' scope="col">Rechazado</th>
                                        <th className='p-2' scope="col">Subsanado</th>
                                        <th className='p-2' scope="col">Aprobado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        analistData && analistData.map((singleData: any) => {
                                            return (
                                                <tr className='text-center '>
                                                    <th className='logo1 border-bottom p-2'>
                                                        <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} />
                                                        <img
                                                            className='logo img-analista'
                                                            data-pr-tooltip={`Trabajador: ${singleData.usuarioNombre}`}
                                                            src={`${configuration.url}${singleData.usu_foto}`}  // URL de la imagen
                                                            alt=""
                                                        />
                                                    </th>
                                                    <td className='border-bottom p-2'>{singleData.borrador}</td>
                                                    <td className='border-bottom p-2'>{singleData.revision}</td>
                                                    <td className='border-bottom p-2'>{singleData.rechazado}</td>
                                                    <td className='border-bottom p-2'>{singleData.subsanado}</td>
                                                    <td className='border-bottom p-2'>{singleData.aprobado}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-12 md:col-7'>
                    <div className='dashboard'>
                        <div className='grid'>
                            <div className='col-12 md:col-6'>
                                <h4 className='color-violet pb-3'>Trámites</h4>
                                <div ref={containerRef} style={{ width: '100%' }}>
                                    {tramiteData && tramiteData.datasets && tramiteData.datasets.length > 0 &&
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}>
                                                    <div style={{
                                                        display: 'inline-block',
                                                        backgroundColor: '#e5e5e5',
                                                        width: 50,
                                                        height: 15
                                                    }}></div>
                                                    <span style={{
                                                        fontSize: '1em',
                                                        color: '#333'
                                                    }}>Eliminado</span>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}>
                                                    <div style={{
                                                        display: 'inline-block',
                                                        backgroundColor: '#ffa726',
                                                        width: 50,
                                                        height: 15
                                                    }}></div>
                                                    <span style={{
                                                        fontSize: '1em',
                                                        color: '#333'
                                                    }}>Borrador</span>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}>
                                                    <div style={{
                                                        display: 'inline-block',
                                                        backgroundColor: '#fff238',
                                                        width: 50,
                                                        height: 15
                                                    }}></div>
                                                    <span style={{
                                                        fontSize: '1em',
                                                        color: '#333'
                                                    }}>En Revisión</span>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}>
                                                    <div style={{
                                                        display: 'inline-block',
                                                        backgroundColor: '#4d9be3',
                                                        width: 50,
                                                        height: 15
                                                    }}></div>
                                                    <span style={{
                                                        fontSize: '1em',
                                                        color: '#333'
                                                    }}>Rechazado</span>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}>
                                                    <div style={{
                                                        display: 'inline-block',
                                                        backgroundColor: '#5dfce9',
                                                        width: 50,
                                                        height: 15
                                                    }}></div>
                                                    <span style={{
                                                        fontSize: '1em',
                                                        color: '#333'
                                                    }}>Subsanado</span>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}>
                                                    <div style={{
                                                        display: 'inline-block',
                                                        backgroundColor: '#98ff38',
                                                        width: 50,
                                                        height: 15
                                                    }}></div>
                                                    <span style={{
                                                        fontSize: '1em',
                                                        color: '#333'
                                                    }}>Aprovado</span>
                                                </div>
                                            </div>


                                            <svg viewBox={`0 0 ${containerWidth} ${tramiteData.datasets.length * barHeight + 60}`} style={{ width: '100%' }}>
                                                <g className="container">
                                                    <g className="chart" transform="translate(100,60)">
                                                        <BarGroups tramiteData={tramiteData} scaleFactor={scaleFactor} barHeight={barHeight} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </>
                                    }
                                </div>

                            </div>
                            <div className='col-12 md:col-6' style={{ height: '35rem', overflow: 'scroll' }}>
                                <h4 className='color-violet pb-3'>Avisos ISRyC</h4>
                                {
                                    (listNotice && listNotice.length > 0) ?
                                        listNotice.map((notice: any) => {
                                            return (
                                                <div className='p-4 border-bottom '>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h5 className='pb-2'>{notice ? notice.avs_titulo : ''}</h5>
                                                        <p>{notice ? formatDate(notice.avs_update) : ''}</p>
                                                    </div>
                                                    <p>{notice ? notice.avs_texto : ''}</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='p-4 border-bottom '>
                                            <h5 className='pb-2'>Sin Avisos</h5>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12 md:col-8'>
                    <h3 className='color-violet'>Mis Pre-Trámites</h3>
                    <p>Gestiona tus trámites en revisión</p>
                </div>
                <div className='col-12 md:col-12'>
                    <DataTable
                        value={preTramites}
                        paginator
                        rows={10}
                        dataKey="ptrm_id"
                        tableStyle={{ minWidth: '50rem' }}
                        loading={loadingTable}
                        style={{ borderRadius: '10px' }}
                    >
                        <Column sortable field="ptrm_consecutivo" header="N° Control Notaria" body={preTramiteIdTemplate}></Column>
                        <Column sortable field="ptrm_update" header="Fecha" body={fechaBodyTemplate}></Column>
                        <Column sortable field="prop_folio" header="Folio electrónico"></Column>
                        <Column sortable field="prop_clave" header="Clave catastral"></Column>
                        <Column sortable field="name" header="Actos" body={actosBodyTemplate}></Column>
                        <Column sortable field="ptrm_status" header="Estatus" body={statusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog
                header="Buscar folio"
                visible={visible}
                style={{ width: '40vw' }}
                onHide={() => setVisible(false)}
                footer={footerContent}
            >
                <div className='grid'>
                    <div className='col-12 md:col-12'>
                        <p className=' text-center'>Ingresa el folio para iniciar la busqueda.</p>
                        <span className="p-float-label">
                            <InputText
                                id="username"
                                className="p-inputtext-lg"
                                value={folio}
                                onChange={(e) => { setFolio(e.target.value) }}
                            />
                            <label htmlFor="username">Folio electronico</label>
                        </span>
                    </div>
                </div>
            </Dialog>
        </section>
    );
}

export default React.memo(GenInicio);