import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ApiServices from '../../../service/apiServices';
import ResulActos from './resul-folio/actos';
import ResulGenerales from './resul-folio/generales';
import ResulNotificador from './resul-folio/notificador';
import ResulTitulares from './resul-folio/titulares';
import Tabs from '../../../Components/Tabs/Tabs';
import Tab from '../../../Components/Tabs/Tab';
import TabHistorialActo from '../../Ventanilla/ResulFolio/ViewFolio/TabViewFolio/HistorialActo';

const ViewFolio = () => {

    const location = useLocation();

    const generalData = location.state.folio

    const [titulares, setTitulares] = useState([])
    const [actoHistory, setActoHistory] = useState()
    const [actoHistoryClone, setActoHistoryClone] = useState()
    const [actoHistoryLoading, setActoHistoryLoading] = useState(true)

    useEffect(() => {
        getTitulares()
        getActoHistory()
    }, [generalData])

    const getTitulares = () => {
        let datos = {
            prop_id: generalData.prop_id
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    function groupObjectsByTrmId(inputArray: any) {
        const groupedObjects: any = {};

        inputArray.forEach((obj: any) => {
            const trmId = obj.trm_id;

            if (!groupedObjects[trmId]) {
                groupedObjects[trmId] = {
                    trm_fecha: obj.trm_fecha,
                    trm_id: trmId,
                    history: []
                };
            }

            groupedObjects[trmId].history.push(obj);
        });

        return Object.values(groupedObjects);
    }

    const getActoHistory = () => {
        let datos = {
            prop_folio: generalData.prop_folio
        }
        ApiServices.apiPost('getHistorial', datos).then(async res => {
            const groupedResult: any = groupObjectsByTrmId(res.data.addenda);
            const reversed = groupedResult.reverse()
            setActoHistory(reversed)
            setActoHistoryClone(reversed)
            setActoHistoryLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <section className='container-fluid'>
            <div className='flex justify-content-between'>
                {/* cuando sean folios de calidad aparecera el color verde se mostrara como el div, si no. se negara el small y el div 0.1 siguiente */}

                <div className='flex col-12 md:col-4'>


                    <div className='verde'>
                        <h3 className='verde'>{`Folio ${generalData.prop_folio}`}</h3>
                    </div>
                </div>
            </div>

            <div className=''>
                <Tabs>
                    <Tab label="Datos generales">
                        <ResulGenerales generalData={generalData} />
                    </Tab>
                    <Tab label="Actos">
                        <TabHistorialActo
                            generalData={generalData}
                            actoHistory={actoHistory}
                            setActoHistory={setActoHistory}
                            actoHistoryClone={actoHistoryClone}
                            actoHistoryLoading={actoHistoryLoading}
                        />
                    </Tab>
                    <Tab label="Titulares">
                        <ResulTitulares titulares={titulares} generalData={generalData} />
                    </Tab>
                </Tabs>
            </div>

        </section>
    );

}

export default React.memo(ViewFolio);