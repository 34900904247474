import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../../service/apiServices';
import EncryptService from '../../../service/encrypt.service';
import { Dropdown } from 'primereact/dropdown';

const NotPagos = () => {

    const [hasPayment, setHasPayment] = useState<any>(false)
    const [folio, setFolio] = useState('')
    const [serie, setSerie] = useState('')

    const [folioSearch, setFolioSearch] = useState('')

    const searchPayment = (payment: any) => {
        let datos
        datos = {
            code: 201,
            pago_serie: selectedSerie.code,
            pago_folio: folio
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                addToProduct(res.data)
            } else {
                showUsedPayment(res.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const showUsedPayment = (data: any) => {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
    }

    const searchFolio = () => {
        let datosInfo
        datosInfo = {
            prop_folio: folioSearch,
            prop_online: 0
        }
        ApiServices.apiPost('getBuscaFolio', datosInfo).then(async res => {
            if (res.data.valido === 1 && res.data.addenda.length > 0) {
                const navigateOptions = {
                    state: {
                        datos: res.data.addenda,
                        pagos: products,
                        actos: preLoadActos
                    }
                };
                navigate('/notarios/pago-folio', navigateOptions)
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Folio no encontrado',
                    detail: 'El folio que buscas no se encuentra en el sistema.',
                    life: 5000
                });
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const [NoChecked, setNoChecked] = useState<any>(false);
    const [SiChecked, setSiChecked] = useState<any>(false);
    const dt = useRef<any>(null);
    const [products, setProducts] = useState<any>([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const navigate = useNavigate();
    const toast = useRef<any>(null);

    useEffect(() => {
        /* const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data)); */
    }, []);

    const removeFromProduct = (id: any) => {
        const newArray = JSON.parse(JSON.stringify(products))

        for (let i = 0; i < newArray.length; i++) {
            if (newArray[i].id === id) {
                newArray.splice(i, 1);
                break;
            }
        }

        setProducts(newArray)
        showSuccess()
    }


    function paymentExist(array: any, id: any) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].pago_id === id) {
                return true;
            }
        }
        return false;
    }
    const [preLoadActos, SetPreLoadActos] = useState([])

    const addToProduct = (datos: any) => {
        const newArray = JSON.parse(JSON.stringify(products))
        if (datos.valido === 1) {
            const paymentClone = JSON.parse(JSON.stringify(datos.addenda))
            if (!paymentExist(newArray, paymentClone.pago_id)) {
                newArray.push(paymentClone)
            } else {
                toast.current?.show({ severity: 'error', summary: 'Pago Agregado', detail: 'El Pago que intentas agregar ya esta agregado.', life: 3000 });
            }

            const actosClone: any = JSON.parse(JSON.stringify(preLoadActos))
            actosClone.push(datos.actos)
            SetPreLoadActos(actosClone)
        } else {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: datos.mensaje, life: 3000 });
            if (datos.mensaje === 'Pago ya utilizado') {
            } else {
                const paymentClone = JSON.parse(JSON.stringify(datos.addenda))
                paymentClone.actos = (datos && datos.actos) ? datos.actos : []
                paymentClone.pago_status = 3
                newArray.push(paymentClone)
            }
        }

        setProducts(newArray)
    }

    function randomIntFromInterval(min: any, max: any) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                {rowData.code}
            </>
        );
    }

    const showSuccess = () => {
        /* toast.current?.show({ severity: 'success', summary: 'Pago Borrado', detail: 'Se ha guardado tu borrador.', life: 3000 }); */
    }


    const VerificaTemplate = (rowData: any) => {
        if (rowData)
            return (
                <>
                    {/* icon: se utiliza para hacer la espera del resultado. si fue verificado o no. paso 1 icon */}
                    {rowData.pago_status === 2 &&
                        <i className='pi pi-sync pi-spin'></i>
                    }
                    {/* Si es ok, solo muestra el texto que si pasó */}
                    {rowData.pago_status === 1 &&
                        < div className='flex'>
                            <Button label='' className="p-button-text">< p className='verde'>Verificado</p ></Button>
                            <Button icon="pi pi-trash" tooltip="Eliminar pago" className="p-button-rounded p-button-text p-button-danger" onClick={() => { removeFromProduct(rowData.id) }} />
                        </div >
                    }
                    {/* si no pasó, muestra el div, el usuario tiene que eliminar para tener todo ok, y haga el siguiente paso */}
                    {rowData.pago_status === 3 &&
                        < div className='flex'>
                            <Button label='No verificado' tooltip="Tu pago ya fue utilizado" className="p-button-text p-button-danger" />
                            <Button icon="pi pi-trash" tooltip="Eliminar pago" className="p-button-rounded p-button-text p-button-danger" onClick={() => { removeFromProduct(rowData.id) }} />
                        </div >
                    }

                </>
            );
    }

    const [allDone, setAllDone] = useState(false)

    const validatePayments = () => {
        if (products && products.length <= 0) {
            toast.current.show({ severity: 'error', summary: 'Revisa tus pagos', detail: 'No hay pagos', life: 3000 });
        } else {
            const allDone = checkAllStatusesAreOne(products)
            if (allDone) {
                setAllDone(true)
                setHasPayment(false)
            } else {
                toast.current.show({ severity: 'error', summary: 'Revisa tus pagos', detail: 'Hay pagos que no estan confirmados', life: 3000 });
            }
        }
    }

    function checkAllStatusesAreOne(paymentArray: any) {
        for (const payment of paymentArray) {
            if (payment.pago_status !== 1) {
                return false;
            }
        }
        return true;
    }

    const series = [
        { name: 'A', code: 'A' },
        { name: 'B', code: 'B' },
        { name: 'C', code: 'C' },
        { name: 'F', code: 'F' },
        { name: 'G', code: 'G' },
        { name: 'I', code: 'I' },
        { name: 'J', code: 'J' }
    ];
    const [selectedSerie, setSelectedSerie] = useState(series[0]);



    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className=''>
                <h3 className='color-violet'>Nuevo trámite</h3>
                <p>Sigue los pasos siguientes para tu trámite</p>
            </div>
            <div className='flex justify-content-center align-items-center'>
                <div className='col-12 md:col-10'>
                    <div className='grid'>

                        {!allDone &&
                            <div className='col-12 md:col-12 flex justify-content-center'>
                                <div>
                                    <p>¿Tu trámite tiene pago?</p>
                                    <div className='grid'>
                                        <div className='col-6 p-field-checkbox'>
                                            <Checkbox onChange={e => setHasPayment(!hasPayment)} checked={hasPayment}></Checkbox>
                                            <label>Si</label>
                                        </div>
                                        <div className='col-6 p-field-checkbox'>
                                            <Checkbox onChange={e => setHasPayment(!hasPayment)} checked={!hasPayment}></Checkbox>
                                            <label>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                        {hasPayment && !allDone &&
                            <>
                                <div className='col-12 md:col-12'>
                                    <p>Ingresa el número de serie y folio de tu comprobante de pago.</p>
                                    <div className='grid'>
                                        <div className='col-12 md:col-4'>
                                            <span className="p-float-label">
                                                <Dropdown
                                                    value={selectedSerie}
                                                    onChange={(e) => setSelectedSerie(e.value)}
                                                    options={series}
                                                    optionLabel="name"
                                                    placeholder="Selecciona una opción"
                                                    className="p-inputtext-lg w-full"
                                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                                />
                                            </span>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <span className="p-float-label">
                                                <InputText id="folio" className="p-inputtext-lg" value={folio} onChange={(e) => { setFolio(e.target.value) }} />
                                                <label htmlFor="folio">Folio</label>
                                            </span>
                                        </div>
                                        <div className='col-12 md:col-4'>
                                            <Button label="Agregar pago" size="large" outlined autoFocus onClick={searchPayment} disabled={!((selectedSerie) && (folio.length > 1))} />
                                        </div>
                                    </div>

                                </div>

                                <div className='col-12 md:col-12'>
                                    <p>Espera a que tus pagos ingresados sean verificados</p>
                                    <div className=' pb-4'>
                                        <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            emptyMessage="No hay pagos." responsiveLayout="scroll">
                                            <Column field="pago_folio" header="Folio" sortable  ></Column>
                                            <Column field="pago_serie" header="Serie" sortable  ></Column>
                                            <Column field="name" style={{ width: '10rem' }} body={(e) => VerificaTemplate(e)} ></Column>
                                        </DataTable>
                                    </div>
                                </div>

                                <div className='col-12 md:col-12 flex justify-content-center'>
                                    <div className='col-4'>
                                        <Button label="Continuar" size="large" disabled={!(products && products.length > 0)}
                                            onClick={() => {
                                                validatePayments()
                                            }} />
                                    </div>
                                </div>

                            </>
                        }

                        {!hasPayment &&
                            <div className='col-12 md:col-12'>
                                <p>Ingresa el folio real para realizar la búsqueda</p>
                                <span className="p-float-label">
                                    <InputText
                                        id="username"
                                        className="p-inputtext-lg"
                                        value={folioSearch}
                                        onChange={(e) => { setFolioSearch(e.target.value) }}
                                    />
                                    <label htmlFor="username">Folio real</label>
                                </span>

                                <div className='col-12 md:col-12 flex justify-content-center'>
                                    <div className='col-4'>
                                        <Button label="Buscar" size="large" onClick={searchFolio} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );

}

export default React.memo(NotPagos);