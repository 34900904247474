import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import ApiServices from '../../../service/apiServices';
import ProductService from '../../../serviceExample/ProductService';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

const VenCatNotario = () => {

    const tipo: any = [
        { name: 'Externo', code: 1 },
        { name: 'Interno', code: 2 },
        { name: 'Notario', code: 3 },
    ];

    const status: any = [
        { name: 'Desactivado', code: 0 },
        { name: 'Nuevo', code: 1 },
        { name: 'Activo', code: 2 },
    ];

    const [areas, setAreas] = useState()
    const [perfiles, setPerfiles] = useState()
    const [foundedColony, setFoundedColony] = useState([])
    const [selectedCP, setSelectedCP] = useState<any>(null)
    const [selectedUser, setSelectedUser] = useState<any>(null)
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);

    const [notaryData, setNotary] = useState({
        name: { value: "", valid: true },
        lastName: { value: "", valid: true },
        secondLastName: { value: "", valid: true },
        email: { value: "", valid: true },
        phone: { value: "", valid: true },
        notaryNumber: { value: "", valid: true },
        street: { value: "", valid: true },
        interiorNumber: { value: "", valid: true },
        exteriorNumber: { value: "", valid: true },
        cp_cp: { value: "", valid: true }
    });

    const handleNotaryData = (value: any, jsonId: any) => {
        const temporalData = JSON.parse(JSON.stringify(notaryData));
        temporalData[jsonId].value = value;
        setNotary(temporalData);
    };

    const handleCPChange = (cp: any) => {
        if (cp.length === 5) {
            getColonyWithPostalCode(cp)
        }
    }

    const getColonyWithPostalCode = (value: any) => {
        let datos
        datos = { "cp_cp": value }
        ApiServices.apiPost('buscarCP', datos).then(async res => {
            if (res.data.valido === 1) {
                setFoundedColony(res.data.addenda)
                setSelectedCP(res.data.addenda[0])
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const getColonyWithPostalCodeWithPreviosColony = (value: any, colonia: any) => {
        let datos
        datos = { "cp_cp": value }
        ApiServices.apiPost('buscarCP', datos).then(async res => {
            if (res.data.valido === 1) {
                setFoundedColony(res.data.addenda)
                if (res.data.addenda && res.data.addenda.length > 0) {
                    const found = res.data.addenda.find((element: any) => element.cp_col == colonia);
                    if (found)
                        setSelectedCP(found)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const ColonyTemplate = (option: any) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '0px',
                background: '#FFFFFF',
                width: '100%',
                height: '100%',
                fontFamily: 'Cabin',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#313131'
            }
            }> {option.cp_col}</div >
        );
    };

    const checkAllValues = () => {
        return (
            notaryData.notaryNumber.value &&
            notaryData.name.value &&
            notaryData.lastName.value &&
            notaryData.secondLastName.value &&
            notaryData.email.value &&
            notaryData.phone.value &&
            selectedCP.cp_id &&
            notaryData.street.value &&
            notaryData.interiorNumber.value &&
            notaryData.exteriorNumber.value
        )
    }

    const handleCreateNotary = () => {
        if (checkAllValues()) {
            let datos
            datos = {
                "not_num": notaryData.notaryNumber.value,
                "not_nom": notaryData.name.value,
                "not_pat": notaryData.lastName.value,
                "not_mat": notaryData.secondLastName.value,
                "not_email": notaryData.email.value,
                "not_tel": notaryData.phone.value,
                "cp_id": selectedCP.cp_id,
                "not_calle": notaryData.street.value,
                "not_int": notaryData.interiorNumber.value,
                "not_ext": notaryData.exteriorNumber.value
            }
            ApiServices.apiPost('addNotario', datos).then(async res => {
                if (res.data.valido === 1) {
                    setVisible(false)
                    showSuccess()
                    getUsers()
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const handleNotaryEditData = (value: any, jsonId: any) => {
        const temporalData = JSON.parse(JSON.stringify(selectedUser));
        temporalData[jsonId] = value;
        setSelectedUser(temporalData);
    };

    const handleEditNotary = () => {
        let datos
        datos = {
            "not_id": selectedUser.not_id,
            "not_num": selectedUser.not_num,
            "not_nom": selectedUser.not_nom,
            "not_pat": selectedUser.not_pat,
            "not_mat": selectedUser.not_mat,
            "not_email": selectedUser.not_email,
            "not_tel": selectedUser.not_tel,
            "cp_id": selectedCP ? selectedCP.cp_id : selectedUser.cp_id,
            "not_calle": selectedUser.not_calle,
            "not_int": selectedUser.not_int,
            "not_ext": selectedUser.not_ext
        }
        ApiServices.apiPost('updateNorario', datos).then(async res => {
            if (res.data.valido === 1) {
                setVisibleEdit(false)
                showSuccess()
                getUsers()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    //

    useEffect(() => {
        getAreas()
        getPerfiles()
    }, [])

    useEffect(() => {
        if (areas && perfiles) {
            getUsers()
        }
    }, [areas, perfiles])

    const getAreas = () => {
        let datos
        ApiServices.apiPost('catAreas', datos).then(async res => {
            setAreas(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getPerfiles = () => {
        let datos
        ApiServices.apiPost('catPerfiles', datos).then(async res => {
            setPerfiles(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getUsers = () => {
        let datos
        ApiServices.apiPost('catNotarios', datos).then(async res => {
            const temporalArray: any = []
            if (res.data.addenda && res.data.addenda.length > 0) {
                res.data.addenda.map((singleElement: any) => {
                    temporalArray.push(singleElement)
                })
            }
            setUsers(temporalArray)
            processUsers(temporalArray)
        }).catch(error => {
            console.log(error)
        })
    }

    const getStatus = (status: any) => {
        switch (status) {
            case 0:
                return 'NUEVO'
            case 1:
                return 'ACTIVADO'
            case 2:
                return 'DESACTIVADO'
            default:
                return ''
        }
    }

    function obtenerJsonPorCodigo(array: any, codigo: any, searchType: any = 'code') {
        if (codigo) {
            for (var i = 0; i < array.length; i++) {
                const tempArray = array[i]
                if (tempArray[searchType] === codigo) {
                    return array[i];
                }
            }
            return null;
        } else {
            return null
        }
    }

    function formatDate(fechaISO: Date) {
        const fecha = new Date(fechaISO);
        const dia = fecha.toLocaleDateString('es-ES', { day: '2-digit' });
        const mes = fecha.toLocaleDateString('es-ES', { month: 'long' });
        const anio = fecha.toLocaleDateString('es-ES', { year: 'numeric' });

        return `${dia}/${mes}/${anio}`;
    }

    const processUsers = (userData: any) => {
        if (userData && userData.length > 0) {
            const newUsersArray: any = []
            userData.map((user: any) => {
                const newUser = JSON.parse(JSON.stringify(user));

                let areaDescription = user.area_desc
                let perfilDescription = user.prf_desc
                let typeDescription = obtenerNombrePorCodigo(tipo, user.usu_tipo)
                let statusDescription = getStatus(user.usu_status)

                if (!user.area_desc)
                    areaDescription = obtenerJsonPorCodigo(areas, user.area_id, 'areas_id')
                if (!user.prf_id)
                    perfilDescription = obtenerJsonPorCodigo(perfiles, user.prf_id, 'prf_id')

                newUser.area_desc = areaDescription
                newUser.prf_desc = perfilDescription
                newUser.usu_tipo_desc = typeDescription
                newUser.usu_complete_name = `${user.not_nom || ''} ${user.not_pat || ''} ${user.not_mat || ''}`
                newUser.not_complete_dom = `${user.not_calle || ''} ${user.not_ext || ''}`
                newUser.usu_status_desc = statusDescription
                newUser.not_update = formatDate(newUser.not_update)

                newUsersArray.push(newUser)
            })
            setUsers(newUsersArray)
            setOriginalUsers(newUsersArray)
        }
    }

    function obtenerNombrePorCodigo(array: any, codigo: any) {
        if (codigo) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].code === codigo) {
                    return array[i].name;
                }
            }
            return '-';
        } else {
            return '-'
        }
    }

    const handleDelete = () => {
        let datos
        datos = { "not_id": selectedUser.not_id }
        ApiServices.apiPost('delNotario', datos).then(async res => {
            if (res.data.valido === 1) {
                setVisibleEdit(false)
                showSuccess()
                getUsers()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const [users, setUsers] = useState([])
    const [originalUsers, setOriginalUsers] = useState([])

    const toast = useRef<Toast>(null);
    const productService = new ProductService();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [rowClick, setRowClick] = useState(true);
    const [visible, setVisible] = useState<boolean>(false);

    const showSuccess = () => {
        toast.current?.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se han guardado los cambios exitosamente', life: 3000 });
    }

    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
            <Button label="Guardar" onClick={handleCreateNotary} autoFocus />
        </div>
    );

    const footerEdit = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <Button label="Eliminar" severity="danger" onClick={handleDelete} />
            </div>
            <div>
                <Button label="Cancelar" onClick={() => setVisibleEdit(false)} severity="secondary" outlined />
                <Button label="Guardar" onClick={handleEditNotary} autoFocus />
            </div>
        </div>
    );

    useEffect(() => {
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => {
                    setSelectedUser(rowData)
                    if (rowData && rowData.cp_cp) {
                        getColonyWithPostalCodeWithPreviosColony(rowData.cp_cp, rowData.cp_col)
                    }
                    setVisibleEdit(true)
                    setSelectedCP(null)
                    handleNotaryData('', 'cp_cp')
                }} />
            </div>
        );
    }

    const placeBodyTemplate = (rowData: any) => {
        return (
            <p>{rowData && `${rowData.cp_mun || ''}, ${rowData.cp_edo || ''}`}</p>
        );
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-10'>
                    <h3>Catálogo Notarios</h3>
                    <p>Realiza los movimientos necesarios.</p>
                </div>
                <div className='col-12 md:col-2'><Button label="Nuevo notario" size="large" onClick={() => setVisible(true)} /></div>
                <div className='col-12'>
                    <DataTable value={users} paginator rows={10}
                        onSelectionChange={(e) => {
                            const value = e.value as Product[];
                            setSelectedProducts(value);
                        }} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                        <Column sortable field="not_update" header="Fecha registro"></Column>
                        <Column sortable field="usu_complete_name" header="Nombre notario"></Column>
                        <Column sortable field="not_fedata" header="Notaria"></Column>
                        <Column sortable field="not_complete_dom" header="Lugar" body={placeBodyTemplate}></Column>
                        <Column body={(e) => (actionBodyTemplate(e))} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>

            {/* Dialog ADD */}
            <Dialog
                header="Notario"
                visible={visible}
                maximizable
                style={{ width: '50vw' }}
                onHide={() => setVisible(false)}
                footer={footerContent}
            >
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="name"
                                className="p-inputtext-lg"
                                value={notaryData.name.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                            />
                            <label htmlFor="name">Nombre</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="lastName"
                                className="p-inputtext-lg"
                                value={notaryData.lastName.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                            />
                            <label htmlFor="lastName">Primer apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="secondLastName"
                                className="p-inputtext-lg"
                                value={notaryData.secondLastName.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                            />
                            <label htmlFor="secondLastName">Segundo apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <InputText
                                id="email"
                                className="p-inputtext-lg"
                                value={notaryData.email.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                            />
                            <label htmlFor="email">Correo eléctronico</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <InputMask
                                id="phone"
                                value={notaryData.phone.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                                className={`p-inputtext-lg ${!notaryData.phone.valid && "p-invalid"}`}
                                mask="(999) 999-9999"
                                placeholder="(999) 999-9999"
                            />
                            <label htmlFor="phone">Teléfono</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText
                                id="notaryNumber"
                                className="p-inputtext-lg"
                                value={notaryData.notaryNumber.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                                keyfilter="int"
                            />
                            <label htmlFor="notaryNumber"># Notaria</label>
                        </span>
                    </div>
                </div>
                <div className='grid'>
                    <div className='col-12 md:col-12'>
                        <h6 className='py-1'>Domicilio</h6>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="street"
                                className="p-inputtext-lg"
                                value={notaryData.street.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                            />
                            <label htmlFor="street">Calle</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="interiorNumber"
                                className="p-inputtext-lg"
                                value={notaryData.interiorNumber.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                            />
                            <label htmlFor="interiorNumber">N° interior</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="exteriorNumber"
                                className="p-inputtext-lg"
                                value={notaryData.exteriorNumber.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                }}
                            />
                            <label htmlFor="exteriorNumber">N° exterior</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="cp_cp"
                                className="p-inputtext-lg"
                                value={notaryData.cp_cp.value}
                                onChange={(e) => {
                                    handleNotaryData(e.target.value, e.target.id);
                                    handleCPChange(e.target.value)
                                }}
                            />
                            <label htmlFor="cp_cp">Codigo postal</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-8'>
                        <span className="p-float-label">
                            <Dropdown
                                id="colony"
                                value={selectedCP}
                                onChange={(e: any) => setSelectedCP(e.value)}
                                options={foundedColony}
                                optionLabel="cp_col"
                                placeholder="Colonia"
                                itemTemplate={ColonyTemplate}
                                panelStyle={{ background: '#FFF' }}
                                className="p-inputtext-lg"
                                emptyMessage='Sin Resultados'
                                style={{ width: '100%' }}
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="colony">Colonia</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <InputText
                                id="state"
                                className="p-inputtext-lg"
                                value={selectedCP ? selectedCP.cp_edo : ''}
                                disabled
                            />
                            <label htmlFor="state">Estado</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <InputText
                                id="municipality"
                                className="p-inputtext-lg"
                                value={selectedCP ? selectedCP.cp_mun : ''}
                                disabled
                            />
                            <label htmlFor="municipality">Municipio</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            {/* Dialog Edit */}
            <Dialog
                header="Editar Notario"
                visible={visibleEdit}
                maximizable
                style={{ width: '50vw' }}
                onHide={() => setVisibleEdit(false)}
                footer={footerEdit}
            >
                {selectedUser &&
                    <>
                        <div className='grid'>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="not_nom"
                                        className="p-inputtext-lg"
                                        value={selectedUser.not_nom}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                    />
                                    <label htmlFor="not_nom">Nombre</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="not_pat"
                                        className="p-inputtext-lg"
                                        value={selectedUser.not_pat}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                    />
                                    <label htmlFor="not_pat">Primer apellido</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="not_mat"
                                        className="p-inputtext-lg"
                                        value={selectedUser.not_mat}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                    />
                                    <label htmlFor="not_mat">Segundo apellido</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                    <InputText
                                        id="not_email"
                                        className="p-inputtext-lg"
                                        value={selectedUser.not_email}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                    />
                                    <label htmlFor="not_email">Correo eléctronico</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                    <InputMask
                                        id="not_tel"
                                        value={selectedUser.not_tel}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                        className={`p-inputtext-lg ${!notaryData.phone.valid && "p-invalid"}`}
                                        mask="(999) 999-9999"
                                        placeholder="(999) 999-9999"
                                    />
                                    <label htmlFor="not_tel">Teléfono</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-12'>
                                <span className="p-float-label">
                                    <InputText
                                        id="not_num"
                                        className="p-inputtext-lg"
                                        value={selectedUser.not_num}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                        keyfilter="int"
                                    />
                                    <label htmlFor="not_num"># Notaria</label>
                                </span>
                            </div>
                        </div>
                        <div className='grid'>
                            <div className='col-12 md:col-12'>
                                <h6 className='py-1'>Domicilio</h6>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="not_calle"
                                        className="p-inputtext-lg"
                                        value={selectedUser.not_calle}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                    />
                                    <label htmlFor="not_calle">Calle</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="not_int"
                                        className="p-inputtext-lg"
                                        value={selectedUser.not_int}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                    />
                                    <label htmlFor="not_int">N° interior</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="not_ext"
                                        className="p-inputtext-lg"
                                        value={selectedUser.not_ext}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                        }}
                                    />
                                    <label htmlFor="not_ext">N° exterior</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="cp_cp"
                                        className="p-inputtext-lg"
                                        value={selectedUser.cp_cp}
                                        onChange={(e) => {
                                            handleNotaryEditData(e.target.value, e.target.id);
                                            handleCPChange(e.target.value)
                                        }}
                                    />
                                    <label htmlFor="cp_cp">Codigo postal</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-8'>
                                <span className="p-float-label">
                                    <Dropdown
                                        id="colony"
                                        value={selectedCP}
                                        onChange={(e: any) => console.log('')}
                                        options={foundedColony}
                                        optionLabel="cp_col"
                                        placeholder="Colonia"
                                        itemTemplate={ColonyTemplate}
                                        panelStyle={{ background: '#FFF' }}
                                        className="p-inputtext-lg"
                                        emptyMessage='Sin Resultados'
                                        style={{ width: '100%' }}
                                        virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                    />
                                    <label htmlFor="colony">Colonia</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                    <InputText
                                        id="state"
                                        className="p-inputtext-lg"
                                        value={selectedCP ? selectedCP.cp_edo : selectedUser.cp_edo}
                                        disabled
                                    />
                                    <label htmlFor="state">Estado</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                    <InputText
                                        id="municipality"
                                        className="p-inputtext-lg"
                                        value={selectedCP ? selectedCP.cp_mun : selectedUser.cp_mun}
                                        disabled
                                    />
                                    <label htmlFor="municipality">Municipio</label>
                                </span>
                            </div>
                        </div>
                    </>
                }
            </Dialog>
        </section >
    );


}

export default React.memo(VenCatNotario);