import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Menu } from 'primereact/menu';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Timeline } from 'primereact/timeline';
import { Panel } from 'primereact/panel';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import ProductService from '../../../../serviceExample/ProductService';
import { MultiSelect } from 'primereact/multiselect';
import { useLocation } from 'react-router-dom';

interface City {
    name: string;
}
/* DEPRECATED */
const ComActos = (props: any) => {

    const location = useLocation();

    const [allHistroy, setAllHistory] = useState(location.state.actos)
    const [searchField, setSearchField] = useState('')

    const dt = useRef<any>(null);
    const [products, setProducts] = useState<any>(null);
    const menu = useRef(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [visible, setVisible] = useState<boolean>(false);
    const [selectedCities, setSelectedCities] = useState(null);


    const events1 = [
        { fecha: 'Enero 2020' },
        { fecha: 'Enero 2021' },
        { fecha: 'Enero 2022' },
        { fecha: 'Enero 2023' },
    ];

    const folios: City[] = [
        { name: 'folio 1' },
        { name: 'folio 2' },
    ];

    const customizedMarker = (item: any) => {
        return (
            <div>
                <i className='pi pi-circle'></i>
            </div>
        );
    };

    const template = (options: any) => {
        return (
            <div className='p-panel-header block'>
                <div className='grid'>
                    <div className='col-3'> <p><span className='font-bold'>Tramite:</span> {options.trm_id}</p></div>
                    <div className='col-3'> <p><span className='font-bold'>Fecha:</span> {formatFecha(options.trm_fecha)}</p></div>
                </div>
            </div>
        )
    }

    const customizedContent = (item: any) => {
        return (
            <div className=''>
                <div className='grid'>
                    <div className='col-12'>
                        <Panel headerTemplate={template(item)}>
                            <DataTable ref={dt} value={item.actos} globalFilter={globalFilter}
                                dataKey="id" paginator rows={5} className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                emptyMessage="No products found." responsiveLayout="scroll">
                                <Column field="act_clave" header="Clave" sortable></Column>
                                <Column field="act_nombre" header="Acto" sortable></Column>
                                <Column field="name" body={FirmadoTemplate} ></Column>
                                {/* <Column field="price" style={{ width: '10rem' }} body={EyeTemplate}></Column> */}
                            </DataTable>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []);


    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.code}
            </>
        );
    }

    const FirmadoTemplate = (rowData: any) => {
        if (rowData.calificador) {
            return (
                <>
                    <p>Firmado</p>
                </>
            );
        } else {
            return (
                <>
                    <p>Sin firma</p>
                </>
            );
        }
    }

    const ClaveTemplate = (rowData: any) => {
        return (
            <>
                <a className='p-button-text tooltip-alert' >CI2</a>
                <Tooltip target=".tooltip-alert" autoHide={false}>
                    <div className="p-3">
                        <h5>Este acto esta sin firmar</h5>
                        <small>Fecha de registro 12/Ene/2022</small>
                        <br />
                        <small>Fecha limite 12/Mar/2022</small>
                    </div>
                </Tooltip>
            </>
        );
    }


    const EyeTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <Button className="p-button-text" icon="pi pi-eye" />
                </div>
            </>
        );
    }



    function filtrarTransacciones(texto: any, fecha: any) {
        if (!fecha && !texto) {
            setAllHistory(location.state.actos)
        } else {
            const newArray = location.state.actos.filter((item: any) => {
                const itemDate = item.firstDate;
                const filterDate = new Date(fecha).toISOString().split('T')[0];
                if (fecha && itemDate !== filterDate) return false;
                if (texto) {
                    const objectString = JSON.stringify(item);
                    return objectString.includes(texto);
                }
                return true;
            });
            setAllHistory(newArray)
        }
    }

    function convertirFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${año}-${mes}-${dia}T00:00:00.000Z`;
        } else {
            return ''
        }
    }

    function formatFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${año}-${mes}-${dia}`;
        } else {
            return ''
        }
    }

    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        // Convertir el formato MMDDYY a MM/DD/YYYY
        const formattedDate = formatDateString(inputValue);

        if (formattedDate) {
            filtrarTransacciones(searchField, convertirFecha(new Date(formattedDate)))
            setDate2(new Date(formattedDate));
        } else {
            setDate2(null);
        }

        // Actualizar el valor del input del Calendar manualmente si el input existe
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };

    return (
        <section className='container-fluid'>

            <div className='grid'>
                <div className='col-12 md:col-8 pb-5'>
                    <h3 className='color-violet'>Búsqueda de coincidencias</h3>
                    <p>Se encontraros los resultados con los siguientes datos.</p>
                </div>

                {/* <div className='col-12 md:col-4'>
                    <span className="p-float-label">
                        <MultiSelect value={selectedCities} onChange={(e) => setSelectedCities(e.value)} options={folios} optionLabel="name"
                            maxSelectedLabels={3} className="w-100 p-inputtext-lg" />
                        <label htmlFor="dd-city">Folios encontrados</label>
                    </span>
                </div> */}
            </div>


            <div className='grid'>
                <div className='col-12 md:col-6'>
                    <h3 className='color-violet'>Historial</h3>
                    <p>Busca los actos con nuestro buscador</p>
                </div>
                <div className='col-12 md:col-3'>
                    <span className="p-float-label">
                        <Calendar
                            value={date2}
                            onChange={(e: any) => {
                                filtrarTransacciones(searchField, convertirFecha(e.value))
                                setDate2(e.value)
                            }}
                            dateFormat="dd/MM/yy"
                            showIcon
                            locale="es"
                            className="p-inputtext-lg"
                            showButtonBar
                            onClearButtonClick={() => { filtrarTransacciones(searchField, convertirFecha(null)) }}
                            ref={calendarRef}
                            onInput={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="username">Fecha*</label>
                    </span>
                </div>
                <div className='col-12 md:col-3'>
                    <span className="p-float-label p-input-icon-left w-100">
                        <i className="pi pi-search" />
                        <InputText
                            className=' p-inputtext-lg'
                            type="search"
                            onChange={(e) => {
                                filtrarTransacciones(e.target.value, convertirFecha(date2))
                                setSearchField(e.target.value)
                            }}
                        />
                        <label htmlFor="username">Buscador general</label>
                    </span>
                </div>
            </div>

            <div className='pt-3'>
                <Timeline value={allHistroy} align="left" opposite={(item) => item.fecha} marker={customizedMarker} content={customizedContent} />
            </div>


        </section>
    );

}

export default React.memo(ComActos);