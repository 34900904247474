import './tab.css'

const TabButtons = ({ buttons, changeTab, activeTab, buttonDisabled = false }) => {

    return (
        <div className="tab-buttons">
            {buttons.map(button => {
                return <button
                    className={`
                    ${button.label === activeTab ? 'active' : 'inactive'}
                    ${button.buttonDisabled ? ' buttonDisabled' : ''}
                  `
                    }
                    onClick={() => changeTab(button.label)}
                    disabled={button.buttonDisabled}
                >
                    {button.label}
                </button>
            })}
        </div>
    )
}

export default TabButtons;