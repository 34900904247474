import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../service/apiServices';
import authGuardService from '../../service/authGuardService';
import EncryptService from '../../service/encrypt.service';
import ProductService from '../../serviceExample/ProductService';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { TieredMenu } from 'primereact/tieredmenu';
import configuration from '../../configurationFile';
/* import { exportToExcel } from 'react-json-to-excel';
 */
interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

interface City {
    name: string;
}

const VenAdminInicio = () => {

    const currentDate = new Date();
    const isoString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}.${currentDate.getMilliseconds().toString().padStart(3, '0')}Z`;

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });


    const [listTramites, setListTramites] = useState([])
    const [listOriginal, setListOriginal] = useState([])
    const [listAnalistas, setListAnalistas] = useState([])
    const [listNotarios, setListNotarios] = useState([])

    useEffect(() => {
        getList()
        getAnalistas()
        getNotarios()
    }, [])

    const getAnalistas = () => {
        let datos
        ApiServices.apiPost('getAnalistas', datos).then(async res => {
            setListAnalistas(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getNotarios = () => {
        let datos
        ApiServices.apiPost('catNotarios', datos).then(async res => {
            setListNotarios(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    function filtrarPorPtrmId(array: any) {
        return array.filter((obj: any) => (obj.ptrm_id === null || obj.ptrm_id === 0 || obj.ptrm_id === "0"));
    }


    const getList = (dateToSearch: any = isoString) => {
        if (dateToSearch) {
            let datos
            datos = { "code": 207, usu_id: 0, texto: "", fecha: dateToSearch }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido && res.data.addenda && res.data.addenda.length > 0) {
                    const newList = res.data.addenda.map((singleElement: any) => {
                        singleElement.formatDate = formatDate(singleElement.trm_fecha)
                        return singleElement
                    })
                    const filteredArray = filtrarPorPtrmId(newList);
                    setListTramites(filteredArray)
                    setListOriginal(filteredArray)
                } else {
                    setListTramites([])
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const statusBodyTemplate = (tramite: any) => {
        const statusType = getStatusType(tramite.trm_status)
        return <Tag value={statusType} severity={getSeverity(tramite.trm_status)}></Tag>;
    };

    const getStatusType = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'Borrador';
            case 1:
                return 'Capturado';
            case 2:
                return 'Asignado';
            case 3:
                return 'Analizado';
            case 4:
                return 'Rechazado';
            case 5:
                return 'Por Subsanar';
            case 6:
                return 'Subsanado';
            case 7:
                return 'Por Firmar Aceptado';
            case 8:
                return 'Por Firmar Rechazado';
            case 9:
                return 'Firmado Aceptado';
            case 10:
                return 'Firmado Rechazado';
            default:
                return null;
        }
    };

    const getSeverity = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'info'
            case 2:
                return 'warning';
            case 3:
                return 'danger';
            case 4:
                return 'warning';
            case 5:
                return 'success';
            case 6:
                return 'info'
            case 7:
                return 'warning';
            case 8:
                return 'danger';
            case 9:
                return 'success';
            case 10:
                return 'success';
            default:
                return null;
        }
    };

    function formatDate(date: any) {
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const newDate = new Date(date);
        const day = newDate.getUTCDate();
        const month = months[newDate.getUTCMonth()];
        const year = newDate.getUTCFullYear();

        const fechaFormateada = `${day}/${month}/${year}`;
        return fechaFormateada;
    }





    /* old */
    const user = authGuardService.getUser()
    const productService = new ProductService();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [selectedCities, setSelectedCities] = useState(null);

    const groupedCities = [
        {
            label: 'Certificaciones',
            code: 'DE',
            items: [
                { label: 'C-001', value: 'Berlin' },
                { label: 'C-002', value: 'Frankfurt' },
                { label: 'C-003', value: 'Hamburg' },
            ]
        },
        {
            label: 'Jurídico',
            code: 'US',
            items: [
                { label: 'J-001', value: 'Chicago' },
                { label: 'J-002', value: 'Los Angeles' },
                { label: 'J-003', value: 'New York' },
            ]
        },
        {
            label: 'Registro',
            code: 'JP',
            items: [
                { label: 'R-001', value: 'Kyoto' },
                { label: 'R-002', value: 'Osaka' },
                { label: 'R-003', value: 'Tokyo' },
            ]
        }
    ];

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.label} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.label}</div>
            </div>
        );
    };


    useEffect(() => {
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    const menu = useRef(null);

    const downloadPDF = async (id: any) => {
        let datos
        datos = { "code": 213, "trm_id": id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    window.open(`${configuration.url}${res.data.addenda}`)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }


    const actionBodyTemplate = (rowData: any) => {
        const navigateOptions = {
            state: {
                tramite: rowData,
            }
        };

        return (
            <div className="actions flex">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => { navigate('/ventanilla/vt-tramite', navigateOptions) }} />
                <TieredMenu model={[
                    {
                        label: 'Descargar Boleta de Ingreso',
                        icon: 'pi pi-file-export',
                        command: () => {
                            downloadPDF(rowData.trm_id)
                        }
                    }
                ]} popup ref={menu} breakpoint="767px" />
                <Button icon="pi pi-ellipsis-h" rounded text severity="secondary" onClick={(e) => menu.current.toggle(e)} />
            </div>
        );
    }

    function buscarPorAreaId(array: any, areaId: any) {
        const resultArray = array.filter((obj: any) => obj.area_id == areaId);
        if (resultArray.length > 0)
            return resultArray[0]
        else
            return null
    }

    const handleChangeAnalist = (rowData: any, e: any) => {
        let datos = {
            trm_id: rowData.trm_id,
            usu_id: user.usu_id,
            usu_id_mov: e.value.usu_id
        }
        ApiServices.apiPost('setAnalista', datos).then(async res => {

        }).catch(error => {
            console.log(error)
        })
    }

    const handleRegistradas = () => {
        let datos: any = {
            code: 608,
            type: 9,
            fecha: date2 || currentDate
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url) {
                    await new Promise(resolve => setTimeout(resolve, 1500));
                    window.open(`${res.data.url}`)
                } else {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                }
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
            }
        }).catch(error => {
            console.log(error)
        })
    }


    const AsignarTemplate = (rowData: any) => {
        const resultados = buscarPorAreaId(listAnalistas, rowData.clavearea);
        return (
            <div>
                <p>{resultados && `${resultados.usu_nom || ''} ${resultados.usu_pat || ''} - ${resultados.area_desc || ''}`}</p>
            </div>
        );
    };

    const templateTemporal = (rowData: any) => (
        <p>{rowData ? rowData.prop_clave : ''}</p>
    );

    const templateWorker = (rowData: any) => (
        <p>{rowData ? `${rowData.usu_nom || ''} ${rowData.usu_pat || ''} ${rowData.usu_mat || ''}` : ''}</p>
    );

    const controlInternoBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.trm_fecha);
        const horaUTC = new Date(rowData.trm_hora);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();
        const hours = horaUTC.getUTCHours().toString().padStart(2, '0');
        const minutes = horaUTC.getUTCMinutes().toString().padStart(2, '0');
        const seconds = horaUTC.getUTCSeconds().toString().padStart(2, '0');


        const fechaFormateada = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

        return (
            <div className="actions">
                <p>{`${fechaFormateada}`}</p>
            </div>
        )
    }

    const [globalFilter, setGlobalFilter] = useState<any>(null);

    function filtrarPorFecha(array: any, fechaBuscada: any) {
        const newArray = array.filter((item: any) => {
            const fechaObjeto = new Date(item.trm_fecha);
            fechaObjeto.setSeconds(0);
            fechaObjeto.setMilliseconds(0);
            const fechaBuscadaAjustada = new Date(fechaBuscada);
            const fechaFormateada1 = fechaObjeto.toISOString().split('T')[0];
            const fechaFormateada2 = fechaBuscadaAjustada.toISOString().split('T')[0];
            return fechaFormateada1 === fechaFormateada2;
        });
        setListTramites(newArray)
    }



    const representativesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.nombre_notario}</span>
            </div>
        );
    };

    const [selectedNotario, setSelectedNotario] = useState<any>()

    function getByNotId(notaria) {
        if (notaria) {
            listOriginal &&
                setListTramites(listOriginal.filter(item => item.not_id == notaria.not_id))
        } else {
            setListTramites(listOriginal)
        }

    }

    const generateExcel = () => {
        const newTramites = listTramites.map((singleTramite) => {
            const areaCelda = buscarPorAreaId(listAnalistas, singleTramite.clavearea);

            const fechaUTC = new Date(singleTramite.trm_fecha);
            const horaUTC = new Date(singleTramite.trm_hora);

            const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
            const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = fechaUTC.getUTCFullYear();
            const hours = horaUTC.getUTCHours().toString().padStart(2, '0');
            const minutes = horaUTC.getUTCMinutes().toString().padStart(2, '0');
            const seconds = horaUTC.getUTCSeconds().toString().padStart(2, '0');


            const fechaFormateada = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

            return ({
                "Numero de Control Interno": singleTramite.trm_consecutivo,
                "Fecha": fechaFormateada,
                "Folio Electronico": singleTramite.prop_folio,
                "Area y Celda": areaCelda && `${areaCelda.usu_nom || ''} ${areaCelda.usu_pat || ''} - ${areaCelda.area_desc || ''}`,
                "Clave Catastral": singleTramite.prop_clave,
                "Trabajador": singleTramite ? `${singleTramite.usu_nom || ''} ${singleTramite.usu_pat || ''} ${singleTramite.usu_mat || ''}` : '',
                "Fedetario": singleTramite.nombre_notario,
                "Solicitante": singleTramite.trm_solicitante,
                "Actos": singleTramite.actos,
                "Status": getStatusType(singleTramite.trm_status)
            })
        })

        /* exportToExcel(newTramites, 'TramitesExcel') */
    }

    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            setDate2(new Date(formattedDate));
            getList(new Date(formattedDate))
        } else {
            setDate2(null);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };


    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <h3 className='color-violet'>Ingreso de Trámites</h3>
                    <p>Busca algún control interno para ver el estatus</p>
                </div>
                <div className='col-12 md:col-8'>
                    <div className='grid'>
                        <div className='col-2'>
                            <span className="p-float-label w-100">
                                <Button label="Descargar Excel" onClick={generateExcel} />
                            </span>
                        </div>
                        <div className='col-2'>
                            <span className="p-float-label w-100">
                                <Dropdown
                                    value={selectedNotario}
                                    options={listNotarios}
                                    itemTemplate={representativesItemTemplate}
                                    onChange={(e) => {
                                        setSelectedNotario(e.value)
                                        getByNotId(e.value)
                                    }}
                                    optionLabel="nombre_notario"
                                    showClear
                                />
                                {!(date2) &&
                                    <label htmlFor="dd-city">Fedetario</label>
                                }
                            </span>
                        </div>
                        <div className='col-2'>
                            <span className="p-float-label w-100">
                                {/* Input Done */}
                                <Calendar
                                    id="icon"
                                    className="p-inputtext-lg"
                                    value={date2}
                                    showIcon
                                    dateFormat='dd/mm/yy'
                                    onChange={(e: any) => {
                                        getList(e.value)
                                        setDate2(e.value)
                                    }}
                                    onClearButtonClick={() => { getList() }}
                                    showButtonBar
                                    locale="es"
                                    ref={calendarRef}
                                    onInput={(e) => handleInputChange(e)}
                                />
                                {!(date2) &&
                                    <label htmlFor="dd-city">Fecha</label>
                                }
                            </span>
                        </div>
                        <div className='col-3'>
                            <span className="p-float-label p-input-icon-left w-100">
                                <i className="pi pi-search" />
                                <InputText
                                    className='p-inputtext-lg'
                                    type="search"
                                    onChange={(e) => {
                                        setGlobalFilter(e.target.value)
                                    }}
                                />
                                <label htmlFor="username">Buscador general</label>
                            </span>
                        </div>
                        <div className='col-3'>
                            <Button label="Descargar registradas" onClick={handleRegistradas} />
                        </div>
                    </div>

                </div>
                <div className='col-12'>
                    <DataTable
                        value={listTramites}
                        paginator
                        rows={10}
                        dataKey="trm_id"
                        tableStyle={{ minWidth: '50rem' }}
                        globalFilter={globalFilter}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        emptyMessage="No se encontraron tramites."
                        filterDisplay="row"
                    >
                        <Column sortable field="trm_consecutivo" header="# Control interno"></Column>
                        <Column sortable field="trm_hora" header="Fecha" body={controlInternoBodyTemplate}></Column>
                        <Column sortable field="prop_folio" header="Folio electronico"></Column>
                        <Column sortable field="clavearea" header="Área y celda" body={AsignarTemplate}></Column>
                        <Column sortable field="prop_clave" header="Clave catastral" body={templateTemporal}></Column>
                        <Column sortable field="usu_nom" header="Trabajador" body={templateWorker}></Column>
                        <Column sortable field="nombre_notario" header="Fedetario" ></Column>
                        <Column sortable field="trm_solicitante" header="Solicitante"></Column>
                        <Column field="actos" header="Actos"></Column>
                        <Column body={statusBodyTemplate} sortable field="trm_status" header="Estatus"></Column>
                        <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </section>
    );

}

export default React.memo(VenAdminInicio);