import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import App from '../src/App';
import { Error } from '../src/pages/Error';
import { NotFound } from '../src/pages/NotFound';
import { Access } from '../src/pages/AccessDenied';
import authGuardService from '../src/service/authGuardService';
import configFile from './configurationFile'
import MenuService from './service/menu.service';
import EncryptService from './service/encrypt.service';
import { Toast } from 'primereact/toast';
import { LoginVentanilla } from './pages/Publico/Login/Login';
import OlvideContraseña from './pages/Publico/OlvideContraseña/OlvideContraseña';
import PreCaptura from './pages/Publico/PreCaptura/PreCaptura';
import VerificarDatos from './pages/Publico/PreCaptura/VerificarDatos';
import Registro from './pages/Publico/Registro/Registro';
import { MainTopBarPublic } from './pages/Publico/TopBar/MainTopBarPublic';
import { Landing } from './pages/Publico/LandingPage/Landing';
import { TimerProvider } from './context/TimerContext';

const AppWrapper = (props: any) => {
	const [colorScheme, setColorScheme] = useState(configFile.theme)
	const [theme, setTheme] = useState('green');
	const [componentTheme, setComponentTheme] = useState('green');
	const [permissions, setPermissions] = useState<any>(null);

	const token = authGuardService.getToken()
	const user = authGuardService.getUser()
	const toast = useRef<any>();
	const permisos: { label: any; }[] = []

	let location = useLocation();

	useEffect(() => {
		onColorSchemeChange(colorScheme);
		onComponentThemeChange('green')
	}, []);

	useEffect(() => {
		user && MenuService.getMenuPerfilConsola(token, user.prf_id).then(res => {
			let respuestaDto = EncryptService.dencryptData(res.data.response);
			if (respuestaDto.valido === 0) {
				toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Email y/o Contraseña Incorrectos', life: 4000 });
			} else {
				permisosMenu(respuestaDto.addenda).then(async permiso => {
					authGuardService.sendPermits(permiso);
					setPermissions(permiso)
				})
			}
		}).catch(error => {
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Intentelo de Nuevo mas tarde.', life: 4000 });
		})
	}, [])

	const permisosMenu = async (menu: any[]) => {
		menu.forEach(menuItem => {
			let permiso: any = menuItem
			if (menuItem.items && menuItem.items.length > 0) {
				permisosMenu(menuItem.items);
			} else {
				permisos.push(permiso);
			}
		});
		return permisos;
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

	const onColorSchemeChange = (scheme: string) => {
		changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
		changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
		setColorScheme(scheme);
	}

	const changeStyleSheetUrl = (id: any, value: any, from: any) => {
		const element = (document.getElementById(id) as HTMLInputElement);
		const urlTokens = (element.getAttribute('href') as String).split('/');

		if (from === 1) {           // which function invoked this function - change scheme
			urlTokens[urlTokens.length - 1] = value;
		} else if (from === 2) {       // which function invoked this function - change color
			urlTokens[urlTokens.length - 2] = value;
		}

		const newURL = urlTokens.join('/');

		replaceLink(element, newURL);
	}

	const onMenuThemeChange = (theme: string) => {
		const layoutLink = document.getElementById('layout-css');
		const href = 'assets/layout/css/' + theme + '/layout-' + colorScheme + '.css';

		replaceLink(layoutLink, href);
		setTheme(theme);
	}

	const onComponentThemeChange = (theme: string) => {
		const themeLink = document.getElementById('theme-css');
		const href = 'assets/theme/' + theme + '/theme-' + colorScheme + '.css';
		replaceLink(themeLink, href);
		setComponentTheme(theme);
	}

	const replaceLink = (linkElement: any, href: string, callback?: any) => {
		const id = linkElement.getAttribute('id');
		const cloneLinkElement = linkElement.cloneNode(true);
		cloneLinkElement.setAttribute('href', href);
		cloneLinkElement.setAttribute('id', id + '-clone');
		linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
		cloneLinkElement.addEventListener('load', () => {
			linkElement.remove();
			cloneLinkElement.setAttribute('id', id);

			if (callback) {
				callback();
			}
		});
	}

	const home = () => {
		return <App colorScheme={colorScheme} onColorSchemeChange={onColorSchemeChange}
			componentTheme={componentTheme} onComponentThemeChange={onComponentThemeChange}
			theme={theme} onMenuThemeChange={onMenuThemeChange} />;
	}

	const ValidateHomeLogin = () => {
		if (authGuardService.isLoggednIn()) return home()
		return ValidateLoggin();
	};

	const ValidateLanding = () => {
		if (configFile.landing) {
			if (authGuardService.isLoggednIn()) return home()
			/* return ValidateLoggin() */
			return <MainTopBarPublic colorScheme={colorScheme} component={<Landing />} />;
		} else {
			return ValidateLoggin()
		}
	}

	const ValidateLoggin = () => {
		if (authGuardService.isLoggednIn()) return home()
		return <MainTopBarPublic colorScheme={colorScheme} component={<TimerProvider><LoginVentanilla /></TimerProvider>} />;
	}

	/* PUBLIC URL */
	switch (location.pathname) {
		case '/home':
			return ValidateHomeLogin()
		case '/error':
			return <Error colorScheme={colorScheme} />
		case '/notfound':
			return <NotFound colorScheme={colorScheme} />
		case '/access':
			return <Access colorScheme={colorScheme} />
		case '/olvideContrasena':
			return <MainTopBarPublic colorScheme={colorScheme} component={<OlvideContraseña />} />
		case '/preCaptura':
			return <MainTopBarPublic colorScheme={colorScheme} component={<PreCaptura />} />
		case '/verificarDatos':
			return <MainTopBarPublic colorScheme={colorScheme} component={<VerificarDatos />} />
		case '/registro':
			return <MainTopBarPublic colorScheme={colorScheme} component={<Registro />} />
		case '/login':
			return ValidateLoggin()
		default:
			return ValidateLanding()
	}
}

export default AppWrapper;