import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';

const ResulTitulares = (props: any) => (
    <section>
        <div className='col-12 md:col-12'>
            <h3 className='color-violet'>Titulares</h3>
            <p>Titulares que son parte de este terreno.</p>
        </div>

        <div className='pt-4'>
            <DataTable
                value={props.titulares}
                dataKey="id"
                paginator
                rows={5}
                className="datatable-responsive"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                emptyMessage="No products found."
            >
                <Column field="ptit_nom" header="Nombre" sortable ></Column>
                <Column field="ptit_pat" header="Primer apellido" sortable ></Column>
                <Column field="ptit_mat" header="Segundo apellido"></Column>
                <Column field="ptit_rfc" header="RFC" ></Column>
                <Column field="ptit_nuda" header="Nuda%" ></Column>
                <Column field="ptit_usufructo" header="Usufructo%" ></Column>
            </DataTable>
        </div>

    </section>
);

export default React.memo(ResulTitulares);