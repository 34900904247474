import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import React from 'react';
import PrivateRoutes from './PrivateRoutes';
import { TimerProvider } from './context/TimerContext';

const App = (props: any) => {
    return (
        <TimerProvider>
            <PrivateRoutes />
        </TimerProvider>
    );
}

export default App;
