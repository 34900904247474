import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../serviceExample/ProductService';
import { useNavigate } from 'react-router-dom';
import authGuardService from '../../../../service/authGuardService';
import EncryptService from '../../../../service/encrypt.service';
import ApiServices from '../../../../service/apiServices';
import configuration from '../../../../configurationFile';
import { TieredMenu } from 'primereact/tieredmenu';
import { Toast } from 'primereact/toast';


const TraEnviados = (props: any) => {

    const navigate = useNavigate();
    const user = authGuardService.getUser()
    const toast = useRef<any>(null);

    const analistNameTemplate = (rowData: any) => {
        const nombre = (rowData && rowData.usu_nom) ? rowData.usu_nom : ''
        const apellido = (rowData && rowData.usu_pat) ? rowData.usu_pat : ''
        const name = `${nombre} ${apellido}`
        return (
            <div className="actions">
                <p>{name}</p>
            </div>
        );
    }

    const controlInternoBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.trm_fecha);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();

        const fechaFormateada = `${day}/${month}/${year}`;

        return (
            <div className="actions">
                <p>{`${fechaFormateada}`}</p>
            </div>
        )
    }

    const [filteredTramites, setFilteredTramites] = useState([])
    const [filteredTramitesOriginal, setFilteredTramitesOriginal] = useState([])

    function filtrarPorStatus(arr: any) {
        const statusPermitidos = [1, 2, 3, 4];
        return arr.filter((item: any) => statusPermitidos.includes(item.trm_status));
    }

    useEffect(() => {
        if (props.tramites && props.tramites.length > 0) {
            const miArrayFiltrado = filtrarPorStatus(props.tramites);
            setFilteredTramites(miArrayFiltrado)
            setFilteredTramitesOriginal(miArrayFiltrado)
        }
    }, [props.tramites])


    /* OLD */
    const dt = useRef<any>(null);
    const productService = new ProductService();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);

    useEffect(() => {
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    const statusBodyTemplate = (tramite: any) => {
        const statusType = getStatusType(tramite.trm_status)
        return <Tag value={statusType} severity={getSeverity(tramite.trm_status)}></Tag>;
    };

    const getStatusType = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'Borrador';
            case 1:
                return 'Capturado';
            case 2:
                return 'Asignado';
            case 3:
                return 'Analizado';
            case 4:
                return 'Rechazado';
            case 5:
                return 'Por Subsanar';
            case 6:
                return 'Subsanado';
            case 7:
                return 'Por Firmar Aceptado';
            case 8:
                return 'Por Firmar Rechazado';
            case 9:
                return 'Firmado Aceptado';
            case 10:
                return 'Firmado Rechazado';
            default:
                return null;
        }
    };

    const getSeverity = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'info'
            case 2:
                return 'warning';
            case 3:
                return 'danger';
            case 4:
                return 'warning';
            case 5:
                return 'success';
            case 6:
                return 'info'
            case 7:
                return 'warning';
            case 8:
                return 'danger';
            case 9:
                return 'success';
            case 10:
                return 'success';
            default:
                return null;
        }
    };



    const menu = useRef(null);

    const downloadPDF = async (id: any) => {
        let datos
        datos = { "code": 213, "trm_id": id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.valido == 0) {
                } else {
                    window.open(`${configuration.url}${res.data.addenda}`)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }


    const actionBodyTemplate = (rowData: any) => {
        const navigateOptions = {
            state: {
                preTramite: rowData
            }
        };
        return (
            <div className="actions" style={{ display: 'flex' }}>
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => {
                    navigate('/notarios/pago-folio-view', navigateOptions)
                }} />
                <TieredMenu model={[
                    {
                        label: 'Descargar Boleta de Ingreso',
                        icon: 'pi pi-file-export',
                        command: () => {
                            downloadPDF(rowData.trm_id)
                        }
                    }
                ]} popup ref={menu} breakpoint="767px" />
                <Button icon="pi pi-ellipsis-h" rounded text severity="secondary" onClick={(e) => menu.current.toggle(e)} />
                {rowData && rowData.trm_status == 7 || rowData.trm_status == 8 || rowData.trm_status == 9 || rowData.trm_status == 10 ?
                    <Button icon="pi pi-file" onClick={() => { SavePDFcert(rowData) }} rounded text severity="secondary" /> : ''}
            </div>
        );
    }

    const SavePDFcert = async (trm: any) => {
        let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: trm.trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    if (typeof res.data.url === 'object') {
                        if (res.data.url && res.data.url.length > 0) {
                            res.data.url.forEach((single: any) => {
                                window.open(`${configuration.url}${single.url}`, '_blank');
                            });
                        }
                    } else {
                        window.open(`${configuration.url}${res.data.url}`, '_blank')
                    }
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const header = (
        <div className='flex justify-content-end'>
            <div className='col-12 md:col-4'>
                <span className="p-float-label p-input-icon-left w-100">
                    <i className="pi pi-search" />
                    <InputText className=' p-inputtext-lg' type="search" onChange={(e) => { searchActos(e.target.value) }} />
                    <label htmlFor="username">Buscador general</label>
                </span>
            </div>
        </div>
    );

    function buscarCoincidencias(texto: any, array: any) {
        const textoMinusculas = texto.toLowerCase();
        const coincidencias = array.filter((objeto: any) => {
            for (const propiedad in objeto) {
                if (objeto[propiedad] && objeto[propiedad].toString().toLowerCase().includes(textoMinusculas)) {
                    return true;
                }
            }
            return false;
        });

        return coincidencias;
    }

    const searchActos = (e: any) => {
        const clone = JSON.parse(JSON.stringify(filteredTramitesOriginal))
        setFilteredTramites(buscarCoincidencias(e, clone))
    }



    return (
        <section>
            <Toast ref={toast} />

            <DataTable
                value={filteredTramites}
                dataKey="trm_id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} trámites"
                globalFilter={globalFilter}
                emptyMessage="No products found."
                header={header}
                loading={props.allTramitesLoading}
            >
                <Column sortable field="trm_consecutivo" header="Control Interno ISRyC"></Column>
                <Column sortable field="trm_fecha" header="Fecha" body={controlInternoBodyTemplate}></Column>
                <Column sortable field="prop_folio" header="Folio electrónico"></Column>
                <Column sortable field="prop_clave" header="Clave catastral"></Column>
                <Column sortable field="name" header="Analista" body={analistNameTemplate}></Column>
                <Column sortable field="actos" header="Actos"></Column>
                <Column sortable field="trm_status" header="Estatus" body={statusBodyTemplate}></Column>
                <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
            </DataTable>
        </section>
    );

}

export default React.memo(TraEnviados);