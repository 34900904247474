import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';
import ApiServices from '../../../../../service/apiServices';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

const AccAreas = () => {

    const toast = useRef<Toast>(null);

    useEffect(() => {
        getAreas()
    }, [])

    const getAreas = () => {
        let datos
        ApiServices.apiPost('catAreas', datos).then(async res => {
            setAreas(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const [areas, setAreas] = useState([])


    return (
        <DataTable value={areas} paginator rows={10} dataKey="area_id" tableStyle={{ minWidth: '50rem' }}>
            <Column sortable field="area_id" header="ID"></Column>
            <Column sortable field="area_desc" header="Áreas"></Column>
        </DataTable>


    );

}

export default React.memo(AccAreas);