import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import authGuardService from '../../../../service/authGuardService';
import EncryptService from '../../../../service/encrypt.service';
import DynamicForm from '../../../actos/DynamicForm';
import DynamicFormView from '../../../actos/DynamicFormView';
import TabHistorialActo from '../../../Ventanilla/ResulFolio/ViewFolio/TabViewFolio/HistorialActo';

interface City {
    name: string;
}

const ResulActosView = (props: any) => {
    const user = authGuardService.getUser()

    const previousPayments = props.previousPayments
    /* const previousFolio = props.previousFolio[0] */
    const responseData = props.responseData
    const preTramite = props.preTramite

    const [selectedCity, setSelectedCity] = useState<City | null>(null);
    const dt = useRef<any>(null);
    const [products, setProducts] = useState<any>(null);
    const [pagos, setPagos] = useState<any>(null);
    const menu = useRef<any>(null);
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        //   const productService = new ProductService();
        //   productService.getProducts().then(data => setProducts(data));
        setProducts([
            {
                "id": "1",
                "code": "I33",
                "name": "Segundo aviso preventivo",
                "price": 337,
            },
            {
                "id": "2",
                "code": "I39",
                "name": "Certificado de libertad o existencia de gravámenes con efectos de aviso preventivo",
                "price": 500,
            },
        ])

        setPagos([
            {
                "id": "1",
                "pago": "G: 30759042",
                "date": "21 Enero 2022",
                "monto": 337,
            },
            {
                "id": "2",
                "pago": "G: 30759042",
                "date": "21 Enero 2022",
                "monto": 337,
            },
        ])
    }, []);

    const montoBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Monto de derechos</span>
                ${rowData.monto}.°°
            </>
        );
    }

    const [selected, setSelected] = useState<any>('')

    const items = [
        {
            items: [
                {
                    label: 'Ver Datos',
                    icon: 'pi pi-eye',
                    command: () => {
                        getActoInformation()
                    },
                }
            ]
        },
    ];

    const [dynamicFormInformation, setDynamicFormInformation] = useState()

    const getActoInformation = () => {
        let datos = {
            act_id: selected.act_id
        }
        ApiServices.apiPost('getCampos', datos).then(async res => {
            setDynamicFormInformation(res.data.addenda)
            setVisible(true)
        }).catch(error => {
            console.log(error)
        })
    }

    const EliminarTemplate = (e: any) => {
        return (
            <>
                <div className='text-center'>
                    {/* {(e.act_clave === 'I33' || e.act_clave === 'I39') &&
                        <> */}
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <Button aria-controls="popup_menu" aria-haspopup onClick={(event) => {
                        setSelected(e)
                        menu.current.toggle(event)
                    }} className="p-button-text p-button-plain" icon="pi pi-ellipsis-v" />
                    {/* </>

                    } */}

                </div>
            </>
        );
    }


    const footerActos = (
        <ColumnGroup>
            <Row>
                <Column className='text-right' footer="Precio total:" colSpan={2} />
                <Column footer={`${(props && props.total) ? `$ ${parseFloat(props.total).toFixed(2)}` : '$0.00'} MXN`} />
                <Column />
            </Row>
        </ColumnGroup>
    );
    const footerPagos = (
        <ColumnGroup>
            <Row>
                <Column className='text-right' footer="Precio total:" colSpan={2} />
                <Column footer="$0.00" />
            </Row>
        </ColumnGroup>
    );

    const [dynamicInformationParent, setDynamicInformationParent] = useState()

    const formatInformation = (informationToFormat: any) => {
        const flattenedArray = informationToFormat.flat();
        const filteredObjects = flattenedArray.filter((obj: any) => obj.cmp_tipo !== "L" && obj.cmp_tipo !== 'vacio');
        const result = filteredObjects.map(({ cmp_id, frm_id, cmp_tipo, value }: any) => ({
            id: cmp_id,
            val: value
        }));

        return result;
    }

    const handleSave = () => {
        let datos: any
        const formatResponse = formatInformation(dynamicInformationParent)

        if (selected.frmu_id) {
            datos = {
                code: 402,
                frmu_id: selected.frmu_id,
                usu_id: user.usu_id,
                campos: formatResponse
            }
        } else {
            datos = {
                code: 401,
                usu_id: user.usu_id,
                act_id: selected.act_id,
                campos: formatResponse,
                trm_id: 0,
                ptrm_id: responseData ? responseData.ptrm_id : preTramite.ptrm_id
            }
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {

        }).catch(error => {
            console.log(error)
        })

        setVisible(false)

    }

    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Guardar" onClick={handleSave} autoFocus />
        </div>
    );

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <p>{`${option.act_clave} ${option.act_nombre}`}</p>
                {/* <p>{`${option.frm_desc} `}</p> */}
            </div>
        );
    };

    const handleAddToActosArray = (e: any) => {
        const newArray = JSON.parse(JSON.stringify(props.actos))
        newArray.push(e)
        props.actosFunction(newArray)
    }

    const deleteActo = () => {
        let datos = {
            frmu_id: selected.frmu_id
        }
        ApiServices.apiPost('deleteRespuestas', datos).then(async res => {
            if (res.data.valido === 1) {
                props.requestActos()
            }

        }).catch(error => {
            console.log(error)
            props.requestActos()
        })
    }

    const pagoBodyTemplate = (payment: any) => {
        return (
            <p>$ {parseFloat(payment.valormx).toFixed(2)} MXN</p>
        )
    }

    const fechaBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.pago_fecha);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();

        const fechaFormateada = `${day}/${month}/${year}`;

        return (
            <div className="actions">
                <p>{`${fechaFormateada}`}</p>
            </div>
        )
    }

    return (
        <section>
            <Toast ref={toast} />
            <div className='grid'>

                <div className='col-12 md:col-6'>
                    <h3 className='color-violet'>Actos Registrados</h3>
                </div>
            </div>

            <div className='pt-4'>
                <DataTable ref={dt} value={props.actos} footerColumnGroup={footerActos}
                    dataKey="id" paginator rows={5} className="datatable-responsive"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    emptyMessage="No products found.">
                    <Column field="act_clave" header="Clave" sortable /* body={codeBodyTemplate} */ ></Column>
                    <Column field="act_nombre" header="Acto" sortable /* body={nameBodyTemplate} */ ></Column>
                    <Column field="valormx" header="Derechos" body={pagoBodyTemplate} ></Column>
                    <Column field="price" style={{ width: '10rem' }} body={(e) => EliminarTemplate(e)} ></Column>
                </DataTable>
            </div>

            {previousPayments && previousPayments.length > 0 &&
                < div >
                    <div className='col-12 md:col-12'>
                        <h3 className='color-violet'>Pagos realizados</h3>
                        <p>Podrá visualizar los pagos</p>

                        <div>
                            <DataTable ref={dt} value={previousPayments} footerColumnGroup={footerPagos}
                                dataKey="id" paginator rows={5} className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                                emptyMessage="No products found." responsiveLayout="scroll">
                                <Column field="pago_serie" header="Serie" sortable ></Column>
                                <Column field="pago_folio" header="Pago" sortable /* body={pagoBodyTemplate} */ ></Column>
                                <Column field="pago_update" header="Fecha de pago" sortable /* body={dateBodyTemplate} */ ></Column>
                                <Column field="price" header="Monto de derechos" body={montoBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

            }

            {props.preTramiteDetail && props.preTramiteDetail.pagos && props.preTramiteDetail.pagos.length > 0 &&
                < div >
                    <div className='col-12 md:col-12'>
                        <h3 className='color-violet'>Pagos realizados</h3>
                        <p>Podrá visualizar los pagos</p>

                        <div>
                            <DataTable ref={dt} value={props.preTramiteDetail.pagos} footerColumnGroup={footerPagos}
                                dataKey="id" paginator rows={5} className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                                emptyMessage="No products found." responsiveLayout="scroll">
                                <Column field="pago_serie" header="Serie" sortable ></Column>
                                <Column field="pago_folio" header="Pago" sortable></Column>
                                <Column field="pago_fecha" header="Fecha de pago" sortable body={fechaBodyTemplate}></Column>
                                <Column field="pago_monto" header="Monto de derechos" body={montoBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            }

            <TabHistorialActo
                generalData={props.generalData}
                actoHistory={props.actoHistory}
                setActoHistory={props.setActoHistory}
                actoHistoryClone={props.actoHistoryClone}
                actoHistoryLoading={props.actoHistoryLoading}
            />

            {/*inicia modal de actos*/}
            <Dialog header={`${(selected && selected.act_nombre) || ''}`} maximizable visible={visible} style={{ width: '50%' }} onHide={() => setVisible(false)}>
                <DynamicForm informationForm={dynamicFormInformation} information={selected} setDynamicInformationParent={setDynamicInformationParent} />

            </Dialog>
        </section >
    );

}

export default React.memo(ResulActosView);