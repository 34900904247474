import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../../service/apiServices';
import authGuardService from '../../../../../service/authGuardService';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import formatDate from '../../../../../utilities/formatDate';
import Tabs from '../../../../../Components/Tabs/Tabs';
import Tab from '../../../../../Components/Tabs/Tab';

const CatAvisos = () => {

    const user = authGuardService.getUser()
    const toast = useRef<any>(null);
    const buttonEl = useRef<any>(null);

    const [visible, setVisible] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visBloque, setVisBloque] = useState<boolean>(false);

    const [titulo, setTitulo] = useState('')
    const [nota, setNota] = useState('')

    const [tituloEdit, setTituloEdit] = useState('')
    const [notaEdit, setNotaEdit] = useState('')
    const [selected, setSelected] = useState<any>()

    const [deleteToolTip, setDeleteToolTip] = useState(false)
    const [date, setDate] = useState<any>(null);

    const [listNotice, setListNotice] = useState([])
    const [listBlock, setListBlock] = useState([])
    const [limitDate1, setLimitDate1] = useState<any>()
    const [limitDate2, setLimitDate2] = useState<any>()
    const [text, setText] = useState<any>()

    useEffect(() => {
        getNoticeList()
        getBlockList()
    }, [])

    const getNoticeList = () => {
        let datos
        ApiServices.apiPost('getAvisos', datos).then(async res => {
            if (res.data.valido === 1) {
                setListNotice(res.data.addenda)
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los aviso.' });
        })
    }

    const getBlockList = () => {
        let datos
        ApiServices.apiPost('getBloqueos', datos).then(async res => {
            if (res.data.valido === 1) {
                setListBlock(res.data.addenda)
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los aviso.' });
        })
    }

    const handleSentNotice = () => {
        if (titulo.length > 1 && nota.length > 1) {
            let datos = {
                usu_id: user.usu_id,
                avs_titulo: titulo,
                avs_texto: nota
            }
            ApiServices.apiPost('addAviso', datos).then(async res => {
                if (res.data.valido === 1) {
                    toast.current.show({ severity: 'success', summary: 'Aviso Agregado', detail: 'Aviso agregado con éxito.' });
                    getNoticeList()
                    getBlockList()
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al agregar el aviso.' });
            })
        } else {
            toast.current.show({ severity: 'warn', summary: 'Informacion incompleta', detail: 'Tienes que agregar un titulo y una nota.' });
        }
        setVisible(false)
        getNoticeList()
    }

    const handleEditNotice = () => {
        if (tituloEdit.length > 1 && notaEdit.length > 1 && selected) {
            let datos = {
                avs_id: selected.avs_id,
                usu_id: user.usu_id,
                avs_titulo: tituloEdit,
                avs_texto: notaEdit
            }

            ApiServices.apiPost('updateAviso', datos).then(async res => {
                if (res.data.valido === 1) {
                    toast.current.show({ severity: 'success', summary: 'Aviso Agregado', detail: 'Aviso agregado con éxito.' });
                    getNoticeList()
                    getBlockList()
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al agregar el aviso.' });
            })
        } else {
            toast.current.show({ severity: 'warn', summary: 'Informacion incompleta', detail: 'Tienes que agregar un titulo y una nota.' });
        }
        setVisibleEdit(false)
        getNoticeList()
    }

    const handleDeleteNotice = () => {
        if (selected) {
            let datos = {
                avs_id: selected.avs_id
            }

            ApiServices.apiPost('deleteAviso', datos).then(async res => {
                if (res.data.valido === 1) {
                    toast.current.show({ severity: 'success', summary: 'Aviso Eliminado', detail: 'Aviso eliminado con éxito.' });
                    getNoticeList()
                    getBlockList()
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al eliminar el aviso.' });
            })
        }
        getNoticeList()
    }

    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
            <Button label="Guardar" onClick={handleSentNotice} />
        </div>
    );

    const editFooterContent = (
        <div>
            <Button label="Cancelar" onClick={() => setVisibleEdit(false)} severity="secondary" outlined />
            <Button label="Guardar" onClick={handleEditNotice} />
        </div>
    );

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions flex">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => {
                    setSelected(rowData)
                    setTituloEdit(rowData.avs_titulo)
                    setNotaEdit(rowData.avs_texto)
                    setVisibleEdit(true)
                }} />
                <Button ref={buttonEl} icon="pi pi-trash" rounded text severity="secondary" onClick={(event) => {
                    setSelected(rowData)
                    setDeleteToolTip(true)
                }} />
            </div>
        );
    }


    const actionBodyBloque = (rowData: any) => {
        return (
            <div className="actions flex">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => {
                    setSelected(rowData)
                    setTituloEdit(rowData.avs_titulo)
                    setNotaEdit(rowData.avs_texto)
                    setVisibleEdit(true)
                }} />
                <Button ref={buttonEl} icon="pi pi-trash" rounded text severity="secondary" onClick={(event) => {
                    setSelected(rowData)
                    setDeleteToolTip(true)
                }} />
            </div>
        );
    }

    const handleDeleteBlock = () => {
        if (selected) {
            let datos = {
                bloq_id: selected.bloq_id
            }

            ApiServices.apiPost('deleteBloqueo', datos).then(async res => {
                if (res.data.valido === 1) {
                    toast.current.show({ severity: 'success', summary: 'Bloqueo Eliminado', detail: 'Bloqueo eliminado con éxito.' });
                    getNoticeList()
                    getBlockList()
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al eliminar el Bloqueo.' });
            })
        }
        getBlockList()
    }

    const accept = () => {
        handleDeleteNotice()
    };

    const acceptDeleteBlock = () => {
        handleDeleteBlock()
    };

    const handleSentBLock = () => {
        if (text && text.length > 1 && limitDate1 && limitDate2) {
            if (selected && selected.bloq_id >= 0) {
                let datos = {
                    bloq_id: selected.bloq_id,
                    usu_id: user.usu_id,
                    bloq_titulo: text,
                    bloq_ini: limitDate1.toISOString(),
                    bloq_fin: limitDate2.toISOString()
                }
                ApiServices.apiPost('updateBloqueo', datos).then(async res => {
                    if (res.data.valido === 1) {
                        toast.current.show({ severity: 'success', summary: 'Bloqueo Editado', detail: 'Bloqueo Editado con éxito.' });
                        getBlockList()
                        setVisible(false)
                        setText(null)
                        setLimitDate1(null)
                        setLimitDate2(null)
                        getNoticeList()
                        getBlockList()
                    }
                }).catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al editar el Bloqueo.' });
                })
            } else {
                let datos = {
                    usu_id: user.usu_id,
                    bloq_titulo: text,
                    bloq_ini: limitDate1.toISOString(),
                    bloq_fin: limitDate2.toISOString()
                }
                ApiServices.apiPost('addBloqueo', datos).then(async res => {
                    if (res.data.valido === 1) {
                        toast.current.show({ severity: 'success', summary: 'Bloqueo Agregado', detail: 'Bloqueo agregado con éxito.' });
                        getBlockList()
                        setVisible(false)
                        setText(null)
                        setLimitDate1(null)
                        setLimitDate2(null)
                        getNoticeList()
                        getBlockList()
                    }
                }).catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al agregar el Bloqueo.' });
                })
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Informacion incompleta', detail: 'Tienes que agregar un titulo y las fechas.' });
        }
    }

    const footerContentBlock = (
        <div>
            <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
            <Button label="Guardar" onClick={handleSentBLock} />
        </div>
    );

    const editFooterContentBlock = (
        <div>
            <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
            <Button label="Guardar" onClick={handleEditNotice} />
        </div>
    );

    const initialDateFormat = (rowData: any) => (
        <p>{rowData && rowData.bloq_ini && formatDate(rowData.bloq_ini)}</p>
    );

    const endDateFormat = (rowData: any) => (
        <p>{rowData && rowData.bloq_fin && formatDate(rowData.bloq_fin)}</p>
    );

    const actionBodyBloqueo = (rowData: any) => {
        return (
            <div className="actions flex">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => {
                    setSelected(rowData)
                    setText(rowData.bloq_titulo)
                    setLimitDate1(new Date(rowData.bloq_ini))
                    setLimitDate2(new Date(rowData.bloq_fin))
                    setVisible(true)
                }} />
                <Button ref={buttonEl} icon="pi pi-trash" rounded text severity="secondary" onClick={(event) => {
                    setSelected(rowData)
                    setDeleteToolTip(true)
                }} />
            </div>
        );
    }

    const calendarRef = useRef(null);
    const calendarRef2 = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            setLimitDate1(new Date(formattedDate));
        } else {
            setLimitDate1(null);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };

    const handleInputChange2 = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            setLimitDate2(new Date(formattedDate));
        } else {
            setLimitDate2(null);
        }
        if (calendarRef2.current && calendarRef2.current.inputElement) {
            calendarRef2.current.inputElement.value = formattedDate || inputValue;
        }
    };

    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };


    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-10'>
                    <h3>Configurador de avisos</h3>
                    <p>Genera los avisos y bloqueos de entrada de tramites necesarios para los notarios.</p>
                </div>

                <div className='col-12 md:col-12'>
                    <div className="tabs">
                        <Tabs>
                            <Tab label="Avisos">
                                <div>
                                    <div className='grid'>
                                        <div className='col-12 md:col-10'>
                                            <h5>Avisos</h5>
                                        </div>
                                        <div className='col-12 md:col-2'><Button label="Nuevo aviso" size="large" onClick={() => setVisible(true)} /></div>
                                        <div className='col-12'>
                                            <ConfirmPopup
                                                target={buttonEl.current}
                                                visible={deleteToolTip}
                                                onHide={() => setDeleteToolTip(false)}
                                                message="Seguro que quieres eliminar este Aviso ?"
                                                icon="pi pi-exclamation-triangle"
                                                acceptClassName='p-button-danger'
                                                acceptLabel='Eliminar'
                                                rejectLabel='Cancelar'
                                                accept={accept}
                                            />
                                            <DataTable
                                                value={listNotice}
                                                paginator
                                                rows={10}
                                                dataKey="avs_id"
                                                tableStyle={{ minWidth: '50rem' }}
                                            >
                                                <Column sortable field="avs_titulo" header="Titulo"></Column>
                                                <Column sortable field="avs_texto" header="Nota"></Column>
                                                <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
                                            </DataTable>
                                        </div>

                                    </div>
                                    {/* ADD NOTICE */}
                                    <Dialog
                                        header="Agregar aviso"
                                        visible={visible}
                                        style={{ width: '50vw' }}
                                        onHide={() => setVisible(false)}
                                        footer={footerContent}
                                    >
                                        <div className='grid'>
                                            <div className='col-12 md:col-12'>
                                                <span className="p-float-label">
                                                    <InputText
                                                        id="username"
                                                        className="p-inputtext-lg"
                                                        value={titulo}
                                                        onChange={(e) => { setTitulo(e.target.value) }}
                                                    />
                                                    <label htmlFor="username">Titulo</label>
                                                </span>
                                            </div>
                                            <div className='col-12 md:col-12'>
                                                <span className="p-float-label">
                                                    <InputTextarea
                                                        rows={5}
                                                        cols={30}
                                                        value={nota}
                                                        onChange={(e) => { setNota(e.target.value) }}
                                                    />
                                                    <label htmlFor="username">Nota</label>
                                                </span>
                                            </div>
                                        </div>
                                    </Dialog>

                                    {/* EDIT NOTICE */}
                                    <Dialog
                                        header="Editar aviso"
                                        visible={visibleEdit}
                                        style={{ width: '50vw' }}
                                        onHide={() => setVisibleEdit(false)}
                                        footer={editFooterContent}
                                    >
                                        <div className='grid'>
                                            <div className='col-12 md:col-12'>
                                                <span className="p-float-label">
                                                    <InputText
                                                        id="username"
                                                        className="p-inputtext-lg"
                                                        value={tituloEdit}
                                                        onChange={(e) => { setTituloEdit(e.target.value) }}
                                                    />
                                                    <label htmlFor="username">Titulo</label>
                                                </span>
                                            </div>
                                            <div className='col-12 md:col-12'>
                                                <span className="p-float-label">
                                                    <InputTextarea
                                                        rows={5}
                                                        cols={30}
                                                        value={notaEdit}
                                                        onChange={(e) => { setNotaEdit(e.target.value) }}
                                                    />
                                                    <label htmlFor="username">Nota</label>
                                                </span>
                                            </div>
                                        </div>
                                    </Dialog>
                                </div>
                            </Tab>
                            <Tab label="Bloqueo de sistema">
                                <div>
                                    <div className='grid'>
                                        <div className='col-12 md:col-10'>
                                            <h5>Bloqueo de sistema</h5>
                                        </div>
                                        <div className='col-12 md:col-2'><Button label="Nuevo bloqueo" size="large" onClick={() => setVisible(true)} /></div>
                                        <div className='col-12'>
                                            <ConfirmPopup
                                                target={buttonEl.current}
                                                visible={deleteToolTip}
                                                onHide={() => setDeleteToolTip(false)}
                                                message="Seguro que quieres eliminar este bloqueo ?"
                                                icon="pi pi-exclamation-triangle"
                                                acceptClassName='p-button-danger'
                                                acceptLabel='Eliminar'
                                                rejectLabel='Cancelar'
                                                accept={acceptDeleteBlock}
                                            />
                                            <DataTable
                                                value={listBlock}
                                                paginator
                                                rows={10}
                                                dataKey="avs_id"
                                                tableStyle={{ minWidth: '50rem' }}
                                            >
                                                <Column sortable field="bloq_titulo" header="Titulo"></Column>
                                                <Column sortable field="bloq_ini" header="Fecha inicio" body={initialDateFormat}></Column>
                                                <Column sortable field="bloq_fin" header="Fecha fin" body={endDateFormat}></Column>
                                                <Column body={actionBodyBloqueo} style={{ width: '3rem' }}></Column>
                                            </DataTable>
                                        </div>
                                    </div>

                                    {/* ADD bloqueo */}
                                    <Dialog
                                        header="Bloqueo"
                                        visible={visible}
                                        style={{ width: '50vw' }}
                                        onHide={() => {
                                            setVisible(false)
                                            setText(null)
                                            setLimitDate1(null)
                                            setLimitDate2(null)
                                        }}
                                        footer={footerContentBlock}
                                    >
                                        <div className='grid'>
                                            <div className='col-12'>
                                                <p>Agrega las fechas donde el sistema no recibira entradas de tramites</p>
                                            </div>
                                            <div className='col-12 md:col-12'>
                                                <span className="p-float-label">
                                                    <InputText
                                                        id="username"
                                                        className="p-inputtext-lg"
                                                        value={text}
                                                        onChange={(e: any) => { setText(e.target.value) }}
                                                    />
                                                    <label htmlFor="username">Titulo</label>
                                                </span>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="flex justify-content-center p-float-label">
                                                    {/* Input Done */}
                                                    <Calendar
                                                        value={limitDate1}
                                                        onChange={(e) => setLimitDate1(e.value)}
                                                        showIcon
                                                        locale="es"
                                                        ref={calendarRef}
                                                        onInput={(e) => handleInputChange(e)}
                                                        dateFormat="dd/MM/yy"
                                                    />
                                                    <label htmlFor="username">Fecha Inicial</label>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-4'>
                                                <div className="flex justify-content-center p-float-label">
                                                    {/* Input Done */}
                                                    <Calendar
                                                        value={limitDate2}
                                                        onChange={(e) => setLimitDate2(e.value)}
                                                        showIcon
                                                        locale="es"
                                                        ref={calendarRef2}
                                                        onInput={(e) => handleInputChange2(e)}
                                                        dateFormat="dd/MM/yy"
                                                    />
                                                    <label htmlFor="username">Fecha Final</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>


            </div>
        </section >


    );

}

export default React.memo(CatAvisos);