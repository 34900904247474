import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';

interface City {
    name: string;
}

const TabGenerales = (props: any) => {

    const generalData = props.generalData

    return (
        <section className=''>
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <small className='black'>Folio Electronico de Personas Morales</small>
                    <p className='result'>{generalData ? generalData.per_folio : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>RFC / CURP</small>
                    <p className='result'>{generalData ? generalData.per_rfc : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Fecha de Apertura</small>
                    <p className='result'>{generalData?.per_fecapertura ? generalData.per_fecapertura : ''}</p>
                </div>
                <div className='col-12 md:col-12'>
                    <small className='black'>Antecedentes</small>
                    <p className='result'>{generalData?.per_antecedente ? generalData.per_antecedente : ''}</p>
                </div>
                <div className='col-12 md:col-12'>
                    <small className='black'>Razon Social o Denominacion</small>
                    <p className='result'>{generalData?.per_razonsocial ? generalData.per_razonsocial : ''}</p>
                </div>
                <div className='col-12 md:col-6'>
                    <small className='black'>Duracion</small>
                    <p className='result'>{generalData?.per_duracion ? generalData.per_duracion : ''}</p>
                </div>
                <div className='col-12 md:col-6'>
                    <small className='black'>Actividades</small>
                    <p className='result'>{generalData?.per_actividades ? generalData.per_actividades : ''}</p>
                </div>
                <div className='col-12 md:col-12'>
                    <small className='black'>Domicilio Social</small>
                    <p className='result'>{generalData?.per_direccion ? generalData.per_direccion : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Estado</small>
                    <p className='result'>{generalData?.per_direccion ? generalData.per_direccion : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Municipio</small>
                    <p className='result'>{generalData?.per_direccion ? generalData.per_direccion : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>CP</small>
                    <p className='result'>{generalData?.cp_id ? generalData.cp_id : ''}</p>
                </div>

                <div className='col-12 md:col-12'>
                    <small className='black'>Objeto Social</small>
                    <p className='result'>{generalData?.per_objeto ? generalData.per_objeto : ''}</p>
                </div>
            </div>

        </section>
    );

}

export default React.memo(TabGenerales);