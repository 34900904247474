import React, { useEffect, useRef } from 'react';
import { useMountEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';

const ErrorPDF = (props: any) => {

    const msgs = useRef<any>(null);

    useMountEffect(() => {
        msgs.current.show(
            {
                sticky: true, severity: 'warn', closable: false, content: (
                    <React.Fragment>
                        <div className=''>
                        <h6><i className="pi pi-exclamation-triangle"></i> Documento invalido</h6> 
                        <div><p>Lo sentimos, tu documento no fue validado por el instituto. Verifica los datos con nuestro instituto para verificar si hay un error. </p></div>
                        </div>
                    </React.Fragment>
                )
            }
        );
    });

    return (
        <section className='grid'>
            <div className='col-12 md:col-12'>
                <div className="p-3 text-center bg-gris">
                    <h3>registro público de la propiedad y del comercio del estado de Morelos
                        Validación de impresión documento</h3>
                </div>
            </div>

            <div className='col-12 md:col-12'>
                <Messages ref={msgs} />
            </div>

            <div className='col-12 text-center'>

                <img className='' src={`/assets/img/doc-valido-pdf.png`} alt="logo" />
            </div>

        </section>
    )
}

export default React.memo(ErrorPDF);