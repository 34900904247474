import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import { Toast } from 'primereact/toast';

const BuscadorNotarios = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const toast = useRef<any>(null);
    const [buttonDisabled, setButtonDisabled] = useState(true)

    const [data, setData]: any = useState({
        folioReal: { value: '', valid: true },
        claveCatastral: { value: '', valid: true },
        nombre: { value: '', valid: true },
        apellidoP: { value: '', valid: true },
        apellidoM: { value: '', valid: true },
        domicilio: { value: '', valid: true },
        zona: { value: '', valid: true },
        calle: { value: '', valid: true },
        estado: { value: '', valid: true },
        municipio: { value: '', valid: true },
        cp: { value: '', valid: true },
        suelo: { value: '', valid: true },
        antecedentesRegistrales: { value: '', valid: true },
    })

    const setValues = (jsonValue: any, value: any) => {
        const cloneJson = JSON.parse(JSON.stringify(data))
        cloneJson[jsonValue].value = value
        setData(cloneJson)
        setButtonDisabled(false)
    }

    const valueExist = () => {
        return (
            (data.folioReal.value && data.folioReal.value.length > 0) ||
            (data.claveCatastral.value && data.claveCatastral.value.length > 0) ||
            (data.nombre.value && data.nombre.value.length > 0) ||
            (data.apellidoP.value && data.apellidoP.value.length > 0) ||
            (data.apellidoM.value && data.apellidoM.value.length > 0) ||
            (data.domicilio.value && data.domicilio.value.length > 0) ||
            (data.zona.value && data.zona.value.length > 0) ||
            (data.calle.value && data.calle.value.length > 0) ||
            (data.estado.value && data.estado.value.length > 0) ||
            (data.municipio.value && data.municipio.value.length > 0) ||
            (data.cp.value && data.cp.value.length > 0) ||
            (data.suelo.value && data.suelo.value.length > 0) ||
            (data.antecedentesRegistrales.value && data.antecedentesRegistrales.value.length > 0) ||
            false
        )
    }

    const searchFolio = () => {
        if (valueExist()) {
            let datos
            datos = {
                code: 208,
                prop_clave: data.claveCatastral.value,
                prop_folio: data.folioReal.value,
                ptit_nom: data.nombre.value,
                ptit_pat: data.apellidoP.value,
                ptit_mat: data.apellidoM.value,
                prop_direccion: data.domicilio.value,
                prop_calle: data.calle.value,
                prop_ext: '',
                prop_int: '',
                prop_zona: data.zona.value,
                prop_usosuelo: data.suelo.value,
                cp_cp: data.cp.value,
                cp_municipio: data.municipio.value,
                prop_antecedente: data.antecedentesRegistrales.value,
                cp_estado: data.estado.value,
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    const navigateOptions = {
                        state: {
                            folio: res.data.addenda[0]
                        }
                    };
                    navigate('/notarios/view-folio', navigateOptions)
                }
            }).catch(error => {
                toast.current.show({
                    severity: 'error',
                    summary: 'Folio no encontrado',
                    detail: 'El folio que buscas no se encuentra en el sistema.',
                    life: 5000
                });
            })
        } else {
            setButtonDisabled(true)
        }
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='col-12 md:col-12 pb-5'>
                <h3 className='color-violet'>Buscar folio</h3>
                <p>Ingresa los datos en los campos necesarios para realizar la búsqueda</p>
            </div>

            <div className='flex justify-content-center align-items-center'>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.folioReal.value}
                                onChange={(e) => setValues('folioReal', e.target.value)}
                                id="folioReal"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="folioReal">Folio real</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.claveCatastral.value}
                                onChange={(e) => setValues('claveCatastral', e.target.value)}
                                id="claveCatastral"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="claveCatastral">Clave Catastral</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.nombre.value}
                                onChange={(e) => setValues('nombre', e.target.value)}
                                id="nombre"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="nombre">Nombre</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.apellidoP.value}
                                onChange={(e) => setValues('apellidoP', e.target.value)}
                                id="apellidoP"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="apellidoP">Primer Apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.apellidoM.value}
                                onChange={(e) => setValues('apellidoM', e.target.value)}
                                id="apellidoM"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="apellidoM">Segundo Apellido</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-12 pb-3 pt-6'>
                        <h5>Buscar por ubicación</h5>
                    </div>

                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText
                                value={data.domicilio.value}
                                onChange={(e) => setValues('domicilio', e.target.value)}
                                id="domicilio"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="domicilio">Domicilio</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-3'>
                        <span className="p-float-label">
                            <InputText
                                value={data.zona.value}
                                onChange={(e) => setValues('zona', e.target.value)}
                                id="zona"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="zona">Zona</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-3'>
                        <span className="p-float-label">
                            <InputText
                                value={data.calle.value}
                                onChange={(e) => setValues('calle', e.target.value)}
                                id="calle"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="calle">Calle</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-3'>
                        <span className="p-float-label">
                            <InputText
                                value={data.estado.value}
                                onChange={(e) => setValues('estado', e.target.value)}
                                id="municipio"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="municipio">Estado</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-3'>
                        <span className="p-float-label">
                            <InputText
                                value={data.municipio.value}
                                onChange={(e) => setValues('municipio', e.target.value)}
                                id="municipio"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="municipio">Municipio</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-3'>
                        <span className="p-float-label">
                            <InputText
                                value={data.cp.value}
                                onChange={(e) => setValues('cp', e.target.value)}
                                id="cp"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="cp">CP</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-3'>
                        <span className="p-float-label">
                            <InputText
                                value={data.suelo.value}
                                onChange={(e) => setValues('suelo', e.target.value)}
                                id="suelo"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="suelo">Uso de Suelo</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-3'>
                        <span className="p-float-label">
                            <InputText
                                value={data.antecedentesRegistrales.value}
                                onChange={(e) => setValues('antecedentesRegistrales', e.target.value)}
                                id="suelo"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="suelo">Antecedentes Registrales</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-3'>
                    </div>

                    <div className='col-12 md:col-3'>
                    </div>

                    <div className='col-12 md:col-3'>
                    </div>

                    <div className='col-12 md:col-3'>
                    </div>

                    <div className='col-12 md:col-3 pt-8'>
                        <Button
                            label="Buscar"
                            disabled={buttonDisabled}
                            onClick={() => {
                                searchFolio()
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>

    );

}

export default React.memo(BuscadorNotarios);