import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import React, { useEffect, useState } from 'react';
import TraEnviados from './TipTramites/TraEnviados';
import TraRevision from './TipTramites/TraRevision';
import TraRecibidos from './TipTramites/TraRecibidos';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import Tabs from '../../../Components/Tabs/Tabs';
import Tab from '../../../Components/Tabs/Tab';

const NotTramites = () => {

    const user = authGuardService.getUser()
    const statusPreTramite = [2, 3, 4, 5]
    const statusNotariosPreTramites = [2, 3, 4]

    const [notarioWorkers, setNotariosWorkers] = useState<any>()
    const [allPreTramites, setAllPreTramites] = useState([])
    const [allPreTramitesLoading, setAllPreTramitesLoading] = useState(true)

    const [allTramites, setAllTramites] = useState([])
    const [allTramitesLoading, setAllTramitesLoading] = useState(true)

    const getPreTramites = async () => {
        if (user.prf_id === 9) {
            let tramites: any = []
            for (const singleStatus of statusPreTramite) {
                let datos
                datos = {
                    code: 305,
                    usu_id: user.usu_id,
                    status: singleStatus
                }
                datos = EncryptService.encryptData(JSON.stringify(datos));
                await ApiServices.processRequestAdd(datos).then(async res => {
                    if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                        res.data.addenda.map((singleTramite: any) => {
                            if (singleTramite.ptrm_status === 2 || singleTramite.ptrm_status === 3 || singleTramite.ptrm_status === 4 || singleTramite.ptrm_status === 5) {
                                tramites.push(singleTramite)
                            }
                        })
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
            setAllPreTramites(tramites)
            setAllPreTramitesLoading(false)
        } else if (user.prf_id === 8) {
            let tramites: any = []
            if (notarioWorkers && notarioWorkers.length > 0) {
                for (const singleNotarioWorker of notarioWorkers as any) {
                    for (const singleStatus of statusNotariosPreTramites) {
                        let datos
                        datos = {
                            code: 305,
                            usu_id: singleNotarioWorker.usu_id,
                            status: singleStatus
                        }
                        datos = EncryptService.encryptData(JSON.stringify(datos));
                        await ApiServices.processRequestAdd(datos).then(async res => {
                            if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                                res.data.addenda.map((singleTramite: any) => {
                                    singleTramite.worker = singleNotarioWorker
                                    tramites.push(singleTramite)
                                })
                            }
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }
                setAllPreTramitesLoading(false)
            }

            for (const singleStatus of statusNotariosPreTramites) {
                let datos
                datos = {
                    code: 305,
                    usu_id: user.usu_id,
                    status: singleStatus
                }
                datos = EncryptService.encryptData(JSON.stringify(datos));
                await ApiServices.processRequestAdd(datos).then(async res => {
                    if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                        res.data.addenda.map((singleTramite: any) => {
                            singleTramite.worker = user
                            tramites.push(singleTramite)
                        })
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
            setAllPreTramites(tramites)
            setAllPreTramitesLoading(false)
        }
    }

    const getTramites = async () => {
        if (user.prf_id === 9) {
            let tramites: any = []
            let datos
            datos = {
                code: 207,
                usu_id: user.usu_id,
                texto: "",
                fecha: ""
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            await ApiServices.processRequestAdd(datos).then(async res => {
                if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                    res.data.addenda.map((singleTramite: any) => {
                        tramites.push(singleTramite)
                    })
                }
            }).catch(error => {
                console.log(error)
            })
            setAllTramites(tramites)
            setAllTramitesLoading(false)
        } else if (user.prf_id === 8) {
            let tramites: any = []
            if (notarioWorkers && notarioWorkers.length > 0) {
                for (const singleNotarioWorker of notarioWorkers as any) {
                    let datos
                    datos = {
                        code: 207,
                        usu_id: singleNotarioWorker.usu_id,
                        texto: "",
                        fecha: ""
                    }
                    datos = EncryptService.encryptData(JSON.stringify(datos));
                    await ApiServices.processRequestAdd(datos).then(async res => {
                        if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                            res.data.addenda.map((singleTramite: any) => {
                                singleTramite.worker = singleNotarioWorker
                                tramites.push(singleTramite)
                            })
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }

            let datos
            datos = {
                code: 207,
                usu_id: user.usu_id,
                texto: "",
                fecha: ""
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            await ApiServices.processRequestAdd(datos).then(async res => {
                if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                    res.data.addenda.map((singleTramite: any) => {
                        singleTramite.worker = user
                        tramites.push(singleTramite)
                    })
                }
            }).catch(error => {
                console.log(error)
            })

            setAllTramites(tramites)
            setAllTramitesLoading(false)
        }
    }

    const getNotariosWorkers = () => {
        const usu = authGuardService.getUser()
        let datos = { "not_id": usu.not_id }
        ApiServices.apiPost('getUsuarioNotario', datos).then(async resp => {
            setNotariosWorkers(resp.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getNotariosWorkers()
    }, []);

    useEffect(() => {
        if (notarioWorkers) {
            getPreTramites()
            getTramites()
        }
    }, [notarioWorkers])

    return (
        <section className='container-fluid'>

            <div className='col-12 pb-3'>
                <h3 className="m-0">Trámites</h3>
                <p>Revisa los trámites que has realizado</p>
            </div>

            <div className="tabs">
                {user.prf_id === 8 &&
                    <Tabs>
                        {/* Listado de Tramites enviados al instituto del notario y empleados */}
                        <Tab label="Trámites Enviados al Instituto">
                            <div>
                                <TraEnviados
                                    tramites={allTramites}
                                    tramiteLoading={allTramitesLoading}
                                />
                            </div>
                        </Tab>
                        <Tab label="Pre-Trámites Recibidos">
                            <div>
                                <TraRecibidos
                                    tramites={allPreTramites}
                                    tramiteLoading={allPreTramitesLoading}
                                />
                            </div>
                        </Tab>
                    </Tabs>

                }

                {user.prf_id === 9 &&
                    <Tabs>
                        <Tab label="Trámites Enviados al Instituto">
                            <div>

                                <TraEnviados
                                    tramites={allTramites}
                                    tramiteLoading={allTramitesLoading}
                                />
                            </div>
                        </Tab>
                        <Tab label="Pre-Trámites Enviados al Notario">
                            <div>

                                <TraRevision
                                    preTramites={allPreTramites}
                                    preTramitesLoading={allPreTramitesLoading}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                }
            </div>

        </section>
    );

}

export default React.memo(NotTramites);