import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	speedAsDuration: true,
});

export const Landing = () => {

	return (
		<section>
			landingpage
		</section>
	)
}