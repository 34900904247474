import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import configuration from "../../../configurationFile";
import ApiServices from "../../../service/apiServices";
import authGuardService from "../../../service/authGuardService";
import EncryptService from "../../../service/encrypt.service";
import Rpp from "./Rpp";
import IndicesPropiedades from "./Types/IndicesPropiedades";
import IndicesSociedades from "./Types/IndicesSociedades";
import RPPComercioActual from "./Types/RPPComercioActual";
import RPPComercioHistorico from "./Types/RPPComercioHistorico";
import RPPPropiedadActual from "./Types/RPPPropiedadActual";
import RPPPropiedadHistorico from "./Types/RPPPropiedadHistorico";

const ConsultHistorica = () => {

    const [filter, setFilter] = useState<boolean>(false);

    const user: any = authGuardService.getUser()
    const toast = useRef<any>();

    let inicio

    if (user && user.usu_rpp == 1) {
        inicio = [
            { name: 'RPP Propiedad Actual', value: 1 },
            { name: 'RPP Propiedad Histórico', value: 2 },
            { name: 'RPP Comercio Actual', value: 3 },
            { name: 'RPP Comercio Histórico', value: 4 }
        ];
    } else if (user && user.usu_rpp == 2) {
        inicio = [
            { name: 'INDICES Propiedades', value: 5 },
            { name: 'INDICES Sociedades', value: 6 },
        ];
    } else if (user && user.usu_rpp == 3) {
        inicio = [
            { name: 'RPP Propiedad Actual', value: 1 },
            { name: 'RPP Propiedad Histórico', value: 2 },
            { name: 'RPP Comercio Actual', value: 3 },
            { name: 'RPP Comercio Histórico', value: 4 },
            { name: 'INDICES Propiedades', value: 5 },
            { name: 'INDICES Sociedades', value: 6 },
        ];
    }



    const [loading, setLoading] = useState(true)
    const [list, setList] = useState<any>([])
    const [rppType, setRppType] = useState('')
    const [rppTypeIndex, setRppTypeIndex] = useState('')
    const [folio, setFolio] = useState(' ')
    const [clavecatastral, setClavecatastral] = useState(' ')
    const [registro, setRegistro] = useState(' ')
    const [bis, setBis] = useState(' ')
    const [foja, setFoja] = useState(' ')
    const [tomo, setTomo] = useState(' ')
    const [volumen, setvolumen] = useState(' ')
    const [seccionVal, setSeccion] = useState(' ')
    const [serie, setSerie] = useState(' ')
    const [notario, setNotario] = useState(' ')
    const [escritura, setEscritura] = useState(' ')
    const [estado, setEstado] = useState(' ')
    const [municipio, setMunicipio] = useState(' ')
    const [nombre, setNombre] = useState(' ')
    const [domiclio, setDomicilio] = useState(' ')
    const [cp, setCp] = useState(' ')
    const [numeroPredio, setNumeroPredio] = useState(' ')

    const [detail, setDetail] = useState(null)
    const [detailModal, setDetailModal] = useState(false)
    const [detailPDF, setDetailPDF] = useState<any>()
    const [buttonPDFLoading, SetButtonPDFLoading] = useState(false)

    const getList = async () => {
        setLoading(true)
        setList([])
        setRppTypeIndex(rppType)
        let datos: any = {
            tipoRPP: rppType,
            folio: folio,
            clavecatastral: clavecatastral,
            registro: registro,
            bis: bis,
            foja: foja,
            tomo: tomo,
            volumen: volumen,
            seccion: seccionVal,
            serie: serie,
            notario: notario,
            escritura: escritura,
            estado: estado,
            municipio: municipio,
            nombre: nombre,
            domicilio: domiclio,
            cp: cp,
            numeropredio: numeroPredio
        }
        await ApiServices.apiPost('getRPP', datos).then(async res => {
            if (res.data.valido == 1 && res.data.addenda && res.data.addenda.length > 0) {
                setLoading(false)
                setList(res.data.addenda)
                setSelectedFolio(res.data.addenda[0])
            } else {
                setLoading(false)
            }
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    const getDetail = async (type: any, id: any) => {
        setDetail(null)
        let datos: any = {
            tipoRPP: type,
            folio: id,
        }
        await ApiServices.apiPost('getRPPDetail', datos).then(async res => {
            setDetailPDF({ type, id })
            setDetail(res.data.addenda)
            setDetailModal(true)
        }).catch(error => {
            console.log(error)
        })
    }

    const [valueSelected, setValueSelected] = useState<any>(0)
    const [selectedFolio, setSelectedFolio] = useState<any>()

    function moveRight() {
        const selectedIndex = (valueSelected + 1) % list.length;
        updateSelected(selectedIndex);
    }
    function moveleft() {
        const selectedIndex = (valueSelected - 1 + list.length) % list.length;
        updateSelected(selectedIndex);
    }
    function updateSelected(selectedIndex: any) {
        setValueSelected(selectedIndex);
        setSelectedFolio(list[selectedIndex])
    }
    const handleDropdownChange = (e: any) => {
        setSelectedFolio(e.value)
        list.map((folio: any, index: any) => {
            if (folio.Folio == e.value.Folio) {
                updateSelected(index);
            }
        })
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => { getDetail(rppType, rowData.Folio) }} />
            </div>
        );
    }

    const clearFilters = async () => {
        /* setLoading(true) */
        setFolio(' ')
        setClavecatastral(' ')
        setRegistro(' ')
        setBis(' ')
        setFoja(' ')
        setTomo(' ')
        setvolumen(' ')
        setSeccion(' ')
        setSerie(' ')
        setNotario(' ')
        setEscritura(' ')
        setEstado(' ')
        setMunicipio(' ')
        setNombre(' ')
        setDomicilio(' ')

        /* setList([]) */
        setRppTypeIndex(rppType)
        /* let datos: any = {
            tipoRPP: rppType,
            folio: ' ',
            clavecatastral: ' ',
            registro: ' ',
            bis: ' ',
            foja: ' ',
            tomo: ' ',
            volumen: ' ',
            seccion: ' ',
            serie: ' ',
            notario: ' ',
            escritura: ' ',
            estado: ' ',
            municipio: ' ',
            nombre: ' ',
            domicilio: ' '
        }
        await ApiServices.apiPost('getRPP', datos).then(async res => {
            if (res.data.valido == 1 && res.data.addenda && res.data.addenda.length > 0) {
                setLoading(false)
                setList(res.data.addenda)
                setSelectedFolio(res.data.addenda[0])
            } else {
                setLoading(false)
            }
        }).catch(error => {
            console.log(error)
            setLoading(false)
        }) */
    }

    const downloadPDF = async (trm: any) => {
        SetButtonPDFLoading(true)
        let datos
        datos = { "code": 706, "rppType": detailPDF.type, "folio": detailPDF.id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                SetButtonPDFLoading(false)
                if (res.data.url[0].valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    if (typeof res.data.url === 'object') {
                        window.open(`${configuration.url}${res.data.url[0].url}`)
                    } else {
                        window.open(`${configuration.url}${res.data.url}`)
                    }
                }
            } else {
                SetButtonPDFLoading(false)
            }
        }).catch(error => {
            SetButtonPDFLoading(false)
            console.log(error)
        })
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-between" style={{ width: '100%' }}>
            <span className="font-bold white-space-nowrap">Detalle</span>
            <div className="col-2 flex">
                <Button className="p-button-outlined p-button-primary" label="Descargar PDF" onClick={downloadPDF} loading={buttonPDFLoading} />
            </div>
        </div>
    );

    return (
        <section>
            <Toast ref={toast} />
            <div className="flex justify-content-between">
                <div>
                    <h3 className="color-violet">Consulta historica</h3>
                </div>
                <div className="">
                    <Button icon="pi pi-filter-fill" aria-label="Filter" size="large" onClick={() => setFilter(true)} />
                </div>
            </div>
            {list && rppType &&
                <div className="flex justify-content-between">
                    <div style={{ display: 'flex' }}>
                        <h5 className="color-violet">{list.length} Registros - </h5>
                        <h5 className="color-violet">
                            {
                                (rppTypeIndex && (rppTypeIndex == '1' || rppTypeIndex == '')) ? ' RPP Propiedad Actual' :
                                    (rppTypeIndex && (rppTypeIndex == '2' || rppTypeIndex == '')) ? ' RPP Propiedad Historico' :
                                        (rppTypeIndex && (rppTypeIndex == '3' || rppTypeIndex == '')) ? ' RPP Comercio Actual' :
                                            (rppTypeIndex && (rppTypeIndex == '4' || rppTypeIndex == '')) ? ' RPP Comercio Historico' :
                                                (rppTypeIndex && (rppTypeIndex == '5' || rppTypeIndex == '')) ? ' Indices Propiedades' :
                                                    (rppTypeIndex && (rppTypeIndex == '6' || rppTypeIndex == '')) ? ' Indices Sociedades' :
                                                        ''
                            }
                        </h5>
                    </div>
                </div>
            }
            <hr />

            {(rppTypeIndex && (rppTypeIndex == '1' || rppTypeIndex == '')) &&
                <RPPPropiedadActual
                    loading={loading}
                    list={list}
                    moveRight={moveRight}
                    moveleft={moveleft}
                    valueSelected={valueSelected}
                    selectedFolio={selectedFolio}
                    handleDropdownChange={handleDropdownChange}
                    getDetail={getDetail}
                />
            }

            {rppTypeIndex && (rppTypeIndex == '2' || rppTypeIndex == '') &&
                <RPPPropiedadHistorico
                    loading={loading}
                    list={list}
                    moveRight={moveRight}
                    moveleft={moveleft}
                    valueSelected={valueSelected}
                    selectedFolio={selectedFolio}
                    handleDropdownChange={handleDropdownChange}
                    getDetail={getDetail}
                />
            }

            {rppTypeIndex && (rppTypeIndex == '3' || rppTypeIndex == '') &&
                <RPPComercioActual
                    loading={loading}
                    list={list}
                    moveRight={moveRight}
                    moveleft={moveleft}
                    valueSelected={valueSelected}
                    selectedFolio={selectedFolio}
                    handleDropdownChange={handleDropdownChange}
                    getDetail={getDetail}
                />
            }

            {rppTypeIndex && (rppTypeIndex == '4' || rppTypeIndex == '') &&
                <RPPComercioHistorico
                    loading={loading}
                    list={list}
                    moveRight={moveRight}
                    moveleft={moveleft}
                    valueSelected={valueSelected}
                    selectedFolio={selectedFolio}
                    handleDropdownChange={handleDropdownChange}
                    getDetail={getDetail}
                />
            }

            {rppTypeIndex && rppTypeIndex == '5' &&
                <IndicesPropiedades
                    loading={loading}
                    list={list}
                    moveRight={moveRight}
                    moveleft={moveleft}
                    valueSelected={valueSelected}
                    selectedFolio={selectedFolio}
                    handleDropdownChange={handleDropdownChange}
                    getDetail={getDetail}
                />
            }

            {rppTypeIndex && rppTypeIndex == '6' &&
                <IndicesSociedades
                    loading={loading}
                    list={list}
                    moveRight={moveRight}
                    moveleft={moveleft}
                    valueSelected={valueSelected}
                    selectedFolio={selectedFolio}
                    handleDropdownChange={handleDropdownChange}
                    actionBodyTemplate={actionBodyTemplate}
                />
            }

            <Dialog visible={detailModal} maximizable={true} onHide={() => setDetailModal(false)} header={headerElement}>
                <Rpp list={detail} />
            </Dialog>

            <Sidebar visible={filter} position="right" onHide={() => setFilter(false)}>
                <h4>Filtros</h4>
                <br />
                <p> Selecciona los campos necesarios para filtrar los resultados.</p>
                <hr />
                <br />
                <div className='grid pb-7'>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="rppType"
                                value={rppType}
                                onChange={(e) => setRppType(e.value)}
                                options={inicio}
                                optionLabel="name"
                                className="w-100 p-inputtext-lg"
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="rppType">Iniciar búsqueda con</label>
                        </span>
                    </div>
                    {/* <div className='col-12'>
                        <span className="p-float-label">
                            <MultiSelect
                            inputId="dd-city"
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e.value)}
                            options={tipo}
                            optionLabel="name"
                            className="w-100 p-inputtext-lg"
                            />
                            <label htmlFor="dd-city">Tipo de búsqueda</label>
                        </span>
                    </div> */}
                    {/*si selecciona folio*/}
                    <div className='col-12'>
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={folio} onChange={(e) => { setFolio(e.target.value) }} />
                            <label htmlFor="username">Folio</label>
                        </span>
                    </div>
                    {/*si selecciona clave catastral*/}
                    <div className='col-12'>
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={clavecatastral} onChange={(e) => { setClavecatastral(e.target.value) }} />
                            <label htmlFor="username">Clave catastral</label>
                        </span>
                    </div>
                    {/*si selecciona registro*/}
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={registro} onChange={(e) => { setRegistro(e.target.value) }} />
                            <label htmlFor="username">Registro</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={bis} onChange={(e) => { setBis(e.target.value) }} />
                            <label htmlFor="username">Bis</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={foja} onChange={(e) => { setFoja(e.target.value) }} />
                            <label htmlFor="username">Foja</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={tomo} onChange={(e) => { setTomo(e.target.value) }} />
                            <label htmlFor="username">Tomo/Libro</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={volumen} onChange={(e) => { setvolumen(e.target.value) }} />
                            <label htmlFor="username">Volumen/año</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText id="username" className="p-inputtext-lg" value={seccionVal} onChange={(e) => { setSeccion(e.target.value) }} />
                            <label htmlFor="dd-city">Sección</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText id="username" className="p-inputtext-lg" value={serie} onChange={(e) => { setSerie(e.target.value) }} />
                            <label htmlFor="dd-city">Serie/Num</label>
                        </span>
                    </div>
                    {/*si selecciona escritura*/}
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={notario} onChange={(e) => { setNotario(e.target.value) }} />
                            <label htmlFor="username">N° Notario</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={escritura} onChange={(e) => { setEscritura(e.target.value) }} />
                            <label htmlFor="username">N° Escritura</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={estado} onChange={(e) => { setEstado(e.target.value) }} />
                            <label htmlFor="username">Estado</label>
                        </span>
                    </div>
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText id="username" className="p-inputtext-lg" value={municipio} onChange={(e) => { setMunicipio(e.target.value) }} />
                            <label htmlFor="dd-city">Municipio</label>
                        </span>
                    </div>
                    {/*si selecciona nombre*/}
                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={nombre} onChange={(e) => { setNombre(e.target.value) }} />
                            <label htmlFor="username">Nombre</label>
                        </span>
                    </div>

                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={domiclio} onChange={(e) => { setDomicilio(e.target.value) }} />
                            <label htmlFor="username">Domiclio</label>
                        </span>
                    </div>

                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={cp} onChange={(e) => { setCp(e.target.value) }} />
                            <label htmlFor="username">Codigo Postal</label>
                        </span>
                    </div>

                    <div className="col-12">
                        <span className='p-float-label '>
                            <InputText id="username" className="p-inputtext-lg" value={numeroPredio} onChange={(e) => { setNumeroPredio(e.target.value) }} />
                            <label htmlFor="username">Numero Predio</label>
                        </span>
                    </div>
                </div>
                <div className='footer-proccess'>
                    <div className='grid'>
                        <div className='col-6'> <Button label="Limpiar" size="large" outlined autoFocus onClick={clearFilters} /></div>
                        <div className='col-6'>  <Button label="Buscar" size="large" autoFocus onClick={() => { getList() }} /></div>
                    </div>
                </div>
            </Sidebar>

        </section>
    );
}

export default React.memo(ConsultHistorica);