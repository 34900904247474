import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import ApiServices from "../service/apiServices";
import { TreeSelect } from "primereact/treeselect";
import { Accordion, AccordionTab } from 'primereact/accordion';
import authGuardService from "../service/authGuardService";
import DynamicForm from "../pages/actos/DynamicForm";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import moment from "moment";
import EncryptService from "../service/encrypt.service";
import { Toast } from "primereact/toast";

interface DatosProps {
    detallePropiedad: any;
    titulares: any;
    detalleTramite: any | null | undefined;
    ocultarModal: any
    mostrarToast: any
}

const AgregarActo = (props: DatosProps) => {
    const toast = useRef<any>(null);
    const user = authGuardService.getUser()

    const [date, setDate] = useState<any>(null);
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const [actos, setActos] = useState([])
    const [actosCategoria, setActosCategoria] = useState([])
    const [selectedActos, setSelectedActos] = useState([])
    const [selectedActosWithInformation, setSelectedACtosWithInformation] = useState([])
    const [firmadores, setFirmadores] = useState([])
    const [fechaTramite, setFechaTramite] = useState(new Date())
    const [firmante, setFirmante] = useState<any>()
    const [fechaFirma, setFechaFirma] = useState(new Date())
    const [selectedArea, setSelectedArea] = useState(0)

    const getActosList = async () => {
        try {
            let datos;

            const res1 = await ApiServices.apiPost('catActo', datos);
            setActos(res1.data.addenda);

            const res2 = await ApiServices.apiPost('catCategoriaActo', datos);
            let generalInformation: any = [];

            if (res2.data.addenda && res2.data.addenda.length > 0) {
                const clonedArray = JSON.parse(JSON.stringify(res2.data.addenda))
                clonedArray.unshift({ ccatacto_id: 0, ccatacto_desc: 'General', ccatacto_status: 1 });

                for (const singleAct of clonedArray) {
                    let datos = { ccatacto_id: singleAct.ccatacto_id };

                    try {
                        const res3 = await ApiServices.apiPost('catActoCat', datos);

                        if (res3.data.addenda && res3.data.addenda) {
                            const formattedActs = JSON.parse(JSON.stringify(res3.data.addenda))
                            const newArray = formattedActs.map((singleAct: any) => {
                                return {
                                    key: singleAct.act_id,
                                    label: `${singleAct.act_clave} ${singleAct.act_nombre}`,
                                    data: singleAct,
                                    icon: ""
                                };
                            });

                            generalInformation.push({
                                key: singleAct.ccatacto_id,
                                label: singleAct.ccatacto_desc,
                                data: singleAct,
                                icon: "",
                                children: newArray,
                                selectable: false
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                setActosCategoria(generalInformation);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getFirmadores = async (area_id) => {
        let datos = {
            area_id: area_id
        }
        ApiServices.apiPost('getFirmadores', datos).then(async res => {
            setFirmadores(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getFormActo = async (idActo) => {
        let datos = {
            act_id: idActo
        }
        return ApiServices.apiPost('getCampos', datos)
    }

    const agregarActo = async (value: any) => {
        let foundObject = actos.find(acto => acto.act_id == value);
        const cloneActo = JSON.parse(JSON.stringify(selectedActos))
        if (cloneActo && cloneActo.length <= 0) {
            setSelectedArea(foundObject.area_id)
            getFirmadores(foundObject.area_id)
        }
        if (foundObject.area_id == selectedArea || selectedArea == 0) {
            const informationForm = await getFormActo(foundObject.act_id)
            foundObject.informacionActo = informationForm.data.addenda
            foundObject.informacionActoFormatted = []
            cloneActo.push(foundObject)
            setSelectedActos(cloneActo)
        }
    }

    const firmadorTemplate = (option, props) => {
        return (
            <div className="flex align-items-center">
                <div>{`${option.usu_trab_id} - ${option && option.usu_nom ? option.usu_nom : ''} ${option && option.usu_pat ? option.usu_pat : ''}`}</div>
            </div>
        );
    };

    const borrarActo = (index) => {
        const cloneActos = JSON.parse(JSON.stringify(selectedActos))
        if (index > -1 && index < cloneActos.length) {
            cloneActos.splice(index, 1);
        }
        if (cloneActos && cloneActos.length <= 0) setSelectedArea(0)
        setSelectedActos(cloneActos)
    }

    const updatedArray2 = (original, valoresA) => {
        return original.map((item2) => {
            const matchingItem = valoresA.find(
                (item1) => item1.cmp_id === item2.cmp_id && item1.act_id === item2.act_id
            );
            if (matchingItem && matchingItem.hasOwnProperty("value")) {
                return { ...item2, value: matchingItem.value };
            }
            return item2;
        });
    }

    const setDynamicInformationParent = useCallback((value) => {
        const cloneActo = JSON.parse(JSON.stringify(selectedActos));
        const flatted = value.flat();


        if (flatted && flatted.length > 0) {
            const newInformation = cloneActo.map((singleActo) => {
                if (singleActo.act_id == flatted[0].act_id) {
                    singleActo.informacionActo = updatedArray2(singleActo.informacionActo, flatted);
                    return singleActo;
                }
                return singleActo; // Add this line to return the unchanged singleActo
            });

            // Only update if there's a difference
            if (JSON.stringify(selectedActos) !== JSON.stringify(newInformation)) {
                setSelectedActos(newInformation);
            }
        }
    }, [selectedActos]);

    function extractActIds(array) {
        return array.map(item => item.act_id);
    }

    const normalFormatMappingCMP = (data: any) => {
        return {
            id: data.cmp_id,
            val: data.value || ''
        }
    }

    const formatearActo = (informationToFormat: any) => {
        const flattenedArray = informationToFormat.flat();
        const filteredObjects = flattenedArray.filter((obj: any) => obj.cmp_tipo !== "L" && obj.cmp_tipo !== 'vacio');
        const result = filteredObjects.map(({ cmp_id, frm_id, cmp_tipo, value }: any) => {
            let newValue = value
            if (typeof value == 'object') {
                if (value && value.not_id) {
                    newValue = value.not_id
                }
            }

            return normalFormatMappingCMP({ cmp_id, frm_id, cmp_tipo, value: newValue })
        });
        return result;
    }

    const addRespuestasActos = async (trm_id) => {
        if (selectedActos && selectedActos.length > 0) {
            const requests = selectedActos.map(async (singleActo) => {
                const formattedResponse = formatearActo(singleActo.informacionActo);

                // FORMATO PARA EL 507
                // Aquí puedes descomentar y adaptar según tu necesidad
                // formatResponse.filter((x: any) => x.cmp_tipo === 'DT').forEach((x: any, index: any) => {
                //     let datosEx = EncryptService.encryptData(JSON.stringify(x.val));
                //     ApiServices.processRequestAdd(datosEx).then(async res => {
                //         if (res.data.valido == 1) {
                //         }
                //     }).catch(error => {
                //         console.log(error)
                //     })
                // })

                let datos = {
                    "code": 401,
                    "usu_id": user.usu_id,
                    "act_id": singleActo.act_id,
                    "campos": formattedResponse,
                    "trm_id": trm_id,
                    "ptrm_id": 0
                };

                datos = EncryptService.encryptData(JSON.stringify(datos));
                try {
                    const res = await ApiServices.processRequestAdd(datos);
                    if (res.data.valido === 1) {
                        return true;
                    } else {
                        throw new Error('Invalid response');
                    }
                } catch (error) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al guardar el trámite', life: 3000 });
                    return false;
                }
            });

            const results = await Promise.all(requests);

            if (results.every(result => result === true)) {
                props.mostrarToast()
                props.ocultarModal()
            }
        }
    };

    const saveTramite = async () => {
        if (firmante) {
            let datos = {
                "code": 108,
                "usu_id": user.usu_id,
                "prop_id": props.detallePropiedad.prop_id,
                "trm_solicitante": '',
                "trm_escritura": '',
                "not_id": 0,
                "trm_fecha": moment(fechaTramite).format('YYYY-MM-DD'),
                "trm_fechafirma": moment(fechaFirma).format('YYYY-MM-DD'),
                "trm_firmante": firmante.usu_id,
                "actos": extractActIds(selectedActos)
            };

            datos = EncryptService.encryptData(JSON.stringify(datos));

            try {
                const res = await ApiServices.processRequestAdd(datos);
                if (res.data.valido === 1) {
                    await addRespuestasActos(res.data.addenda.tramite.trm_id);
                }
            } catch (error) {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al guardar el trámite', life: 3000 });
            }
        } else {
            toast.current?.show({ severity: 'error', summary: 'Firmante', detail: 'Debes de elegir un firmador', life: 3000 });
        }
    };

    useEffect(() => {
        getActosList()
    }, [])

    const calendarRef = useRef(null);
    const calendarRef2 = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            setFechaTramite(new Date(formattedDate));
        } else {
            setFechaTramite(null);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };

    const handleInputChange2 = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            setFechaFirma(new Date(formattedDate));
        } else {
            setFechaFirma(null);
        }
        if (calendarRef2.current && calendarRef2.current.inputElement) {
            calendarRef2.current.inputElement.value = formattedDate || inputValue;
        }
    };

    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };

    return (
        <section>
            <Toast ref={toast} />
            <p>Al generar un acto histórico, se agrega al historial de actos.</p>
            <div className='grid'>
                <div className='col-12 md:col-6'>
                    {/* Input Done */}
                    <Calendar
                        value={fechaTramite}
                        showIcon
                        locale="es"
                        onChange={(e: any) => { setFechaTramite(e.target.value) }}
                        inputId="fechaTramite"
                        dateFormat='dd/mm/yy'
                        ref={calendarRef}
                        onInput={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="fechaTramite">Fecha de entrada</label>
                </div>
                <div className='col-12 md:col-6'>
                    {/* Input Done */}
                    <Calendar
                        value={fechaFirma}
                        showIcon
                        locale="es"
                        onChange={(e: any) => { setFechaFirma(e.target.value) }}
                        inputId="fechaFirma"
                        dateFormat='dd/mm/yy'
                        ref={calendarRef2}
                        onInput={(e) => handleInputChange2(e)}
                    />
                    <label htmlFor="fechaFirma">Fecha de firma</label>
                </div>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <TreeSelect
                            value={selectedNodeKey}
                            onChange={(e) => agregarActo(e.value)}
                            options={actosCategoria}
                            filter
                            className="w-full"
                            placeholder="Selecciona un Acto para agregar"
                        ></TreeSelect>
                        <label htmlFor="dd-city">Acto</label>
                    </span>
                </div>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <Dropdown
                            value={firmante}
                            onChange={(e) => { setFirmante(e.value) }}
                            options={firmadores}
                            optionLabel="usu_trab_id"
                            itemTemplate={firmadorTemplate}
                            className="w-full p-inputtext-lg"
                            virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                        />
                        <label htmlFor="username">Firmador</label>
                    </span>
                </div>
                <Accordion style={{ width: '100%', marginTop: 15 }}>
                    {selectedActos && selectedActos.length > 0 &&
                        selectedActos.map((singleActo, index) => {
                            return (
                                <AccordionTab
                                    header={
                                        <span className="flex align-items-center gap-2 w-full" style={{ width: '100%' }}>
                                            <span className="white-space-nowrap">{`${singleActo.act_clave} - ${singleActo.act_nombre}`}</span>
                                            <span className="ml-auto">
                                                <Button icon="pi pi-trash" rounded aria-label="Eliminar Acto" onClick={() => { borrarActo(index) }} />
                                            </span>
                                        </span>
                                    } headerStyle={{ width: '100%' }}
                                >
                                    <DynamicForm
                                        edicion={true}
                                        propiedadDetail={props.detallePropiedad}
                                        informationForm={singleActo.informacionActo}
                                        information={singleActo}
                                        setDynamicInformationParent={setDynamicInformationParent}
                                        titulares={props.titulares}
                                        tramite={props.detalleTramite}
                                    />
                                </AccordionTab>
                            )
                        })
                    }
                </Accordion>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
                <Button label="Cancelar" onClick={props.ocultarModal} autoFocus style={{ width: '20%', marginRight: 10 }} />
                <Button label="Guardar" onClick={saveTramite} autoFocus style={{ width: '20%', marginLeft: 10 }} />
            </div>
        </section >
    );

}

export default memo(AgregarActo);


