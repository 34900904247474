import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../serviceExample/ProductService';
import ApiServices from '../../../../service/apiServices';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import configuration from '../../../../configurationFile';

const ResulGenerales = (props: any) => {
    const [selectedCity, setSelectedCity] = useState<any>(null);
    const [checked, setChecked] = useState<boolean>(false);

    const notario: any = [
        { name: 'notario n' },
        { name: 'notario n' },
        { name: 'notario n' },
    ];

    const dt = useRef<any>(null);
    const [products, setProducts] = useState<any>(null);

    useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []);

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.code}
            </>
        );
    }
    const IconDocumentTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <i className='pi pi-file' style={{ 'fontSize': '2em' }}></i>
                </div>
            </>
        );
    }

    const [visible, setVisible] = useState(false);
    const buttonEl = useRef<any>(null);
    const [selectedFile, setSelectedFile] = useState<any>()

    const accept = () => {
        if (selectedFile) {
            handleDeleteDocument(selectedFile.ptrmd_id, selectedFile.ptrm_id)
        }
    };

    const EliminarTemplate = (rowData: any) => {
        return (
            <>
                <div>
                    <a href={`${configuration.url}${rowData.ptrmd_url}`} target="_blank">
                        <Button
                            tooltip="Ver archivo subido"
                            className="p-button-text"
                            icon="pi pi-eye"
                        />
                    </a>
                    <Button
                        tooltip="Eliminar archivo subido"
                        className="p-button-text p-button-danger"
                        icon="pi pi-trash"
                        ref={buttonEl} onClick={() => {
                            setVisible(true)
                            setSelectedFile(rowData)
                        }}
                    />
                </div>
            </>
        );
    }

    const [file, setFile] = useState<any>();
    const refFile: any = useRef()
    const [fileName, setFileName] = useState('')
    const handleFileClick = (e: any) => {
        refFile.current.click()
    }

    const readUploadFile = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            if (esMayorQue5MB(e.target.files[0].size)) {
                props.fileToBig()
            } else {
                setFile(e.target.files[0]);
            }
        }
    }

    function esMayorQue5MB(bytes: any) {
        const megabytes = bytes / (1024 * 1024); // Convertir a megabytes
        return megabytes > 5;
    }

    const toast = useRef<any>(null);

    const handleUploadDocument = () => {
        if (fileName && file) {
            const ptrm_id = (props && props.preTramite) ? props.preTramiteDetail.tramite.ptrm_id : props.exist.ptrm_id
            const data = {
                "ptrm_id": ptrm_id,
                "ptrmd_desc": fileName || file.name,
                "ptrmd_url": "media/doc/"
            }

            ApiServices.apiPostFile('addDocPre', file, data).then(async res => {
                if (res.data.valido === 1) {
                    props.requestPreTramiteDetail()
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            if (file)
                toast.current.show({ severity: 'error', summary: 'Subir Archivo', detail: 'Debes de seleccionar un archivo para subir', life: 3000 });
            else
                toast.current.show({ severity: 'error', summary: 'Nombre del Archivo', detail: 'Debes ingresar un nombre al archivo', life: 3000 });
        }
    }

    const handleDeleteDocument = (id: any, ptrm: any) => {
        const data = {
            "ptrm_id": ptrm,
            "ptrmd_id": id,
        }

        ApiServices.apiPost('deleteDocPre', data).then(async res => {
            if (res.data.valido === 1) {
                props.requestPreTramiteDetail()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const notarioTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{option ? `${option.not_fedata || ''} - ${option.not_nom || ''} ${option.not_pat || ''} ${option.not_mat || ''}` : ''}</div>
            </div>
        );
    };

    const [size, setSize] = useState(0)


    return (
        <section className=''>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <small className='black'>Folio</small>
                    <p className='result'>{props.folioDatos.prop_folio}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Clave catastral</small>
                    <p className='result'>{props.folioDatos.prop_clave}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Antecedentes</small>
                    <p className='result'>{props.folioDatos.prop_antecedente}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Domicilio registral</h5>
                </div>
                <div className='col-12 md:col-12'>
                    <small className='black'>Domicilio Registral</small>
                    <p className='result'>{props.folioDatos.prop_direccion}</p>
                </div>
                <div className='col-12'>
                    <h5>Domicilio convencional</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Calle</small>
                    <p className='result'>{props.folioDatos.prop_calle}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. exterior</small>
                    <p className='result'>{props.folioDatos.prop_ext}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. interior</small>
                    <p className='result'>{props.folioDatos.prop_int}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Colonia</small>
                    <p className='result'>{props.folioDatos.cp_col}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Municipio</small>
                    <p className='result'>{props.folioDatos.cp_mun}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Estado</small>
                    <p className='result'>{props.folioDatos.cp_edo}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Localidad</small>
                    <p className='result'>{props.folioDatos.cp_loc}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Codigo postal</small>
                    <p className='result'>{props.folioDatos.cp_cp}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Indiviso</small>
                    <p className='result'>{props.folioDatos.prop_indiviso}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Uso Suelo</small>
                    <p className='result'>{props.folioDatos.prop_ususuelo}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Superficie del predio</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Superficie total</small>
                    <p className='result'>{props.folioDatos.prop_suptotal}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Restante</small>
                    <p className='result'>{props.folioDatos.prop_suprestante}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Construidas</small>
                    <p className='result'>{props.folioDatos.prop_constr}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Medidas y Colindancias</small>
                    <p className='result'>{props.folioDatos.prop_medidas}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <hr />
                    <h5>Solicitante</h5>
                </div>
                <div className='col-12 md:col-12'>
                    <p>El solicitante es el:</p>
                    <div className='grid'>
                        <div className='col-12 md:col-5'>
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    className="p-inputtext-lg"
                                    placeholder="Nombre del Solicitante"
                                    value={props.requestName}
                                    onChange={(e) => { props.setRequestName(e.target.value) }}
                                    style={{ height: '100%' }}
                                />
                                <label htmlFor="username">Nombre del solicitante</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-2'>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', border: '2px solid #e0e0e0', borderRadius: 10, height: '100%' }} onClick={() => {
                                if (props && props.titulares && props.titulares.length > 0) {
                                    props.setRequestName(props?.titulares ? `${props.titulares[size].ptit_nom} ${props.titulares[size].ptit_pat} ${props.titulares[size].ptit_mat}` : props.exist.trm_solicitante)
                                    const lenghtSize = props?.titulares ? props.titulares.length : 0
                                    const selectedIndex = (size + 1) % lenghtSize;
                                    setSize(selectedIndex)
                                    props.setRequester(true)
                                }
                            }}>
                                <i className="pi pi-clone"></i>
                                <p>¿Es Dueño?</p>
                            </div>
                            {/* <Checkbox inputId="ingredient1" checked={props.requester} onClick={() => { props.setRequester(!props.requester) }} />
                            <label htmlFor="ingredient1" className="ml-2">¿Dueño - Otro?</label> */}
                        </div>
                        <div className='col-12 md:col-5' >
                            <span className="p-float-label">
                                <Dropdown
                                    inputId="dd-city"
                                    value={props.selectedNotaryList}
                                    onChange={(e) => {
                                        props.setSelectedNotaryList(e.value)
                                        props.setRequester(true)
                                    }}
                                    options={props.notaryList}
                                    optionLabel="not_num"
                                    filter
                                    showClear
                                    filterBy="not_num"
                                    className="p-inputtext-lg"
                                    showFilterClear
                                    itemTemplate={notarioTemplate}
                                    valueTemplate={notarioTemplate}
                                    style={{ height: '100%' }}
                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                />
                                <label htmlFor="dd-city">Notario</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    className="p-inputtext-lg"
                                    value={props.escrituraNumber}
                                    onChange={(e) => { props.setEscrituraNumber(e.target.value) }}
                                />
                                <label htmlFor="username">N° de escritura</label>
                            </span>
                        </div>
                    </div>
                </div>
                {props.exist &&
                    <div className='col-12 md:col-12'>
                        <h5>Documentación</h5>
                        <p>Agrega los documentos necesarios para tu tramite</p>
                        <div className='grid'>
                            <div className='col-12 md:col-4'>
                                <div className='pt-2 text-center'>
                                    {file ?
                                        <div className='pt-2 document-ok'>
                                            <p>
                                                <i className='pi pi-file' style={{ 'fontSize': '3em' }} />
                                            </p>
                                            <p className=''>{file.name}</p>
                                        </div>
                                        :
                                        <>
                                            <input
                                                ref={refFile}
                                                type="file"
                                                hidden
                                                onChange={readUploadFile}
                                            />
                                            <p className='document' onClick={handleFileClick}>
                                                <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }} />
                                                Selecciona para subir documento
                                            </p>
                                        </>
                                    }
                                </div>


                                <div className='col-12 md:col-12 py-5'>
                                    <span className="p-float-label">
                                        <InputText
                                            id="username"
                                            className="p-inputtext-lg"
                                            placeholder="Marta Maria"
                                            value={fileName}
                                            onChange={(e) => { setFileName(e.target.value) }}
                                        />
                                        <label htmlFor="username">Nombre del documento</label>
                                    </span>
                                </div>
                                <div>
                                    <Button label="Agregar" className='p-button-outlined' onClick={handleUploadDocument} />
                                </div>
                            </div>
                            {
                                props.preTramiteDetail && props.preTramiteDetail.docs &&
                                <div className='col-12 md:col-8'>
                                    <ConfirmPopup
                                        target={buttonEl.current}
                                        visible={visible}
                                        onHide={() => setVisible(false)}
                                        message="Are you sure you want to proceed?"
                                        icon="pi pi-exclamation-triangle"
                                        accept={accept}
                                    />
                                    <DataTable value={props.preTramiteDetail.docs}
                                        dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        emptyMessage="No products found." responsiveLayout="scroll">
                                        <Column field="code" body={IconDocumentTemplate} ></Column>
                                        <Column field="ptrmd_desc" header="Nombre del documento"></Column>
                                        <Column field="name" style={{ width: '10rem' }} body={EliminarTemplate} ></Column>
                                    </DataTable>
                                </div>
                            }
                        </div>

                    </div>
                }
            </div>
        </section>
    );

}

export default React.memo(ResulGenerales);