import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useState } from "react";
import { FilterMatchMode } from 'primereact/api';

const IndicesSociedades = (props: any) => {

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Nombre: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Folio: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Numero: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Foja: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Tomo: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Volumen: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Seccion: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Capital: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Domici: { value: null, matchMode: FilterMatchMode.CONTAINS },
        UsrCap: { value: null, matchMode: FilterMatchMode.CONTAINS },
        FecCap: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const fechaBodyTemplate = (rowData: any) => {
        return (
            <p>{formatFecha(rowData.FecCap)}</p>
        )
    }

    function formatFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${dia}-${mes}-${año}`;
        } else {
            return ''
        }
    }

    useEffect(() => {
        onGlobalFilterChange({ "target": { "value": '' } })
    }, [props.loading])

    const cols = [
        { field: 'Nombre', header: 'Nombre' },
        { field: 'Numero', header: 'Registro' },
        { field: 'Foja', header: 'Foja' },
        { field: 'Tomo', header: 'Tomo/libro' },
        { field: 'Volumen', header: 'Volumen/año' },
        { field: 'Seccion', header: 'Sección' },
        { field: 'Capital', header: 'Capital Social' },
        { field: 'Domici', header: 'Otros datos' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc: any = new jsPDF.default('landscape', 'mm', 'a4');
                doc.autoTable(exportColumns, props.list);
                window.open(doc.output('bloburl'), '_blank');

            });
        });
    };

    const renderHeader = () => {
        return (
            <div className="grid flex justify-content-between">
                <div className="col px-5">
                    <span className="p-input-icon-right">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Buscador general"
                            className="p-inputtext-lg"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                        />
                    </span>
                </div>
                <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file-pdf" data-pr-tooltip="PDF" label="PDF" onClick={exportPdf} />
                </div>
            </div>
        );
    };
    const header = renderHeader();

    return (
        <section>

            <div className="bor-hist">
                <ScrollPanel style={{ width: '100%', height: '700px' }} className="custombar1">
                    <DataTable
                        emptyMessage={'Sin Resultados'}
                        loading={props.loading}
                        value={props.list}
                        paginator
                        header={header}
                        rows={10}
                        globalFilterFields={[
                            "Nombre",
                            "Folio",
                            "Numero",
                            "Foja",
                            "Tomo",
                            "Volumen",
                            "Seccion",
                            "Capital",
                            "Domici",
                            "FecCap",
                            "UsrCap"
                        ]}
                        dataKey="id"
                        filters={filters}
                        filterDisplay="row"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} registros"
                    >
                        <Column field="Nombre" header="Nombre" sortable ></Column>
                        <Column field="Numero" header="Registro" sortable ></Column>
                        <Column field="Foja" header="Foja" sortable ></Column>
                        <Column field="Tomo" header="Tomo/libro" sortable ></Column>
                        <Column field="Volumen" header="Volumen/año" sortable ></Column>
                        <Column field="Seccion" header="Sección" sortable ></Column>
                        <Column field="Capital" header="Capital Social" sortable ></Column>
                        <Column field="Domici" header="Otros datos" sortable ></Column>
                    </DataTable>
                </ScrollPanel>
            </div>


        </section >
    );
}

export default React.memo(IndicesSociedades);