import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import configuration from '../../configurationFile';
import { Toast } from 'primereact/toast';
import ApiServices from '../../service/apiServices';
import EncryptService from '../../service/encrypt.service';
import authGuardService from '../../service/authGuardService';


const VenPerfil = () => {
    const user = authGuardService.getUser()
    const toast = useRef<any>(null);
    const refFile: any = useRef()

    const [visible, setVisible] = useState<boolean>(false);
    const [password, setPassword] = useState<any>('')
    const [passwordValid, setPasswordValid] = useState<any>(true)
    const [confirmPassword, setConfirmPassword] = useState<any>('')
    const [confirmPasswordValid, setConfirmPasswordValid] = useState<any>(true)
    const [userPhoto, setUserPhoto] = useState(user.usu_foto)

    useEffect(() => {
        if (!userPhoto) {
            handleUserDetails()
        }
    }, [userPhoto])

    const handleUserDetails = () => {
        let datos
        datos = { "usu_id": user.usu_id }
        ApiServices.apiPost('detalleUsuario', datos).then(async res => {
            setUserPhoto(res.data.addenda[0].usu_foto)
        }).catch(error => {
            console.log(error)
        })
    }

    const validatePasswords = () => {
        if (password === confirmPassword) {
            let datos
            datos = {
                code: 104,
                usu_id: user.usu_id,
                usu_psw: password
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido) {
                    toast.current.show({ severity: 'success', summary: 'Contraseña Actualizada' });
                    setVisible(false)
                }

            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error al Actualizar' });
            })
        } else {
            setPasswordValid(false)
            setConfirmPasswordValid(false)
        }
    }

    const handleFileClick = (e: any) => {
        refFile.current.click()
    }

    const readUploadFile = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            handleUploadDocument(e.target.files[0])
        }
    }

    const handleUploadDocument = (file: any) => {
        const data = {
            "usu_id": user.usu_id,
            "usu_foto": 'media/perfil/'
        }
        ApiServices.apiPostFile('addFotoPerfil', file, data).then(async res => {
            if (res.data.valido === 1) {
                handleUserDetails()
                authGuardService.sendUser(res.data.addenda[0])
                window.location.reload();
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const footerPasswords = (
        <div className=''>
            <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
            <Button label="Actualizar" onClick={validatePasswords} autoFocus />
        </div>
    );

    useEffect(() => {
        getPerfiles()
    }, [])
    const [perfil, setPerfil] = useState(null)
    const getPerfiles = () => {
        let datos
        ApiServices.apiPost('catPerfiles', datos).then(async res => {
            setPerfil(res.data.addenda.find((a: any) => a.prf_id == user.prf_id))
        }).catch(error => {
            console.log(error)
        })
    }

    const refCertificado: any = useRef()
    const refKey: any = useRef()
    const [fileCertificado, setFileCertificado] = useState<any>();
    const [fileKey, setFileKey] = useState<any>();

    const handleCertificadoClick = (e: any) => {
        refCertificado.current.click()
    }

    const handleKeyClick = (e: any) => {
        refKey.current.click()
    }

    const readUploadCertificado = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            setFileCertificado(e.target.files[0])
        }
    }

    const readUploadKey = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            setFileKey(e.target.files[0])
        }
    }

    const [passwordE, setPasswordE] = useState<any>('')

    const handleUploadEfirma = () => {
        if (fileCertificado && fileKey) {
            const data = {
                "usu_id": user.usu_id,
                "usu_efirma_password": passwordE === '' ? '' : EncryptService.encryptData(passwordE)
            }

            ApiServices.apiPost2Files('efirma-add', fileKey, fileCertificado, EncryptService.encryptData(data)).then((res: any) => {
                if (res.data.valido == 1) {
                    toast.current.show({ severity: 'success', summary: 'Guardado con Exito' });
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            toast.current.show({ severity: 'error', summary: 'Debes subir ambos archivos KEY y CER' });
        }

    }


    return (
        <section className='container-fluid'>
            <Toast ref={toast} />

            <div className='grid'>
                <div className='col-12'>
                    <h3 className='pb-4'>Mi perfil</h3>
                </div>
            </div>
            <div className='grid'>
                <div className={user && (user.prf_id == 3 || user.prf_id == 6 || user.prf_id == 7) ? 'col-12 md:col-4' : 'col-12 md:col-12'}>
                    <div className='dashboard grid'>
                        <div className='col-12 text-center'>
                            <div className='pb-3'>
                                <img className='photo-perfil' src={`${configuration.url}/${userPhoto}`} alt="profile" />
                                <span className='camera-perfil'>
                                    <input
                                        ref={refFile}
                                        type="file"
                                        hidden
                                        onChange={readUploadFile}
                                    />
                                    <Button size="small" icon="pi pi-camera" rounded aria-label="Filter" onClick={handleFileClick} />
                                </span>
                            </div>
                            <h4 className="text-bold">{`
                            ${(user && user.usu_nom) ? user.usu_nom.toUpperCase() : ''}
                            ${(user && user.usu_pat) ? user.usu_pat.toUpperCase() : ''}
                            ${(user && user.usu_mat) ? user.usu_mat.toUpperCase() : ''}
                            `}</h4>
                            <p>ID {user && user.usu_trab_id ? user.usu_trab_id : ''}</p>
                            <p>{perfil ? perfil['prf_desc'] : ''}</p>

                        </div>
                        <div className="col-12 md:col-6">
                            <small className="gris">Teléfono</small>
                            <p>{user ? user.usu_tel : ''}</p>
                        </div>
                        <div className="col-12 p-md-6">
                            <small className="gris">Correo</small>
                            <p>{user ? user.usu_email : ''}</p>
                        </div>
                        <div className="col-12 text-center">
                            <Button label="Cambiar contraseña" text onClick={() => setVisible(true)} />
                        </div>
                    </div>
                </div>
                {user && (user.prf_id == 3 || user.prf_id == 6 || user.prf_id == 7) &&
                    <div className='col-12 md:col-8'>
                        <div className='dashboard'>
                            <h4>Agrega E-Firma</h4>
                            <p>Al agregar tu E-Firma será utilizada para las firmas que consideres que son correctas para su validación.</p>
                            <div className='col-12 md:col-12' style={{ display: 'flex' }}>
                                <div className='col-6 pt-2 text-center'>
                                    {fileCertificado ?
                                        <div className='pt-2 document-ok'>
                                            <p>
                                                <i className='pi pi-file' style={{ 'fontSize': '3em' }} />
                                            </p>
                                            <p className=''>{fileCertificado.name}</p>
                                        </div>
                                        :
                                        user && user.usu_efirma_cert ?
                                            <>
                                                <input
                                                    ref={refCertificado}
                                                    type="file"
                                                    hidden
                                                    onChange={readUploadCertificado}
                                                    accept=".cer"
                                                />

                                                <div className='pt-2 document-ok' onClick={handleCertificadoClick}>
                                                    <p>
                                                        <i className='pi pi-file' style={{ 'fontSize': '3em' }} />
                                                    </p>
                                                    <p className=''>{'Certificado Cargado'}</p>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <input
                                                    ref={refCertificado}
                                                    type="file"
                                                    hidden
                                                    onChange={readUploadCertificado}
                                                    accept=".cer"
                                                />
                                                <p className='document' onClick={handleCertificadoClick}>
                                                    <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }} />
                                                    Selecciona para subir tu certifificado
                                                </p>
                                            </>
                                    }
                                </div>
                                <div className='col-6 pt-2 text-center'>
                                    {fileKey ?
                                        <div className='pt-2 document-ok'>
                                            <p>
                                                <i className='pi pi-file' style={{ 'fontSize': '3em' }} />
                                            </p>
                                            <p className=''>{fileKey.name}</p>
                                        </div>
                                        :
                                        user && user.usu_efirma_key ?
                                            <>
                                                <input
                                                    ref={refKey}
                                                    type="file"
                                                    hidden
                                                    onChange={readUploadKey}
                                                    accept=".key"
                                                />

                                                <div className='pt-2 document-ok' onClick={handleKeyClick}>
                                                    <p>
                                                        <i className='pi pi-file' style={{ 'fontSize': '3em' }} />
                                                    </p>
                                                    <p className=''>{'Key Cargado'}</p>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <input
                                                    ref={refKey}
                                                    type="file"
                                                    hidden
                                                    onChange={readUploadKey}
                                                    accept=".key"
                                                />
                                                <p className='document' onClick={handleKeyClick}>
                                                    <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }} />
                                                    Selecciona para subir tu certifificado
                                                </p>
                                            </>
                                    }
                                </div>
                            </div>

                            <div className='col-12 md:col-12'>
                                <br />
                                <p>Si ingresas tu contraseña de tu eFirma se guardara y firmará automaticamente, si no la ingresas te pedirá la misma cada vez que firmes.</p>
                                <div className='col-4'>
                                    <span className="p-float-label">
                                        <Password value={passwordE} onChange={(e) => setPasswordE(e.target.value)} id="username" className="p-inputtext-lg" feedback={false} />
                                        <label htmlFor="username">Contraseña</label>
                                    </span>
                                </div>
                            </div>
                            <div className=' flex justify-content-end'>
                                <div className='col-12 md:col-4'>
                                    <Button label="Guardar" raised onClick={handleUploadEfirma} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>


            <Dialog
                header="Actualizar contraseña"
                visible={visible}
                style={{ width: '40vw' }}
                onHide={() => setVisible(false)}
                footer={footerPasswords}
            >
                <p>Ingresa los datos en los campos para realizar el cambio</p>
                <div className='grid'>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <Password
                                id="password"
                                inputId='password'
                                value={password}
                                className={`p-inputtext-lg ${!passwordValid && 'p-invalid'}`}
                                onChange={(e) => {
                                    setPasswordValid(true)
                                    setConfirmPasswordValid(true)
                                    setPassword(e.target.value)
                                }}
                                toggleMask
                                feedback={false}
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="password">Nueva contraseña</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <Password
                                id="confirmPassword"
                                inputId='confirmPassword'
                                value={confirmPassword}
                                className={`p-inputtext-lg ${!confirmPasswordValid && 'p-invalid'}`}
                                onChange={(e) => {
                                    setPasswordValid(true)
                                    setConfirmPasswordValid(true)
                                    setConfirmPassword(e.target.value)
                                }}
                                toggleMask
                                feedback={false}
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="confirmPassword">Confirmar contraseña</label>
                        </span>
                    </div>
                    {!confirmPasswordValid && !passwordValid &&
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <p>Las contraseñas no coinciden</p>
                            </span>
                        </div>
                    }
                </div>
            </Dialog>
        </section >
    );

}

export default React.memo(VenPerfil);