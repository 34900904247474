import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import { Button } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';

const ResulGeneralesView = (props: any) => {
    const toast = useRef<any>(null);

    const IconDocumentTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <i className='pi pi-file' style={{ 'fontSize': '2em' }}></i>
                </div>
            </>
        );
    }

    const [visible, setVisible] = useState(false);
    const buttonEl = useRef<any>(null);
    const [selectedFile, setSelectedFile] = useState<any>()

    const accept = () => {
        if (selectedFile) {
            handleDeleteDocument(selectedFile.trm_id, selectedFile.trmd_id)
        }
    };

    const EliminarTemplate = (rowData: any) => {
        return (
            <>
                <div>
                    <Button
                        tooltip="Eliminar archivo subido"
                        className="p-button-text p-button-danger"
                        icon="pi pi-trash"
                        ref={buttonEl} onClick={() => {
                            setVisible(true)
                            setSelectedFile(rowData)
                        }}
                    />
                </div>
            </>
        );
    }

    const [file, setFile] = useState<any>();
    const refFile: any = useRef()
    const [fileName, setFileName] = useState('')

    const handleFileClick = (e: any) => {
        refFile.current.click()
    }

    function esMayorQue5MB(bytes: any) {
        const megabytes = bytes / (1024 * 1024); // Convertir a megabytes
        return megabytes > 5;
    }

    const readUploadFile = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            if (e.target.files[0].type == 'application/pdf') {
                if (esMayorQue5MB(e.target.files[0].size)) {
                    props.fileToBig()
                } else {
                    setFile(e.target.files[0]);
                }
            }
            else
                toast.current.show({ severity: 'error', summary: 'No es PDF', detail: 'El Archivo no cumple con el formato PDF', life: 3000 });
        }
    }

    const reset = () => {
        /* refFile.current.value = null; */
        setFile(null)
    };

    const handleUploadDocument = () => {
        const trm_id = (props && props.preTramite) ? props.preTramiteDetail.tramite.ptrm_id : props.exist.trm_id
        const data = {
            "trm_id": trm_id,
            "trmd_desc": fileName || file.name,
            "trmd_url": "media/doc/"
        }

        ApiServices.apiPostFile('addDoc', file, data).then(async res => {
            if (res.data.valido === 1) {
                props.requestPreTramiteDetail()
                setFileName('')
                setFile(null)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleDeleteDocument = (id: any, ptrm: any) => {
        const data = {
            "trm_id": id,
            "trmd_id": ptrm,
        }

        ApiServices.apiPost('deleteDoc', data).then(async res => {
            if (res.data.valido === 1) {
                props.requestPreTramiteDetail()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <section className=''>
            <Toast ref={toast} />

            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <small className='black'>Folio</small>
                    <p className='result'>{props.folioDatos.prop_folio}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Clave catastral</small>
                    <p className='result'>{props.folioDatos.prop_clave}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Domicilio registral</h5>
                </div>
                <div className='col-12 md:col-12'>
                    <small className='black'>Domicilio registral</small>
                    <p className='result'>{props.folioDatos.prop_direccion}</p>
                </div>
                <div className='col-12'>
                    <h5>Domicilio convencional</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Codigo postal</small>
                    <p className='result'>{props.folioDatos.cp_cp}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Localidad</small>
                    <p className='result'>{props.folioDatos.cp_loc}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Municipio</small>
                    <p className='result'>{props.folioDatos.cp_mun}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Estado</small>
                    <p className='result'>{props.folioDatos.cp_edo}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Colonia</small>
                    <p className='result'>{props.folioDatos.cp_col}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. interior</small>
                    <p className='result'>{props.folioDatos.prop_int}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. exterior</small>
                    <p className='result'>{props.folioDatos.prop_ext}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Calle</small>
                    <p className='result'>{props.folioDatos.prop_calle}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Superficie del predio</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Medidas y Colindancias</small>
                    <p className='result'>{props.folioDatos.prop_medidas}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Construidas</small>
                    <p className='result'>{props.folioDatos.prop_constr}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Superficie total</small>
                    <p className='result'>{props.folioDatos.prop_suptotal}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <hr />
                    <h5>Solicitante</h5>
                </div>
                <div className='col-12 md:col-12'>
                    <p>El solicitante es el:</p>
                    <div className='grid'>
                        <div className='col-2'>
                            <Checkbox inputId="ingredient1" checked={props.requester} onClick={() => { props.setRequester(!props.requester) }} disabled />
                            <label htmlFor="ingredient1" className="ml-2">¿Dueño?</label>
                        </div>
                        <div className='col-2'>
                            <Checkbox inputId="ingredient1" checked={!props.requester} onClick={() => { props.setRequester(!props.requester) }} disabled />
                            <label htmlFor="ingredient1" className="ml-2">¿Notario?</label>
                        </div>
                        {props.requester &&
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="username"
                                        className="p-inputtext-lg"
                                        placeholder="Nombre del Solicitante"
                                        value={props.requestName}
                                        disabled
                                        onChange={(e) => { props.setRequestName(e.target.value) }}
                                    />
                                    <label htmlFor="username">Nombre del solicitante</label>
                                </span>
                            </div>}
                        {
                            !props.requester &&
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <Dropdown
                                        inputId="dd-city"
                                        value={props.selectedNotaryList}
                                        onChange={(e) => props.setSelectedNotaryList(e.value)}
                                        options={props.notaryList}
                                        optionLabel="not_fedata"
                                        filter
                                        showClear
                                        filterBy="not_fedata"
                                        className="p-inputtext-lg"
                                        disabled
                                        virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                    />
                                    <label htmlFor="dd-city">Notario</label>
                                </span>
                            </div>
                        }
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    className="p-inputtext-lg"
                                    value={props.escrituraNumber}
                                    disabled
                                    onChange={(e) => { props.setEscrituraNumber(e.target.value) }}
                                />
                                <label htmlFor="username">N° de escritura</label>
                            </span>
                        </div>
                    </div>
                </div>
                {props.exist &&
                    props.preTramiteDetail && props.preTramiteDetail.docs &&
                    <div className='col-12 md:col-12'>
                        <h5>Documentación</h5>
                        <div className='grid'>
                            <div className='col-12 md:col-8'>
                                <ConfirmPopup
                                    target={buttonEl.current}
                                    visible={visible}
                                    onHide={() => setVisible(false)}
                                    message="¿Estas seguro de eliminar el archivo?"
                                    icon="pi pi-exclamation-triangle"
                                    accept={accept}
                                    acceptLabel='Aceptar'
                                    rejectLabel='Cancelar'
                                />
                                <DataTable value={props.preTramiteDetail.docs}
                                    dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    emptyMessage="Sin Documentos" responsiveLayout="scroll">
                                    <Column field="code" body={IconDocumentTemplate} ></Column>
                                    <Column field="trmd_desc" header="Nombre del documento"></Column>
                                    <Column field="name" style={{ width: '10rem' }} body={EliminarTemplate} ></Column>
                                </DataTable>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </section>
    );

}

export default React.memo(ResulGeneralesView);