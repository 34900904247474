import { Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import authGuardService from './service/authGuardService';

import InicioAdministrador from './pages/Administrador/Screens/Inicio';

import { MainTopBarAdministrador } from './pages/Administrador/TopBar/MainTopBarAdministrador';
import CatActos from './pages/Administrador/Screens/catalogos/cat-actos/cat-actos';
import CatNotario from './pages/Administrador/Screens/catalogos/cat-notario/cat-notario';
import AccAreas from './pages/Administrador/Screens/accesos/acc-areas/acc-areas';
import AccPerfiles from './pages/Administrador/Screens/accesos/acc-perfiles/acc-perfiles';
import AccUsuarios from './pages/Administrador/Screens/accesos/acc-usuarios/acc-usuarios';
import Areas from './pages/Administrador/Screens/consultas/areas';
import FDuplicidad from './pages/Administrador/Screens/consultas/duplicidad';
import PerfilAdmin from './pages/Administrador/Screens/seguridad/perfil-admin';
import Configurador from './pages/Administrador/Screens/seguridad/configurador';
import { MainTopBarVentanilla } from './pages/Ventanilla/TopBar/MainTopBarVentanilla';
import CatAvisos from './pages/Administrador/Screens/catalogos/cat-avisos/cat-avisos';


import { MainTopBarNotario } from './pages/Notarios/TopBar/MainTopBarNotario';
import NotPagos from './pages/Notarios/pagos/NotPagos';
import GenInicio from './pages/Notarios/NotGeneral/GenInicio';
import TraInicio from './pages/Notarios/NotTrabajador/TraInicio';
import PagoFolio from './pages/Notarios/PagoFolio/PagoFolio';
import ViewFolio from './pages/Notarios/ViewFolio/ViewFolio';
import NotPerfil from './pages/Notarios/NotConfig/NotPerfil';
import NotAccesos from './pages/Notarios/NotConfig/NotAccesos';
import NotHistorial from './pages/Notarios/NotHistorial/NotHistorial';
import NotTramites from './pages/Notarios/NotTramites/NotTramites';
import FirmInicio from './pages/Interno/firmador/FirmInicio';
import { MainTopBarInterno } from './pages/Interno/TopBar/MainTopBarInterno';
import IntPerfil from './pages/Interno/IntPerfil';
import BuscaFolio from './pages/Interno/ResulFolio/BuscaFolio';
import IntViewFolio from './pages/Interno/ResulFolio/ViewFolio/ViewFolio';
import DupFolio from './pages/Interno/ResulFolio/DuplicidadFolio/DupFolio';
import ComActos from './pages/Interno/ResulFolio/DuplicidadFolio/ComActos';
import SecreInicio from './pages/Interno/secretaria/SecreInicio';
import AnaInicio from './pages/Interno/analista/AnaInicio';
import IntHistorial from './pages/Interno/historial/IntHistorial';
import VenAdminInicio from './pages/Ventanilla/VenAdminInicio';
import VenTrabInicio from './pages/Ventanilla/VenTrabInicio';
import VenBuscador from './pages/Ventanilla/ResulFolio/VenBuscador';
import VenCatNotario from './pages/Ventanilla/CatNotario/VenCatNotario';
import VenViewFolio from './pages/Ventanilla/ResulFolio/ViewFolio/ViewFolio';
import VenViewFolioMorales from './pages/Ventanilla/ResulFolio/ViewFolioMorales/ViewFolioMorales';
import VenViewNewFolio from './pages/Ventanilla/ResulFolio/ViewNewFolio/ViewNewFolio';
import VenPagoFolio from './pages/Ventanilla/PagoFolio/VenPagoFolio';
import VenPagos from './pages/Ventanilla/pagos/VenPagos';
import VenDupFolio from './pages/Ventanilla/DuplicidadFolio/VenDupFolio';
import VenPerfil from './pages/Ventanilla/VenPerfil';
import { useEffect, useRef, useState } from 'react';
import MenuService from './service/menu.service';
import EncryptService from './service/encrypt.service';
import { MainTopBarGeneral } from './Components/GeneralTopBar/TopBar/MainTopBarGeneral';
import { NotFound } from './pages/NotFound';
import { LoadingPage } from './pages/Loading';
import PagoFolioView from './pages/Notarios/PagoFolioView/PagoFolioView';
import VenPagoFolioView from './pages/Ventanilla/PagoFolioView/VenPagoFolioView';
import Buscador from './pages/Notarios/ViewFolio/Buscador';
import ConsulHistorica from './pages/Interno/ConsulHistorica/ConsulHistorica';
import { MainTopBarGeneralSearch } from './Components/GeneralTopBarSearch/TopBar/MainTopBarGeneralSearch';
import ViewBuscadorInterno from './pages/Ventanilla/ResulFolio/ViewBuscadorInterno/ViewBuscadorInterno';
import EditCaratula from './pages/Interno/ResulFolio/ViewFolio/EditCaratula';
import { MainTopBarPublic } from './pages/Publico/TopBar/MainTopBarPublic';
import ErrorPDF from './pages/Publico/ErrorPDF/ErrorPDF';
import PreCaptura from './pages/Publico/PreCaptura/PreCaptura';
import VenBuscadorMorales from './pages/Ventanilla/ResulFolio/VenBuscadorMorales';

const PrivateRoutes = (props: any) => {
    const user = authGuardService.getUser()
    const token = authGuardService.getToken()
    const toast = useRef<any>();
    const [permissions, setPermissions] = useState<any>(null);
    const permisos: { label: any; }[] = []
    const navigate = useNavigate();

    useEffect(() => {
        user && MenuService.getMenuPerfilConsola(token, user.prf_id).then(res => {
            let respuestaDto = EncryptService.dencryptData(res.data.response);
            if (respuestaDto.valido === 0) {
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Email y/o Contraseña Incorrectos', life: 4000 });
            } else {
                permisosMenu(respuestaDto.addenda).then(async permiso => {
                    authGuardService.sendPermits(permiso);
                    setPermissions(permiso)
                })
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Intentelo de Nuevo mas tarde.', life: 4000 });
        })
    }, [])

    const permisosMenu = async (menu: any[]) => {
        menu.forEach(menuItem => {
            let permiso: any = menuItem
            if (menuItem.items && menuItem.items.length > 0) {
                permisosMenu(menuItem.items);
            } else {
                permisos.push(permiso);
            }
        });
        return permisos;
    }

    const isAuthenticated = (path: any) => {
        const permissions = authGuardService.getPermits()
        let check = false
        permissions?.forEach((singlePermission: any) => {
            if (singlePermission && singlePermission.url)
                if (path.toLowerCase() == singlePermission.url.toLowerCase()) check = true;
        });
        return check;
    }

    function buscarPorCaption(array: any, captionBuscado: any) {
        return array.find((item: any) => item.caption === captionBuscado);
    }

    /* const selectHome = () => {
        const { prf_id } = authGuardService.getUser()

        if (prf_id === 8 || prf_id === 9 || prf_id === 10) {
            return <MainTopBarNotario component={<GenInicio />} />
        } else if (prf_id === 1) {
            return <MainTopBarVentanilla component={<VenAdminInicio />} />
        } else {
            return <MainTopBarGeneral component={<InicioAdministrador />} />
        }
    } */

    /* const selectHome = () => {
        const { prf_id } = authGuardService.getUser()
        const permisos = authGuardService.getPermits()
        if (permisos) {
            const objetoInicio = buscarPorCaption(permisos, "Inicio");
            return <Navigate to={objetoInicio.url} replace />
        }
        return null; // Puedes devolver algo por defecto si es necesario
    } */

    /* const HomeRoute = async () => {
        const user = authGuardService.getUser();
        const permisos = authGuardService.getPermits();


        if (user.prf_id === 8) {
            return <MainTopBarGeneral component={<GenInicio />} />
        } else if (user.prf_id === 9) {
            <MainTopBarGeneral component={<TraInicio />} />
        }

        if (permisos && permisos.length > 0) {
            const objetoInicio = buscarPorCaption(permisos, "Inicio");
            if (objetoInicio)
                return <Navigate to={objetoInicio.url} replace />;
            else
                return <Navigate to={permisos[0].url} replace />
        } else {
            return <Navigate to={'notFound'} replace />
        }
    }; */

    const [initialRoute, setInitialRoute] = useState('');

    useEffect(() => {
        async function fetchInitialRoute() {
            const user = authGuardService.getUser();
            const permisos = authGuardService.getPermits();

            if (permisos && permisos.length > 0) {
                const objetoInicio = buscarPorCaption(permisos, 'Inicio');
                if (objetoInicio) {
                    setInitialRoute(objetoInicio.url);
                } else {
                    setInitialRoute(permisos[0].url);
                }
            }
        }

        // Llama a fetchInitialRoute solo si hay permisos disponibles
        if (permissions !== null) {
            fetchInitialRoute();
        }
    }, [permissions]);

    if (!initialRoute) {
        // Puedes mostrar un componente de carga aquí si es necesario
        return null;
        /* return <LoadingPage />; */
    }

    return (
        <div className="layout-main-content">
            <Routes>
                <Route path="/" element={<Navigate to={initialRoute} replace />} />

                <Route path="/notFound" element={<NotFound />} />

                {/* PUBLICO */}
                <Route path='publico/error-pdf' element={<MainTopBarPublic component={<ErrorPDF />} />} />

                {/* Interno */}
                <Route path='/interno/busca-folio' element={<MainTopBarGeneralSearch component={<BuscaFolio />} />} />
                <Route path='/interno/int-perfil' element={<MainTopBarGeneralSearch component={<IntPerfil />} />} />
                <Route path='/interno/view-folio' element={<MainTopBarGeneralSearch component={<IntViewFolio />} />} />
                <Route path='/interno/dup-folio' element={<MainTopBarGeneralSearch component={<DupFolio />} />} />
                <Route path='/interno/com-actos' element={<MainTopBarGeneralSearch component={<ComActos />} />} />
                <Route path='/interno/int-historial' element={<MainTopBarGeneralSearch component={<IntHistorial />} />} />
                <Route path='/interno/consul-historica' element={<MainTopBarGeneralSearch component={<ConsulHistorica />} />} />

                {/* Perfiles internos */}
                <Route path='/interno/firm-inicio' element={<MainTopBarGeneralSearch component={<FirmInicio />} />} />
                <Route path='/interno/ana-inicio' element={<MainTopBarGeneralSearch component={<AnaInicio />} />} />
                <Route path='/interno/secre-inicio' element={<MainTopBarGeneralSearch component={<SecreInicio />} />} />

                {/* Administrador General */}
                <Route path="/administrador/inicio" element={<MainTopBarGeneralSearch component={<InicioAdministrador />} />} />
                <Route path="/administrador/cat-actos" element={<MainTopBarGeneralSearch component={<CatActos />} />} />
                <Route path="/administrador/cat-avisos" element={<MainTopBarGeneralSearch component={<CatAvisos />} />} />
                <Route path="/administrador/cat-notario" element={<MainTopBarGeneralSearch component={<CatNotario />} />} />
                <Route path="/administrador/cat-areas" element={<MainTopBarGeneralSearch component={<AccAreas />} />} />
                <Route path="/administrador/acc-perfiles" element={<MainTopBarGeneralSearch component={<AccPerfiles />} />} />
                <Route path="/administrador/acc-usuarios" element={<MainTopBarGeneralSearch component={<AccUsuarios />} />} />
                <Route path="/administrador/duplicidad" element={<MainTopBarGeneralSearch component={<FDuplicidad />} />} />
                <Route path="/administrador/areas" element={<MainTopBarGeneralSearch component={<Areas />} />} />
                <Route path="/administrador/perfil-admin" element={<MainTopBarGeneralSearch component={<PerfilAdmin />} />} />
                <Route path="/administrador/configurador" element={<MainTopBarGeneralSearch component={<Configurador />} />} />


                {<Route path='/consulta-historica' element={<MainTopBarNotario component={<ConsulHistorica />} />} />}

                {/* NOTARIO ADMINISTRADOR */}
                {<Route path='/notarios/gen-inicio' element={<MainTopBarNotario component={<GenInicio />} />} />}
                {<Route path='/notarios/not-accesos' element={<MainTopBarNotario component={<NotAccesos />} />} />}
                {/* NOTARIO TRABAJADOR */}
                {<Route path='/notarios/not-tramite' element={<MainTopBarNotario component={<TraInicio />} />} />}
                {<Route path='/notarios/tra-inicio' element={<MainTopBarNotario component={<TraInicio />} />} />}
                {/* NOTARIO AMBOS */}
                {<Route path='/notarios/not-perfil' element={<MainTopBarNotario component={<NotPerfil />} />} />}
                {<Route path='/notarios/not-pagos' element={<MainTopBarNotario component={<NotPagos />} />} />}
                {<Route path='/notarios/pago-folio' element={<MainTopBarNotario component={<PagoFolio />} />} />}
                {<Route path='/notarios/pago-folio-view' element={<MainTopBarNotario component={<PagoFolioView />} />} />}
                {<Route path='/notarios/not-tramites' element={<MainTopBarNotario component={<NotTramites />} />} />}
                {<Route path='/notarios/not-historial' element={<MainTopBarNotario component={<NotHistorial />} />} />}
                {<Route path='/notarios/view-folio' element={<MainTopBarNotario component={<ViewFolio />} />} />}
                {<Route path='/notarios/buscador' element={<MainTopBarNotario component={<Buscador />} />} />}

                {/* VENTANILLA ADMINISTRADOR */}
                <Route path='/ventanilla/vtadmin-inicio' element={<MainTopBarGeneralSearch component={<VenAdminInicio />} />} />
                <Route path='/ventanilla/cat-notario' element={<MainTopBarGeneralSearch component={<VenCatNotario />} />} />
                {/* VENTANILLA TRABAJADOR */}
                <Route path='/ventanilla/vttrab-inicio' element={<MainTopBarGeneralSearch component={<VenTrabInicio />} />} />
                {/* VENTANILLA AMBOS */}
                <Route path='/ventanilla/ven-perfil' element={<MainTopBarGeneralSearch component={<VenPerfil />} />} />
                <Route path='/ventanilla/ven-pagos' element={<MainTopBarGeneralSearch component={<VenPagos />} />} />
                <Route path='/ventanilla/vt-buscador' element={<MainTopBarGeneralSearch component={<VenBuscador />} />} />
                <Route path='/ventanilla/vt-buscador-interno-morales' element={<MainTopBarGeneralSearch component={<VenBuscadorMorales />} />} />
                <Route path='/ventanilla/vt-buscador-interno' element={<MainTopBarGeneralSearch component={<ViewBuscadorInterno />} />} />
                <Route path='/ventanilla/dup-folio' element={<MainTopBarGeneralSearch component={<VenDupFolio />} />} />
                <Route path='/ventanilla/vt-tramite' element={<MainTopBarGeneralSearch component={<VenPagoFolio />} />} />
                <Route path='/ventanilla/vt-tramite-view' element={<MainTopBarGeneralSearch component={<VenPagoFolioView />} />} />
                <Route path='/ventanilla/view-folio' element={<MainTopBarGeneralSearch component={<VenViewFolio />} />} />
                <Route path='/ventanilla/view-folio-morales' element={<MainTopBarGeneralSearch component={<VenViewFolioMorales />} />} />
                <Route path='/ventanilla/new-folio' element={<MainTopBarGeneralSearch component={<VenViewNewFolio />} />} />


            </Routes>

        </div>
    );

}

export default PrivateRoutes;
