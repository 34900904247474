import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import React, { useEffect, useState } from 'react';
import ProductService from '../../../serviceExample/ProductService';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

const InicioAdministrador = () => {

    const productService = new ProductService();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [rowClick, setRowClick] = useState(true);



    useEffect(() => {
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" />
            </div>
        );
    }



    return (
        <section className='container-fluid'>
            <div className='grid'>
                <div className='col-12 md:col-3'>
                    <h3 className='color-violet'>Bienvenido</h3>
                    <p>Administrador general</p>
                </div>
                <div className='col-12 md:col-3'>
                    <div className='dashboard'>
                        <h5>50,000</h5>
                        <p>Total de folios registrados</p>
                    </div>
                </div>

                <div className='col-12 md:col-3'>
                    <div className='dashboard'>
                        <h5>25,000</h5>
                        <p>Folios duplicados <a onClick={() => { navigate('/administrador/duplicidad') }}>Ver</a></p>
                    </div>
                </div>
                <div className='col-12 md:col-3'>
                    <div className='dashboard'>
                        <h5>10,000</h5>
                        <p>Cerrados por duplicidad <a onClick={() => { navigate('/administrador/duplicidad') }}>Ver</a></p>
                    </div>
                </div>
            </div>
            <hr />
            <div className='grid'>
                <div className='col-12 md:col-8'>
                    <div className='grid'>
                        <div className='col-12'>
                            <h2>Actividades del dia</h2>
                        </div>
                        <div className='col-12 md:col-4'>
                            <h5 className="pb-2 color-violet">Notarios</h5>
                            <div className='dashboard mb-2'>
                                <h5>100</h5>
                                <p>Recibidos <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></p>
                            </div>
                        </div>
                        <div className='col-12 md:col-8'>
                            <h5 className="pb-2 color-violet">Ventanilla</h5>
                            <div className='dashboard'>
                                <table className='w-100'>
                                    <tbody>
                                        <tr>
                                            <td><h5>10</h5></td>
                                            <td><p>Nuevos</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                        <tr>
                                            <td><h5>200</h5></td>
                                            <td><p>Enviados</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className='col-12 md:col-4'>
                            <h5 className="pb-2 color-violet">Certificaciones</h5>
                            <div className='dashboard'>
                                <table className='w-100'>
                                    <tbody>
                                        <tr>
                                            <td><h5>10</h5></td>
                                            <td><p>Secretaria</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                        <tr>
                                            <td><h5>200</h5></td>
                                            <td><p>Analista(s)</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                        <tr>
                                            <td><h5>10</h5></td>
                                            <td><p>Calificador(es)</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='col-12 md:col-4'>
                            <h5 className="pb-2 color-violet">Juridico</h5>
                            <div className='dashboard'>
                                <table className='w-100'>
                                    <tbody>
                                        <tr>
                                            <td><h5>10</h5></td>
                                            <td><p>Secretaria</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                        <tr>
                                            <td><h5>200</h5></td>
                                            <td><p>Analista(s)</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                        <tr>
                                            <td><h5>10</h5></td>
                                            <td><p>Calificador(es)</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='col-12 md:col-4'>
                            <h5 className="pb-2 color-violet">Registro</h5>
                            <div className='dashboard'>
                                <table className='w-100'>
                                    <tbody>
                                        <tr>
                                            <td><h5>10</h5></td>
                                            <td><p>Secretaria</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                        <tr>
                                            <td><h5>200</h5></td>
                                            <td><p>Analista(s)</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                        <tr>
                                            <td><h5>10</h5></td>
                                            <td><p>Calificador(es)</p></td>
                                            <td>  <a onClick={() => { navigate('/administrador/areas') }}>Ver</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-12 md:col-4'>

                    <div className='dashboard'>
                        <div className='dash-header mb-2'>
                            <h5>Buscador</h5>
                            <p>Llena los campos necesarios para iniciar la búsqueda.</p>
                        </div>
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText id="username" className="p-inputtext-lg" />
                                <label htmlFor="username">Consulta un Folio</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText id="username" className="p-inputtext-lg" />
                                <label htmlFor="username">Consulta un N° interno</label>
                            </span>
                        </div>
                        <div className='col-12 mt-5'>
                            <Button label="Buscar" size="large" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );

}

export default React.memo(InicioAdministrador);