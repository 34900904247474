import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';
import { Tag } from 'primereact/tag';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { Chip } from 'primereact/chip';
import { Calendar } from 'primereact/calendar';
import ListTrabNotario from './list-TrabNotario';
import ApiServices from '../../../../../service/apiServices';
import EncryptService from '../../../../../service/encrypt.service';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

interface City {
    name: string;
}

const AccUsuarios = () => {
    const [areas, setAreas] = useState()
    const [perfiles, setPerfiles] = useState()
    const [notarys, setNotarys] = useState()

    useEffect(() => {
        getAreas()
        getPerfiles()
        getNotary()
        getActos()
    }, [])

    useEffect(() => {
        if (areas && perfiles && notarys) {
            getUsers()
        }
    }, [areas, perfiles, notarys])

    const getUsers = () => {
        let datos
        datos = { "code": 106 }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            setUsers(res.data.addenda)
            setOriginalUsers(res.data.addenda)
            processUsers(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const [selectedNotarys, setSelectedNotarys] = useState<any>([])
    const [actos, setActos] = useState([])

    const getNotary = () => {
        let datos
        ApiServices.apiPost('catNotarios', datos).then(async res => {
            const temporalArray: any = []
            if (res.data.addenda && res.data.addenda.length > 0) {
                res.data.addenda.map((singleElement: any) => {
                    temporalArray.push(singleElement)
                })
            }
            setNotarys(temporalArray)
        }).catch(error => {
            console.log(error)
        })
    }

    const processUsers = (userData: any) => {
        if (userData && userData.length > 0) {
            const newUsersArray: any = []
            userData.map((user: any) => {
                const newUser = JSON.parse(JSON.stringify(user));

                let areaDescription = user.area_desc
                let perfilDescription = user.prf_desc
                let typeDescription = obtenerNombrePorCodigo(tipo, user.usu_tipo)
                let statusDescription = getStatus(user.usu_status)

                if (!user.area_desc)
                    areaDescription = obtenerJsonPorCodigo(areas, user.area_id, 'areas_id')
                if (!user.prf_id)
                    perfilDescription = obtenerJsonPorCodigo(perfiles, user.prf_id, 'prf_id')

                newUser.area_desc = areaDescription
                newUser.prf_desc = perfilDescription
                newUser.usu_tipo_desc = typeDescription
                newUser.usu_complete_name = `${user.usu_nom || ''} ${user.usu_pat || ''} ${user.usu_mat || ''}`
                newUser.usu_status_desc = statusDescription

                newUsersArray.push(newUser)
            })
            setUsers(newUsersArray)
            setOriginalUsers(newUsersArray)
        }
    }

    const getAreas = () => {
        let datos
        ApiServices.apiPost('catAreas', datos).then(async res => {
            setAreas(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getPerfiles = () => {
        let datos
        ApiServices.apiPost('catPerfiles', datos).then(async res => {
            setPerfiles(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getActos = () => {
        let datos
        ApiServices.apiPost('catActo', datos).then(async res => {
            setActos(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const [users, setUsers] = useState([])
    const [originalUsers, setOriginalUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState<any>()
    const [selectedUserType, setSelectedUserType] = useState<City | null>(null);
    const [selectedCity, setSelectedCity] = useState<any>()

    const [checked, setChecked] = useState<boolean>(false);
    const toast = useRef<Toast>(null);
    const productService = new ProductService();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [rowClick, setRowClick] = useState(true);
    const [visible, setVisible] = useState<boolean>(false);
    const [filter, setFilter] = useState<boolean>(false);
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [trabId, setTrabId] = useState()
    const [usuDesfirmar, setUsuDesfirmar] = useState<any>()
    const [usuEdicion, setUsuEdicion] = useState<any>()
    const [usuNuevofolio, setUsuNuevofolio] = useState<any>()
    const [usuRPP, setUsuRPP] = useState<any>({ type1: false, type2: false, type3: false })

    const [selectedArea, setSelectedArea] = useState<any>('')
    const [selectedPerfil, setSelectedPerfil] = useState<any>('')

    const area: any = [
        { name: 'Certificaciones', code: 1 },
        { name: 'Registro', code: 2 },
        { name: 'Juridico', code: 3 },
        { name: 'Ventanilla', code: 4 }
    ];

    const perfil: any = [
        { name: 'Calificador', code: 1 },
        { name: 'Analista', code: 2 },
        { name: 'Secretaria', code: 3 },
    ];

    const tipo: any = [
        { name: 'Externo', code: 1 },
        { name: 'Interno', code: 2 },
        { name: 'Notario', code: 3 },
    ];
    const estatus: any = [
        { name: 'Desactivado', code: 0 },
        { name: 'Nuevo', code: 1 },
        { name: 'Activo', code: 2 },
    ];

    const showSuccess = () => {
        toast.current?.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se han guardado los cambios exitosamente', life: 3000 });
    }

    const confirm1 = (event: any) => {
        confirmPopup({
            target: event.currentTarget,
            message: '¿Estas seguro de rechazar la petición?',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };

    const accept = () => {
        toast.current?.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        toast.current?.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const changeUserStatus = () => {
        let datos
        datos = {
            "code": 107,
            "usu_id": selectedUser.usu_id,
            "usu_activo": selectedUser.usu_activo === 0 ? 1 : 0
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                handleUserDetails(selectedUser)
                getUsers()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const [selectedActos, setSelectedActos] = useState([])

    const checkFields = () => {
        const fieldsToCheck = ['User', 'Area', 'Perfil']

        const newArray = fieldsToCheck.map((field) => {
            if (field === 'User') {
                if (selectedUser && selectedUser.usu_id) {
                    return true
                }
                else {
                    toast.current?.show({ severity: 'info', summary: 'Error', detail: 'El error al actualizar usuario', life: 3000 });
                    return false
                }
            } else if (field === 'Area') {
                if (selectedArea && selectedArea.area_id) {
                    return true
                }
                else {
                    toast.current?.show({ severity: 'info', summary: 'Area no seleccionada', detail: 'Selecciona un area para el usuario', life: 3000 });
                    return false
                }
            } else if (field === 'Perfil') {
                if (selectedPerfil && selectedPerfil.prf_id) {
                    return true
                }
                else {
                    toast.current?.show({ severity: 'info', summary: 'Perfil no seleccionado', detail: 'Selecciona un perfil para el usuario', life: 3000 });
                    return false
                }
            } else {
                return false
            }
        })

        if (newArray.includes(false)) {
            return false
        } else {
            return true
        }
    }

    const changeUserInformation = () => {
        if (checkFields()) {
            let datos
            let rppType

            if (usuRPP.type3 || (usuRPP.type1 && usuRPP.type2))
                rppType = '3'
            else if (usuRPP.type2)
                rppType = '2'
            else if (usuRPP.type1)
                rppType = '1'
            else
                rppType = '0'

            datos = {
                "usu_id": selectedUser.usu_id,
                "dep_id": 0,
                "area_id": selectedArea.area_id,
                "prf_id": selectedPerfil.prf_id,
                "not_id": selectedArea.area_id == 8 ? selectedNotarys.not_id : 0,
                "usu_trab_id": trabId || '0',
                "usu_desfirmar": usuDesfirmar || 0,
                "usu_nuevofolio": usuNuevofolio || 0,
                "usu_edicionfolio": usuEdicion || 0,
                "usu_rpp": rppType
            }
            ApiServices.apiPost('completarUsuario', datos).then(async res => {
                if (res.data.valido === 1) {
                    setVisible(false)
                    showSuccess()
                    getUsers()
                }
            }).catch(error => {
                console.log(error)
            })
        }

    }

    const footerContent = (
        <div className='flex justify-content-between'>
            <div className="flex flex-row flex-wrap">
                <div className='pr-5'>
                    <Button label="Rechazar" className="p-button-danger" onClick={confirm1} />   {/* si es nueva solicitud, aparecer btn rechazo*/}
                </div>
                {/* <div className='pr-5'>
                    <Checkbox inputId="ingredient1" checked={checked} />
                    <label htmlFor="ingredient1" className="ml-2">Activar des-firma</label>
                </div> */}
                <div className='pr-5'>
                    <Checkbox
                        inputId="ingredient1"
                        checked={selectedUser && selectedUser.usu_activo == 1}
                        onChange={changeUserStatus}
                    />
                    <label
                        htmlFor="ingredient1"
                        className="ml-2">{`${selectedUser && selectedUser.usu_activo == 0 ? 'Activar' : 'Desactivar'} usuario`}</label>
                </div>
            </div>
            <div>
                <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
                <Button label="Guardar" onClick={changeUserInformation} autoFocus />
            </div>
        </div>
    );

    function filtrarPorUsuarios(previousActos: any) {
        const actIdsABuscar = previousActos.map((item: any) => item.act_id.toString());
        return actos.filter((objeto: any) => actIdsABuscar.includes(objeto.act_id.toString()));
    }

    const handleUserDetails = (rowData: any) => {
        let datos: any
        datos = { "usu_id": rowData.usu_id }
        ApiServices.apiPost('detalleUsuario', datos).then(async res => {
            setVisible(true)
            setSelectedUser(res.data.addenda[0])
            setSelectedUserType(obtenerJsonPorCodigo(tipo, res.data.addenda[0].usu_tipo, 'code'))
            setSelectedArea(obtenerJsonPorCodigo(areas, res.data.addenda[0].area_id, 'area_id'))
            setSelectedPerfil(obtenerJsonPorCodigo(perfiles, res.data.addenda[0].prf_id, 'prf_id'))
            setSelectedNotarys(obtenerJsonPorCodigo(notarys, res.data.addenda[0].not_id, 'not_id'))
            setTrabId(res.data.addenda[0].usu_trab_id)
            setUsuDesfirmar(res.data.addenda[0].usu_desfirmar)
            setUsuEdicion(res.data.addenda[0].usu_edicionfolio)
            setUsuNuevofolio(res.data.addenda[0].usu_nuevofolio)

            if (res.data.addenda[0].usu_rpp == 1)
                setUsuRPP({ type1: true, type2: false, type3: false })
            else if (res.data.addenda[0].usu_rpp == 2)
                setUsuRPP({ type1: false, type2: true, type3: false })
            else if (res.data.addenda[0].usu_rpp == 3)
                setUsuRPP({ type1: true, type2: true, type3: true })
            else
                setUsuRPP({ type1: false, type2: false, type3: false })

            ApiServices.apiPost('getActoNoFirma', datos).then(async res => {
                setSelectedActos(filtrarPorUsuarios(res.data.addenda))
            }).catch(error => {
                console.log(error)
            })


            if (res.data.addenda[0].not_id)
                getNotaryProfiles(res.data.addenda[0].not_id)
        }).catch(error => {
            console.log(error)
        })
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => {
                    handleUserDetails(rowData)
                }} />
            </div>
        );
    }

    const statusBodyTemplate = (product: any) => {
        return <Tag value={getStatus(product.usu_activo)} style={{ color: getSeverity(product.usu_activo), fontWeight: 'bold' }}></Tag>;
    };

    const getStatus = (status: any) => {
        switch (status) {
            case 0:
                return 'DESACTIVADO'
            case 1:
                return 'ACTIVADO'
            default:
                return ''
        }
    }

    const getSeverity = (status: any) => {
        switch (status) { //EN ESTATUS SOLO APLICA NUEVO, ACTIVADO, DESACTIVADO
            case 0:
                return '#5B5B5F';
            case 1:
                return '#1531FF';
            default:
                return '#5B5B5F';
        }
    };

    function obtenerNombrePorCodigo(array: any, codigo: any) {
        if (codigo) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].code === codigo) {
                    return array[i].name;
                }
            }
            return '-';
        } else {
            return '-'
        }
    }

    function obtenerJsonPorCodigo(array: any, codigo: any, searchType: any = 'code') {
        if (codigo) {
            for (var i = 0; i < array.length; i++) {
                const tempArray = array[i]
                if (tempArray[searchType] === codigo) {
                    return array[i];
                }
            }
            return null;
        } else {
            return null
        }
    }

    const onGlobalFilterChange = (event: any) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        area_desc: { value: null, matchMode: FilterMatchMode.CONTAINS },
        prf_desc: { value: null, matchMode: FilterMatchMode.CONTAINS },
        usu_tipo_desc: { value: null, matchMode: FilterMatchMode.CONTAINS },
        usu_complete_name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const value = filters['global'] ? filters['global'].value : '';

    const [allFilters, setAllFilters] = useState([])
    const [filterAreas, setFilterAreas] = useState<any>(null)
    const [filterPerfil, setFilterPerfil] = useState<any>(null)
    const [filterType, setFilterType] = useState<any>(null)
    const [filterStatus, setFilterStatus] = useState<any>(null)

    const filterArray = (filtersSelected: any) => {
        const filteredUsers: any = []
        const filterOne = filtersSelected[0]
        const filterTwo = filtersSelected[1]
        const filterThree = filtersSelected[2]
        const filterFour = filtersSelected[3]


        originalUsers.map((user: any) => {
            let isFit: any = []

            for (let prop in user) {
                if (user[prop] && user[prop] !== null) {
                    if (filterOne && user[prop].toString().toLowerCase().includes(filterOne.name.toLowerCase())) isFit.push(true)
                    if (filterTwo && user[prop].toString().toLowerCase().includes(filterTwo.name.toLowerCase())) isFit.push(true)
                    if (filterThree && user[prop].toString().toLowerCase().includes(filterThree.name.toLowerCase())) isFit.push(true)
                    if (filterFour && user[prop].toString().toLowerCase().includes(filterFour.name.toLowerCase())) isFit.push(true)
                    /* if (user[prop].toString().toLowerCase().includes(filterOne.name.toLowerCase())) {
                        filteredUsers.push(user);
                    } */
                }
            }

            if (filtersSelected && filtersSelected.length == isFit.length)
                filteredUsers.push(user)
        });
        setUsers(filteredUsers)
    }

    const onFilterSave = () => {
        const tempArray: any = []
        if (filterAreas) {
            tempArray.push({ key: 0, filter: 'areas', id: filterAreas.area_id, name: filterAreas.area_desc })
        }
        if (filterPerfil) {
            tempArray.push({ key: 1, filter: 'perfil', id: filterPerfil.prf_id, name: filterPerfil.prf_desc })
        }
        if (filterType) {
            tempArray.push({ key: 2, filter: 'type', id: filterType.code, name: filterType.name })
        }
        if (filterStatus) {
            tempArray.push({ key: 3, filter: 'status', id: filterStatus.code, name: filterStatus.name })
        }
        setAllFilters(tempArray)
        filterArray(tempArray)
    }

    const clearAllFilters = () => {
        setUsers(originalUsers)
        setAllFilters([])
        setFilterAreas(null)
        setFilterPerfil(null)
        setFilterType(null)
        setFilterStatus(null)
    }

    function formattDate(fechaISO: any) {
        const fecha = new Date(fechaISO);
        const options: any = { day: '2-digit', month: 'long', year: 'numeric', timeZone: 'UTC' };
        const dia = fecha.toLocaleDateString('es-ES', options);

        return dia;
    }

    const [notarySelectedList, setNotarySelectedList] = useState([])

    const getNotaryProfiles = (id: any) => {
        let datos = {
            not_id: id
        }
        ApiServices.apiPost('getUsuarioNotario', datos).then(async res => {
            const temporalArray: any = []
            if (res.data.addenda && res.data.addenda.length > 0) {
                setNotarySelectedList(res.data.addenda)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const addToNoFirmar = (id: any) => {
        let datos = {
            "usu_id": selectedUser.usu_id,
            "act_id": id
        }
        ApiServices.apiPost('addActoNoFirma', datos).then(async res => {

        }).catch(error => {
            console.log(error)
        })
    }

    const removeFromNoFirmar = (id: any) => {
        let datos = {
            "usu_id": selectedUser.usu_id,
            "act_id": id
        }
        ApiServices.apiPost('deleteActoNoFirma', datos).then(async res => {

        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-8'>
                    <h3>Usuarios</h3>
                    <p>Revisa las nuevas solicitudes para dar de alta al usuario.</p>
                </div>
                <div className='col-12 md:col-4'>
                    <div className='grid'>
                        <div className='col-10'>
                            <span className="p-input-icon-right">
                                <i className="pi pi-search" />
                                <span className='p-float-label '>
                                    <InputText id="buscador" className="p-inputtext-lg" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} />
                                    <label htmlFor="buscador">Buscador</label>
                                </span>
                            </span>
                        </div>
                        <div className='col-2'>
                            <Button icon="pi pi-filter-fill" aria-label="Filter" size="large" onClick={() => setFilter(true)} />
                        </div>
                    </div>

                </div>
                <div className='col-12'>
                    {
                        allFilters && allFilters.map((individualFilter: any) => (
                            <Chip key={individualFilter.key} label={individualFilter.name} /* removable */ style={{ marginRight: 10 }} />
                        ))
                    }
                </div>
                <div className='col-12'>
                    <DataTable
                        value={users}
                        paginator
                        rows={10}
                        dataKey="usu_id"
                        filters={filters}
                        onSelectionChange={(e) => {
                            const value = e.value as Product[];
                            setSelectedProducts(value);
                        }}
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column sortable field="area_desc" header="Área"></Column>
                        <Column sortable field="prf_desc" header="Perfil"></Column>
                        <Column sortable field="usu_tipo_desc" header="Tipo"></Column>
                        <Column sortable field="name" header="Celda"></Column>
                        <Column sortable field='usu_complete_name' header="Usuario"></Column>
                        <Column sortable header="Status" body={statusBodyTemplate}></Column>
                        <Column body={(e) => actionBodyTemplate(e)} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog header="Usuario" visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                id="username"
                                className="p-inputtext-lg"
                                disabled
                                placeholder="Nombre"
                                value={selectedUser ? selectedUser.usu_nom : ''}
                            />
                            <label htmlFor="username">Nombre</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={selectedUser ? selectedUser.usu_pat : ''}
                                id="username"
                                className="p-inputtext-lg"
                                disabled
                            />
                            <label htmlFor="username">Primer apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={selectedUser ? selectedUser.usu_mat : ''}
                                id="username"
                                className="p-inputtext-lg"
                                disabled
                            />
                            <label htmlFor="username">Segundo apellido</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label w-100">
                            <InputText
                                value={selectedUser ? formattDate(selectedUser.usu_fecnac) : ''}
                                id="username"
                                className="p-inputtext-lg"
                                disabled
                            />
                            <label htmlFor="dd-city">Fecha de nacimiento</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={selectedUser ? selectedUser.usu_email : ''}
                                id="username"
                                className="p-inputtext-lg"
                                disabled
                            />
                            <label htmlFor="username">Correo electrónico</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={selectedUser ? selectedUser.usu_tel : ''}
                                id="username"
                                className="p-inputtext-lg"
                                disabled
                            />
                            <label htmlFor="username">Teléfono</label>
                        </span>
                    </div>
                </div>

                <Divider align="left">
                    <small className='text-black-alpha-90 font-bold'>Tipo de usuario</small>
                </Divider>

                {/* estos campos se muestran para el registro de notarios*/}
                {selectedUser && selectedUser.usu_tipo === 3 &&
                    <div className='grid'>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    value={selectedUserType ? selectedUserType.name : ''}
                                    id="username"
                                    className="p-inputtext-lg"
                                    disabled
                                />
                                <label htmlFor="dd-city">Tipo de usuario</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                {/* <Dropdown
                                    value={selectedCountry}
                                    onChange={(e) => setSelectedCountry(e.value)}
                                    options={countries}
                                    optionLabel="name"
                                    placeholder="Select a Country"
                                    valueTemplate={selectedCountryTemplate}
                                    itemTemplate={countryOptionTemplate}
                                    className="w-full md:w-14rem"
                                    /> */}

                                <Dropdown
                                    filter
                                    inputId="dd-city"
                                    value={selectedNotarys || (selectedUser && selectedUser.not_id)}
                                    onChange={(e) => setSelectedNotarys(e.value)}
                                    options={notarys}
                                    optionLabel="not_fedata"
                                    className="p-inputtext-lg"
                                    style={{ width: '100%' }}
                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                />
                                <label htmlFor="username">Notaria N°</label>
                            </span>
                        </div>
                        {/* <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    value={selectedUser ? selectedUser.usu_curp : ''}
                                    id="username"
                                    className="p-inputtext-lg"
                                    disabled
                                />
                                <label htmlFor="username">CURP</label>
                            </span>
                        </div> */}
                    </div>
                }

                {/* estos campos se muestran para el registro de interno*/}
                {selectedUser && selectedUser.usu_tipo == 2 &&
                    <div className='grid'>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <Dropdown inputId="dd-city" value={selectedUserType} virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                    onChange={(e) => setSelectedUserType(e.value)} options={tipo} optionLabel="name" className="p-inputtext-lg" style={{ width: '100%' }} />
                                <label htmlFor="dd-city">Tipo de usuario</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText id="username" className="p-inputtext-lg" defaultValue={selectedUser ? selectedUser.usu_trab_id : ''} onChange={(e: any) => { setTrabId(e.target.value) }} />
                                <label htmlFor="username">ID trabajador</label>
                            </span>
                        </div>
                    </div>
                }

                {/* estos campos se muestran para el registro de externos*/}
                {selectedUser && selectedUser.usu_tipo == 1 &&
                    <div className='grid'>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <Dropdown
                                    inputId="dd-city"
                                    value={selectedUserType}
                                    onChange={(e) => setSelectedUserType(e.value)}
                                    options={tipo}
                                    optionLabel="name"
                                    className="p-inputtext-lg"
                                    style={{ width: '100%' }}
                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                />
                                <label htmlFor="dd-city">Tipo de usuario</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText id="username" className="p-inputtext-lg" placeholder="69" />
                                <label htmlFor="username">Dependencia</label>
                            </span>
                        </div>
                    </div>
                }

                {/* estos campos se muestran para el registro de notario o externo*/}
                {selectedUser && (selectedUser.usu_tipo === 1 || selectedUser.usu_tipo === 3) &&
                    <div className='grid'>
                        <div className='col-12 md:col-12'>
                            <Divider align="left">
                                <small className='text-black-alpha-90 font-bold'>Domicilio</small>
                            </Divider>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    value={selectedUser ? selectedUser.usu_calle : ''}
                                    id="username"
                                    className="p-inputtext-lg"
                                    disabled
                                />
                                <label htmlFor="username">Calle</label>
                            </span>
                        </div>
                        {selectedUser && selectedUser.usu_int &&
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={selectedUser ? selectedUser.usu_int : ''}
                                        id="username"
                                        className="p-inputtext-lg"
                                        disabled
                                    />
                                    <label htmlFor="username">N° interior</label>
                                </span>
                            </div>
                        }
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    value={selectedUser ? selectedUser.usu_ext : ''}
                                    id="username"
                                    className="p-inputtext-lg"
                                    disabled
                                />
                                <label htmlFor="username">N° exterior</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    value={selectedUser ? selectedUser.cp_col : ''}
                                    id="username"
                                    className="p-inputtext-lg"
                                    disabled
                                />
                                <label htmlFor="username">Colonia</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    value={selectedUser ? selectedUser.cp_mun : ''}
                                    id="username"
                                    className="p-inputtext-lg"
                                    disabled
                                />
                                <label htmlFor="username">Municipio</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    value={selectedUser ? selectedUser.cp_cp : ''}
                                    id="username"
                                    className="p-inputtext-lg"
                                    disabled
                                />
                                <label htmlFor="username">Codigo postal</label>
                            </span>
                        </div>
                    </div>
                }

                <Divider align="left">
                    <small className='text-black-alpha-90 font-bold'>Perfil de usuario</small>
                </Divider>

                {/* selecciona el tipo de area y perfil si es notario, si es interno, muestra el perfil y el notario que ingreso, puede modificar. */}
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-city"
                                value={selectedArea}
                                onChange={(e) => setSelectedArea(e.value)}
                                options={areas}
                                optionLabel="area_desc"
                                className="p-inputtext-lg"
                                style={{ width: '100%' }}
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-city">Área</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-city"
                                value={selectedPerfil}
                                onChange={(e) => setSelectedPerfil(e.value)}
                                options={perfiles}
                                optionLabel="prf_desc"
                                className="p-inputtext-lg"
                                style={{ width: '100%' }}
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-city">Perfil</label>
                        </span>
                    </div>

                </div>

                <div>
                    {/* Si es tipo usuario interno, muestra la celda asignada */}
                    {/* <div className='col-12 md:col-4'>
                        <small className='black'>Celda</small>
                        <p className='result'>VE-001</p>
                    </div> */}
                    {/* estos campos se muestran dependiendo del perfil interno seleccionado para dar permisos*/}
                    <div className='col-12'>
                        <Divider align="left">
                            <small className='text-black-alpha-90 font-bold'>Permisos</small>
                        </Divider>
                        <div className="flex flex-wrap">
                            <div className="flex align-items-center mr-3">
                                <div className="field-checkbox">
                                    <Checkbox inputId="binary" checked={usuDesfirmar == 1} onChange={e => setUsuDesfirmar(usuDesfirmar == 1 ? 0 : 1)} />
                                    <label htmlFor="binary">Activar des-firma</label>
                                </div>
                            </div>
                            <div className="flex align-items-center mr-3">
                                <div className="field-checkbox">
                                    <Checkbox inputId="binary6" checked={usuEdicion == 1} onChange={e => setUsuEdicion(usuEdicion == 1 ? 0 : 1)} />
                                    <label htmlFor="binary6">Edición de Folios</label>
                                </div>
                            </div>
                            {selectedPerfil && selectedPerfil.prf_id == 2 &&
                                <div className="flex align-items-center mr-3" >
                                    <div className="field-checkbox">
                                        <Checkbox inputId="binary2" checked={usuNuevofolio == 1} onChange={e => setUsuNuevofolio(usuNuevofolio == 1 ? 0 : 1)} />
                                        <label htmlFor="binary2">Creación de nuevos folios</label>
                                    </div>
                                </div>
                            }
                            <div className="flex align-items-center mr-3">
                                <div className="field-checkbox">
                                    <Checkbox inputId="binary" checked={usuRPP.type1 || usuRPP.type3} onChange={e => setUsuRPP({ ...usuRPP, type1: !usuRPP.type1 })} />
                                    <label htmlFor="binary">RPP</label>
                                </div>
                            </div>
                            <div className="flex align-items-center mr-3">
                                <div className="field-checkbox">
                                    <Checkbox inputId="binary" checked={usuRPP.type2 || usuRPP.type3} onChange={e => setUsuRPP({ ...usuRPP, type2: !usuRPP.type2 })} />
                                    <label htmlFor="binary">Indices</label>
                                </div>
                            </div>
                            <div className="flex align-items-center mr-3">
                                <div className="field-checkbox">
                                    <Checkbox inputId="binary" checked={usuRPP.type3 || (usuRPP.type1 && usuRPP.type2)} onChange={e => {
                                        setUsuRPP({ type1: !usuRPP.type3, type2: !usuRPP.type3, type3: !usuRPP.type3 })
                                    }} />
                                    <label htmlFor="binary">Ambos</label>
                                </div>
                            </div>
                        </div>
                        {selectedPerfil && (selectedPerfil.prf_id == 3 || selectedPerfil.prf_id == 6 || selectedPerfil.prf_id == 7) &&
                            <div className='col-12 md:col-12'>
                                <span className="p-float-label">
                                    <MultiSelect
                                        showSelectAll={false}
                                        value={selectedActos}
                                        onChange={(e) => {
                                            if (e.value.includes(e.selectedOption)) {
                                                addToNoFirmar(e.selectedOption.act_id)
                                            } else {
                                                removeFromNoFirmar(e.selectedOption.act_id)
                                            }
                                            setSelectedActos(e.value)
                                        }
                                        }
                                        options={actos}
                                        optionLabel="act_nombre"
                                        placeholder="Actos que no firman"
                                        maxSelectedLabels={3}
                                        className="w-full"
                                    />
                                    <label htmlFor="dd-city">Actos que no firman</label>
                                </span>
                            </div>
                        }
                    </div>
                </div>

                {/* Si es tipo usuario notario y ya esta dado de alta, muestra la tabla de usuarios(trabajadores) que a agregado */}
                {selectedUser && selectedUser.usu_tipo === 3 &&
                    <div>
                        <Divider align="left">
                            <small className='text-black-alpha-90 font-bold'>Trabajadores del notario</small>
                        </Divider>
                        <ListTrabNotario listWorkers={notarySelectedList} getNotaryProfiles={getNotaryProfiles} />
                    </div>
                }
            </Dialog>

            <Sidebar visible={filter} position="right" onHide={() => setFilter(false)}>
                <h4>Filtración</h4>
                <br />
                <p> Selecciona los campos necesarios para realizar una filtración.</p>
                <hr />
                <br />
                <div className='grid'>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-city"
                                value={filterAreas}
                                onChange={(e) => setFilterAreas(e.value)}
                                options={areas}
                                optionLabel="area_desc"
                                className="w-100 p-inputtext-lg"
                                showClear
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-city">Área</label>
                        </span>
                    </div>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-city"
                                value={filterPerfil}
                                onChange={(e) => setFilterPerfil(e.value)}
                                options={perfiles}
                                optionLabel="prf_desc"
                                className="w-100 p-inputtext-lg"
                                showClear
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-city">Perfil</label>
                        </span>
                    </div>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-city"
                                value={filterType}
                                onChange={(e) => setFilterType(e.value)}
                                options={tipo}
                                optionLabel="name"
                                className="w-100 p-inputtext-lg"
                                showClear
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-city">Tipo</label>
                        </span>
                    </div>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-city"
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.value)}
                                options={estatus}
                                optionLabel="name"
                                className="w-100 p-inputtext-lg"
                                showClear
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-city">Estatus</label>
                        </span>
                    </div>
                </div>
                <div className='footer-proccess'>
                    <div className='grid'>
                        <div className='col-6'>
                            <Button
                                label="Limpiar"
                                size="large"
                                outlined
                                onClick={clearAllFilters}
                            />
                        </div>
                        <div className='col-6'>
                            <Button
                                label="Filtrar"
                                size="large"
                                onClick={onFilterSave}
                            />
                        </div>
                    </div>
                </div>
            </Sidebar>
        </section >
    );

}

export default React.memo(AccUsuarios);

function confirmPopup(arg0: { target: any; message: string; icon: string; accept: () => void; reject: () => void; }) {
    throw new Error('Function not implemented.');
}
