import React from 'react';

const VerificarDatos = (props: any) => {

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>VerificarDatos</h5>
                </div>
            </div>
        </div>
    );

}

export default React.memo(VerificarDatos);