import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Menu } from 'primereact/menu';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Timeline } from 'primereact/timeline';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import authGuardService from '../../../../service/authGuardService';
import ApiServices from '../../../../service/apiServices';

interface City {
    name: string;
}

const ResulActos = (props: any) => {
    const user = authGuardService.getUser()
    const toast = useRef<any>(null);

    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [solicitudMSG, setSolicitudMSG] = useState('')


    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [selected, setSelected] = useState<any>()

    const customizedContent = (item: any) => {
        return (
            <div className=''>
                <div className='grid'>
                    <div className='col-12'>
                        <Panel headerTemplate={
                            <div className='p-panel-header block'>
                                <div className='grid'>
                                    <div className='col-3'> <p><span className='font-bold'>Fecha de prelación: </span>{formatearFecha(item.trm_fecha)}</p></div>
                                    <div className='col-3'>  <p><span className='font-bold'>Numero de Control: </span>{item.trm_id} </p></div>
                                </div>
                            </div>
                        }>
                            <DataTable
                                value={item.history}
                                dataKey="id"
                                paginator
                                rows={5}
                                className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                emptyMessage="No products found."
                            >
                                <Column field="act_clave" header="Clave" sortable style={{ width: '20%' }}></Column>
                                <Column field="act_nombre" header="Acto" sortable style={{ width: '50%' }}></Column>
                                <Column field="name" body={FirmadoTemplate} style={{ width: '20%' }}></Column>
                                <Column field="price" body={EyeTemplate} style={{ width: '10%' }}></Column>
                            </DataTable>
                        </Panel>

                    </div>
                </div>

            </div>
        );
    };

    const FirmadoTemplate = (rowData: any) => {
        if (rowData.trm_status == 9 || rowData.trm_status == 10) {
            return (
                <p style={{ textDecoration: 'underline' }} onClick={() => {
                    ModalSetVisible(true)
                    setSelected(rowData)
                }}>Firmado</p>
            );
        } else {
            return (
                <p>Sin Firmar</p>
            )
        }
    }

    const EyeTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <Button className="p-button-text" icon="pi pi-eye" onClick={() => {
                        props.getActoInformationView(rowData)
                    }} />
                </div>
            </>
        );
    }

    const dateTemplate = (rowData: any) => {
        if (rowData.firstDate) {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <Button className="p-button-text" icon="pi pi-exclamation-circle" style={{ color: '#000' }} />
                    <p>{`${rowData.trm_consecutivo} - ${formatearFecha(rowData.firstDate)}`}</p>
                </div>
            );

        } else {
            return (
                <p></p>
            )
        }
    }

    const handleCancelRequest = () => {
        let datos = {
            area_id: 4,
            trm_id: props.tramite.tramite.trm_id,
            act_id: selected.act_id,
            des_nota_sol: solicitudMSG,
            usu_id_sol: user.usu_id
        }

        ApiServices.apiPost('addDesfirmar', datos).then(async res => {
            if (res.data.valido === 1) {
                toast.current.show({ severity: 'success', summary: 'Enviado con éxito', detail: 'Solicitud de DesFirmar Enviada con Exito', life: 3000 });
                ModalSetVisible(false)
            } else {
                toast.current.show({ severity: 'error', summary: 'Hubo un error', detail: 'Ocurrio un error al enviar la solicitud', life: 3000 });
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Hubo un error', detail: 'Ocurrio un error al enviar la solicitud', life: 3000 });
        })
    }

    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => { { ModalSetVisible(false) } }} autoFocus />
            <Button label="Enviar" onClick={handleCancelRequest} autoFocus disabled={!((solicitudMSG) && (solicitudMSG.length > 1))} />
        </div>
    );

    const [allHistroy, setAllHistory] = useState(props.actoHistory)
    const [searchField, setSearchField] = useState('')

    useEffect(() => {
        setAllHistory(props.actoHistory)
    }, [props.actoHistory])

    function filtrarTransacciones(texto: any, fecha: any) {
        if (!fecha && !texto) {
            setAllHistory(props.actoHistory)
        } else {
            const newArray = props.actoHistory.filter((item: any) => {
                if (fecha && item.trm_fecha !== fecha) return false
                if (texto) {
                    const objectString = JSON.stringify(item);
                    return objectString.includes(texto);
                }
                return true;
            });
            setAllHistory(newArray)
        }
    }

    function convertirFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${año}-${mes}-${dia}T00:00:00.000Z`;
        } else {
            return ''
        }
    }

    function formatearFecha(fechaStr: any) {
        // Crear un objeto Date a partir de la cadena de fecha
        const fecha: any = new Date(fechaStr);

        if (isNaN(fecha)) {
            return "Fecha inválida";
        }

        // Obtener el día, mes y año de la fecha
        const dia = fecha.getUTCDate();
        const mes = fecha.getUTCMonth() + 1; // Los meses van de 0 a 11, por lo que sumamos 1
        const año = fecha.getUTCFullYear();

        // Formatear la fecha en el formato dd/mm/yyyy
        const fechaFormateada = `${dia}/${mes}/${año}`;

        return fechaFormateada;
    }

    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            setDate2(new Date(formattedDate));
            filtrarTransacciones(searchField, convertirFecha(new Date(formattedDate)))

        } else {
            setDate2(null);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };


    return (
        <section>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-3'>
                    <h3 className='color-violet'>Historial de actos</h3>
                    <p>Busca los actos con nuestro buscador</p>
                </div>
                {/* <div className='col-12 md:col-2'>
                    <div className='pr-5'>
                        <Checkbox inputId="ingredient1" checked={checked} />
                        <label htmlFor="ingredient1" className="ml-2">Filtrar vigentes</label>
                    </div>
                </div> */}
                <div className='col-12 md:col-3'>
                    <span className="p-float-label">
                        {/* Input Done */}
                        <Calendar
                            value={date2}
                            onChange={(e: any) => {
                                filtrarTransacciones(searchField, convertirFecha(e.value))
                                setDate2(e.value)
                            }}
                            dateFormat="dd/MM/yy"
                            showIcon
                            className="p-inputtext-lg"
                            showButtonBar
                            locale="es"
                            onClearButtonClick={() => { filtrarTransacciones(searchField, convertirFecha(null)) }}
                            ref={calendarRef}
                            onInput={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="username">Fecha</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label p-input-icon-left w-100">
                        <i className="pi pi-search" />
                        <InputText
                            className=' p-inputtext-lg'
                            type="search"
                            onChange={(e) => {
                                filtrarTransacciones(e.target.value, convertirFecha(date2))
                                setSearchField(e.target.value)
                            }}
                        />
                        <label htmlFor="username">Buscador general</label>
                    </span>
                </div>
            </div>

            <div className='pt-3' style={{ height: 500, overflow: 'scroll' }}>
                {/* <Timeline
                    value={allHistroy}
                    content={customizedContent}
                /> */}

                <DataTable
                    value={allHistroy}
                    dataKey="id"
                    className="datatable-responsive"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    emptyMessage="No products found."
                >
                    <Column field="firstDate" body={dateTemplate} header="Prelación" sortable style={{ width: '20%' }}></Column>
                    <Column field="act_clave" header="ID" sortable style={{ width: '20%' }}></Column>
                    <Column field="act_nombre" header="Acto" sortable style={{ width: '50%' }}></Column>
                    <Column field="name" body={FirmadoTemplate} style={{ width: '20%' }}></Column>
                    <Column field="price" body={EyeTemplate} style={{ width: '10%' }}></Column>
                </DataTable>
            </div>

            <Dialog header="Solicitud de desfirmar" visible={modalVisible} style={{ width: '80vw' }} onHide={() => ModalSetVisible(false)} footer={footerContent}>
                <div>
                    <div className='col-12 md:col-4' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span >
                            <label htmlFor="dd-city">Folio</label>
                            <p>{props && props.detail ? props.detail.prop_folio : ''}</p>
                        </span>
                        <span >
                            <label htmlFor="dd-city">Acto</label>
                            <p>{selected ? selected.act_nombre : ''}</p>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea id="username" rows={5} cols={30} value={solicitudMSG} onChange={(e) => { setSolicitudMSG(e.target.value) }} />
                            <label htmlFor="username">Nota *</label>
                        </span>
                    </div>
                </div>

            </Dialog>
        </section>
    );

}

export default React.memo(ResulActos);