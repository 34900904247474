import { Button } from 'primereact/button';
import React, { useState }  from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';


const IntPerfil = () => {

    const [visible, setVisible] = useState<boolean>(false);

    const footerContent = (
        <div className=''>
         <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
                <Button label="Actualizar" onClick={() => { { setVisible(false)} }} autoFocus />
        </div>
    );

    return (
        <section className='container-fluid'>
            <div className='grid'>
                <div className='col-12'>
                    <h3 className='pb-4'>Mi perfil</h3>
                </div>
            </div>
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <div className='dashboard grid'>
                        <div className='col-12 text-center'>
                        <div className='pb-3'>
                                <img className='photo-perfil' src="../../../../assets/demo/images/avatar/profile.jpg" alt="profile" />
                                <span className='camera-perfil'>
                                    <Button size="small" icon="pi pi-camera" rounded aria-label="Filter" />
                                </span>
                            </div>
                            <h4 className="text-bold">Juan Carlos Montes</h4>
                            <p>ID 4560875</p>

                        </div>
                        <div className="col-12 md:col-6">
                            <small className="gris">Teléfono</small>
                            <p>55 55 55 55 55</p>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <small className="gris">Correo</small>
                            <p>juan@gmail.com</p>
                        </div>
                        <div className="col-12 text-center">
                            <Button label="Cambiar contraseña" text  onClick={() => setVisible(true)}/>
                        </div>
                    </div>
                </div>
                <div className='col-12 md:col-8'>
                    <div className='dashboard'>
                        <h4>Agrega E-Firma</h4>
                        <p>Al agregar tu E-Firma será utilizada para las firmas que consideres que son correctas para su validación.</p>
                        <div className='col-12 md:col-4'>
                            <div className='pt-2 text-center'>
                                <a className='document' href="">
                                    <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }}></i>
                                    Selecciona para subir E-firma</a>
                            </div>
                            <div className='pt-2 document-ok'>
                                {/*Se agrega el 1ero con icono para abrir el documento */}
                                <a href="">
                                    <i className='pi pi-file' style={{ 'fontSize': '3em' }}></i></a>
                                {/*Se agrega el 2do con texto del nombre del archivo con el peso, puede seleccionar para hacer cambio de documento */}
                                <a className='' href="">
                                    Documento.png 10mg</a>
                            </div>
                        </div>
                        <div className='col-12 md:col-12'>
                            <br />
                            <p>Si ingresas tu contraseña de tu eFirma se guardara y firmará automaticamente, si no la ingresas te pedirá la misma cada vez que firmes.</p>
                            <div className='col-4'>
                                <span className="p-float-label">
                                    <InputText id="username" className="p-inputtext-lg" />
                                    <label htmlFor="username">Contraseña</label>
                                </span>
                            </div>
                        </div>
                        <div className=' flex justify-content-end'>
                        <div className='col-12 md:col-4'>
                        <Button label="Guardar" raised/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Dialog header="Actualizar contraseña" visible={visible} maximizable style={{ width: '40vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <p>Ingresa los datos en los campos para realizar el cambio</p>
                <div className='grid'>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText id="username" className="p-inputtext-lg" />
                            <label htmlFor="username">Contraseña actual</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText id="username" className="p-inputtext-lg" />
                            <label htmlFor="username">Nueva contraseña</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText id="username" className="p-inputtext-lg" />
                            <label htmlFor="username">Confirmar contraseña</label>
                        </span>
                    </div>
                </div>
            </Dialog>
        </section>
    );

}

export default React.memo(IntPerfil);