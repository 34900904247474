import { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import TopBarGeneral from './TopBarGeneralSearch';
import { classNames } from 'primereact/utils';
import { useLocation } from 'react-router-dom';
import PrimeReact from 'primereact/api';
import AppMenuGeneral from './VerticalMenu/AppMenuGeneralSearch';
import authGuardService from '../../../service/authGuardService';
import ApiServices from '../../../service/apiServices';

export const MainTopBarGeneralSearch = (props: any) => {

	const user = authGuardService.getUser()

	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const toast = useRef<any>();
	const navigate = useNavigate();
	const permisos: { label: any; }[] = []

	const [menuMode, setMenuMode] = useState('horizontal');

	const [rightMenuActive, setRightMenuActive] = useState(false);
	const [configActive, setConfigActive] = useState(false);
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [sidebarStatic, setSidebarStatic] = useState(false);
	const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
	const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
	const [menuActive, setMenuActive] = useState(false);
	const [searchActive, setSearchActive] = useState(false);
	const [topbarMenuActive, setTopbarMenuActive] = useState(false);
	const [notificationMenuActive, setNotificationMenuActive] = useState(false);
	const [ripple, setRipple] = useState(true);
	const [sidebarActive, setSidebarActive] = useState(false);
	const [pinActive, setPinActive] = useState(false);
	const [activeInlineProfile, setActiveInlineProfile] = useState(false);
	const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);

	const copyTooltipRef = useRef<any>();
	const location = useLocation();

	const permissions = authGuardService.getPermits()
	const [dinamicMenu, setDinamicMenu] = useState<any>([])

	useEffect(() => {
		agruparPorMenuPadre()
	}, [])

	function agruparPorMenuPadre() {
		const clonePermissions = JSON.parse(JSON.stringify(permissions))

		const result: any = [];

		// Filtrar los elementos con expanded igual a 0
		const expanded0 = clonePermissions.filter((item: any) => item.expanded === "0");

		// Filtrar los elementos con expanded igual a 1
		const expanded1 = clonePermissions.filter((item: any) => item.expanded === "1");

		// Agrupar los elementos con expanded igual a 1 por su menu_padre
		const grupos: any = {};
		expanded1.forEach((item: any) => {
			if (!grupos[item.menu_padre]) {
				grupos[item.menu_padre] = [];
			}
			grupos[item.menu_padre].push({ label: item.caption, to: item.url });
		});

		// Agregar los elementos con expanded igual a 0 al resultado
		expanded0.forEach((item: any) => {
			result.push({ label: item.caption, to: item.url });
		});

		// Agregar los grupos al resultado
		for (const menuPadre in grupos) {
			if (grupos.hasOwnProperty(menuPadre)) {
				result.push({ label: menuPadre, items: grupos[menuPadre] });
			}
		}
		if (user.usu_rpp == 1 || user.usu_rpp == 2 || user.usu_rpp == 3) {
			result.push({
				label: 'Consulta Histórica', to: '/consulta-historica'
			})
		}
		setDinamicMenu(result);
	}

	function agruparOld() {
		const agrupado: any = {};
		permissions &&
			permissions.forEach((item: any) => {
				const menuPadre = item.menu_padre || item.menu_nombre;
				if (!agrupado.hasOwnProperty(menuPadre)) {
					agrupado[menuPadre] = [];
				}

				agrupado[menuPadre].push({
					label: item.caption,
					to: item.url
				});
			});

		const arrayAgrupado: any = Object.keys(agrupado).map(key => {
			if (agrupado[key].length > 1) {
				return ({
					label: key,
					items: agrupado[key],
				})
			} else if (agrupado[key].length === 1) {
				return ({
					label: key,
					url: agrupado[key][0].to
				})
			}
		});

		if (user.usu_rpp == 1 || user.usu_rpp == 2 || user.usu_rpp == 3) {
			arrayAgrupado.push({
				label: 'Consulta Histórica', to: '/consulta-historica'
			})
		}
		setDinamicMenu(arrayAgrupado);
	}


	PrimeReact.ripple = true;

	const menu = [
		{
			label: 'Inicio[vtadmin]', to: '/ventanilla/vtadmin-inicio'
		},
		{
			label: 'Inicio[vttrab]', to: '/ventanilla/vttrab-inicio'
		},
		{
			label: 'Tramite', to: '/ventanilla/ven-pagos'
		},
		{
			label: 'Catalogo notarios', to: '/ventanilla/cat-notario'
		}
	];

	let rightMenuClick: any;
	let configClick: any;
	let menuClick: any;
	let searchClick: boolean = false;
	let topbarItemClick: any;
	let notificationItemClick: any;

	useEffect(() => {
		copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
	}, [location]);

	useEffect(() => {
		setResetActiveIndex(true)
		setMenuActive(false)
	}, [menuMode])

	const onDocumentClick = () => {
		if (!searchClick && searchActive) {
			onSearchHide();
		}

		if (!topbarItemClick) {
			setTopbarMenuActive(false)
		}

		if (!notificationItemClick) {
			setNotificationMenuActive(false)
		}

		if (!menuClick) {
			if (isHorizontal() || isSlim()) {
				setMenuActive(false)
				setResetActiveIndex(true)
			}

			if (overlayMenuActive || staticMenuMobileActive) {
				setOverlayMenuActive(false);
				setStaticMenuMobileActive(false)
			}

			hideOverlayMenu();
			unblockBodyScroll();
		}

		if (!rightMenuClick) {
			setRightMenuActive(false)
		}

		if (configActive && !configClick) {
			setConfigActive(false);
		}

		topbarItemClick = false;
		notificationItemClick = false;
		menuClick = false;
		configClick = false;
		rightMenuClick = false;
		searchClick = false;
	}

	const onSearchHide = () => {
		setSearchActive(false);
		searchClick = false;
	}

	//Cambiar la posicion del menu
	const onMenuModeChange = (menuMode: any) => {
		setMenuMode(menuMode);
		setOverlayMenuActive(false);
	}

	const onRightMenuButtonClick = () => {
		rightMenuClick = true;
		setRightMenuActive(true)
	}

	const onRightMenuClick = () => {
		rightMenuClick = true;
	}

	const onRightMenuActiveChange = (active: any) => {
		setRightMenuActive(active);
	}

	const onConfigClick = () => {
		configClick = true;
	}

	const onConfigButtonClick = (event: any) => {
		setConfigActive(prevState => !prevState)
		configClick = true;
		event.preventDefault();
	}

	const onRippleChange = (e: any) => {
		PrimeReact.ripple = e.value;
		setRipple(e.value);
	}

	const onMenuButtonClick = (event: any) => {
		menuClick = true;

		if (isOverlay()) {
			setOverlayMenuActive(prevState => !prevState)
		}

		if (isDesktop()) {
			setStaticMenuDesktopInactive(prevState => !prevState)
		} else {
			setStaticMenuMobileActive(prevState => !prevState)
		}

		event.preventDefault();
	}

	const hideOverlayMenu = () => {
		setOverlayMenuActive(false)
		setStaticMenuMobileActive(false)
	}

	const onTopbarItemClick = (event: any) => {
		topbarItemClick = true;
		setTopbarMenuActive(prevState => !prevState)
		hideOverlayMenu();
		event.preventDefault();
	}

	const onNotificationItemClick = (event: any) => {
		notificationItemClick = true;
		setNotificationMenuActive(prevState => !prevState)
		hideOverlayMenu();
		event.preventDefault();
	}

	const onToggleMenu = (event: any) => {
		menuClick = true;

		if (overlayMenuActive) {
			setOverlayMenuActive(false)
		}

		if (sidebarActive) {
			setSidebarStatic(prevState => !prevState)
		}

		event.preventDefault();
	}

	const onSidebarMouseOver = () => {
		if (menuMode === 'sidebar' && !sidebarStatic) {
			setSidebarActive(isDesktop());
			setTimeout(() => {
				setPinActive(isDesktop())
			}, 200);
		}
	}

	const onSidebarMouseLeave = () => {
		if (menuMode === 'sidebar' && !sidebarStatic) {
			setTimeout(() => {
				setSidebarActive(false);
				setPinActive(false);
			}, 250);
		}
	}

	const onMenuClick = () => {
		menuClick = true;
	}

	const onMenuClickFalse = () => {
		menuClick = false
	}

	const onChangeActiveInlineMenu = (event: any) => {
		setActiveInlineProfile(prevState => !prevState);
		event.preventDefault();
	}

	const onRootMenuItemClick = () => {
		setMenuActive(prevState => !prevState)
	}

	const onMenuItemClick = (event: any) => {
		if (!event.item.items) {
			hideOverlayMenu();
			setResetActiveIndex(true);
		}

		if (!event.item.items && (isHorizontal() || isSlim())) {
			setMenuActive(false);
		}
	}

	const isHorizontal = () => {
		return menuMode === 'horizontal';
	}

	const isSlim = () => {
		return menuMode === 'slim';
	}

	const isOverlay = () => {
		return menuMode === 'overlay';
	}

	const isDesktop = () => {
		return window.innerWidth > 991;
	}


	const onInputClick = () => {
		searchClick = true
	}

	const breadcrumbClick = () => {
		searchClick = true;
		setSearchActive(true);
	}

	const unblockBodyScroll = () => {
		if (document.body.classList) {
			document.body.classList.remove('blocked-scroll');
		} else {
			document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
				'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}

	const layoutClassName = classNames('layout-wrapper', {
		'layout-static': menuMode === 'static',
		'layout-overlay': menuMode === 'overlay',
		'layout-overlay-active': overlayMenuActive,
		'layout-slim': menuMode === 'slim',
		'layout-horizontal': menuMode === 'horizontal',
		'layout-active': menuActive,
		'layout-mobile-active': staticMenuMobileActive,
		'layout-sidebar': menuMode === 'sidebar',
		'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
		'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
		'p-ripple-disabled': !ripple
	});

	return (
		<div className={layoutClassName} onClick={onDocumentClick}>
			<Toast ref={toast} />
			<div className="layout-main">
				<TopBarGeneral items={dinamicMenu} menuMode={menuMode} colorScheme={props.colorScheme} menuActive={menuActive}
					topbarMenuActive={topbarMenuActive} notificationMenuActive={notificationMenuActive} activeInlineProfile={activeInlineProfile} onTopbarItemClick={onTopbarItemClick} onNotificationItemClick={onNotificationItemClick} onMenuButtonClick={onMenuButtonClick}
					onSidebarMouseOver={onSidebarMouseOver} onSidebarMouseLeave={onSidebarMouseLeave} onToggleMenu={onToggleMenu}
					onChangeActiveInlineMenu={onChangeActiveInlineMenu} onMenuClick={onMenuClick} onMenuClickFalse={onMenuClickFalse} onMenuItemClick={onMenuItemClick}
					onRootMenuItemClick={onRootMenuItemClick} resetActiveIndex={resetActiveIndex} />

				<AppMenuGeneral model={dinamicMenu} onRootMenuItemClick={onRootMenuItemClick} onMenuItemClick={onMenuItemClick} onToggleMenu={onToggleMenu} onMenuClick={onMenuClick} menuMode={menuMode}
					colorScheme={props.colorScheme} menuActive={menuActive}
					sidebarActive={sidebarActive} sidebarStatic={sidebarStatic} pinActive={pinActive}
					onSidebarMouseLeave={onSidebarMouseLeave} onSidebarMouseOver={onSidebarMouseOver}
					activeInlineProfile={activeInlineProfile} onChangeActiveInlineMenu={onChangeActiveInlineMenu} resetActiveIndex={resetActiveIndex} />

				<div className="layout-main-content" >
					<div className="login-panel p-fluid">
						{props.component}
					</div>
				</div>
			</div>
		</div >
	)
}