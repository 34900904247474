import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import React, { useEffect, useState } from 'react';
import ApiServices from '../../service/apiServices';
import { InputNumber } from 'primereact/inputnumber';
import EncryptService from '../../service/encrypt.service';
import authGuardService from '../../service/authGuardService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';


const FolioDinamico = (props: any) => {
    const user = authGuardService.getUser()
    const [titulares, setTitulares] = useState([])

    useEffect(() => {
        getTitulares()
    }, [])


    const getTitulares = () => {
        let datos = {
            frmu_id: props && props.information && props.information.frmu_id,
            cmp_id: props && props.frm && props.frm.cmp_id
        }
        ApiServices.apiPost('getControlDinamicoFolio', datos).then(async res => {
            let dataEx = res.data.addenda
            if (dataEx && dataEx.length > 0) {
                if (res.data.addenda && res.data.addenda.length > 0) {
                    const formatted = res.data.addenda.map((singleTitular, index) => {

                        singleTitular.usu_id = user.usu_id
                        singleTitular.cmp_uno = singleTitular.cmptdyn_uno
                        singleTitular.prop_direccion = singleTitular.cmptdyn_dos
                        singleTitular.cmp_status = 1

                        singleTitular.temporalID = index + 1000
                        return singleTitular
                    })
                    setTitulares(formatted)
                }
            }
        }).catch(error => {

        })
    }

    const addNewTitular = () => {
        if (titulares.length > 0) {
            const lastObject = titulares[titulares.length - 1];
            const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
                acc[key] = '';
                return acc;
            }, {});

            emptyObject.temporalID = titulares.length + 1000
            emptyObject.frmu_id = props.information.frmu_id
            emptyObject.usu_id = user.usu_id
            emptyObject.cmp_id = ''
            emptyObject.cmp_uno = ''
            emptyObject.cmp_status = ''

            setTitulares([...titulares, emptyObject]);
            props.handleValueChange([...titulares, emptyObject], props.indexes[0], props.indexes[1])
        } else {
            const newTitular = {
                temporalID: titulares.length + 1000,
                frmu_id: props.information.frmu_id,
                usu_id: user.usu_id,
                cmp_id: '',
                cmp_uno: '',
                cmp_status: ''
            }
            setTitulares([...titulares, newTitular]);
            props.handleValueChange([...titulares, newTitular], props.indexes[0], props.indexes[1])
        }
    }

    const isDeletedTemplate = (rowData, index) => {
        if (rowData && rowData.ptit_status && rowData.ptit_status == 0)
            return <Tag severity="danger" value="Se Eliminara"></Tag>
        else return ''
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return <InputText
            type="text"
            value={options.value}
            onChange={(e) => {
                options.editorCallback(e.target.value)
            }}
            onBlur={(e) => { searchFolio(e.target.value, options.rowData) }}
            onKeyDown={(e) => e.stopPropagation()}
        />;

    };

    const handleChangeTitulares = (index: string | number, field: any, value: any) => {
        const newTitulares = [...titulares];
        newTitulares[index] = {
            ...newTitulares[index],
            [field]: value
        };
        setTitulares(newTitulares);
        props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
    };

    const onCellEditComplete = (e: { rowIndex: any; field: any; newValue: any; }) => {
        handleChangeTitulares(e.rowIndex, e.field, e.newValue)
    };

    const deleteTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].cmp_status = 0
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const recoverTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].cmp_status = 1
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const actionBodyTemplate = (rowData, index) => {
        if (rowData.cmp_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return <Button type="button" severity="danger" icon="pi pi-trash" rounded onClick={() => { deleteTitular(rowData, index.rowIndex) }}></Button>;
    };

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold"></span>
            <Button icon="pi pi-plus" rounded raised onClick={addNewTitular} />
        </div>
    );

    const searchFolio = (folio, row) => {
        let datos
        datos = {
            code: 208,
            prop_clave: '',
            prop_folio: folio,
            ptit_nom: '',
            ptit_pat: '',
            ptit_mat: '',
            prop_direccion: '',
            prop_calle: '',
            prop_ext: '',
            prop_int: '',
            prop_zona: '',
            prop_usosuelo: '',
            cp_cp: '',
            cp_municipio: '',
            prop_antecedente: '',
            cp_estado: ''
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                if (res.data.addenda && res.data.addenda.length > 0) {
                    if (res.data.addenda.length == 1) {
                        const newTitulares = titulares.map((singleTitular) => {
                            if (singleTitular.temporalID == row.temporalID) {
                                singleTitular.cmp_status = res.data.addenda[0].prop_status
                                singleTitular.prop_direccion = res.data.addenda[0].prop_direccion
                                singleTitular.cmp_uno = res.data.addenda[0].prop_folio

                                return singleTitular
                            } else
                                return singleTitular
                        })
                        setTitulares(newTitulares)
                        props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
                    }
                } else {
                    console.log('No se encontro ningun folio')
                }
            } else {
                console.log('Error')
            }
        }).catch(error => {
            console.log(error)
        })
    }


    return (
        <section className='dynamicStyle'>
            <Panel header={props && props.frm && props.frm.cmp_titulo ? props.frm.cmp_titulo : props && props.frm && props.frm.cmp_valores ? props.frm.cmp_valores : ''}>
                <div className='p-datatable p-component p-datatable-responsive-scroll datatable-responsive'>
                    <DataTable
                        value={titulares}
                        dataKey="ptit_id"
                        paginator
                        editMode="cell"
                        rows={5}
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        emptyMessage="No hay Folios."
                        showGridlines
                        header={header}
                    >
                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={isDeletedTemplate} />


                        <Column field={'cmp_uno'} header={'No. Folio'} editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete} />
                        <Column field={'prop_direccion'} header={'Dirección'} />
                        <Column field={'cmp_status'} header={'Firmado'} />

                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={actionBodyTemplate} />
                    </DataTable>
                </div>
            </Panel>
        </section >
    );

}

export default React.memo(FolioDinamico);