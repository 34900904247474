import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Menu } from 'primereact/menu';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Timeline } from 'primereact/timeline';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import ApiServices from '../../../../../../service/apiServices';
import authGuardService from '../../../../../../service/authGuardService';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';

const ResulActos = (props: any) => {

    const user = authGuardService.getUser()
    const toast = useRef<any>(null);

    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [solicitudMSG, setSolicitudMSG] = useState('')
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [selected, setSelected] = useState<any>()
    const [allHistroy, setAllHistory] = useState()
    const [searchField, setSearchField] = useState('')

    const FirmadoTemplate = (rowData: any) => {
        if (rowData.trm_status == 9 || rowData.trm_status == 10) {
            return (
                <>
                    <Tooltip target=".custom-target-icon2" />
                    <Button
                        label={`Firmado ${rowData.calificador ? ` - ${rowData.calificador}` : ''}`}
                        text
                        severity="secondary"
                        tooltip={`${rowData.calificador ? `${rowData.calificador} - ${rowData.calificador_nombre}` : ''}`}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => {
                            ModalSetVisible(true)
                            setSelected(rowData)
                        }}
                        style={{ fontSize: 16, width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'start' }}
                    />
                </>
            );
        } else {
            return (
                <p>Sin Firmar</p>
            )
        }
    }

    const EyeTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <Button className="p-button-text" icon="pi pi-eye" onClick={() => {
                        props.getActoInformationView(rowData)
                        props.setTramiteSelected({ tramite: rowData })
                    }} />
                </div>
            </>
        );
    }

    const dateTemplate = (rowData: any) => {
        if (rowData.trm_fecha) {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <p>{`${formatearFecha(rowData.trm_fecha)}`}</p>
                </div>
            );

        } else {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <p>{`${formatearFecha(rowData.trm_fecha)}`}</p>
                </div>
            )
        }
    }

    const dateTemplate2 = (rowData: any) => {
        if (rowData.trm_firmado) {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <p>{`${formatearFecha(rowData.trm_firmado)}`}</p>
                </div>
            );

        } else {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <p>{``}</p>
                </div>
            )
        }
    }

    const handleCancelRequest = () => {
        let datos = {
            area_id: 4,
            trm_id: props.tramite.tramite.trm_id,
            act_id: selected.act_id,
            des_nota_sol: solicitudMSG,
            usu_id_sol: user.usu_id
        }

        ApiServices.apiPost('addDesfirmar', datos).then(async res => {
            if (res.data.valido === 1) {
                toast.current.show({ severity: 'success', summary: 'Enviado con éxito', detail: 'Solicitud de DesFirmar Enviada con Exito', life: 3000 });
                ModalSetVisible(false)
            } else {
                toast.current.show({ severity: 'error', summary: 'Hubo un error', detail: 'Ocurrio un error al enviar la solicitud', life: 3000 });
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Hubo un error', detail: 'Ocurrio un error al enviar la solicitud', life: 3000 });
        })
    }

    function filtrarTransacciones(texto: any, fecha: any) {
        if (!fecha && !texto) {
            setAllHistory(props.actoHistory)
        } else {
            const newArray = props.actoHistory.filter((item: any) => {
                const itemDate = item.firstDate;
                const filterDate = new Date(fecha).toISOString().split('T')[0];
                if (fecha && itemDate !== filterDate) return false;
                if (texto) {
                    const objectString = JSON.stringify(item);
                    return objectString.includes(texto);
                }
                return true;
            });
            setAllHistory(newArray);
        }
    }

    function convertirFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${año}-${mes}-${dia}T00:00:00.000Z`;
        } else {
            return ''
        }
    }

    function formatearFecha(fechaStr: any) {
        // Crear un objeto Date a partir de la cadena de fecha
        const fecha: any = new Date(fechaStr);

        if (isNaN(fecha)) {
            return "Fecha inválida";
        }

        const año = fecha.getUTCFullYear();
        const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0');
        const dia = fecha.getUTCDate().toString().padStart(2, '0');

        // Formatear la fecha en el formato dd/mm/yyyy
        const fechaFormateada = `${dia}/${mes}/${año}`;

        return fechaFormateada;
    }

    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => { { ModalSetVisible(false) } }} autoFocus />
            <Button label="Enviar" onClick={handleCancelRequest} autoFocus disabled={!((solicitudMSG) && (solicitudMSG.length > 1))} />
        </div>
    );

    useEffect(() => {
        filtrarPorCertificados(props.actoHistory, false)
    }, [props.actoHistory])

    const formuStatusTemplate = (rowData: any) => {
        return (
            < p > {rowData && rowData.frmu_status == 0 ? 'Borrado' :
                rowData.frmu_status == 1 ? 'Vigente' :
                    rowData.frmu_status == 2 ? 'No Vigente' :
                        rowData.frmu_status == 3 ? 'No Vigente' :
                            ''
            }</p>
        );
    }

    const [checked, setChecked] = useState(false)
    const [checkedPreCodificadas, setCheckedPreCodificadas] = useState(false)
    const [checkedCertificadas, setCheckedCertificadas] = useState(false)

    function filtrarPorFrmuStatus() {
        setChecked(!checked)

        if (!checked) {
            const newData = props.actoHistory.filter((obj) => obj.trm_status == 1);

            props.setActoHistory(newData)
        } else {
            props.setActoHistory(props.actoHistoryClone)
        }
    }

    function filtrarPorPreCodificadas() {
        setCheckedPreCodificadas(!checkedPreCodificadas)

        if (!checkedPreCodificadas) {
            const newData = props.actoHistory.filter((obj) => (obj.trm_status != 9 && obj.trm_status != 10));

            setAllHistory(newData)
        } else {
            setAllHistory(props.actoHistoryClone)
        }
    }

    function filtrarPorCertificados(data = props.actoHistoryClone, checkedValue: boolean) {
        if (checkedValue) {
            const newData = data.filter(obj => {
                const actClave = obj.act_clave;
                return actClave && actClave.startsWith('CI');
            });
            setAllHistory(newData)
            setCheckedCertificadas(true)
        } else {
            const newData = data.filter(obj => {
                const actClave = obj.act_clave;
                return !actClave || !actClave.startsWith('CI');
            });
            setAllHistory(newData)
            setCheckedCertificadas(false)
        }
    }

    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        // Convertir el formato MMDDYY a MM/DD/YYYY
        const formattedDate = formatDateString(inputValue);

        if (formattedDate) {
            filtrarTransacciones(searchField, convertirFecha(new Date(formattedDate)))
            setDate2(new Date(formattedDate));
        } else {
            setDate2(null);
        }

        // Actualizar el valor del input del Calendar manualmente si el input existe
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };


    return (
        <section>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-2'>
                    <h3 className='color-violet'>Historial de actos</h3>
                    <p>Busca los actos con nuestro buscador</p>
                </div>
                <div className='col-12 md:col-2'>
                    <div className='pr-5'>
                        <Checkbox inputId="ingredient1" checked={checked} onChange={filtrarPorFrmuStatus} />
                        <label htmlFor="ingredient1" className="ml-2">Filtrar vigentes</label>
                    </div>
                </div>
                <div className='col-12 md:col-2'>
                    <div className='pr-5'>
                        <Checkbox inputId="PreCodificadas" checked={checkedPreCodificadas} onChange={filtrarPorPreCodificadas} />
                        <label htmlFor="PreCodificadas" className="ml-2">Prelación</label>
                    </div>
                </div>
                <div className='col-12 md:col-2'>
                    <div className='pr-5'>
                        <Checkbox inputId="Certificados" checked={checkedCertificadas} onChange={() => { filtrarPorCertificados(props.actoHistoryClone, !checkedCertificadas) }} />
                        <label htmlFor="Certificados" className="ml-2">Mostrar Certificados</label>
                    </div>
                </div>
                <div className='col-12 md:col-2'>
                    <span className="p-float-label">
                        {/* Input Done */}
                        <Calendar
                            value={date2}
                            onChange={(e: any) => {
                                filtrarTransacciones(searchField, convertirFecha(e.value))
                                setDate2(e.value)
                            }}
                            dateFormat="dd/MM/yy"
                            showIcon
                            className="p-inputtext-lg"
                            showButtonBar
                            locale="es"
                            onClearButtonClick={() => { filtrarTransacciones(searchField, convertirFecha(null)) }}
                            ref={calendarRef}
                            onInput={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="username">Fecha</label>
                    </span>
                </div>
                <div className='col-12 md:col-2'>
                    <span className="p-float-label p-input-icon-left w-100">
                        <i className="pi pi-search" />
                        <InputText
                            className=' p-inputtext-lg'
                            type="search"
                            onChange={(e) => {
                                filtrarTransacciones(e.target.value, convertirFecha(date2))
                                setSearchField(e.target.value)
                            }}
                        />
                        <label htmlFor="username">Buscador general</label>
                    </span>
                </div>
            </div>

            <div className='pt-3' style={{ height: 500, overflow: 'scroll' }}>
                <DataTable
                    value={allHistroy}
                    dataKey="frmu_id"
                    className="datatable-responsive"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    emptyMessage="No products found."
                    sortOrder={-1}
                    sortField='trm_fecha'
                >
                    <Column field="trm_consecutivo" header="# Consecutivo" sortable></Column>
                    <Column field="trm_fecha" /* body={dateTemplate} */ header="Fecha" sortable></Column>
                    <Column field="act_clave" header="ID" sortable></Column>
                    <Column field="act_nombre" header="Acto" sortable ></Column>
                    <Column field="trm_escritura" header="Numero de Escritura" sortable ></Column>
                    <Column field="trm_firmado" header="Fecha de Inscripcion" sortable body={dateTemplate2}></Column>
                    <Column field="name" body={FirmadoTemplate} style={{ width: '14%' }}></Column>
                    <Column field="Estatus" body={formuStatusTemplate}></Column>
                    <Column field="price" body={EyeTemplate}></Column>
                </DataTable>
            </div>

            <Dialog header="Solicitud de desfirmar" visible={modalVisible} style={{ width: '80vw' }} onHide={() => ModalSetVisible(false)} footer={footerContent}>
                <div>
                    <div className='col-12 md:col-4' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span >
                            <label htmlFor="dd-city">Folio</label>
                            <p>{props && props.detail ? props.detail.prop_folio : ''}</p>
                        </span>
                        <span >
                            <label htmlFor="dd-city">Acto</label>
                            <p>{selected ? selected.act_nombre : ''}</p>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea id="username" rows={5} cols={30} value={solicitudMSG} onChange={(e) => { setSolicitudMSG(e.target.value) }} />
                            <label htmlFor="username">Nota *</label>
                        </span>
                    </div>
                </div>

            </Dialog>
        </section>
    );

}

export default React.memo(ResulActos);