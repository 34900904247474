import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import ApiServices from '../../../../../service/apiServices';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

interface City {
    name: string;
}

const AccPerfiles = () => {

    useEffect(() => {
        getPerfiles()
    }, [])

    const getPerfiles = () => {
        let datos
        ApiServices.apiPost('catPerfiles', datos).then(async res => {
            setPerfiles(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const [perfiles, setPerfiles] = useState([])

    const toast = useRef<Toast>(null);


    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-10'>
                    <h3>Catálogo Perfiles</h3>
                    <p>Genera las Perfiles necesarios.</p>
                </div>
                <div className='col-12'>
                    <DataTable value={perfiles} paginator rows={10}
                        dataKey="prf_id" tableStyle={{ minWidth: '50rem' }}>
                        <Column sortable field="prf_id" header="ID"></Column>
                        {/*<Column sortable field="prf_desc" header="Área"></Column>*/}
                        <Column sortable field="prf_desc" header="Perfil"></Column>
                    </DataTable>
                </div>
            </div>
        </section>


    );

}

export default React.memo(AccPerfiles);