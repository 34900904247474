import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ResulActos from './PagoResulFolioView/actosView';
import ResulGenerales from './PagoResulFolioView/generalesView';
import ResulTitulares from './PagoResulFolioView/titularesView';
import { Dialog } from 'primereact/dialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { useLocation, useNavigate } from "react-router-dom";
import { Messages } from 'primereact/messages';
import formatDate from '../../../utilities/formatDate';
import { Steps } from 'primereact/steps';
import Tabs from '../../../Components/Tabs/Tabs';
import Tab from '../../../Components/Tabs/Tab';
import TimeLine from '../../../Components/TimeLine/TimeLine';

const PagoFolioView = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const msgs = useRef<any>(null);

    const previousFolio: any = location.state.datos
    const previousPayments: any = location.state.pagos
    const preTramite: any = location.state.preTramite

    const [preTramiteDetail, setPreTramiteDetail] = useState<any>(null)
    const [preTramiteFolio, setPreTramiteFolio] = useState<any>()
    const [modalRechazar, setModalRechazar] = useState<boolean>(false);
    const [rechazarText, setRechazarText] = useState('')
    const [modalRechazado, setModalRechazado] = useState(false)
    const [modalSentToNotario, setModalSentToNotario] = useState(false)
    const [actosArray, setActosArray] = useState<any>()
    const [actosList, setActosList] = useState([])
    const [escrituraNumber, setEscrituraNumber] = useState(preTramite ? preTramite.trm_escritura : '')
    const [modalBorradorGuardado, setModalBorradorGuardado] = useState(false)

    const [notaryList, setNotaryList] = useState([])
    const [selectedNotaryList, setSelectedNotaryList] = useState<any>(null)
    const [requestName, setRequestName] = useState('')
    const [timeLine, setTimeLine] = useState<any>(null)

    const events1 = [
        { label: 'Capturado' },
        { label: 'Asignado' },
        { label: 'Analizando' },
        { label: 'A-R-C' },
        { label: 'Firmado' }
    ];

    const events2 = [
        { label: 'Capturado', tooltip: timeLine ? timeLine.capturista : '', time: timeLine ? timeLine.trm_fecha : '' },
        { label: 'Asignado', tooltip: timeLine ? timeLine.analista : '' },
        { label: 'Analizando', tooltip: '', time: timeLine ? timeLine.trm_analizado : '' },
        { label: 'A-R-C', tooltip: timeLine ? timeLine.statusARC : '', time: timeLine ? timeLine.fechahoraARC : '' },
        { label: 'Firmado', tooltip: timeLine ? timeLine.trm_firmador : '', time: timeLine ? timeLine.trm_firmado : '' }
    ];

    const searchNotaryById = (id: any) => {
        if (notaryList && notaryList.length > 0) {
            notaryList.map((element: any) => {
                if (element.not_id == id) {
                    setSelectedNotaryList(element)
                }
            })
        }
    }

    useEffect(() => {
        if (preTramite && notaryList) {
            let datos2
            if (preTramite.trm_id) {
                datos2 = { code: 204, trm_id: preTramite.trm_id }
            } else {
                datos2 = { code: 303, ptrm_id: preTramite.ptrm_id }
            }
            datos2 = EncryptService.encryptData(JSON.stringify(datos2));
            ApiServices.processRequestAdd(datos2).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    searchNotaryById(res.data.addenda.tramite.not_id)
                    setEscrituraNumber(res.data.addenda.tramite.ptrm_escritura)
                }
            }).catch(error => {
                console.log(error)
            })


            if ((preTramite.ptrm_status === 3 || preTramite.ptrm_status === 4)) {
                msgs.current.show(
                    {
                        sticky: true,
                        severity: 'error',
                        summary: 'PreTramite Rechazado / ',
                        detail: `Motivo: ${preTramite.ptrm_nota}`,
                        closable: false,
                    }
                );
            }
            if (preTramite.prop_folio) {
                let datos = {
                    prop_folio: preTramite.prop_folio,
                    prop_online: 0
                }
                ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                    setPreTramiteFolio(res.data.addenda)
                    setActiveIndex(0)
                }).catch(error => {
                    console.log(error)
                })
            } else {
                let datos = {
                    prop_id: preTramite.prop_id,
                }
                ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
                    let datos = {
                        prop_folio: res.data.addenda[0].prop_folio,
                        prop_online: 0
                    }
                    ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                        setPreTramiteFolio(res.data.addenda)
                        setActiveIndex(0)
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }, [preTramite, notaryList])

    const getActosInformation = () => {
        const idToSearch = responseData ? responseData.ptrm_id : preTramite ? preTramite.ptrm_id : null
        if (idToSearch) {
            let datos = {
                ptrm_id: idToSearch,
                trm_id: 0
            }
            ApiServices.apiPost('getFormatosResp', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda.length > 0) {
                    const newArray: any = []

                    res.data.addenda.map(async (singleElement: any) => {
                        if (singleElement.ptrm_id === idToSearch) {
                            newArray.push(singleElement)
                        }
                    })
                    matchSavedActos(newArray)
                    /* setActosArray(newArray) */
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    function findByActId(array: any, targetActId: any) {
        return array.find((item: any) => item.act_id == targetActId);
    }

    const [totalActos, setTotalActos] = useState(0)

    const matchSavedActos = (newActos: any) => {
        if (newActos && newActos.length > 0) {
            let total = 0;
            const matchedActos = newActos.map((singleActo: any) => {
                const match = findByActId(actosList, singleActo.act_id)

                if (match) {
                    singleActo.act_umas = match.act_umas
                    singleActo.act_dias = match.act_dias
                    singleActo.cnpg_id = match.cnpg_id
                    singleActo.area_id = match.area_id
                    singleActo.act_status = match.act_status
                    singleActo.act_update = match.act_update
                    singleActo.valormx = match.valormx

                    if (match.valormx) {
                        total = total + parseFloat(match.valormx)
                    }
                }

                return singleActo
            })
            setTotalActos(total)
            setActosArray(matchedActos)
        }
    }

    const getActosList = () => {
        let datos
        ApiServices.apiPost('catActo', datos).then(async res => {
            setActosList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<any>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [modalSoliInter, ModalSoliInter] = useState<boolean>(false);
    const buttonEl = useRef<any>(null);

    const accept = () => {
        ModalSetVisible(true)
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado tu trámite', life: 3000 });
    }

    const customizedMarker = (item: any) => {
        return (
            <div>
                {/* circulo vasio: se utilizara cuando no tiene un proceso todavia realizado */}
                <i className='pi pi-circle'></i>
                {/* circulo lleno: aparecera cuando el estatus se valla llenando, en cada punto tiene que aparecer un toolpin con el estatus de quien lo reviso y la hora de movimiento */}
                <Button className='p-button-text tooltip-view' type="button" icon="pi pi-circle-fill" tooltipOptions={{ position: 'bottom' }} />
                <Tooltip target=".tooltip-view" autoHide={false}>
                    <div className="p-3">
                        <h5>Capturado</h5>
                        <p>Juan Montes Torres <br />
                            <small>12 Marz 2022 12:00pm</small></p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const footerContent = (
        <div>
            <Button label="Cerrar" onClick={() => { { setVisible(false); showSuccess() } }} autoFocus />
        </div>
    );

    useEffect(() => {
        getActosList()
        getNotaryList()
        if (preTramite && preTramite.trm_id)
            getTimeLine(preTramite.trm_id)
    }, [])

    const getTimeLine = (id: any) => {
        if (id) {
            let datos = {
                trm_id: id,
            }
            ApiServices.apiPost('getTimeLine', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                    setTimeLine(res.data.addenda[0])
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    useEffect(() => {
        getActosInformation()
    }, [actosList])



    const getNotaryList = () => {
        let datos
        ApiServices.apiPost('catNotarios', datos).then(async res => {
            setNotaryList(res.data.addenda)
            searchNotary(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const searchNotary = (notarys: any) => {
        if (notarys && notarys.length > 0) {
            notarys.map((element: any) => {
                if (element.not_id === user.not_id) {
                    setSelectedNotaryList(element)
                }
            })
        }
    }

    const getActosArray = () => {
        if (actosArray && actosArray.length > 0) {
            const ids = actosArray.map((element: any) => {
                return element.act_id
            })
            return ids
        }
    }

    const getPagosArray = () => {
        if (previousPayments && previousPayments.length > 0) {
            const ids = previousPayments.map((element: any) => {
                return element.pago_id
            })
            return ids
        }
    }

    function obtenerFechaYHoraActualEnFormato() {
        const mesesEnEspanol = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const fechaActual = new Date();
        const dia = fechaActual.getDate();
        const mes = mesesEnEspanol[fechaActual.getMonth()];
        const anio = fechaActual.getFullYear();
        let hora = fechaActual.getHours();
        const minutos = fechaActual.getMinutes();
        let periodo = "am";

        // Convertir la hora a formato de 12 horas y ajustar el periodo (am/pm)
        if (hora >= 12) {
            periodo = "pm";
            if (hora > 12) {
                hora -= 12;
            }
        }

        // Agregar un cero al principio si los minutos son menores a 10
        const minutosFormateados = (minutos < 10) ? "0" + minutos : minutos;

        const fechaYHoraFormateada = `${dia} ${mes} ${anio} a las ${hora}:${minutosFormateados} ${periodo}`;
        return fechaYHoraFormateada;
    }

    /* CONFIRMED */

    const user = authGuardService.getUser()

    const [modalNewScript, setModalNewScript] = useState(false)
    const [responseData, setResponseData] = useState<any>()

    const handlePreviousActos = () => {
        const arrayListSaved = preTramite.actos.split(', ')

        if (arrayListSaved && arrayListSaved.length > 0) {
            arrayListSaved.map((singleAct: any) => {

                const foundItem = actosArray.find((item: any) => item.act_clave == singleAct);
                if (!foundItem) {
                    const elementToPush = actosList.find((item: any) => item.act_clave == singleAct);
                    const newArray = JSON.parse(JSON.stringify(actosArray))
                    newArray.push(elementToPush)
                    setActosArray(newArray)
                }
            })
        }
    }

    useEffect(() => {
        if (preTramite && preTramite.actos && actosList && actosList.length > 0 && actosArray) {
            handlePreviousActos()
        }
    }, [actosList, preTramite, actosArray])

    function formatDateHora(date: any, hours: any) {
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const newDate = new Date(date);
        const newHour = new Date(hours);
        const day = newDate.getUTCDate();
        const month = months[newDate.getUTCMonth()];
        const year = newDate.getUTCFullYear();
        const hour = newHour.getUTCHours();
        const minutes = newHour.getUTCMinutes();

        const fechaFormateada = `${day} ${month} ${year} a las ${hour}:${minutes}`;
        return fechaFormateada;
    }

    
    function groupObjectsByTrmId(inputArray: any) {
        // Ordenar los objetos por fecha antes de agruparlos
        inputArray.sort((a, b) => {
            return a.trm_fecha.localeCompare(b.trm_fecha);
        });

        const groupedObjects: any = {};

        inputArray.forEach((obj) => {
            const trmFecha = obj.trm_fecha;

            if (!groupedObjects[trmFecha]) {
                groupedObjects[trmFecha] = {
                    trm_fecha: trmFecha,
                    history: []
                };
            }

            groupedObjects[trmFecha].history.push(obj);
        });

        return Object.values(groupedObjects);
    }

    const getActoHistory = () => {
        const previousFolio: any = location && location.state && location.state.datos && location.state.datos[0] && location.state.datos[0].prop_folio
        const preTramite: any = location?.state?.preTramite?.prop_folio

        let datos = {
            prop_folio: previousFolio || preTramite
        }
        ApiServices.apiPost('getHistorial', datos).then(async res => {
            const groupedResult: any = groupObjectsByTrmId(res.data.addenda);
            const reversed = groupedResult.reverse()
            setActoHistory(reversed)
            setActoHistoryClone(reversed)
            setActoHistoryLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }


    useEffect(() => {
        getActoHistory()
    }, []);

    const [actoHistory, setActoHistory] = useState()
    const [actoHistoryLoading, setActoHistoryLoading] = useState(true)
    const [actoHistoryClone, setActoHistoryClone] = useState()



    return (
        <section>
            <Toast ref={toast} />

            <div className='container-fluid'>
                <div className='flex justify-content-between'>
                    {/* cuando sean folios de calidad aparecera el color verde se mostrara como el div, si no. se negara el small y el div 0.1 siguiente */}

                    {previousFolio &&
                        <div className='flex col-12 md:col-4'>
                            {previousFolio[0] && previousFolio[0].prop_calidad == 1 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle verde'></i>
                                    <div className='line'></div>
                                </div>
                            }

                            {previousFolio[0] && previousFolio[0].prop_calidad == 2 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle rojo'></i>
                                    <div className='lineRed'></div>
                                </div>
                            }

                            {previousFolio[0] && previousFolio[0].prop_calidad == 3 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle naranja'></i>
                                    <div className='lineOrange'></div>
                                </div>
                            }

                            <div className={
                                (previousFolio[0] && previousFolio[0].prop_calidad == 1) ? 'verde' :
                                    (previousFolio[0] && previousFolio[0].prop_calidad == 2) ? 'rojo' :
                                        (previousFolio[0] && previousFolio[0].prop_calidad == 3) ? 'naranja' :
                                            ''
                            }>
                                <h3 className={
                                    (previousFolio[0] && previousFolio[0].prop_calidad == 1) ? 'verde' :
                                        (previousFolio[0] && previousFolio[0].prop_calidad == 2) ? 'rojo' :
                                            (previousFolio[0] && previousFolio[0].prop_calidad == 3) ? 'naranja' :
                                                ''
                                }>Folio {previousFolio[0] ? previousFolio[0].prop_folio : ''}</h3>
                                {previousFolio[0] && previousFolio[0].prop_calidad == 1 &&
                                    <small>Este es un folio de calidad</small>
                                }
                                {previousFolio[0] && previousFolio[0].prop_calidad == 2 &&
                                    <h6>Este es un folio cerrado</h6>
                                }
                                {previousFolio[0] && previousFolio[0].prop_calidad == 3 &&
                                    <h6>Advertencia</h6>
                                }
                                {((preTramite && preTramite.trm_id) || (preTramite && preTramite.trm_id)) &&
                                    <p className='pt-2 text-center black'>
                                        <small className='font-bold'>Control Interno ISRyC {(preTramite && preTramite.trm_consecutivo && `${preTramite.trm_consecutivo}`)}</small>
                                        <br />
                                        {((preTramite && preTramite.trm_id && `${formatDateHora(preTramite.trm_fecha, preTramite.trm_hora)}`))}
                                    </p>
                                }
                                {((preTramite && preTramite.ptrm_id && !preTramite.trm_id && preTramiteDetail)) &&
                                    <p className='pt-2 text-center black'>
                                        <small className='font-bold'>Control Interno Notaria {(preTramite && preTramite.ptrm_consecutivo && `${preTramite.ptrm_consecutivo}`)}</small>
                                        <br />
                                        {((preTramite && preTramite.ptrm_id && `${formatDateHora(preTramiteDetail.tramite.ptrm_fecha, preTramiteDetail.tramite.ptrm_hora)}`))}
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    {preTramiteFolio &&
                        <div className='flex col-12 md:col-4'>
                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle verde'></i>
                                    <div className='line'></div>
                                </div>
                            }

                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle rojo'></i>
                                    <div className='lineRed'></div>
                                </div>
                            }

                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle naranja'></i>
                                    <div className='lineOrange'></div>
                                </div>
                            }

                            <div className={
                                (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1) ? 'verde' :
                                    (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2) ? 'rojo' :
                                        (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3) ? 'naranja' :
                                            ''
                            }>
                                <h3 className={
                                    (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1) ? 'verde' :
                                        (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2) ? 'rojo' :
                                            (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3) ? 'naranja' :
                                                ''
                                }>Folio {preTramiteFolio[0] ? preTramiteFolio[0].prop_folio : ''}</h3>
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1 &&
                                    <small>Este es un folio de calidad</small>
                                }
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2 &&
                                    <h6>Este es un folio cerrado</h6>
                                }
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3 &&
                                    <h6>Advertencia</h6>
                                }
                                {((preTramite && preTramite.trm_id) || (preTramite && preTramite.trm_id)) &&
                                    <p className='pt-2 text-center black'>
                                        <small className='font-bold'>Control Interno ISRyC {(preTramite && preTramite.trm_consecutivo && `${preTramite.trm_consecutivo}`)}</small>
                                        <br />
                                        {((preTramite && preTramite.trm_id && `${formatDateHora(preTramite.trm_fecha, preTramite.trm_hora)}`))}
                                    </p>
                                }
                                {((preTramite && preTramite.ptrm_id && !preTramite.trm_id && preTramiteDetail)) &&
                                    <p className='pt-2 text-center black'>
                                        <small className='font-bold'>Control Interno Notaria {(preTramite && preTramite.ptrm_consecutivo && `${preTramite.ptrm_consecutivo}`)}</small>
                                        <br />
                                        {((preTramite && preTramite.ptrm_id && `${formatDateHora(preTramiteDetail.tramite.ptrm_fecha, preTramiteDetail.tramite.ptrm_hora)}`))}
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    <div className='col-12 md:col-6'>
                        {timeLine &&
                            <TimeLine timeLine={timeLine} />
                        }
                    </div>

                </div>

                <Messages ref={msgs} />

                <div className=''>
                    <Tabs>
                        <Tab label="Datos generales">
                            {previousFolio &&
                                <ResulGenerales
                                    folioDatos={previousFolio[0]}
                                    notaryList={notaryList}
                                    setNotaryList={setNotaryList}
                                    selectedNotaryList={selectedNotaryList}
                                    setSelectedNotaryList={setSelectedNotaryList}
                                    escrituraNumber={escrituraNumber}
                                    setEscrituraNumber={setEscrituraNumber}
                                    requestName={requestName}
                                    setRequestName={setRequestName}
                                    exist={responseData}
                                    preTramiteDetail={preTramiteDetail}
                                />
                            }{
                                preTramiteFolio &&
                                <ResulGenerales
                                    folioDatos={preTramiteFolio[0]}
                                    notaryList={notaryList}
                                    setNotaryList={setNotaryList}
                                    selectedNotaryList={selectedNotaryList}
                                    setSelectedNotaryList={setSelectedNotaryList}
                                    escrituraNumber={escrituraNumber}
                                    setEscrituraNumber={setEscrituraNumber}
                                    requestName={requestName}
                                    setRequestName={setRequestName}
                                    exist={preTramite}
                                    preTramiteDetail={preTramiteDetail}
                                />
                            }
                        </Tab>
                        {previousFolio &&
                            <Tab label="Actos" disabled={!responseData}>
                                <ResulActos
                                    previousFolio={previousFolio}
                                    previousPayments={previousPayments}
                                    actos={actosArray}
                                    actosList={actosList}
                                    actosFunction={setActosArray}
                                    responseData={responseData}
                                    preTramite={preTramite}
                                    requestActos={getActosInformation}
                                    total={totalActos}
                                    totalFunction={setTotalActos}
                                    preTramiteDetail={preTramiteDetail}

                                    actoHistory={actoHistory}
                                    setActoHistory={setActoHistory}
                                    actoHistoryClone={actoHistoryClone}
                                    actoHistoryLoading={actoHistoryLoading}
                                />
                            </Tab>
                        }
                        {preTramiteFolio &&
                            <Tab label="Actos">
                                <ResulActos
                                    previousFolio={previousFolio}
                                    previousPayments={previousPayments}
                                    actos={actosArray}
                                    actosList={actosList}
                                    actosFunction={setActosArray}
                                    responseData={responseData}
                                    preTramite={preTramite}
                                    requestActos={getActosInformation}
                                    total={totalActos}
                                    totalFunction={setTotalActos}
                                    preTramiteDetail={preTramiteDetail}

                                    actoHistory={actoHistory}
                                    setActoHistory={setActoHistory}
                                    actoHistoryClone={actoHistoryClone}
                                    actoHistoryLoading={actoHistoryLoading}
                                />
                            </Tab>
                        }
                        <Tab label="Titulares">
                            <ResulTitulares
                                previousFolio={previousFolio}
                                previousPayments={previousPayments}
                                responseData={responseData}
                                preTramite={preTramite}
                                preTramiteDetail={preTramiteDetail}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div >
        </section >
    );


}

export default React.memo(PagoFolioView);


