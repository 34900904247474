import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Timeline } from 'primereact/timeline';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import configuration from '../../../../../configurationFile';
import ApiServices from '../../../../../service/apiServices';
import authGuardService from '../../../../../service/authGuardService';
import EncryptService from '../../../../../service/encrypt.service';
import formatDate from '../../../../../utilities/formatDate';
import DynamicForm from '../../../../actos/DynamicForm';

interface City {
    name: string;
}

const TabHistorialActo = (props: any) => {

    const user = authGuardService.getUser()
    const toast = useRef<any>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [loading, setLoading] = useState(false)
    const [PDFLoading, setPDFLoading] = useState(false)
    const [value, setValue] = useState('')
    const [dynamicFormInformation, setDynamicFormInformation] = useState()
    const [visibleActoView, setVisibleActoView] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>('')
    const [dynamicInformationParent, setDynamicInformationParent] = useState()
    const [checkedCertificadas, setCheckedCertificadas] = useState(false)
    const [allHistroy, setAllHistory] = useState(props.actoHistory)

    const controlInternoBodyTemplate = (trm_fecha: any) => {
        const fechaUTC = new Date(trm_fecha);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();
        const fechaFormateada = `${day}/${month}/${year}`;

        return (
            <p>{`${fechaFormateada}`}</p>
        )
    }

    const dateTemplate = (rowData: any) => {
        return (
            <p>{rowData && formatDate(rowData.trm_fecha)}</p>
        );
    }

    const formuStatusTemplate = (rowData: any) => {
        return (
            < p > {rowData && rowData.frmu_status == 0 ? 'Borrado' :
                rowData.frmu_status == 1 ? 'Vigente' :
                    rowData.frmu_status == 2 ? 'No Vigente' :
                        rowData.frmu_status == 3 ? 'No Vigente' :
                            ''
            }</p>
        );
    }

    const customizedContent = (item: any) => {
        return (
            <div className=''>
                <div className='grid'>
                    <div className='col-12'>
                        <Panel headerTemplate={
                            <div className='p-panel-header block'>
                                <div className='grid'>
                                    <div className='col-3'> <p><span className='font-bold'>Fecha de registro: </span>{controlInternoBodyTemplate(item.trm_fecha)}</p></div>
                                    <div className='col-3'>  <p><span className='font-bold'>Tramite Consecutivo:</span>{item && item.history && item.history.length > 0 ? ` ${item.history[0].trm_consecutivo}` : ''} </p></div>
                                    <div className='col-3'>  <p><span className='font-bold'>Numero de Escritura:</span>{item && item.history && item.history.length > 0 ? ` ${item.history[0].trm_escritura}` : ''} </p></div>
                                    {
                                        item && item.history && item.history.length > 0 && item.history[0].trm_id_padre &&
                                        <div className='col-3'><span className='font-bold'><p>Multiples Folios</p></span></div>
                                    }

                                </div>
                            </div>
                        }>
                            <DataTable
                                value={item.history}
                                dataKey="id"
                                paginator
                                rows={5}
                                className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                emptyMessage="No products found."
                            >
                                <Column field='trm_consecutivo' header="# Consecutivo" sortable></Column>
                                <Column field='trm_fecha' header="Fecha" sortable body={dateTemplate}></Column>
                                <Column field="act_clave" header="Clave" sortable ></Column>
                                <Column field="act_nombre" header="Acto" sortable ></Column>
                                <Column field="name" body={FirmadoTemplate} ></Column>
                                <Column field="frmu_status" body={formuStatusTemplate} ></Column>
                                <Column field="price" style={{ width: '10rem' }} body={EyeTemplate} ></Column>
                            </DataTable>
                        </Panel>

                    </div>
                </div>

            </div>
        );
    };

    const FirmadoTemplate = (rowData: any) => {
        return (
            <p>{rowData && (rowData.trm_status == 9 || rowData.trm_status == 10) ? 'Firmado' : 'Sin Firmar'}</p>
        );
    }

    const SavePDFinsc = async (trm: any) => {
        setPDFLoading(true)
        let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: trm }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    setPDFLoading(false)
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'PDF no disponible', life: 3000 });
                } else {
                    setPDFLoading(false)
                    if (typeof res.data.url === 'object') {
                        if (res.data.url && res.data.url.length > 0) {
                            res.data.url.forEach((single: any) => {
                                window.open(`${configuration.url}${single.url}`, '_blank');
                            });
                        }
                    } else {
                        window.open(`${configuration.url}${res.data.url}`, '_blank')
                    }
                }
            }
        }).catch(error => {
            setPDFLoading(false)
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error en el servidor, intente de nuevo mas tarde', life: 3000 });
        })
    }

    const searchFormulario = (tramite: any) => {
        setLoading(true)
        let datos = {
            ptrm_id: 0,
            trm_id: tramite.trm_id
        }
        ApiServices.apiPost('getFormatosResp', datos).then(async res => {
            if (res.data.valido === 1 && res.data.addenda.length > 0) {
                const found = res.data.addenda.find((objeto: any) => objeto.act_id === tramite.act_id);
                if (found && found.frmu_id) {
                    let datos = {
                        act_id: tramite.act_id
                    }
                    ApiServices.apiPost('getCampos', datos).then(async res => {
                        setDynamicFormInformation(res.data.addenda)
                        let merged = { ...tramite };
                        merged = { ...merged, ...found };
                        setSelected(merged)
                        setVisibleActoView(true)
                        setLoading(false)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            } else {
                setLoading(false)
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Formulario no encontrado', life: 3000 });
            }
        }).catch(error => {
            setLoading(false)
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error en el servidor, intente de nuevo mas tarde', life: 3000 });
        })
    }

    const EyeTemplate = (rowData: any) => {
        return (
            <div className='text-center'>
                <Button className="p-button-text" icon="pi pi-eye" onClick={() => { searchFormulario(rowData) }} loading={loading} />
                <Button className="p-button-text" icon="pi pi-file-pdf" onClick={() => { SavePDFinsc(rowData.trm_id) }} loading={PDFLoading} />
            </div>
        );
    }

    const searchInside = (value: any) => {
        if (value && value.length > 2) {
            const newElements = buscarPalabra(allHistroy, value)
            setAllHistory(newElements)
        } else if (value == '') {
            props.setAllHistory(props.actoHistoryClone)
        }
    }

    function buscarPalabra(array: any, palabra: any) {
        let resultados: any = [];
        palabra = palabra.toLowerCase();
        array.forEach((obj: any) => {
            const keys = Object.keys(obj);
            const values = Object.values(obj).map(value => {
                return typeof value === 'string' ? value.toLowerCase() : value;
            });

            if (keys.some(key => key.toLowerCase().includes(palabra)) || values.some(value => {
                if (typeof value === 'string') {
                    return value.includes(palabra);
                }
                return false;
            })) {
                resultados.push(obj);
            }

            obj.history.forEach((historyObj: any) => {
                const historyKeys = Object.keys(historyObj);
                const historyValues = Object.values(historyObj).map(value => {
                    return typeof value === 'string' ? value.toLowerCase() : value;
                });

                if (historyKeys.some(key => key.toLowerCase().includes(palabra)) || historyValues.some(value => {
                    if (typeof value === 'string') {
                        return value.includes(palabra);
                    }
                    return false;
                })) {
                    resultados.push(obj);
                }
            });
        });

        return resultados;
    }

    function buscarPorFecha(fecha: any) {
        if (fecha) {
            const resultados = allHistroy.filter((obj: any) => {
                const fechaObjeto = new Date(Date.UTC(
                    obj.trm_fecha.substring(0, 4),
                    obj.trm_fecha.substring(5, 7) - 1,
                    obj.trm_fecha.substring(8, 10),
                    0, 0, 0
                ));
                return fechaObjeto.getUTCDate() === fecha.getUTCDate() &&
                    fechaObjeto.getUTCMonth() === fecha.getUTCMonth() &&
                    fechaObjeto.getUTCFullYear() === fecha.getUTCFullYear();
            });
            setAllHistory(resultados);
        }
    }

    function filtrarPorFrmuStatus(data: any) {
        setChecked(!checked)

        if (!checked) {
            const newData = allHistroy.filter((obj: any) => {
                const alMenosUnoFrmuUno = obj.history.some((historyObj: any) => historyObj.frmu_status === 1);
                return alMenosUnoFrmuUno;
            }).map((obj: any) => ({
                ...obj,
                history: obj.history.filter((historyObj: any) => historyObj.frmu_status === 1)
            }));

            setAllHistory(newData)
        } else {
            setAllHistory(props.actoHistoryClone)
        }
    }

    useEffect(() => {
        if (props.actoHistory)
            filtrarPorCertificados(props.actoHistory, false)
    }, [props.actoHistory])

    function eliminarObjetosConActClaveCI(data) {
        return data
            .map(entry => {
                const newHistory = entry.history.filter(item => item.act_clave && !item.act_clave.startsWith('CI'));
                return { ...entry, history: newHistory };
            })
            .filter(entry => entry.history.length > 0);
    }

    function filtrarObjetosConActClaveCI(data) {
        return data
            .map(entry => {
                const newHistory = entry.history.filter(item => item.act_clave && item.act_clave.startsWith('CI'));
                return { ...entry, history: newHistory };
            })
            .filter(entry => entry.history.length > 0);
    }


    function filtrarPorCertificados(data = props.actoHistoryClone, checkedValue: boolean) {
        if (checkedValue) {
            const newArray = filtrarObjetosConActClaveCI(props.actoHistoryClone)
            setAllHistory(newArray)
            setCheckedCertificadas(true)
        } else {
            const newArray = eliminarObjetosConActClaveCI(props.actoHistoryClone)
            setAllHistory(newArray)
            setCheckedCertificadas(false)
        }
    }


    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            setDate2(new Date(formattedDate));
            buscarPorFecha(new Date(formattedDate))
        } else {
            setDate2(null);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };

    function transformarArray(arr: any) {
        const result: any = [];

        arr.reverse().forEach((element: any) => {
            const firstDate = element.history[0].trm_fecha;

            element.history.forEach((historyElement: any) => {
                if (historyElement === element.history[0]) {
                    result.push({ ...historyElement, firstDate });
                } else {
                    result.push({ ...historyElement, firstDate: undefined });
                }
            });
        });

        return result;
    }


    return (
        <section>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-3'>
                    <h3 className='color-violet'>Historial de actos</h3>
                    <p>Busca los actos con nuestro buscador</p>
                </div>
                <div className='col-12 md:col-2'>
                    <div className='pr-5'>
                        <Checkbox inputId="ingredient1" checked={checked} onChange={filtrarPorFrmuStatus} />
                        <label htmlFor="ingredient1" className="ml-2">Filtrar vigentes</label>
                    </div>
                </div>
                <div className='col-12 md:col-2'>
                    <div className='pr-5'>
                        <Checkbox inputId="Certificados" checked={checkedCertificadas} onChange={() => { filtrarPorCertificados(props.actoHistoryClone, !checkedCertificadas) }} />
                        <label htmlFor="Certificados" className="ml-2">Mostrar Certificados</label>
                    </div>
                </div>
                <div className='col-12 md:col-3'>
                    <span className="p-float-label">
                        {/* Input Done */}
                        <Calendar
                            value={date2}
                            onChange={(e: any) => {
                                buscarPorFecha(e.value)
                                setDate2(e.value)
                            }}
                            dateFormat="dd/MM/yy" showIcon
                            className="p-inputtext-lg"
                            showButtonBar
                            locale="es"
                            onClearButtonClick={() => {
                                setAllHistory(props.actoHistoryClone)
                            }}
                            ref={calendarRef}
                            onInput={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="username">Fecha</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label p-input-icon-left w-100">
                        <i className="pi pi-search" />
                        <InputText
                            className=' p-inputtext-lg'
                            type="search"
                            onChange={(e) => { setValue(e.target.value) }}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    searchInside(value)
                                }
                            }}
                        />
                        <label htmlFor="username">Buscador general</label>
                    </span>
                </div>
            </div>

            <div className='pt-3'>
                <Timeline
                    value={allHistroy}
                    content={customizedContent}
                />
            </div>

            {/* Modal Ver Acto */}
            <Dialog
                header={`${(selected && selected.act_nombre) || ''}`}
                maximizable
                visible={visibleActoView}
                style={{ width: '50%' }}
                onHide={() => setVisibleActoView(false)}
            >
                <DynamicForm
                    informationForm={dynamicFormInformation}
                    information={selected}
                    setDynamicInformationParent={setDynamicInformationParent}
                    generalData={props.generalData}
                    actoHistory={transformarArray(props.actoHistory)}
                    actoHistoryClone={transformarArray(props.actoHistoryClone)}
                />
            </Dialog>
        </section>
    );

}

export default React.memo(TabHistorialActo);