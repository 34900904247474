import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import { Toast } from 'primereact/toast';
import authGuardService from '../../../service/authGuardService';

const VenBuscadorMorales = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const toast = useRef<any>(null);

    const user = authGuardService.getUser()

    const onlyView = location.state && location.state.onlyView ? true : false
    const propState = location.state && location.state.folio ? true : false

    useEffect(() => {
        if (location && location.state && location.state.folio) {
            setValues('folioReal', location.state.folio)
        }
    }, [location])

    const [data, setData]: any = useState({
        rfc: { value: '', valid: true },
        fepm: { value: '', valid: true },
        denominacionSocial: { value: '', valid: true },
        direccion: { value: '', valid: true },
        antecedentes: { value: '', valid: true }
    })

    const [buttonDisabled, setButtonDisabled] = useState(true)

    const setValues = (jsonValue: any, value: any) => {
        const cloneJson = JSON.parse(JSON.stringify(data))
        cloneJson[jsonValue].value = value
        setData(cloneJson)

        setButtonDisabled(false)
    }

    const [notFound, setNotFound] = useState(false)

    const valueExist = () => {
        return (
            (data.rfc.value && data.rfc.value.length > 0) ||
            (data.fepm.value && data.fepm.value.length > 0) ||
            (data.denominacionSocial.value && data.denominacionSocial.value.length > 0) ||
            (data.direccion.value && data.direccion.value.length > 0) ||
            (data.antecedentes.value && data.antecedentes.value.length > 0) ||
            false
        )
    }

    const searchFolio = () => {
        if (valueExist()) {
            let datos
            datos = {
                code: 801,
                per_rfc: data.rfc.value,
                per_folio: data.fepm.value,
                per_razonsocial: data.denominacionSocial.value,
                per_direccion: data.direccion.value,
                per_antecedente: data.antecedentes.value
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    let url: any
                    let exist = true
                    const navigateOptions = {
                        state: {
                            pagos: (location.state && location.state.pagos) ? location.state.pagos : [],
                            folios: res.data.addenda,
                            preLoadActos: (location.state && location.state.actos) ? location.state.actos : [],
                            onlyView
                        }
                    };
                    if (res.data.addenda && res.data.addenda.length > 1) {
                        url = '/ventanilla/dup-folio'
                    } else if (onlyView) {
                        url = '/ventanilla/view-folio-morales'
                    } else if (res.data.addenda && res.data.addenda.length === 1) {
                        url = '/ventanilla/vt-tramite'
                    }
                    if (exist)
                        navigate(url, navigateOptions)
                    else
                        setNotFound(true)
                } else {
                    setNotFound(true)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            setButtonDisabled(true)
        }
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />

            <div className='col-12 md:col-12 pb-5'>
                <h3 className='color-violet'>Buscar Personas Morales</h3>
                <p>Ingresa los datos en los campos necesarios para realizar la búsqueda</p>
            </div>

            {notFound &&
                <div className='col-12 md:col-12 pb-5'>
                    <div className="grid grid-nogutter" style={{ background: '#F4CBB4', borderRadius: 10 }}>
                        <div className="col">
                        </div>
                        <div className="col-10" style={{ display: 'flex' }}>
                            <div style={{ width: '1%', height: '100%', background: '#D34C00', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }} />
                            <div style={{ width: '85%', height: '8rem', marginLeft: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <span style={{ color: '#D34C00', fontSize: 24, fontFamily: 'Cabin', fontWeight: '700', wordWrap: 'break-word' }}>
                                    Datos no encontrados
                                </span>
                                <span style={{ color: '#5B5B5F', fontSize: 16, fontFamily: 'Cabin', fontWeight: '400', wordWrap: 'break-word' }}>
                                    No se encontraron coincidencias en el sistema. rectifica si existe en otro lugar dentro del instituto y genera un nuevo folio para darlo de alta en el sistema.
                                </span>
                            </div>
                        </div>
                        <div className="col-2">
                            <div style={{ width: '90%', height: '8rem', justifyContent: 'flex-end', alignItems: 'center', display: 'flex', marginRight: '1rem' }}>
                                {(user && user.usu_nuevofolio) ?
                                    <Button label="Generar Nuevo Folio" style={{
                                        padding: 16, color: 'white',
                                        fontSize: 15,
                                        fontFamily: 'Cabin',
                                        fontWeight: '400',
                                        wordWrap: 'break-word',
                                    }} onClick={() => {
                                        navigate('/ventanilla/new-folio', {
                                            state: {
                                                folio: data.folioReal.value
                                            }
                                        })
                                    }} />
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className='flex justify-content-center align-items-center'>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.rfc.value}
                                onChange={(e) => setValues('rfc', e.target.value)}
                                id="rfc"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="rfc">RFC</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.fepm.value}
                                onChange={(e) => setValues('fepm', e.target.value)}
                                id="fepm"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="fepm">Folio Electronico Personas Morales</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.denominacionSocial.value}
                                onChange={(e) => setValues('denominacionSocial', e.target.value)}
                                id="denominacionSocial"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="denominacionSocial">Denominacion Social</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.direccion.value}
                                onChange={(e) => setValues('direccion', e.target.value)}
                                id="direccion"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="direccion">Direccion</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <InputText
                                value={data.antecedentes.value}
                                onChange={(e) => setValues('antecedentes', e.target.value)}
                                id="antecedentes"
                                className="p-inputtext-lg"
                            />
                            <label htmlFor="antecedentes">Antecedentes</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-2 pt-8'>
                        <Button
                            label="Buscar"
                            disabled={buttonDisabled}
                            onClick={() => {
                                searchFolio()
                            }}
                        />
                    </div>
                </div>
            </div>
        </section >

    );

}

export default React.memo(VenBuscadorMorales);