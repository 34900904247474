import { useNavigate } from 'react-router-dom';
import AppMenuPublic from './VerticalMenu/AppMenuPublic';
import { classNames } from 'primereact/utils';
import authGuardService from '../../../service/authGuardService';
import { Button } from 'primereact/button';

const TopBarPublic = (props: any) => {

    const onTopbarSubItemClick = (event: any) => {
        authGuardService.logout()
        event.preventDefault();
        navigate('/login')
    }

	const navigate = useNavigate();

    return (

        <div className="layout-topbar">
            <div className="layout-topbar-left" >
                <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-bars"></i>
                </button>

                <button className="p-link" onClick={() => navigate('/')}>
                    <img className='logo' src={`/assets/img/logo.png`} alt="logo" />
                </button>
            </div>

            <AppMenuPublic model={props.items} menuMode={props.menuMode} colorScheme={props.colorScheme} menuActive={props.menuActive} activeInlineProfile={props.activeInlineProfile}
                onSidebarMouseOver={props.onSidebarMouseOver} onSidebarMouseLeave={props.onSidebarMouseLeave} toggleMenu={props.onToggleMenu} onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                onMenuClick={props.onMenuClick} onRootMenuItemClick={props.onRootMenuItemClick} onMenuItemClick={props.onMenuItemClick} />

            <div className="layout-topbar-right flex">
                <div className='pr-3'>
                    <Button label="Iniciar sesión" className='p-button-lg' onClick={() => { navigate("/login") }} />
                </div>
                <div>
                    <Button label="Regístrate" className='p-button-lg p-button-white' onClick={() => { navigate("/registro") }} />
                </div>
            </div>
        </div >
    )
}

export default TopBarPublic;