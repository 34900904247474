import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import TabGenerales from './TabViewFolio/generales';
import TabActos from './TabViewFolio/actos/ActosCaptura';
import TabTitulares from './TabViewFolio/titulares';
import TabNotificador from './TabViewFolio/notificador';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ProductService from '../../../../serviceExample/ProductService';
import EncryptService from '../../../../service/encrypt.service';
import ApiServices from '../../../../service/apiServices';
import authGuardService from '../../../../service/authGuardService';
import configuration from '../../../../configurationFile';
import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Messages } from 'primereact/messages';
import EditCaratula from './EditCaratula';
import { Steps } from 'primereact/steps';
import './viewFolio.css'
import Tabs from '../../../../Components/Tabs/Tabs';
import Tab from '../../../../Components/Tabs/Tab';
import { Chip } from 'primereact/chip';
import TimeLine from '../../../../Components/TimeLine/TimeLine';
import AgregarActo from '../../../../Components/AgregarActo';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

interface City {
    name: string;
}

const IntViewFolio = () => {

    const location = useLocation()
    const user = authGuardService.getUser()

    const previousFolio = location.state.folio

    const [tramiteDetail, setTramiteDetail] = useState<any>()
    const [propiedadDetail, setPropiedadDetail] = useState<any>()
    const [actosListOriginal, setActosListOriginal] = useState([])
    const [actosArray, setActosArray] = useState<any>([])
    const [formattedActos, setFormattedActos] = useState<any>([])
    const [totalActos, setTotalActos] = useState(0)
    const [actoHistory, setActoHistory] = useState()
    const [actoHistoryClone, setActoHistoryClone] = useState()
    const [titulares, setTitulares] = useState([])
    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const [notifications, setNotifications] = useState<any>()
    const menu = useRef<any>(null);
    const [date, setDate] = useState<any>(null);
    const msgs1 = useRef<any>(null);
    const [timeLine, setTimeLine] = useState<any>()

    useEffect(() => {
        getTramiteDetail()
        getPropiedadDetail()
        getActosList()
        getActoHistory()
        getTitulares()
        getNotifications()
        getTimeLine()
    }, [])

    const getTimeLine = () => {
        let datos = {
            trm_id: previousFolio.trm_id,
        }
        ApiServices.apiPost('getTimeLine', datos).then(async res => {
            if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                setTimeLine(res.data.addenda[0])
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (tramiteDetail)
            handlePreviousActos()
    }, [actosListOriginal])

    useEffect(() => {
        if (actosArray && tramiteDetail)
            getActosResponse()
    }, [actosArray, tramiteDetail])

    const getTramiteDetail = () => {
        let datos
        datos = { code: 204, trm_id: previousFolio.trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                setTramiteDetail(res.data.addenda)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const getPropiedadDetail = () => {
        let datos = {
            prop_id: previousFolio.prop_id,
        }
        ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
            if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                const codigoPostal = res && res.data && res.data.addenda && res.data.addenda[0] && res.data.addenda[0].cp_cp &&
                    (typeof res.data.addenda[0].cp_cp === 'object'
                        ? (res.data.addenda[0].cp_cp.length > 1
                            ? res.data.addenda[0].cp_cp[1]
                            : res.data.addenda[0].cp_cp[0])
                        : res.data.addenda[0].cp_cp);

                const newClone = { ...res.data.addenda[0], ['cp_cp']: codigoPostal }
                setPropiedadDetail(newClone)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const getActosList = async () => {
        let datos
        const res1 = await ApiServices.apiPost('catActo', datos);
        setActosListOriginal(res1.data.addenda);
    }

    const getActosResponse = () => {
        let datos = {
            ptrm_id: 0,
            trm_id: previousFolio.trm_id
        }
        ApiServices.apiPost('getFormatosResp', datos).then(async res => {
            if (res.data.valido === 1 && res.data.addenda.length > 0) {
                const newArray: any = []

                res.data.addenda.map(async (singleElement: any) => {
                    if (singleElement.trm_id === previousFolio.trm_id) {
                        const cloned = JSON.parse(JSON.stringify(singleElement))
                        newArray.push(cloned)
                    }
                })
                matchSavedActos(newArray)
            } else {
                matchSavedActos(tramiteDetail.actos)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    function findByActId(array: any, targetActId: any) {
        return array.find((item: any) => item.act_id == targetActId);
    }

    function mergeArraysByActId(array1: any, array2: any) {
        const mapArray2 = new Map(array2.map((obj: any) => [obj.act_id, obj]));
        const mergedArray = array1.map((obj1: any) => {
            const obj2 = mapArray2.get(obj1.act_id);
            return obj2 && typeof obj1 === 'object' && typeof obj2 === 'object' ? { ...obj1, ...obj2 } : obj1;
        });
        return mergedArray;
    }

    const matchSavedActos = (newActos: any) => {
        let newArray = []
        if (newActos && newActos.length > 0) {
            let total = 0;
            const matchedActos = newActos.map((singleActo: any) => {
                let formatted
                const cloneSingleActo = JSON.parse(JSON.stringify(singleActo))
                const match = findByActId(actosListOriginal, cloneSingleActo.act_id)
                if (match) {
                    cloneSingleActo.act_umas = match.act_umas
                    cloneSingleActo.act_dias = match.act_dias
                    cloneSingleActo.cnpg_id = match.cnpg_id
                    cloneSingleActo.area_id = match.area_id
                    cloneSingleActo.act_status = match.act_status
                    cloneSingleActo.act_update = match.act_update
                    cloneSingleActo.act_pesos = match.valormx

                    if (match.valormx) {
                        total = total + parseFloat(match.valormx || 0)
                    }
                    formatted = cloneSingleActo
                } else {
                    const match = findByActId(actosListOriginal, cloneSingleActo.acto_id)
                    cloneSingleActo.act_pesos = match.valormx || 0
                    formatted = { ...cloneSingleActo, ...match }
                }
                return formatted
            })
            setTotalActos(total)
            matchRejectedActos(matchedActos)
        }
    }

    const matchRejectedActos = (list: any) => {
        if (tramiteDetail && tramiteDetail.rechazos && tramiteDetail.rechazos.length > 0) {
            const resultado = list.map((singleItem1: any) => {
                const match = tramiteDetail.rechazos.find((singleItem2: any) => singleItem1.act_id == singleItem2.act_id);
                if (match) {
                    return { ...singleItem1, ...match };
                }
                return singleItem1;
            });
            setFormattedActos(resultado)
        } else {
            setFormattedActos(list)
        }
    }

    const handlePreviousActos = () => {
        const arrayListSaved = tramiteDetail.actos

        if (arrayListSaved && arrayListSaved.length > 0) {
            arrayListSaved.map((singleAct: any) => {
                const foundItem = actosArray.find((item: any) => item.act_id == singleAct.acto_id);
                if (!foundItem) {
                    const elementToPush = actosListOriginal.find((item: any) => item.act_clave == singleAct.acto_id);
                    const newArray = JSON.parse(JSON.stringify(actosArray))
                    newArray.push(elementToPush)
                    setActosArray(newArray)
                }
            })
        }
    }

    function groupObjectsByTrmId(inputArray: any) {
        const groupedObjects: any = {};

        inputArray.forEach((obj: any) => {
            const trmId = obj.trm_id;

            if (!groupedObjects[trmId]) {
                groupedObjects[trmId] = {
                    trm_fecha: obj.trm_fecha,
                    trm_id: trmId,
                    trm_consecutivo: obj.trm_consecutivo,
                    history: []
                };
            }

            groupedObjects[trmId].history.push(obj);
        });

        return Object.values(groupedObjects);
    }

    function transformarArray(arr: any) {
        const result: any = [];

        arr.reverse().forEach((element: any) => {
            const firstDate = element.history[0].trm_fecha;

            element.history.forEach((historyElement: any) => {
                if (historyElement === element.history[0]) {
                    result.push({ ...historyElement, firstDate });
                } else {
                    result.push({ ...historyElement, firstDate: undefined });
                }
            });
        });

        return result;
    }


    const getActoHistory = () => {
        let datos = {
            prop_folio: previousFolio.prop_folio
        }
        ApiServices.apiPost('getHistorial', datos).then(async res => {
            const groupedResult: any = groupObjectsByTrmId(res.data.addenda);
            const newArray = transformarArray(groupedResult)
            setActoHistory(newArray)
            setActoHistoryClone(newArray)
        }).catch(error => {
            console.log(error)
        })
    }

    const getTitulares = () => {
        let datos = {
            prop_id: previousFolio.prop_id
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getNotifications = () => {
        let datos
        datos = { code: 501, prop_id: previousFolio.prop_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                setNotifications(res.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const searchFolio = () => {
        let datos
        datos = {
            code: 208,
            prop_clave: propiedadDetail.prop_clave,
            prop_folio: propiedadDetail.prop_folio,
            ptit_nom: '',
            ptit_pat: '',
            ptit_mat: '',
            prop_direccion: propiedadDetail.prop_direccion,
            prop_calle: propiedadDetail.prop_calle,
            prop_ext: propiedadDetail.prop_ext,
            prop_int: propiedadDetail.prop_int
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                if (res.data.addenda && res.data.addenda.length > 0) {
                    navigate('/interno/dup-folio', {
                        state: {
                            folios: res.data.addenda
                        }
                    })
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const SavePDFinsc = async (trm: any) => {
        let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: trm.trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    if (typeof res.data.url === 'object') {
                        if (res.data.url && res.data.url.length > 0) {
                            res.data.url.forEach((single: any) => {
                                window.open(`${configuration.url}${single.url}`, '_blank');
                            });
                        }
                    } else {
                        window.open(`${configuration.url}${res.data.url}`, '_blank')
                    }
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }


    function obtenerFechaYHoraActualEnFormato() {
        const mesesEnEspanol = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const fechaActual = new Date();
        const dia = fechaActual.getDate();
        const mes = mesesEnEspanol[fechaActual.getMonth()];
        const anio = fechaActual.getFullYear();
        let hora = fechaActual.getHours();
        const minutos = fechaActual.getMinutes();
        let periodo = "am";

        // Convertir la hora a formato de 12 horas y ajustar el periodo (am/pm)
        if (hora >= 12) {
            periodo = "pm";
            if (hora > 12) {
                hora -= 12;
            }
        }

        // Agregar un cero al principio si los minutos son menores a 10
        const minutosFormateados = (minutos < 10) ? "0" + minutos : minutos;

        const fechaYHoraFormateada = `${dia} ${mes} ${anio} a las ${hora}:${minutosFormateados} ${periodo}`;
        return fechaYHoraFormateada;
    }

    const generateDuplicateAlert = () => {
        let datos
        datos = { "code": 212, trm_id: previousFolio.trm_id, obs_tipo: selectedCity.type, obs_nota: note, usu_id: user.usu_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Observacion Enviada con Exito', life: 3000 });
                ObservacionVisible(false)
            }
        }).catch(error => {
            console.log(error)
        })
    }





    /* OLD */


    const toast = useRef<any>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [Observacion, ObservacionVisible] = useState<boolean>(false);
    const [impresion, ImpresionVisible] = useState<boolean>(false);
    const buttonEl = useRef<any>(null);
    const buttonConfirm = useRef<any>(null);
    const [selectedCity, setSelectedCity] = useState<any>(null);
    const [selectedImpre, setSelectedImpre] = useState<City | null>(null);
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [products, setProducts] = useState([]);
    const productService = new ProductService();
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [displayACFolio, setDisplayACFolio] = useState(false);

    const [displayALFolio, setDisplayALFolio] = useState(false);
    const [displayCAFolio, setDisplayCAFolio] = useState(false);
    const [displayMCFolio, setDisplayMCFolio] = useState(false);
    const [displayAHFolio, setDisplayAHFolio] = useState(false);

    const events1 = [
        { label: 'Capturado' },
        { label: 'Asignado' },
        { label: 'Analizando' },
        { label: 'A-R-C' },
        { label: 'Firmado' }
    ];

    const events2 = [
        /*  timeLine.trm_firmado ? timeLine.trm_firmador :
             timeLine.statusARC ? timeLine.statusARC :
                 timeLine.trm_analizado ? timeLine.trm_analizado :
                     timeLine.analista ? timeLine.analista :
                         timeLine.capturista ? timeLine.capturista
                             : 0 */
        { label: 'Capturado', tooltip: timeLine ? timeLine.capturista : '', time: timeLine ? timeLine.trm_fecha : '' },
        { label: 'Asignado', tooltip: timeLine ? timeLine.analista : '' },
        { label: 'Analizando', tooltip: '', time: timeLine ? timeLine.trm_analizado : '' },
        { label: 'A-R-C', tooltip: timeLine ? timeLine.statusARC : '', time: timeLine ? timeLine.fechahoraARC : '' },
        { label: 'Firmado', tooltip: timeLine ? timeLine.trm_firmador : '', time: timeLine ? timeLine.trm_firmado : '' }
    ];

    const motivo = [
        { name: 'Duplicidad de folio', type: 1 },
        { name: 'Folio conflictivo', type: 2 },
    ];

    const impre: City[] = [
        { name: 'Actos vigentes resumido' },
        { name: 'Actos vigentes con detalle' },
        { name: 'Historial de actos resumido' },
        { name: 'Historial de actos con detalle' },
    ];

    useEffect(() => {
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    const accept = () => {
        ModalSetVisible(true)
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado tu trámite', life: 3000 });
    }

    const customizedMarker = (item: any) => {
        return (
            <div>
                {/* circulo vasio: se utilizara cuando no tiene un proceso todavia realizado */}
                <i className='pi pi-circle'></i>
                {/* circulo lleno: aparecera cuando el estatus se valla llenando, en cada punto tiene que aparecer un toolpin con el estatus de quien lo reviso y la hora de movimiento */}
                <Button className='p-button-text tooltip-view' type="button" icon="pi pi-circle-fill" tooltipOptions={{ position: 'bottom' }} />
                <Tooltip target=".tooltip-view" autoHide={false}>
                    <div className="p-3">
                        <h5>Capturado</h5>
                        <p>Juan Montes Torres <br />
                            <small>12 Marz 2022 12:00pm</small></p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => { ObservacionVisible(false) }} autoFocus />
            <Button label="Guardar" onClick={generateDuplicateAlert} autoFocus />
        </div>
    );

    const footerImpre = (
        <div>
            <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
            <Button label="Descargar" onClick={() => { { setVisible(false) } }} autoFocus />
        </div>
    );

    const CheckBodyTemplate = (product: Product) => {
        return (
            <div>
                <Checkbox checked={checked}></Checkbox>
            </div>
        );
    };

    const handleSentRevision = () => {
        let datos
        datos = { "code": 504, trm_id: [previousFolio.trm_id], usu_id: user.usu_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                ModalSetVisible(true)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const acceptConfirm = () => {
        handleSentRevision()
        setVisibleConfirm(false)
    };

    const rejectConfirm = () => {
        setVisibleConfirm(false)
    };

    const items = [
        /* {
            label: 'Abrir / Cerrar folio',
            command: () => { setDisplayACFolio(true) },
        },
        {
            label: 'Alertar folio',
            command: () => { setDisplayALFolio(true) },
        },
        {
            label: 'Folio de calidad',
            command: () => { setDisplayCAFolio(true) },
        }, */
        {
            label: 'Modificación caratula',
            command: () => { setDisplayMCFolio(true) },
        },
        {
            label: 'Agregar Actos',
            command: () => { setDisplayAHFolio(true) },
        },
    ]

    const renderFooter = (
        <div>
            <Button label="Cancelar" onClick={() => { ObservacionVisible(false) }} autoFocus />
            <Button label="Guardar" onClick={generateDuplicateAlert} autoFocus />
        </div>
    );

    const [note, changeNote] = useState('')

    function formatDate(date: any, hours: any) {
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const newDate = new Date(date);
        const newHour = new Date(hours);
        const day = newDate.getUTCDate();
        const month = months[newDate.getUTCMonth()];
        const year = newDate.getUTCFullYear();
        const hour = newHour.getUTCHours();
        const minutes = newHour.getUTCMinutes();

        const fechaFormateada = `${day} ${month} ${year} a las ${hour}:${minutes}`;
        return fechaFormateada;
    }

    return (
        <section>
            <Toast ref={toast} />
            <div className='container-fluid'>
                <div className='flex justify-content-between'>
                    {/* cuando sean folios de calidad aparecera el color verde se mostrara como el div, si no. se negara el small y el div 0.1 siguiente */}

                    <div className='flex col-12 md:col-4'>
                        {propiedadDetail && propiedadDetail.prop_calidad == 1 &&
                            <div className='text-center'>
                                <i className='pi pi-check-circle verde'></i>
                                <div className='line'></div>
                            </div>
                        }

                        {propiedadDetail && propiedadDetail.prop_calidad == 2 &&
                            <div className='text-center'>
                                <i className='pi pi-check-circle rojo'></i>
                                <div className='lineRed'></div>
                            </div>
                        }

                        {propiedadDetail && propiedadDetail.prop_calidad == 3 &&
                            <div className='text-center'>
                                <i className='pi pi-check-circle naranja'></i>
                                <div className='lineOrange'></div>
                            </div>
                        }

                        <div className={
                            (propiedadDetail && propiedadDetail.prop_calidad == 1) ? 'verde' :
                                (propiedadDetail && propiedadDetail.prop_calidad == 2) ? 'rojo' :
                                    (propiedadDetail && propiedadDetail.prop_calidad == 3) ? 'naranja' :
                                        ''
                        }>
                            <h3 className={
                                (propiedadDetail && propiedadDetail.prop_calidad == 1) ? 'verde' :
                                    (propiedadDetail && propiedadDetail.prop_calidad == 2) ? 'rojo' :
                                        (propiedadDetail && propiedadDetail.prop_calidad == 3) ? 'naranja' :
                                            ''
                            }>Folio {propiedadDetail ? propiedadDetail.prop_folio : ''}</h3>
                            {propiedadDetail && propiedadDetail.prop_calidad == 1 &&
                                <small>Este es un folio de calidad</small>
                            }
                            {propiedadDetail && propiedadDetail.prop_calidad == 2 &&
                                <h6>Este es un folio cerrado</h6>
                            }
                            {propiedadDetail && propiedadDetail.prop_calidad == 3 &&
                                <h6>Advertencia</h6>
                            }
                            {(previousFolio && previousFolio.trm_id) &&
                                <>
                                    <p className='pt-2 text-center black'>
                                        <small className='font-bold'>{`Control Interno - ${tramiteDetail && tramiteDetail.tramite.trm_consecutivo}`}</small>
                                        <br />
                                        {tramiteDetail ? `${formatDate(tramiteDetail.tramite.trm_fecha, tramiteDetail.tramite.trm_hora)}` : ''}
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                    <div className='col-12 md:col-6'>
                        {timeLine &&
                            <TimeLine timeLine={timeLine} />
                        }
                    </div>
                </div>

                <div className=''>
                    <Messages ref={msgs1} />
                </div>

                <div className='flex justify-content-end flex-wrap'>
                    <div className='position-btns'>
                        <div className='flex justify-content-end flex-wrap'>
                            {user && user.usu_edicionfolio == 1 &&
                                <div className='pr-3'>
                                    <Menu model={items} popup ref={menu} id="popup_menu" />
                                    <Button onClick={(event) => menu.current.toggle(event)} tooltip="Configuración" tooltipOptions={{ position: 'top' }} severity="secondary" icon="pi pi-cog" rounded />
                                </div>
                            }
                            <div className='pr-3'>
                                <Button onClick={searchFolio} tooltip="Buscar coincidencias" tooltipOptions={{ position: 'top' }} severity="secondary" icon="pi pi-arrow-right-arrow-left" rounded />
                            </div>
                            <div className='pr-3'>
                                <Button onClick={() => { { ObservacionVisible(true) } }} tooltip="Crear una observación" tooltipOptions={{ position: 'top' }} severity="secondary" icon="pi pi-megaphone" rounded />
                            </div>
                            <div className='pr-3'>
                                <Button onClick={() => { SavePDFinsc(previousFolio) }} tooltip="Impresión de folio" tooltipOptions={{ position: 'top' }} severity="secondary" icon="pi pi-print" rounded />
                            </div>
                        </div>
                    </div>
                </div>
                <Tabs>
                    <Tab label="Datos generales">
                        <div>
                            <TabGenerales propiedadDetail={propiedadDetail} tramiteDetail={tramiteDetail} />
                        </div>
                    </Tab>
                    <Tab label="Actos">
                        <div>
                            <TabActos
                                propiedadDetail={propiedadDetail}
                                tramiteDetail={tramiteDetail}
                                formattedActos={formattedActos}
                                setFormattedActos={setFormattedActos}
                                actoHistory={actoHistory}
                                edicion={true}
                                refreshActos={getTramiteDetail}
                                handleSentRevision={handleSentRevision}
                                groupObjectsByTrmId={groupObjectsByTrmId}
                                getTramiteDetail={getTramiteDetail}
                                setActoHistory={setActoHistory}
                                actoHistoryClone={actoHistoryClone}
                                titulares={titulares}
                            />
                        </div>
                    </Tab>
                    <Tab label="Titulares">
                        <div>
                            <TabTitulares titulares={titulares} />
                        </div>
                    </Tab>
                    <Tab label="Notificaciones">
                        <div>
                            <TabNotificador notifications={notifications} />
                        </div>
                    </Tab>
                </Tabs>
            </div>
            {
                !(user.prf_id == 3 || user.prf_id === 6) && !(previousFolio.trm_status == 9 || previousFolio.trm_status == 10 || previousFolio.trm_status == 3) &&
                <div className='footer-folio flex justify-content-end'>
                    <div className='flex col-4'>
                        <ConfirmPopup
                            target={buttonConfirm.current}
                            visible={visibleConfirm}
                            onHide={() => setVisibleConfirm(false)}
                            message="¿Estas seguro de los datos ingresados que son correctos?"
                            icon="pi pi-exclamation-triangle"
                            accept={acceptConfirm}
                            reject={rejectConfirm}
                            acceptLabel='Si'
                            rejectLabel='No'
                        />
                        <Button ref={buttonConfirm} label="Enviar a revisión" onClick={() => { setVisibleConfirm(true) }} />
                    </div>
                </div>
            }


            <ConfirmPopup target={buttonEl.current} visible={visible} onHide={() => setVisible(false)}
                message="¿Estas seguro que los datos ingresados que son correctos?" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} acceptLabel='Si' rejectLabel='No' />

            {/*inicia dialog para enviar solicitud*/}
            <Dialog
                header="Tu solicitud ha sido enviada"
                visible={modalVisible}
                style={{ width: '30vw' }}
                onHide={() => {
                    ModalSetVisible(false)
                    navigate('/')
                }}
            >

                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <h2>{previousFolio && previousFolio.trm_consecutivo}</h2>
                        <small>Número control interno</small>
                    </div>
                    <div>
                        <p>{`${formatDate(tramiteDetail?.tramite?.trm_fecha, tramiteDetail?.tramite?.trm_hora)}`}</p>
                        <small>Fecha y hora de registro</small>

                    </div>
                </div>

            </Dialog>


            {/*inicia dialog para realizar una observacion*/}
            <Dialog header="Nueva observación" visible={Observacion} style={{ width: '50vw' }} onHide={() => ObservacionVisible(false)} footer={footerContent}>
                <div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown inputId="dd-city" value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={motivo} optionLabel="name" className="w-100 p-inputtext-lg" virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }} />
                            <label htmlFor="dd-city">Motivo</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea id="username" rows={5} cols={30} onChange={(e) => { changeNote(e.target.value) }} />
                            <label htmlFor="username">Nota</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            {/*inicia dialog para realizar una impresion de folio*/}
            <Dialog header="Impresión de folio" visible={impresion} style={{ width: '50vw' }} onHide={() => ImpresionVisible(false)} footer={footerImpre}>
                <div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown inputId="dd-city" value={selectedImpre} onChange={(e) => setSelectedImpre(e.value)} options={impre} optionLabel="name" className="w-100 p-inputtext-lg" virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }} />
                            <label htmlFor="dd-city">Tipo de impresión</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <DataTable value={products} paginator rows={10}
                            onSelectionChange={(e) => {
                                const value = e.value as Product[];
                                setSelectedProducts(value);
                            }} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                            <Column body={CheckBodyTemplate} field="code"></Column>
                            <Column sortable field="code" header="Clave"></Column>
                            <Column sortable field="name" header="Detalle acto"></Column>
                            <Column sortable field="name" header="Fecha"></Column>
                            <Column sortable field="name" header="Folio de pago"></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>

            {/*INICIA DIALOGS PARA CONFIGURADOR DE FOLIO*/}

            {/*abrir cerar folio*/}
            <Dialog header="Abrir/Cerrar folio" visible={displayACFolio} maximizable modal style={{ width: '50vw' }} footer={renderFooter} onHide={() => ImpresionVisible(false)} >
                <p>Se tomaran tus datos de usuario para el registro de autorización, fecha y hora de registro.</p>
                <div className='grid'>
                    <div className='col-12 md:col-3'>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={checked} />
                            <label htmlFor="binary">Abrir folio*</label>
                        </div>
                    </div>
                    <div className='col-12 md:col-3'>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={checked} />
                            <label htmlFor="binary">Cerrar folio*</label>
                        </div>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea rows={5} cols={30} autoResize />
                            <label htmlFor="username">Justificación</label>
                        </span>
                    </div>
                </div>
            </Dialog>
            {/*alertar folio*/}
            <Dialog header="Alertar folio" visible={displayALFolio} maximizable modal style={{ width: '50vw' }} footer={renderFooter} onHide={() => ImpresionVisible(false)} >
                <p>Se tomaran tus datos de usuario para el registro de autorización, fecha y hora de registro.</p>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label">
                            <Dropdown optionLabel="name" className="w-full p-inputtext-lg" virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }} />
                            <label htmlFor="username">Tipo de alerta</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText id="username" />
                            <label htmlFor="username">Titulo</label>
                        </span>
                    </div>

                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea rows={5} cols={30} autoResize />
                            <label htmlFor="username">Justificación</label>
                        </span>
                    </div>
                </div>

            </Dialog>
            {/*folio de calidad*/}
            <Dialog header="Folio de calidad" visible={displayCAFolio} maximizable modal style={{ width: '50vw' }} footer={renderFooter} onHide={() => ImpresionVisible(false)} >
                <p>Se tomaran tus datos de usuario para el registro de autorización, fecha y hora de registro.</p>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={checked} />
                            <label htmlFor="binary">Activar folio de calidad*</label>
                        </div>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={checked} />
                            <label htmlFor="binary">Desactivar folio de calidad*</label>
                        </div>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea rows={5} cols={30} autoResize />
                            <label htmlFor="username">Justificación</label>
                        </span>
                    </div>
                </div>
            </Dialog>
            {/*modificacion caratula*/}
            <Dialog
                header="Edición de datos"
                visible={displayMCFolio}
                maximizable
                modal
                style={{ width: '70vw' }}
                onHide={() => setDisplayMCFolio(false)}
            >
                <EditCaratula
                    data={{
                        id: propiedadDetail && propiedadDetail.prop_id,
                        folio: propiedadDetail && propiedadDetail.prop_folio,
                        claveCatastral: propiedadDetail && propiedadDetail.prop_clave,
                        antecedentes: propiedadDetail && propiedadDetail.prop_antecedente,
                        domicilioRegistral: propiedadDetail && propiedadDetail.prop_direccion,
                        codigoPostal: propiedadDetail && propiedadDetail.cp_cp && (typeof propiedadDetail.cp_cp == 'object' ? (propiedadDetail.length > 1 ? propiedadDetail.cp_cp[1] : propiedadDetail.cp_cp[0]) : propiedadDetail.cp_cp),
                        localidad: propiedadDetail && propiedadDetail.cp_loc,
                        municipio: propiedadDetail && propiedadDetail.cp_mun,
                        estado: propiedadDetail && propiedadDetail.cp_edo,
                        cruzamiento: propiedadDetail && propiedadDetail.prop_cruzamientos,
                        colonia: propiedadDetail && propiedadDetail.cp_col,
                        numeroInterior: propiedadDetail && propiedadDetail.prop_int,
                        numeroExterior: propiedadDetail && propiedadDetail.prop_ext,
                        calle: propiedadDetail && propiedadDetail.prop_calle,
                        medidasColindancias: propiedadDetail && propiedadDetail.prop_medidas,
                        construida: propiedadDetail && propiedadDetail.prop_constr,
                        superficieTotal: propiedadDetail && propiedadDetail.prop_suptotal,
                        titulares: titulares
                    }}
                    activarModal={() => { setDisplayMCFolio(true) }}
                    desactivarModal={() => { setDisplayMCFolio(false) }}
                />
            </Dialog>

            {/*agregar actos historicos*/}
            <Dialog header="Agregar Acto Historico" visible={displayAHFolio} maximizable modal style={{ width: '50vw' }} onHide={() => setDisplayAHFolio(false)} position='top' >
                <AgregarActo
                    detallePropiedad={propiedadDetail}
                    titulares={titulares}
                    detalleTramite
                    ocultarModal={() => { setDisplayAHFolio(false) }}
                    mostrarToast={() => { toast.current?.show({ severity: 'success', summary: 'Trámite Guardado', detail: 'El trámite fue guardado con éxito', life: 3000 }); }}
                />
            </Dialog>

        </section >
    );

}

export default React.memo(IntViewFolio);


