import React, { useEffect, useState } from "react";

function LiveClockUpdate() {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);

        return () => {
            clearInterval(timerID);
        };
    }, []);

    const tick = () => {
        setDate(new Date());
    };

    return (
        <div>
            <small>{date.toLocaleDateString("en-GB")} - </small>
            <small>{date.toLocaleTimeString()}</small>
        </div>
    );
}

export default LiveClockUpdate;
