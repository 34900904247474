import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import React, { useEffect, useState } from 'react';
import ApiServices from '../../service/apiServices';
import { InputNumber } from 'primereact/inputnumber';
import EncryptService from '../../service/encrypt.service';
import authGuardService from '../../service/authGuardService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';


const I1Acto = (props: any) => {
    const user = authGuardService.getUser()

    const prop_id = props.preTramiteDetail ? props.preTramiteDetail.tramite.prop_id :
        props.responseData ? props.responseData.prop_id :
            props.previousFolio ? props.previousFolio[0].prop_id :
                props.generalData ? props.generalData.prop_id :
                    props.propiedadDetail ? props.propiedadDetail.prop_id :
                        0

    const columns = [
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'ptit_nuda', header: 'Nuda %' },
        { field: 'nuda_enaje', header: 'Nuda Enaje%' },
        { field: 'ptit_usufructo', header: 'Uso Fructo %' },
        { field: 'usufructo_enaje', header: 'Uso Fructo Enaje %' },
        { field: 'cmptd_representacion', header: 'Representación' }
    ];

    const columns3 = [
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'lote', header: 'Lote' },
        { field: 'manzana', header: 'Manzana' },
        { field: 'zona', header: 'Zona' },
        { field: 'ptit_nuda', header: 'Nuda %' },
        { field: 'nuda_enaje', header: 'Nuda Enaje%' },
        { field: 'ptit_usufructo', header: 'Uso Fructo %' },
        { field: 'usufructo_enaje', header: 'Uso Fructo Enaje %' },
        { field: 'cmptd_representacion', header: 'Representación' }
    ];

    const columns4 = [
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'ptit_nuda', header: 'Nuda %' },
        { field: 'ptit_usufructo', header: 'Uso Fructo %' },
        { field: 'cmptd_representacion', header: 'Cargo' }
    ];



    const columns2 = [
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'ptit_nuda', header: 'Nuda %' },
        { field: 'ptit_usufructo', header: 'Uso Fructo %' },
        { field: 'cmptd_representacion', header: 'Representación' }
    ];

    const [titulares, setTitulares] = useState([])
    const [titularesSeleccionados, setTitularesSeleccionados] = useState([])

    const [data, setData]: any = useState({
        paterno: { value: '', valid: false },
        materno: { value: '', valid: false },
        nombre: { value: '', valid: false },
        rfc: { value: '', valid: false },
        curp: { value: '', valid: false },
        nuda: { value: 0, valid: false },
        usufructo: { value: 0, valid: false },
        representacion: { value: '', valid: false },
    })

    const setValues = (jsonValue: any, value: any) => {
        const cloneJson = JSON.parse(JSON.stringify(data))
        cloneJson[jsonValue].value = value
        cloneJson[jsonValue].valid = value?.toString().length > 0
        setData(cloneJson)
    }

    const formattTitulares = (titulares) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))

        if (cloneTitulares && cloneTitulares.length > 0) {
            const newTitulares = cloneTitulares.map((singleTitular) => {
                return {
                    cmptdt_id: singleTitular.cmptdt_id || 0,
                    ptit_nom: singleTitular.cmpdt_nombre,
                    ptit_pat: singleTitular.cmpdt_paterno_rs,
                    ptit_mat: singleTitular.cmpdt_materno,
                    ptit_rfc: singleTitular.cmpdt_rfc,
                    ptit_nuda: singleTitular.cmpdt_nuda,
                    cmpdt_curp: singleTitular.cmpdt_curp,
                    nuda_enaje: singleTitular.cmptd_nuda_enaje,
                    ptit_usufructo: singleTitular.cmpdt_usufructo,
                    usufructo_enaje: singleTitular.cmptd_usufructo_enaje,
                    cmptd_representacion: singleTitular.cmptd_representacion,
                    ptit_status: singleTitular.cmpdt_movimiento
                }
            })
            setTitulares(newTitulares)
            props.handleValueChange(newTitulares)
        }
    }

    const getTitularesSingle = () => {
        let datos = {
            cmp_id: props.cmpId,
            frmu_id: props.responseData ? props.responseData.ptrm_id : (props.information?.frmu_id || props.preTramiteFolio?.ptrm_id),
        }
        ApiServices.apiPost('getTitularesActualiza', datos).then(async res => {
            let dataEx = res.data.addenda
            if (dataEx && dataEx.length > 0) {
                formattTitulares(res.data.addenda)
            }
        }).catch(error => {

        })
    }


    const getTitulares = () => {
        let datos = {
            cmp_id: props.cmpId,
            frmu_id: props.responseData ? props.responseData.ptrm_id : (props.information?.frmu_id || props.preTramiteFolio?.ptrm_id),
        }
        ApiServices.apiPost('getTitularesActualiza', datos).then(async res => {
            let dataEx = res.data.addenda
            if (dataEx && dataEx.length > 0) {
                formattTitulares(res.data.addenda)
            } else {
                getTitularesFolio()
            }
        }).catch(error => {

        })
    }

    const getTitularesFolio = () => {
        let datos = {
            prop_id: props.preTramiteDetail ? props.preTramiteDetail.tramite.prop_id :
                props.responseData ? props.responseData.prop_id :
                    props.previousFolio ? props.previousFolio[0].prop_id :
                        props.generalData ? props.generalData.prop_id :
                            props.propiedadDetail ? props.propiedadDetail.prop_id :
                                0
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
            props.handleValueChange(res.data.addenda, props.indexes[0], props.indexes[1])
        }).catch(error => {
            console.log(error)
        })
    }

    const addNewTitular = () => {
        if (titulares.length > 0) {

            const lastObject = titulares[titulares.length - 1];
            const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
                acc[key] = '';
                return acc;
            }, {});

            emptyObject.prop_id = prop_id
            emptyObject.ptit_id = '0';
            emptyObject.ptit_nom = data.nombre.value;
            emptyObject.ptit_pat = data.paterno.value;
            emptyObject.ptit_mat = data.materno.value;
            emptyObject.ptit_rfc = data.rfc.value;
            emptyObject.ptit_fecnac = '1980-03-23'
            emptyObject.ptit_nuda = data.nuda.value
            emptyObject.ptit_usufructo = data.usufructo.value
            emptyObject.usu_id = user.usu_id.value
            emptyObject.ptit_status = '1'

            setData({
                paterno: { value: '', valid: false },
                materno: { value: '', valid: false },
                nombre: { value: '', valid: false },
                rfc: { value: '', valid: false },
                curp: { value: '', valid: false },
                nuda: { value: 0, valid: false },
                usufructo: { value: 0, valid: false },
                representacion: { value: '', valid: false },
            })

            setTitulares([...titulares, emptyObject]);
            props.handleValueChange([...titulares, emptyObject], props.indexes[0], props.indexes[1])
        } else {
            const newTitular = {
                prop_id: prop_id,
                ptit_id: 0,
                ptit_fecnac: '1980-03-23',
                cmptdt_id: "",
                ptit_nom: "",
                ptit_pat: "",
                ptit_mat: "",
                ptit_rfc: "",
                ptit_nuda: 0.00,
                cmpdt_curp: "",
                nuda_enaje: 0.00,
                ptit_usufructo: 0.00,
                usufructo_enaje: 0.00,
                cmptd_representacion: "",
                ptit_status: 1,
                usu_id: user.usu_id.value
            }
            setTitulares([...titulares, newTitular]);
            props.handleValueChange([...titulares, newTitular], props.indexes[0], props.indexes[1])
        }
    }

    const isDeletedTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Tag severity="danger" value="Se Eliminara"></Tag>
        else return ''
    };

    const textEditor = (options: ColumnEditorOptions) => {
        if (options.field == 'ptit_nuda' || options.field == "ptit_usufructo" || options.field == 'nuda_enaje' || options.field == 'usufructo_enaje') {
            return (
                <InputNumber value={options.value}
                    onValueChange={(e) => options.editorCallback(e.value)} maxFractionDigits={2} minFractionDigits={2} min={0} max={100} />
            )
        } else
            return <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
            />;
    };

    const handleChangeTitulares = (index: string | number, field: any, value: any) => {
        const newTitulares = [...titulares];
        newTitulares[index] = {
            ...newTitulares[index],
            [field]: value
        };
        setTitulares(newTitulares);
        props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
    };

    const onCellEditComplete = (e: { rowIndex: any; field: any; newValue: any; }) => {
        handleChangeTitulares(e.rowIndex, e.field, e.newValue)
    };

    const deleteTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 0
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const recoverTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 1
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const actionBodyTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return <Button type="button" severity="danger" icon="pi pi-trash" rounded onClick={() => { deleteTitular(rowData, index.rowIndex) }}></Button>;
    };

    const headerTemplate = (options) => {
        const className = `${options.className} justify-content-space-between`;

        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <span className="font-bold">Adjudica en favor de:</span>
                </div>
                <div>
                    <Button onClick={addNewTitular}>
                        Agregar
                    </Button>
                </div>
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold"></span>
            <Button icon="pi pi-plus" rounded raised onClick={addNewTitular} />
        </div>
    );

    useEffect(() => {
        if (props && props.frm && props.frm.cmp_funcion && props.frm.cmp_funcion == 'titulares')
            getTitulares()
        else {
            getTitularesSingle()
        }
    }, [])

    return (
        <section className='dynamicStyle'>
            <Panel header={props && props.frm && props.frm.cmp_titulo ? props.frm.cmp_titulo : props && props.frm && props.frm.cmp_valores ? props.frm.cmp_valores : ''}>
                <div className='p-datatable p-component p-datatable-responsive-scroll datatable-responsive'>
                    <DataTable
                        value={titulares}
                        dataKey="ptit_id"
                        paginator
                        editMode="cell"
                        rows={5}
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        emptyMessage="No hay titulares."
                        showGridlines
                        header={header}
                    >
                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={isDeletedTemplate} />
                        {props && props.frm && props.frm.cmp_funcion && props.frm.cmp_funcion == 'titulares' ?
                            columns.map(({ field, header }) => {
                                return <Column
                                    key={field}
                                    field={field}
                                    header={header}
                                    editor={(options) => textEditor(options)}
                                    onCellEditComplete={onCellEditComplete}
                                />;
                            })
                            : props && props.frm && props.frm.cmp_tipo && props.frm.cmp_tipo == 'DT2' ?
                                columns3.map(({ field, header }) => {
                                    return <Column
                                        key={field}
                                        field={field}
                                        header={header}
                                        editor={(options) => textEditor(options)}
                                        onCellEditComplete={onCellEditComplete}
                                    />;
                                })
                                : props && props.frm && props.frm.cmp_tipo && props.frm.cmp_tipo == 'DT3' ?
                                    columns4.map(({ field, header }) => {
                                        return <Column
                                            key={field}
                                            field={field}
                                            header={header}
                                            editor={(options) => textEditor(options)}
                                            onCellEditComplete={onCellEditComplete}
                                        />;
                                    })
                                    :
                                    columns2.map(({ field, header }) => {
                                        return <Column
                                            key={field}
                                            field={field}
                                            header={header}
                                            editor={(options) => textEditor(options)}
                                            onCellEditComplete={onCellEditComplete}
                                        />;
                                    })
                        }
                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={actionBodyTemplate} />
                    </DataTable>
                </div>
            </Panel>
        </section >
    );

}

export default React.memo(I1Acto);