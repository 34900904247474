import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Checkbox } from "primereact/checkbox";
import ApiServices from '../../../service/apiServices';
import EncryptService from '../../../service/encrypt.service';
import { useNavigate } from 'react-router-dom';
import { InputMask } from "primereact/inputmask";
import { Password } from 'primereact/password';

interface City {
    name: string;
}

const Registro = (props: any) => {
    const navigate = useNavigate();

    const [selectedUserType, setSelectedUSerType] = useState<any>(null);
    const [birthDate, setBirthDate] = useState<any>(null);
    const [termsChecked, setTermsChecked] = useState<any>(true);
    const [privacyChecked, setPrivacyChecked] = useState<any>(true);
    const [generalData, setGeneralData] = useState({
        name: { value: '', valid: true },
        lastName: { value: '', valid: true },
        secondLastName: { value: '', valid: true },
        email: { value: '', valid: true },
        phone: { value: '', valid: true }
    })
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [notaryData, setNotaryData] = useState({
        notary: { value: '', valid: true },
        curp: { value: '', valid: true },
        street: { value: '', valid: true },
        interiorNumber: { value: '', valid: true },
        exteriorNumber: { value: '', valid: true },
        colony: { value: '', valid: true },
        municipality: { value: '', valid: true },
        postalCode: { value: '', valid: true }
    })
    const [area, setArea] = useState<City | null>(null);
    const [profile, setProfile] = useState<City | null>(null);
    const [internData, setInternData] = useState({
        workerId: { value: '', valid: true }
    })

    const [foundedColony, setFoundedColony] = useState([])

    const handleGeneralData = (e: any, json: any) => {
        const temporalData = JSON.parse(JSON.stringify(json));
        temporalData[e.target.id].value = e.target.value
        setGeneralData(temporalData)
    }

    const handleNotaryData = (e: any, json: any) => {
        const temporalData = JSON.parse(JSON.stringify(json));
        temporalData[e.target.id].value = e.target.value
        if (e.target.id == 'postalCode') handleCPChange(e.target.value)
        setNotaryData(temporalData)
    }

    const handleInternData = (e: any, json: any) => {
        const temporalData = JSON.parse(JSON.stringify(json));
        temporalData[e.target.id].value = e.target.value
        setInternData(temporalData)
    }

    const handleCPChange = (cp: any) => {
        if (cp.length == 5) {
            getColonyWithPostalCode(cp)
        }
    }

    const getColonyWithPostalCode = (value: any) => {
        let datos
        datos = { "cp_cp": value }
        ApiServices.apiPost('buscarCP', datos).then(async res => {
            if (res.data.valido == 1) {
                setFoundedColony(res.data.addenda)
                setSelectedCP(res.data.addenda[0])
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const ColonyTemplate = (option: any) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '0px',
                background: '#FFFFFF',
                width: '100%',
                height: '100%',
                fontFamily: 'Cabin',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#313131'
            }
            }> {option.cp_col}</div >
        );
    };

    const [selectedCP, setSelectedCP] = useState<any>(null)

    const [userRegistered, setUserRegistered] = useState(false);

    function stripPhonenumber(phonenumber: any) {
        return phonenumber.replace(/[^0-9]+/g, "");
    }

    const handleRegisterUser = () => {
        if (selectedUserType) {
            let datos
            datos = {
                code: 105,
                usu_tipo: selectedUserType.id,
                usu_nom: generalData.name.value,
                usu_pat: generalData.lastName.value,
                usu_mat: generalData.secondLastName.value,
                usu_fecnac: formattDate(birthDate),
                usu_email: generalData.email.value,
                usu_tel: stripPhonenumber(generalData.phone.value),
                usu_psw: password,
                not_id: "",
                usu_curp: notaryData.curp.value || '',
                prf_id: "",
                usu_trab_id: internData.workerId.value || '',
                cp_id: selectedCP && selectedCP.cp_id || '',
                usu_calle: notaryData.street.value || '',
                usu_ext: notaryData.exteriorNumber.value || '',
                usu_int: notaryData.interiorNumber.value || ''
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido) {
                    setUserRegistered(true)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    function formattDate(data: any) {
        const fecha = new Date(data);
        const anio = fecha.getFullYear();
        const mes = String(fecha.getMonth() + 1).padStart(2, '0');
        const dia = String(fecha.getDate()).padStart(2, '0');

        return `${anio}-${mes}-${dia}`;
    }

    function validatePasswords() {
        if (password && confirmPassword) {
            return !(password === confirmPassword)
        }
    }





    const userTypes = [
        { name: 'Externo', code: 'notario', id: 1 },
        { name: 'Interno', code: 'interno', id: 2 },
        { name: 'Notario', code: 'notario', id: 3 }
    ];

    const areasList = [
        { name: 'Certificaciones' },
        { name: 'Registro' },
        { name: 'Juridico' },
        { name: 'Ventanilla' }
    ];

    const profileList: City[] = [
        { name: 'Calificador' },
        { name: 'Analista' },
        { name: 'Secretaria' },
    ];

    const userTypesTemplate = (option: any) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '0px',
                background: '#FFFFFF',
                width: '100%',
                height: '100%',
                fontFamily: 'Cabin',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#313131'
            }
            }> {option.name}</div >
        );
    };


    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    return (
        <>
            {userRegistered ?
                <div className='flex justify-content-center align-items-center'>
                    <div className='col-6'>
                        <div className='text-center'>
                            <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                            <h4 className='mb-5'>Tu registro se ha realizado con éxito</h4>
                            <p>{`Espera a que nuestros administradores validen tu información, enviaremos un correo al ${generalData.email.value} para dar a conocer tu activación de usuario`}</p>
                            {/* <div className='mb-5'>
                                <h2>00231</h2>
                                <small>Numero de folio de activación</small>
                            </div> */}
                            <div className=' flex justify-content-center'>
                                <div className='col-4'>
                                    <Button label='Terminar' onClick={() => { navigate("/login") }} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <section className='container-fluid'>
                    <h1 className='color-violet'>Registrate</h1>
                    <p>Bienvenido a nuestro registro, selecciona el tipo de usuario que eres para seguir con tu proceso.</p>

                    {/* Tipo de Usuario */}
                    <div className='grid'>
                        <div className='col-12 md:col-4'>
                            <Dropdown
                                value={selectedUserType}
                                onChange={(e) => setSelectedUSerType(e.value)}
                                options={userTypes}
                                optionLabel="name"
                                placeholder="Tipo de Usuario"
                                itemTemplate={userTypesTemplate}
                                panelStyle={{ background: '#FFF' }}
                                className="p-inputtext-lg"
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                        </div>
                    </div>

                    {/* Datos Generales */}
                    <div className='grid'>
                        <div className='col-12'>
                            <h6 className="py-2">Datos generales</h6>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="name"
                                    value={generalData.name.value}
                                    onChange={(e) => { handleGeneralData(e, generalData) }}
                                    className={`p-inputtext-lg ${!generalData.name.valid && 'p-invalid'}`}
                                />
                                <label htmlFor="username">Nombre *</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="lastName"
                                    value={generalData.lastName.value}
                                    onChange={(e) => { handleGeneralData(e, generalData) }}
                                    className={`p-inputtext-lg ${!generalData.lastName.valid && 'p-invalid'}`}
                                />
                                <label htmlFor="username">Primer apellido *</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="secondLastName"
                                    value={generalData.secondLastName.value}
                                    onChange={(e) => { handleGeneralData(e, generalData) }}
                                    className={`p-inputtext-lg ${!generalData.secondLastName.valid && 'p-invalid'}`}
                                />
                                <label htmlFor="username">Segundo apellido</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="email"
                                    keyfilter="email"
                                    value={generalData.email.value}
                                    onChange={(e) => { handleGeneralData(e, generalData) }}
                                    className={`p-inputtext-lg ${!generalData.email.valid && 'p-invalid'}`}
                                />
                                <label htmlFor="username">Correo electrónico *</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputMask
                                    id="phone"
                                    value={generalData.phone.value}
                                    onChange={(e) => {
                                        handleGeneralData(e, generalData)
                                    }}
                                    className={`p-inputtext-lg ${!generalData.phone.valid && 'p-invalid'}`}
                                    mask="(999) 999-9999"
                                    placeholder="(999) 999-9999"
                                ></InputMask>
                                <label htmlFor="username">Teléfono *</label>
                            </span>
                        </div>
                    </div>

                    {/* Datos Notario */}
                    {selectedUserType && selectedUserType.code == 'notario' &&
                        <div className='grid'>
                            <div className='col-12'>
                                <h6 className="py-2">Caracteristicas notaria</h6>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="notary"
                                        value={notaryData.notary.value}
                                        onChange={(e) => { handleNotaryData(e, notaryData) }}
                                        className={`p-inputtext-lg ${!notaryData.notary.valid && 'p-invalid'}`}
                                    />
                                    <label htmlFor="username">Dependencia *</label>
                                </span>
                            </div>
                            {/* <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="curp"
                                        value={notaryData.curp.value}
                                        onChange={(e) => { handleNotaryData(e, notaryData) }}
                                        className={`p-inputtext-lg ${!notaryData.curp.valid && 'p-invalid'}`}
                                    />
                                    <label htmlFor="username">CURP *</label>
                                </span>
                            </div> */}
                            <div className='col-12 md:col-12'>
                                <h6 className='py-1'>Domicilio</h6>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="street"
                                        value={notaryData.street.value}
                                        onChange={(e) => { handleNotaryData(e, notaryData) }}
                                        className={`p-inputtext-lg ${!notaryData.street.valid && 'p-invalid'}`}
                                    />
                                    <label htmlFor="username">Calle</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="interiorNumber"
                                        value={notaryData.interiorNumber.value}
                                        onChange={(e) => { handleNotaryData(e, notaryData) }}
                                        className={`p-inputtext-lg ${!notaryData.interiorNumber.valid && 'p-invalid'}`}
                                    />
                                    <label htmlFor="username">N° interior</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="exteriorNumber"
                                        value={notaryData.exteriorNumber.value}
                                        onChange={(e) => { handleNotaryData(e, notaryData) }}
                                        className={`p-inputtext-lg ${!notaryData.exteriorNumber.valid && 'p-invalid'}`}
                                    />
                                    <label htmlFor="username">N° exterior</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="postalCode"
                                        value={notaryData.postalCode.value}
                                        onChange={(e) => { handleNotaryData(e, notaryData) }}
                                        className={`p-inputtext-lg ${!notaryData.postalCode.valid && 'p-invalid'}`}
                                        maxLength={5}
                                        keyfilter="int"
                                    />
                                    <label htmlFor="username">Codigo postal</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <Dropdown
                                        value={selectedCP}
                                        onChange={(e) => setSelectedCP(e.value)}
                                        options={foundedColony}
                                        optionLabel="cp_col"
                                        placeholder="Colonia"
                                        itemTemplate={ColonyTemplate}
                                        panelStyle={{ background: '#FFF' }}
                                        className="p-inputtext-lg"
                                        emptyMessage='Sin Resultados'
                                        virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                    />
                                    <label htmlFor="username">Colonia</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        disabled
                                        id="municipality"
                                        value={selectedCP ? selectedCP.cp_mun : ''}
                                        onChange={(e) => { handleNotaryData(e, notaryData) }}
                                        className={`p-inputtext-lg ${!notaryData.municipality.valid && 'p-invalid'}`}
                                    />
                                    <label htmlFor="username">Municipio</label>
                                </span>
                            </div>
                        </div>
                    }

                    {/* Datos Interno */}
                    {selectedUserType && selectedUserType.code == 'interno' &&
                        <div className='grid'>
                            <div className='col-12'>
                                <h6 className="py-2">Caracteristicas persona interna</h6>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <Dropdown
                                        inputId="area"
                                        id='area'
                                        value={area}
                                        onChange={(e) => setArea(e.value)}
                                        options={areasList}
                                        optionLabel="name"
                                        className="p-inputtext-lg"
                                        virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                    />
                                    <label htmlFor="area">Área</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <Dropdown
                                        inputId="profile"
                                        id='profile'
                                        value={profile}
                                        onChange={(e) => setProfile(e.value)}
                                        options={profileList}
                                        optionLabel="name"
                                        className="p-inputtext-lg"
                                        virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                    />
                                    <label htmlFor="dd-city">Perfil</label>
                                </span>
                            </div>
                            {/* <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        id="workerId"
                                        value={internData.workerId.value}
                                        onChange={(e) => { handleInternData(e, internData) }}
                                        className={`p-inputtext-lg ${!internData.workerId.valid && 'p-invalid'}`}
                                    />
                                    <label htmlFor="username">ID trabajador</label>
                                </span>
                            </div> */}
                        </div>
                    }

                    {/* Contraseña */}
                    <div className='grid'>
                        <div className='col-12'>
                            <h6 className="py-2">Contraseña</h6>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <Password
                                    id="password"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    className={`p-inputtext-lg ${validatePasswords() && 'p-invalid'}`}
                                    placeholder="Contraseña"
                                    feedback={false}
                                    toggleMask
                                    aria-describedby="password-help"
                                />
                                {validatePasswords() &&
                                    <small id="password-help" style={{ color: 'red' }}>
                                        Las contraseñas no coinciden.
                                    </small>
                                }
                                <label htmlFor="username">Contraseña *</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <Password
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                                    className={`p-inputtext-lg ${validatePasswords() && 'p-invalid'}`}
                                    placeholder="Contraseña"
                                    feedback={false}
                                    toggleMask
                                    aria-describedby="confirmPassword-help"
                                />
                                {validatePasswords() &&
                                    <small id="confirmPassword-help" style={{ color: 'red' }}>
                                        Las contraseñas no coinciden.
                                    </small>
                                }
                                <label htmlFor="username">Confirmar Contraseña *</label>
                            </span>
                        </div>
                    </div>

                    {/* Terminos y Condiciones */}
                    {/* <div className='grid'>
                        <div className='col-12'>
                            <h6 className="py-2">Para continuar, por favor acepta los siguientes términos.</h6>
                        </div>
                        <div className='col-12 md:col-12'>
                            <div className='p-field-checkbox'>
                                <Checkbox onChange={e => setPrivacyChecked(e.checked)} checked={privacyChecked}></Checkbox>
                                <label>Acepto términos y condiciones</label>
                            </div>
                            <div className='p-field-checkbox'>
                                <Checkbox onChange={e => setTermsChecked(e.checked)} checked={termsChecked}></Checkbox>
                                <label>Acepto Aviso de privacidad</label>
                            </div>
                        </div>
                    </div>
 */}
                    {/* Zona de Botones */}
                    <div className='flex justify-content-end'>
                        <div className=' col-3'>
                            <Button
                                label='Registrar'
                                onClick={handleRegisterUser}
                                disabled={!(privacyChecked && termsChecked)}
                            />
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default React.memo(Registro);