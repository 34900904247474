import { Button } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import { useLocation, useNavigate } from 'react-router-dom';
import EncryptService from '../../../../service/encrypt.service';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import authGuardService from '../../../../service/authGuardService';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';

const VenViewNewFolio = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const toast = useRef<any>(null);
    const buttonEl = useRef<any>(null);
    const buttonConfirm = useRef<any>(null);
    const user = authGuardService.getUser()
    const [visible, setVisible] = useState<boolean>(false);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [visibleConfirm, setVisibleConfirm] = useState(false)

    const folio = location.state && location.state.folio ? location.state.folio : 0

    const [claveCatastral, setClaveCatastral] = useState('')
    const [tomoLibro, setTomoLibro] = useState('')
    const [volumen, setVolumen] = useState('')
    const [seccion, setSeccion] = useState('')
    const [serie, setSerie] = useState('')
    const [registro, setRegistro] = useState('')
    const [foja, setFoja] = useState('')
    const [direccionGeneral, setDireccionGeneral] = useState('')
    const [zona, setZona] = useState('')
    const [calle, setCalle] = useState('')
    const [numeroInterior, setNumeroInterior] = useState('')
    const [numeroExterior, setNumeroExterior] = useState('')
    const [condominio, setCondominio] = useState('')
    const [lote, setLote] = useState('')
    const [lt, setLt] = useState('')
    const [mz, setMz] = useState('')
    const [edificio, setEdificio] = useState('')
    const [estado, setEstado] = useState('')
    const [municipio, setMunicipio] = useState('')
    const [cp, setCp] = useState('')
    const [totalm2, setTotalm2] = useState('')
    const [construidom2, setConstruidom2] = useState('')
    const [medidasColindancias, setMedidasColindancias] = useState('')
    const [titulares, setTitulares] = useState([])

    function obtenerFechaYHoraActualEnFormato() {
        const mesesEnEspanol = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const fechaActual = new Date();
        const dia = fechaActual.getDate();
        const mes = mesesEnEspanol[fechaActual.getMonth()];
        const anio = fechaActual.getFullYear();
        let hora = fechaActual.getHours();
        const minutos = fechaActual.getMinutes();
        let periodo = "am";

        if (hora >= 12) {
            periodo = "pm";
            if (hora > 12) {
                hora -= 12;
            }
        }

        const minutosFormateados = (minutos < 10) ? "0" + minutos : minutos;

        const fechaYHoraFormateada = `${dia + 1} ${mes} ${anio} a las ${hora}:${minutosFormateados} ${periodo}`;
        return fechaYHoraFormateada;
    }

    const acceptConfirm = () => {
        addNewFolio()
    };

    const rejectConfirm = () => {
        setVisibleConfirm(false)
    };

    const addTitulares = async (data: any) => {
        let amountNuda = 0
        let amountUso = 0
        titulares && titulares.length > 0 && titulares.forEach((singleTitular) => {
            amountNuda = singleTitular.ptit_nuda + amountNuda
            amountUso = singleTitular.ptit_usufructo + amountUso
        })

        if (amountNuda > 100 || amountUso > 100 || amountNuda < 100 || amountUso < 100) {
            if (amountNuda > 100) {
                toast.current?.show({ severity: 'warn', summary: 'Nuda Mayor', detail: 'La suma de las nudas es mayor a 100%', life: 3000 });
            } else if (amountNuda < 100) {
                toast.current?.show({ severity: 'warn', summary: 'Nuda Menor', detail: 'La suma de las nudas es menor a 100%', life: 3000 });
            }
            if (amountUso > 100) {
                toast.current?.show({ severity: 'warn', summary: 'Uso Mayor', detail: 'La suma de los Uso Fructo es mayor a 100%', life: 3000 });
            } else if (amountUso < 100) {
                toast.current?.show({ severity: 'warn', summary: 'Uso Menor', detail: 'La suma de los Uso Fructo es menor a 100%', life: 3000 });
            }
        }
        else {
            if (titulares && titulares.length > 0) {
                const promises = titulares.map(titular => {
                    const cloneTitular = { ...titular };
                    delete cloneTitular.ptit_update;
                    delete cloneTitular.ptit_regfed;

                    const dateOnly = cloneTitular.ptit_fecnac ? cloneTitular.ptit_fecnac.split('T')[0] : ''
                    cloneTitular.ptit_fecnac = dateOnly
                    cloneTitular.prop_id = data.prop_id

                    let datos = cloneTitular;
                    return ApiServices.apiPost('updateTitularPropiedad', datos);
                });

                try {
                    await Promise.all(promises);
                } catch (error) {
                    console.log(error);
                }
            }
        }

        setNewProperty(data)
        toast.current.show({ severity: 'success', summary: 'Propiedad Creada', detail: 'La propiedad fue creada con exito', life: 15000 });
    }

    const addNewFolio = () => {
        let datos
        datos = {
            "usu_id": user.usu_id,
            "prop_folio": folio,
            "prop_clave": claveCatastral,
            "prop_libro": tomoLibro,
            "prop_vol": volumen,
            "prop_secc": seccion,
            "prop_serie": serie,
            "prop_reg": registro,
            "prop_foja": foja,
            "prop_direccion": direccionGeneral,
            "prop_zona": zona,
            "prop_calle": calle,
            "prop_int": numeroInterior,
            "prop_ext": numeroExterior,
            "prop_condo": condominio,
            "prop_lote_cond": lote,
            "prop_lt": lt,
            "prop_mz": mz || " ",
            "prop_edificio": edificio,
            "cp_id": cp,
            "prop_suptotal": totalm2,
            "prop_constr": construidom2,
            "prop_medidas": medidasColindancias,
            "prop_status": 1,
            "prop_update": 0,
            "cp_edo": estado,
            "cp_mun": municipio
        }

        ApiServices.apiPost('addPropiedad', datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.addenda && res.data.addenda.length > 0) {
                    addTitulares(res.data.addenda[0])
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Error al crear', detail: 'Hubo un error al crear la propiedad, revisa que los datos esten completos', life: 4000 });
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const [newProperty, setNewProperty] = useState<any>()

    const searchFolio = () => {
        let datos
        datos = {
            code: 208,
            prop_clave: '',
            prop_folio: folio,
            ptit_nom: '',
            ptit_pat: '',
            ptit_mat: '',
            prop_direccion: '',
            prop_calle: '',
            prop_ext: '',
            prop_int: ''
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                let url: any
                let exist = true
                const navigateOptions = {
                    state: {
                        pagos: (location.state && location.state.pagos) ? location.state.pagos : [],
                        folios: res.data.addenda,
                        preLoadActos: (location.state && location.state.actos) ? location.state.actos : []
                    }
                };
                if (res.data.addenda && res.data.addenda.length > 1) {
                    url = '/ventanilla/dup-folio'
                } else if (res.data.addenda && res.data.addenda.length === 1) {
                    url = '/ventanilla/vt-tramite'
                }

                if (exist)
                    navigate(url, navigateOptions)
            }
        }).catch(error => {
            console.log(error)
        })

    }

    const [foundedColony, setFoundedColony] = useState([])
    const [selectedCP, setSelectedCP] = useState<any>(null)

    const handleCPChange = (cp: any) => {
        if (cp.length === 5) {
            getColonyWithPostalCode(cp)
        }
    }

    const getColonyWithPostalCode = (value: any) => {
        let datos
        datos = { "cp_cp": value }
        ApiServices.apiPost('buscarCP', datos).then(async res => {
            if (res.data.valido === 1) {
                setFoundedColony(res.data.addenda)
                setSelectedCP(res.data.addenda[0])
                setEstado(res.data.addenda[0].cp_edo)
                setMunicipio(res.data.addenda[0].cp_mun)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const ColonyTemplate = (option: any) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '0px',
                background: '#FFFFFF',
                width: '100%',
                height: '100%',
                fontFamily: 'Cabin',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#313131'
            }
            }> {option.cp_col}</div >
        );
    };

    const columns = [
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Primer apellido' },
        { field: 'ptit_mat', header: 'Segundo apellido' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'ptit_nuda', header: 'Nuda %' },
        { field: 'ptit_usufructo', header: 'Uso Fructo %' }
    ];

    const addNewTitular = () => {
        if (titulares && titulares.length > 0) {
            const lastObject = titulares[titulares.length - 1];
            const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
                acc[key] = '';
                return acc;
            }, {});

            emptyObject.prop_id = 0
            emptyObject.ptit_fecnac = '1980-03-23'
            emptyObject.ptit_status = '1'
            emptyObject.ptit_id = '0';
            emptyObject.usu_id = user.usu_id

            setTitulares([...titulares, emptyObject]);
        } else {
            const emptyObject = {
                prop_id: 0,
                ptit_fecnac: '1980-03-23',
                ptit_status: '1',
                ptit_id: '0',
                usu_id: user.usu_id,
                ptit_nom: '',
                ptit_pat: '',
                ptit_mat: '',
                ptit_rfc: '',
                ptit_nuda: '',
                ptit_usufructo: ''
            }
            setTitulares([...titulares, emptyObject]);
        }
    }

    const isDeletedTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Tag severity="danger" value="Se Eliminara"></Tag>
        else return ''
    };

    const textEditor = (options: ColumnEditorOptions) => {
        if (options.field == 'ptit_nuda' || options.field == "ptit_usufructo") {
            return (
                <InputNumber value={options.value}
                    onValueChange={(e) => options.editorCallback(e.value)} maxFractionDigits={2} minFractionDigits={2} min={0} max={100} />
            )
        } else
            return <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
            />;
    };


    const handleChangeTitulares = (index: string | number, field: any, value: any) => {
        const newTitulares = [...titulares];
        newTitulares[index] = {
            ...newTitulares[index],
            [field]: value
        };
        setTitulares(newTitulares);
    };


    const onCellEditComplete = (e: { rowIndex: any; field: any; newValue: any; }) => {
        handleChangeTitulares(e.rowIndex, e.field, e.newValue)
    };

    const deleteTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 0
        }
        setTitulares(cloneTitulares)
    }

    const recoverTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 1
        }
        setTitulares(cloneTitulares)
    }

    const actionBodyTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return <Button type="button" severity="danger" icon="pi pi-trash" rounded onClick={() => { deleteTitular(rowData, index.rowIndex) }}></Button>;
    };

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold"></span>
            <Button icon="pi pi-plus" rounded raised onClick={addNewTitular} />
        </div>
    );


    return (
        <section className='container-fluid'>
            <Toast ref={toast} />

            <div className='flex justify-content-between'>
                <div className='col-12 md:col-6'>
                    <h3 className='color-violet'>Nuevo folio</h3>
                    <p>Si ya existe en la institución pero no en el sistema, Ingresa los datos siguientes para dar de alta un nuevo folio</p>
                </div>
            </div>

            {newProperty &&
                <div className='col-12 md:col-12 pb-5'>
                    <div className="grid grid-nogutter" style={{ background: '#B0E899', borderRadius: 10 }}>
                        <div className="col">
                        </div>
                        <div className="col-10" style={{ display: 'flex' }}>
                            <div style={{ width: '1%', height: '100%', background: '#2C9004', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }} />
                            <div style={{ width: '85%', height: '8rem', marginLeft: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <span style={{ color: '#2C9004', fontSize: 24, fontFamily: 'Cabin', fontWeight: '700', wordWrap: 'break-word' }}>
                                    Nuevo Folio Generado: <span style={{ color: '#000000', fontSize: 24, fontFamily: 'Cabin', fontWeight: '700', wordWrap: 'break-word' }}>{newProperty ? newProperty.prop_folio : ''}</span>
                                </span>
                            </div>
                        </div>
                        <div className="col-2">
                            <div style={{ width: '90%', height: '8rem', justifyContent: 'flex-end', alignItems: 'center', display: 'flex', marginRight: '1rem' }}>
                                <Button label="Regresar a Buscador" style={{
                                    padding: 16, color: 'white',
                                    fontSize: 15,
                                    fontFamily: 'Cabin',
                                    fontWeight: '400',
                                    wordWrap: 'break-word',
                                }} onClick={() => {
                                    navigate('/ventanilla/vt-buscador', {
                                        state: {
                                            folio: newProperty.prop_folio
                                        }
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className=''>
                <section className=''>
                    <div className='grid'>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="cvecatastral"
                                    className="p-inputtext-lg"
                                    placeholder="Clave catastral"
                                    onChange={(e) => { setClaveCatastral(e.target.value) }}
                                />
                                <label htmlFor="cvecatastral">Clave catastral *</label>
                            </span>
                        </div>
                    </div>

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Antecedentes</h5>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="tomolibro"
                                    className="p-inputtext-lg"
                                    placeholder="Tomo/Libro"
                                    onChange={(e) => { setTomoLibro(e.target.value) }}
                                />
                                <label htmlFor="tomolibro">Tomo/Libro</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="volumen"
                                    className="p-inputtext-lg"
                                    placeholder="Volumen"
                                    onChange={(e) => { setVolumen(e.target.value) }}
                                />
                                <label htmlFor="volumen">Volumen</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="seccion"
                                    className="p-inputtext-lg"
                                    placeholder="Sección"
                                    onChange={(e) => { setSeccion(e.target.value) }}
                                />
                                <label htmlFor="seccion">Sección</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="serie"
                                    className="p-inputtext-lg"
                                    placeholder="Serie"
                                    onChange={(e) => { setSerie(e.target.value) }}
                                />
                                <label htmlFor="serie">Serie</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="registro"
                                    className="p-inputtext-lg"
                                    placeholder="Registro"
                                    onChange={(e) => { setRegistro(e.target.value) }}
                                />
                                <label htmlFor="registro">Registro</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="foja"
                                    className="p-inputtext-lg"
                                    placeholder="Foja"
                                    onChange={(e) => { setFoja(e.target.value) }}
                                />
                                <label htmlFor="foja">Foja</label>
                            </span>
                        </div>
                    </div>

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Domicilio registral</h5>
                        </div>
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr1"
                                    className="p-inputtext-lg"
                                    placeholder="Domicilio registral"
                                    onChange={(e) => { setDireccionGeneral(e.target.value) }}
                                />
                                <label htmlFor="dr1">Domicilio registral</label>
                            </span>
                        </div>
                    </div>

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Domicilio convencional</h5>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr1"
                                    className="p-inputtext-lg"
                                    placeholder="Zona*"
                                    onChange={(e) => { setZona(e.target.value) }}
                                />
                                <label htmlFor="dr1">Zona*</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr2"
                                    className="p-inputtext-lg"
                                    placeholder="Calle*"
                                    onChange={(e) => { setCalle(e.target.value) }}
                                />
                                <label htmlFor="dr2">Calle*</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr3"
                                    className="p-inputtext-lg"
                                    placeholder="N°. interior"
                                    onChange={(e) => { setNumeroInterior(e.target.value) }}
                                />
                                <label htmlFor="dr3">N°. interior</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr4"
                                    className="p-inputtext-lg"
                                    placeholder="N°. exterior"
                                    onChange={(e) => { setNumeroExterior(e.target.value) }}
                                />
                                <label htmlFor="dr4">N°. exterior</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr5"
                                    className="p-inputtext-lg"
                                    placeholder="Condominio"
                                    onChange={(e) => { setCondominio(e.target.value) }}
                                />
                                <label htmlFor="dr5">Condominio</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr6"
                                    className="p-inputtext-lg"
                                    placeholder="Lote condominal"
                                    onChange={(e) => { setLote(e.target.value) }}
                                />
                                <label htmlFor="dr6">Lote condominal</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr7"
                                    className="p-inputtext-lg"
                                    placeholder="Lt"
                                    onChange={(e) => { setLt(e.target.value) }}
                                />
                                <label htmlFor="dr7">Lt</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr8"
                                    className="p-inputtext-lg"
                                    placeholder="Mz"
                                    onChange={(e) => { setMz(e.target.value) }}
                                />
                                <label htmlFor="dr8">Mz</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="dr9"
                                    className="p-inputtext-lg"
                                    placeholder="Edificio"
                                    onChange={(e) => { setEdificio(e.target.value) }}
                                />
                                <label htmlFor="dr9">Edificio</label>
                            </span>
                        </div>

                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="cp_cp"
                                    className="p-inputtext-lg"
                                    value={cp}
                                    onChange={(e) => {
                                        handleCPChange(e.target.value);
                                        setCp(e.target.value)
                                    }}
                                />
                                <label htmlFor="cp_cp">Codigo postal</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="state"
                                    className="p-inputtext-lg"
                                    value={estado}
                                    onChange={(e) => {
                                        setEstado(e.target.value)
                                    }}
                                />
                                <label htmlFor="state">Estado</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="municipality"
                                    className="p-inputtext-lg"
                                    value={municipio}
                                    onChange={(e) => {
                                        setMunicipio(e.target.value)
                                    }}
                                />
                                <label htmlFor="municipality">Municipio</label>
                            </span>
                        </div>
                    </div>

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Superficie del predio</h5>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="sp1"
                                    className="p-inputtext-lg"
                                    placeholder="Total m2"
                                    onChange={(e) => { setTotalm2(e.target.value) }}
                                />
                                <label htmlFor="sp1">Total m2</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                <InputText
                                    id="sp2"
                                    className="p-inputtext-lg"
                                    placeholder="construido m2"
                                    onChange={(e) => { setConstruidom2(e.target.value) }}
                                />
                                <label htmlFor="sp2">construido m2</label>
                            </span>
                        </div>
                    </div>

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Medidas y Colindancias</h5>
                        </div>
                        <div className='col-12 md:col-12'>
                            <InputTextarea
                                id="mc1"
                                style={{ "minHeight": '191px' }}
                                className="p-inputtext-lg"
                                onChange={(e) => { setMedidasColindancias(e.target.value) }}
                            />
                        </div>
                    </div>

                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <h4>Titulares</h4>
                        </div>
                    </Divider>

                    <div className='pt-4'>
                        <DataTable
                            value={titulares}
                            dataKey="ptit_id"
                            paginator
                            editMode="cell"
                            rows={5}
                            className="datatable-responsive"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            emptyMessage="No hay titulares."
                            header={header}
                            showGridlines
                        >
                            <Column
                                headerStyle={{ textAlign: 'center' }}
                                bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                                body={isDeletedTemplate} />
                            {columns.map(({ field, header }) => {
                                return <Column
                                    key={field}
                                    field={field}
                                    header={header}
                                    editor={(options) => textEditor(options)}
                                    onCellEditComplete={onCellEditComplete}
                                />;
                            })}
                            <Column
                                headerStyle={{ textAlign: 'center' }}
                                bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                                body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </section>
            </div>

            <div className='footer-view flex justify-content-end'>
                <div className='flex col-3'>
                    {!newProperty &&
                        <>
                            <ConfirmPopup
                                target={buttonConfirm.current}
                                visible={visibleConfirm}
                                onHide={() => setVisibleConfirm(false)}
                                message="¿Estas seguro de los datos ingresados que son correctos?"
                                icon="pi pi-exclamation-triangle"
                                accept={acceptConfirm}
                                reject={rejectConfirm}
                                acceptLabel='Si'
                                rejectLabel='No'
                            />
                            <Button size="large" ref={buttonConfirm} label="Enviar a revisión" onClick={() => { setVisibleConfirm(true) }} />
                        </>
                    }
                </div>
            </div>

            {/* Guardado con éxito  */}
            <Dialog
                header="Se ha guardado con éxito"
                visible={modalVisible}
                style={{ width: '30vw' }}
                onHide={() => ModalSetVisible(false)}
                footer={
                    <div>
                        <Button label="Cerrar" onClick={() => ModalSetVisible(false)} autoFocus />
                    </div>
                }
            >
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        {/* <h2>{responseData ? `${responseData.trm_consecutivo}` : ''}</h2> */}
                        <h2>98765</h2>
                        <p>Nuevo folio electronico</p>
                    </div>
                    <div>
                        <p className='m-0' style={{ 'fontWeight': 'bold' }}>{obtenerFechaYHoraActualEnFormato()}</p>
                        <small>Fecha y hora de registro</small>
                    </div>
                </div>
            </Dialog>

        </section>
    );
}

export default React.memo(VenViewNewFolio);


