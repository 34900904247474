import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Steps } from 'primereact/steps';
import { Tooltip } from 'primereact/tooltip';
import { memo, useEffect, useRef, useState } from 'react';

interface DatosProps {
    timeLine: any;
}

const TimeLine = (props: DatosProps) => {

    const overlayTable = useRef(null);

    const [timeLine, setTimeLine] = useState([])
    const [timeLineTable, setTimeLineTable] = useState([])

    function formatDate(date: Date) {
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const newDate = new Date(date);
        const day = newDate.getUTCDate();
        const month = months[newDate.getUTCMonth()];
        const year = newDate.getUTCFullYear();
        const hour = newDate.getUTCHours();
        const minutes = newDate.getUTCMinutes();

        const fechaFormateada = `${day} ${month} ${year} a las ${hour}:${minutes}`;
        return fechaFormateada;
    }

    const itemRenderer = (item: any) => (
        <span
            className="custom-target-icon cursor-pointer"
            style={{
                backgroundColor: 'white',
                color: '#000000',
                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}
            data-pr-tooltip={`${item.fecha && formatDate(item.fecha)} ${item.fecha && ' - '} ${item.nombre}`}
            data-pr-position="bottom"
        >
            <Tooltip target=".custom-target-icon" />
            <span
                className="border-circle border-1 h-3rem w-3rem z-1 cursor-pointer"
                style={{
                    backgroundColor: 'white',
                    color: '#000000',
                    marginTop: '-25px', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    borderColor: `${item.finished ? '#873EFE' : '#000000'}`
                }}
            >
                {item.waiting &&
                    <i className={`pi pi-ellipsis-h text-xl`} />
                }
                {item.process &&
                    <i className={`pi pi-hourglass text-xl`} />
                }
                {item.finished &&
                    <i className={`pi pi-check-circle text-xl`} style={{ color: '#873EFE' }} />
                }
            </span>
            <p>{item.label}</p>
            <p style={{ visibility: item.status ? 'visible' : 'hidden', marginTop: '-18px' }}>
                {item.status || 'Empty'}
            </p>
        </span>
    );

    const handleTimeLine = () => {
        const temporalTimeLine = props.timeLine;

        const steps = [
            { key: 'capturado', label: 'Capturado', conditions: [temporalTimeLine.trm_capturado, temporalTimeLine.capturista], dateKey: 'trm_capturado', nameKey: 'capturista' },
            { key: 'asignado', label: 'Asignado', conditions: [temporalTimeLine.analista], dateKey: '', nameKey: 'analista' },
            { key: 'analizado', label: 'Analizando', conditions: [temporalTimeLine.trm_analizado, temporalTimeLine.analista], dateKey: 'trm_analizado', nameKey: 'analista' },
            { key: 'arc', label: 'A-R-C', conditions: [temporalTimeLine.fechahoraARC, temporalTimeLine.analistaARC], dateKey: 'fechahoraARC', nameKey: 'analistaARC', statusARC: temporalTimeLine.statusARC },
            { key: 'firmado', label: 'Firmado', conditions: [temporalTimeLine.trm_firmado, temporalTimeLine.firmador], dateKey: 'trm_firmado', nameKey: 'firmador' },
        ];

        const getStatus = (conditions) => {
            if (conditions.every(Boolean)) {
                return { waiting: false, process: false, finished: true };
            }
            if (conditions.some(Boolean)) {
                return { waiting: false, process: true, finished: false };
            }
            return { waiting: true, process: false, finished: false };
        };

        const timeline = steps.map(step => {
            const status = getStatus(step.conditions);
            const date = status.finished ? temporalTimeLine[step.dateKey] : '';
            const name = step.conditions.some(Boolean) ? temporalTimeLine[step.nameKey] : '';

            return {
                label: step.label,
                template: (item) => itemRenderer(item),
                waiting: status.waiting,
                process: status.process,
                finished: status.finished,
                fecha: date || '',
                nombre: name || '',
                status: step.statusARC
            };
        });

        setTimeLine(timeline);
    }

    const handleTimeLineTable = () => {
        const temporalTimeLine = props.timeLine;

        const temporalTimeLineTable = [
            { field: 'Recepcionista', id: temporalTimeLine.capturista_trab_id, name: temporalTimeLine.capturista, fecha: temporalTimeLine.trm_capturado ? formatDate(temporalTimeLine.trm_capturado) : '' },
            { field: 'Analista Asignado', id: temporalTimeLine.analista_trab_id, name: temporalTimeLine.analista, fecha: temporalTimeLine.trm_capturado ? formatDate(temporalTimeLine.trm_capturado) : '' },
            { field: 'Calificador Asignado', id: temporalTimeLine.firmador_trab_id, name: temporalTimeLine.firmador, fecha: temporalTimeLine.trm_firmado ? formatDate(temporalTimeLine.trm_firmado) : '' }
        ]
        setTimeLineTable(temporalTimeLineTable)
    }

    useEffect(() => {
        if (props && props.timeLine) {
            handleTimeLine()
            handleTimeLineTable()
        }
    }, [props.timeLine])

    return (
        <section style={{ display: 'flex', flexDirection: 'column' }}>
            <Button icon="pi pi-table" rounded onClick={(e) => overlayTable.current.toggle(e)} style={{ alignSelf: 'flex-end', marginTop: '-20px', marginBottom: '10px' }} />

            <Steps model={timeLine} readOnly={false} className="m-2 pt-4" />
            <OverlayPanel ref={overlayTable} showCloseIcon dismissable={true}>
                <DataTable value={timeLineTable} showGridlines stripedRows showHeaders={false}>
                    <Column field="field" />
                    <Column field="id" />
                    <Column field="name" />
                    <Column field='fecha' />
                </DataTable>
            </OverlayPanel>

        </section >
    );

}

export default memo(TimeLine);

