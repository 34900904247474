import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ResulActos from './PagoResulFolio/actos';
import ResulGenerales from './PagoResulFolio/generales';
import ResulTitulares from './PagoResulFolio/titulares';
import { Dialog } from 'primereact/dialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { useLocation, useNavigate } from "react-router-dom";
import { Messages } from 'primereact/messages';
import configuration from '../../../configurationFile';
import formatDate from '../../../utilities/formatDate';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { timeline } from 'console';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Tab from "../../../Components/Tabs/Tab";
import Tabs from "../../../Components/Tabs/Tabs";

import './tabView.css'
import { Tag } from 'primereact/tag';
import { Chip } from 'primereact/chip';
import TimeLine from '../../../Components/TimeLine/TimeLine';

const VenPagoFolio = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const msgs = useRef<any>(null);

    const previousFolio: any = location.state.folios
    const preTramite: any = location.state.tramite

    const [previousPayments, setPreviousPayments] = useState(location.state.pagos)

    const [preTramiteDetail, setPreTramiteDetail] = useState<any>(null)
    const [preTramiteFolio, setPreTramiteFolio] = useState<any>()
    const [modalRechazar, setModalRechazar] = useState<boolean>(false);
    const [rechazarText, setRechazarText] = useState('')
    const [modalRechazado, setModalRechazado] = useState(false)
    const [modalSentToNotario, setModalSentToNotario] = useState(false)
    const [actosArray, setActosArray] = useState<any>([])
    const [actosList, setActosList] = useState([])
    const [escrituraNumber, setEscrituraNumber] = useState(preTramite ? preTramite.trm_escritura : '')
    const [modalBorradorGuardado, setModalBorradorGuardado] = useState(false)
    const [newPaymentModal, setNewPaymentModal] = useState(false)

    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const dt = useRef<any>(null);
    const [preLoadActos, SetPreLoadActos] = useState([])
    const [products, setProducts] = useState<any>([]);
    const [allDone, setAllDone] = useState(false)
    const [hasPayment, setHasPayment] = useState<any>(true)
    const series = [
        { name: 'A', code: 'A' },
        { name: 'B', code: 'B' },
        { name: 'C', code: 'C' },
        { name: 'F', code: 'F' },
        { name: 'G', code: 'G' },
        { name: 'I', code: 'I' },
        { name: 'J', code: 'J' }
    ];
    const [selectedSerie, setSelectedSerie] = useState(series[0]);
    const [folio, setFolio] = useState('')
    const searchPayment = (payment: any) => {
        let datos
        datos = {
            code: 201,
            pago_serie: selectedSerie.code,
            pago_folio: folio
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                addToProduct(res.data)
            } else {
                showUsedPayment(res.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    function eliminarPagoPorId(pago_id: any) {
        const newArray = JSON.parse(JSON.stringify(previousPayments))
        const index = newArray.findIndex((pago: any) => pago.pago_id === pago_id);
        if (index !== -1) {
            newArray.splice(index, 1);
        }

        setPreviousPayments(newArray);
    }

    const addToProduct = (datos: any) => {
        const newArray = JSON.parse(JSON.stringify(products))
        if (datos.valido === 1) {
            const paymentClone = JSON.parse(JSON.stringify(datos.addenda))
            if (!paymentExist(newArray, paymentClone.pago_id) && !paymentExist(previousPayments, paymentClone.pago_id)) {
                newArray.push(paymentClone)
            } else {
                toast.current?.show({ severity: 'error', summary: 'Pago Agregado', detail: 'El Pago que intentas agregar ya esta agregado.', life: 3000 });
            }

            const actosClone: any = JSON.parse(JSON.stringify(preLoadActos))
            actosClone.push(datos.actos)
            SetPreLoadActos(actosClone)
        } else {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: datos.mensaje, life: 3000 });
            if (datos.mensaje === 'Pago ya utilizado') {
            } else {
                const paymentClone = JSON.parse(JSON.stringify(datos.addenda))
                paymentClone.actos = (datos && datos.actos) ? datos.actos : []
                paymentClone.pago_status = 3
                newArray.push(paymentClone)
            }
        }

        setProducts(newArray)
    }

    function paymentExist(array: any, id: any) {
        if (array && array.length > 0)
            for (let i = 0; i < array.length; i++) {
                if (array[i].pago_id === id) {
                    return true;
                }
            }
        return false;
    }

    const showUsedPayment = (data: any) => {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
    }

    const removeFromProduct = (id: any) => {
        const newArray = JSON.parse(JSON.stringify(products))

        for (let i = 0; i < newArray.length; i++) {
            if (newArray[i].id === id) {
                newArray.splice(i, 1);
                break;
            }
        }

        setProducts(newArray)
        showSuccess()
    }


    const VerificaTemplate = (rowData: any) => {
        if (rowData)
            return (
                <>
                    {/* icon: se utiliza para hacer la espera del resultado. si fue verificado o no. paso 1 icon */}
                    {rowData.pago_status === 2 &&
                        <i className='pi pi-sync pi-spin'></i>
                    }
                    {/* Si es ok, solo muestra el texto que si pasó */}
                    {rowData.pago_status === 1 &&
                        < div className='flex'>
                            <Button label='' className="p-button-text">< p className='verde'>Verificado</p ></Button>
                            <Button icon="pi pi-trash" tooltip="Eliminar pago" className="p-button-rounded p-button-text p-button-danger" onClick={() => { removeFromProduct(rowData.id) }} />
                        </div >
                    }
                    {/* si no pasó, muestra el div, el usuario tiene que eliminar para tener todo ok, y haga el siguiente paso */}
                    {rowData.pago_status === 3 &&
                        < div className='flex'>
                            <Button label='No verificado' tooltip="Tu pago ya fue utilizado" className="p-button-text p-button-danger" />
                            <Button icon="pi pi-trash" tooltip="Eliminar pago" className="p-button-rounded p-button-text p-button-danger" onClick={() => { removeFromProduct(rowData.id) }} />
                        </div >
                    }

                </>
            );
    }

    const searchFolio = () => {
        let datosInfo
        datosInfo = {
            prop_folio: '10',
            prop_online: 0
        }
        ApiServices.apiPost('getBuscaFolio', datosInfo).then(async res => {
            const navigateOptions = {
                state: {
                    datos: res.data.addenda,
                    pagos: products,
                    actos: preLoadActos
                }
            };
            navigate('/ventanilla/vt-tramite', navigateOptions)
        }).catch(error => {
            console.log(error)
        })
    }

    const getActosInformation = async () => {
        const idToSearch = responseData ? responseData.trm_id : preTramite ? preTramite.trm_id : null
        if (idToSearch) {
            let datos = {
                trm_id: idToSearch,
                ptrm_id: 0
            }
            await ApiServices.apiPost('getFormatosResp', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda.length > 0) {
                    const newArray: any = []

                    res.data.addenda.map(async (singleElement: any) => {
                        if (singleElement.trm_id === idToSearch) {
                            newArray.push(singleElement)
                        }
                    })
                    matchSavedActos(newArray)
                    /* setActosArray(newArray) */
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    function findByActId(array: any, targetActId: any) {
        return array.find((item: any) => item.act_id == targetActId);
    }

    const [totalActos, setTotalActos] = useState(0)

    function mergeArraysByActId(array1: any, array2: any) {
        const mapArray2 = new Map(array2.map((obj: any) => [obj.act_id, obj]));
        const mergedArray = array1.map((obj1: any) => {
            const obj2 = mapArray2.get(obj1.act_id);
            return obj2 && typeof obj1 === 'object' && typeof obj2 === 'object' ? { ...obj1, ...obj2 } : obj1;
        });
        return mergedArray;
    }

    const matchSavedActos = (newActos: any) => {
        let newArray = []
        if (newActos && newActos.length > 0) {
            let total = 0;
            const matchedActos = newActos.map((singleActo: any) => {
                const match = findByActId(actosListOriginal, singleActo.act_id)
                if (match) {
                    singleActo.act_umas = match.act_umas
                    singleActo.act_dias = match.act_dias
                    singleActo.cnpg_id = match.cnpg_id
                    singleActo.area_id = match.area_id
                    singleActo.act_status = match.act_status
                    singleActo.act_update = match.act_update
                    singleActo.act_pesos = match.valormx

                    if (match.valormx) {
                        total = total + parseFloat(match.valormx)
                    }
                }

                return singleActo
            })

            if (actosArray.length > 0)
                newArray = mergeArraysByActId(actosArray, matchedActos)
            else
                newArray = matchedActos
            setTotalActos(total)
            setActosArray(newArray)
        }
    }

    const [actosListOriginal, setActosListOriginal] = useState([])

    const getActosList = async () => {
        try {
            let datos;

            const res1 = await ApiServices.apiPost('catActo', datos);
            setActosListOriginal(res1.data.addenda);

            const res2 = await ApiServices.apiPost('catCategoriaActo', datos);
            let generalInformation: any = [];

            if (res2.data.addenda && res2.data.addenda.length > 0) {
                const clonedArray = JSON.parse(JSON.stringify(res2.data.addenda))
                clonedArray.unshift({ ccatacto_id: 0, ccatacto_desc: 'General', ccatacto_status: 1 });

                for (const singleAct of clonedArray) {
                    let datos = { ccatacto_id: singleAct.ccatacto_id };

                    try {
                        const res3 = await ApiServices.apiPost('catActoCat', datos);

                        if (res3.data.addenda && res3.data.addenda) {
                            const formattedActs = JSON.parse(JSON.stringify(res3.data.addenda))
                            const newArray = formattedActs.map((singleAct: any) => {
                                return {
                                    key: singleAct.act_id,
                                    label: `${singleAct.act_clave} ${singleAct.act_nombre}`,
                                    data: singleAct,
                                    icon: ""
                                };
                            });

                            generalInformation.push({
                                key: singleAct.ccatacto_id,
                                label: singleAct.ccatacto_desc,
                                data: singleAct,
                                icon: "",
                                children: newArray,
                                selectable: false
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                setActosList(generalInformation);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const requestPreTramiteDetail = () => {
        if (preTramite) {
            let datos
            datos = { code: 204, trm_id: preTramite.trm_id }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    setEscrituraNumber(res.data.addenda.tramite.trm_escritura)
                    setRequestName(res.data.addenda.tramite.trm_solicitante)

                    if (res.data.addenda.hijos && res.data.addenda.hijos > 0) {
                        setTotalFolios(res.data.addenda.hijos)
                    }

                    if (res?.data?.addenda?.tramite?.not_id)
                        searchNotaryFromDetail(res.data.addenda.tramite.not_id)
                }
            }).catch(error => {
                console.log(error)
            })
        } else if (responseData) {
            let datos
            datos = { code: 204, trm_id: preTramite.trm_id }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    setEscrituraNumber(res.data.addenda.tramite.trm_escritura)
                    setRequestName(res.data.addenda.tramite.trm_solicitante)
                    if (res.data.addenda.hijos && res.data.addenda.hijos > 0) {
                        setTotalFolios(res.data.addenda.hijos)
                    }

                    if (res?.data?.addenda?.tramite?.not_id)
                        searchNotaryFromDetail(res.data.addenda.tramite.not_id)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    /* OLD */

    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<any>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [modalSoliInter, ModalSoliInter] = useState<boolean>(false);
    const buttonEl = useRef<any>(null);

    const [totalFolios, setTotalFolios] = useState([])
    const [titularesResp, setTitularesResp] = useState()
    const [timeLine, setTimeLine] = useState<any>()

    const events1 = [
        { label: 'Capturado' },
        { label: 'Asignado' },
        { label: 'Analizando' },
        { label: 'A-R-C' },
        { label: 'Firmado' }
    ];

    const events2 = [
        { label: 'Capturado', tooltip: timeLine ? timeLine.capturista : '', time: timeLine ? timeLine.trm_fecha : '' },
        { label: 'Asignado', tooltip: timeLine ? timeLine.analista : '' },
        { label: 'Analizando', tooltip: '', time: timeLine ? timeLine.trm_analizado : '' },
        { label: 'A-R-C', tooltip: timeLine ? timeLine.statusARC : '', time: timeLine ? timeLine.fechahoraARC : '' },
        { label: 'Firmado', tooltip: timeLine ? timeLine.trm_firmador : '', time: timeLine ? timeLine.trm_firmado : '' }
    ];
    const accept = () => {
        ModalSetVisible(true)
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado tu trámite', life: 3000 });
    }

    const fileToBig = () => {
        toast.current.show({ severity: 'error', summary: 'Archivo muy grande', detail: 'El Archivo excede los 5MB', life: 3000 });
    }

    const customizedMarker = (item: any) => {
        return (
            <div>
                {/* circulo vasio: se utilizara cuando no tiene un proceso todavia realizado */}
                <i className='pi pi-circle'></i>
                {/* circulo lleno: aparecera cuando el estatus se valla llenando, en cada punto tiene que aparecer un toolpin con el estatus de quien lo reviso y la hora de movimiento */}
                <Button className='p-button-text tooltip-view' type="button" icon="pi pi-circle-fill" tooltipOptions={{ position: 'bottom' }} />
                <Tooltip target=".tooltip-view" autoHide={false}>
                    <div className="p-3">
                        <h5>Capturado</h5>
                        <p>Juan Montes Torres <br />
                            <small>12 Marz 2022 12:00pm</small></p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const footerContent = (
        <div>
            <Button label="Cerrar" onClick={() => { { setVisible(false); showSuccess() } }} autoFocus />
        </div>
    );

    const [notaryList, setNotaryList] = useState([])
    const [selectedNotaryList, setSelectedNotaryList] = useState<any>(null)
    const [requestName, setRequestName] = useState('')


    const getNotaryList = async () => {
        let datos
        await ApiServices.apiPost('catNotarios', datos).then(async res => {
            setNotaryList(res.data.addenda)
            searchNotary(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const searchNotary = (notarys: any) => {
        if (notarys && notarys.length > 0) {
            notarys.map((element: any) => {
                if (element.not_id === user.not_id) {
                    setSelectedNotaryList(element)
                }
            })
        }
    }

    const searchNotaryFromDetail = (id: any) => {
        if (notaryList && notaryList.length > 0) {
            notaryList.map((element: any) => {
                if (element.not_id === id) {
                    setSelectedNotaryList(element)
                }
            })
        }
    }

    const getActosArray = () => {
        if (actosArray && actosArray.length > 0) {
            const ids = actosArray.map((element: any) => {
                return element.act_id
            })
            return ids
        }
    }

    const getPagosArray = () => {
        if (previousPayments && previousPayments.length > 0) {
            const ids = previousPayments.map((element: any) => {
                return element.pago_id
            })
            return ids
        }
    }

    const getHijosArray = () => {
        if (totalFolios && totalFolios.length > 0) {
            const final = totalFolios.map((singleFolio: any) => {
                return singleFolio.prop_id
            })
            return final
        } else
            return []
    }

    function obtenerFechaYHoraActualEnFormato(data) {
        const mesesEnEspanol = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        // Parsear las fechas y horas del data
        const fechaActual = data && data.trm_fecha && new Date(data.trm_fecha);
        const horaActual = data && data.trm_hora && new Date(data.trm_hora);

        if (fechaActual || horaActual) {


            const dia = fechaActual.getDate();
            const mes = mesesEnEspanol[fechaActual.getMonth()];
            const anio = fechaActual.getFullYear();
            let hora = horaActual.getUTCHours(); // Usar UTC para la hora
            const minutos = horaActual.getUTCMinutes(); // Usar UTC para los minutos
            const segundos = horaActual.getUTCSeconds(); // Usar UTC para los segundos
            let periodo = "am";

            // Convertir la hora a formato de 12 horas y ajustar el periodo (am/pm)
            if (hora >= 12) {
                periodo = "pm";
                if (hora > 12) {
                    hora -= 12;
                }
            }
            // Ajustar para mostrar la hora "12" correctamente en formato de 12 horas
            if (hora === 0) {
                hora = 12;
            }

            // Agregar un cero al principio si los minutos o segundos son menores a 10
            const minutosFormateados = minutos < 10 ? "0" + minutos : minutos;
            const segundosFormateados = segundos < 10 ? "0" + segundos : segundos;

            const fechaYHoraFormateada = `${dia + 1} ${mes} ${anio} a las ${hora}:${minutosFormateados}:${segundosFormateados} ${periodo}`;
            return fechaYHoraFormateada;
        } else {
            return ''
        }
    }

    const user = authGuardService.getUser()

    const handleAllActosToSave = (tramite: any) => {

        const actos = getActosArray()

        if (actosArray && actosArray.length) {
            actosArray.map((singleActo: any) => {
                if (!(singleActo.frmu_id))
                    handleSave(singleActo, tramite)
            })
        }
    }

    const handleSave = (selectedForm: any, tramite: any) => {
        let datos: any

        datos = {
            code: 401,
            usu_id: user.usu_id,
            act_id: selectedForm.act_id,
            campos: [],
            ptrm_id: 0,
            trm_id: tramite.trm_id
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
        }).catch(error => {
            console.log(error)
        })

    }

    const handleSaveTramite = () => {
        const actos = getActosArray()
        const pagos = getPagosArray()
        const hijos = getHijosArray()

        const propId = previousFolio && previousFolio[0].prop_id

        let datos: any = {
            code: 203,
            usu_id: user.usu_id,
            prop_id: propId || 0,
            trm_solicitante: requestName || selectedNotaryList.nombre_notario,
            not_id: selectedNotaryList ? selectedNotaryList.not_id : 0,
            trm_escritura: escrituraNumber,
            actos: actos || [],
            pagos: pagos || [],
            hijos: hijos || []
        }

        console.log(datos)
        if (requestName || selectedNotaryList) {
            setModalBorradorGuardado(true)
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido) {
                    setResponseData(res.data.addenda)
                    handleAllActosToSave(res.data.addenda)
                    getTimeLine(res.data.addenda.trm_id)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            toast.current.show({ severity: 'error', summary: 'Nombre del Solicitante', detail: 'Debes de ingresar el nombre del solicitante', life: 3000 });
        }
    }

    const handleSaveSavedTramite = () => {
        /* if (escrituraNumber) { */

        const actos = getActosArray()
        const pagos = getPagosArray()

        const propId = previousFolio ? previousFolio[0].prop_id : preTramite.prop_id

        let datos: any = {
            code: 209,
            trm_id: preTramite ? preTramite.trm_id : responseData.trm_id,
            usu_id: user.usu_id,
            prop_id: propId,
            trm_solicitante: requestName,
            not_id: selectedNotaryList ? selectedNotaryList.not_id : 0,
            trm_escritura: escrituraNumber,
            actos: actos || [],
            pagos: pagos || [],
        }
        if (requestName) {
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido) {
                    setResponseData(res.data.addenda)
                    setModalBorradorGuardado(true)
                    SavePDFentrada()
                    handleAllActosToSave(res.data.addenda)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            toast.current.show({ severity: 'error', summary: 'Nombre del Solicitante', detail: 'Debes de ingresar el nombre del solicitante', life: 3000 });
        }
    }

    const SavePDFentrada = async () => {
        /* let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: preTramite ? preTramite.trm_id : responseData.trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: res.data.url[0].mensaje, life: 3000 });
                } else {
                    if (typeof res.data.url === 'object') {
                        window.open(`${configuration.url}${res.data.url[0].url}`)
                    } else {
                        window.open(`${configuration.url}${res.data.url}`)
                    }
                }
            }
        }).catch(error => {
            console.log(error)
        }) */
    }

    const [modalNewScript, setModalNewScript] = useState(false)
    const [responseData, setResponseData] = useState<any>()

    const handlePreviousActos = () => {
        const arrayListSaved = preTramite.actos.split(', ')
        const newArray = JSON.parse(JSON.stringify(actosArray))

        if (arrayListSaved && arrayListSaved.length > 0) {
            arrayListSaved.map((singleAct: any) => {
                const foundItem = actosArray.find((item: any) => item.act_id == singleAct);
                if (!foundItem) {
                    const elementToPush = actosListOriginal.find((item: any) => item.act_clave == singleAct);
                    newArray.push(elementToPush)
                }
            })
        }

        matchSavedActos(newArray)
    }

    const handlePreviousActosPayment = () => {
        const actIdsArray = location.state.preLoadActos[0].map((item: any) => item.act_id);

        const newArray = JSON.parse(JSON.stringify(actosArray))

        if (actIdsArray && actIdsArray.length > 0) {
            actIdsArray.map((singleAct: any) => {
                const foundItem = actosArray.find((item: any) => item.act_id == singleAct);
                if (!foundItem) {
                    const elementToPush = actosListOriginal.find((item: any) => item.act_id == singleAct);
                    newArray.push(elementToPush)
                }
            })
        }

        matchSavedActos(newArray)
    }

    useEffect(() => {
        getActosList()
        getNotaryList()
        if (preTramite && preTramite.trm_id)
            getTimeLine(preTramite.trm_id)
    }, [])


    const getTimeLine = (id: any) => {
        if (id) {
            let datos = {
                trm_id: id,
            }
            ApiServices.apiPost('getTimeLine', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                    setTimeLine(res.data.addenda[0])
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    useEffect(() => {
        if (preTramite && preTramite.actos && actosListOriginal && actosListOriginal.length > 0 && actosArray) {
            handlePreviousActos()
            getTitulares()
        } else if (location && location.state.preLoadActos && location.state.preLoadActos.length > 0 && actosListOriginal && actosListOriginal.length > 0 && actosArray) {
            handlePreviousActosPayment()
            getTitulares()
        }
    }, [actosListOriginal, preTramite, location.state.preLoadActos])

    const getTitulares = () => {
        let datos = {
            prop_id: preTramiteDetail ? preTramiteDetail.tramite.prop_id : responseData ? responseData.prop_id : previousFolio ? previousFolio[0].prop_id : 0

        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (preTramiteDetail || responseData || previousFolio) {
            let datos = {
                prop_id: preTramiteDetail ? preTramiteDetail.tramite.prop_id : responseData ? responseData.prop_id : previousFolio ? previousFolio[0].prop_id : 0

            }
            ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
                setTitularesResp(res.data.addenda)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [preTramiteDetail, responseData, previousFolio])


    useEffect(() => {
        if (preTramite) {
            let datos2
            datos2 = { code: 204, trm_id: preTramite.trm_id }

            datos2 = EncryptService.encryptData(JSON.stringify(datos2));
            ApiServices.processRequestAdd(datos2).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    setEscrituraNumber(res.data.addenda.tramite.trm_escritura)
                    setRequestName(res.data.addenda.tramite.trm_solicitante)
                    if (res.data.addenda.hijos) {
                        setTotalFolios(res.data.addenda.hijos)
                    }

                    if (res?.data?.addenda?.tramite?.not_id)
                        searchNotaryFromDetail(res.data.addenda.tramite.not_id)

                    if (preTramite.ptrm_status === 3 && res.data.addenda) {
                        msgs.current.show(
                            {
                                sticky: true,
                                severity: 'error',
                                summary: 'PreTramite Rechazado / ',
                                detail: `Motivo: ${res.data.addenda.trm_nota || 'Sin Motivo'}`,
                                closable: false,
                            }
                        );
                    } else if (preTramite.ptrm_status === 4 && res.data.addenda) {
                        msgs.current.show(
                            {
                                sticky: true,
                                severity: 'warn',
                                summary: 'PreTramite Rechazado / ',
                                detail: `Motivo: ${res.data.addenda.ptrm_nota || 'Sin Motivo'}`,
                                closable: false,
                            }
                        );
                    }
                }
            }).catch(error => {
                console.log(error)
            })

            if (preTramite.prop_folio) {
                let datos = {
                    prop_folio: preTramite.prop_folio,
                    prop_online: 0
                }
                ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                    setPreTramiteFolio(res.data.addenda)
                    setActiveIndex(0)
                }).catch(error => {
                    console.log(error)
                })
            } else {
                let datos = {
                    prop_id: preTramite.prop_id,
                }
                ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
                    let datos = {
                        prop_folio: res.data.addenda[0].prop_folio,
                        prop_online: 0
                    }
                    ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                        setPreTramiteFolio(res.data.addenda)
                        setActiveIndex(0)
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(error => {
                    console.log(error)
                })

                let datos2 = {
                    prop_id: preTramite.prop_id

                }
                ApiServices.apiPost('getTitularesFolio', datos2).then(async res => {
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }, [preTramite])

    const [requester, setRequester] = useState(true)

    useEffect(() => {
        getActosInformation()
    }, [actosListOriginal])

    const acceptTramite = () => {
        if (responseData || preTramite) {
            handleSaveSavedTramite()
        } else {
            handleSaveTramite()
        }
    };

    const buttonConfirm = useRef<any>(null);
    const [confirmButton, setConfirmButton] = useState(false)

    const SavePDFinsc = async () => {
        const id = preTramite ? preTramite.trm_id : responseData ? responseData.trm_id : 0

        let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    if (typeof res.data.url === 'object') {
                        if (res.data.url && res.data.url.length > 0) {
                            res.data.url.forEach((single: any) => {
                                window.open(`${configuration.url}${single.url}`, '_blank');
                            });
                        }
                    } else {
                        window.open(`${configuration.url}${res.data.url}`, '_blank')
                    }
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const controlInternoBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.trm_fecha);
        const horaUTC = new Date(rowData.trm_hora);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();
        const hours = horaUTC.getUTCHours().toString().padStart(2, '0');
        const minutes = horaUTC.getUTCMinutes().toString().padStart(2, '0');
        const seconds = horaUTC.getUTCSeconds().toString().padStart(2, '0');


        const fechaFormateada = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

        return (
            <div className="actions">
                <p>{`${rowData.trm_consecutivo} - ${fechaFormateada}`}</p>
            </div>
        )
    }

    const addPayment = async () => {
        if (preTramite ? preTramite.trm_id : responseData && responseData.trm_id) {
            if (products && products.length > 0) {
                for (const singlePayment of products) {
                    let datos = {
                        trm_id: preTramite ? preTramite.trm_id : responseData && responseData.trm_id,
                        pago_id: singlePayment.pago_id,
                        usu_id: user.usu_id
                    }
                    await ApiServices.apiPost('addPago', datos).then(async res => {

                    }).catch(error => {
                        console.log(error)
                    })
                }
                window.location.reload();
            }
        } else {
            if (products && products.length > 0) {
                const newPagos = JSON.parse(JSON.stringify(previousPayments))
                products.map((single: any) => {
                    newPagos.push(single)
                })
                setPreviousPayments(newPagos)
            }
            setProducts([])
            setNewPaymentModal(false)
        }
    }


    return (
        <section>
            <Toast ref={toast} />

            <div className='container-fluid'>
                <div className='flex justify-content-between'>
                    {/* cuando sean folios de calidad aparecera el color verde se mostrara como el div, si no. se negara el small y el div 0.1 siguiente */}

                    {previousFolio &&
                        <div className='flex col-12 md:col-4'>
                            {previousFolio[0] && previousFolio[0].prop_calidad == 1 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle verde'></i>
                                    <div className='line'></div>
                                </div>
                            }

                            {previousFolio[0] && previousFolio[0].prop_calidad == 2 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle rojo'></i>
                                    <div className='lineRed'></div>
                                </div>
                            }

                            {previousFolio[0] && previousFolio[0].prop_calidad == 3 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle naranja'></i>
                                    <div className='lineOrange'></div>
                                </div>
                            }

                            <div className={
                                (previousFolio[0] && previousFolio[0].prop_calidad == 1) ? 'verde' :
                                    (previousFolio[0] && previousFolio[0].prop_calidad == 2) ? 'rojo' :
                                        (previousFolio[0] && previousFolio[0].prop_calidad == 3) ? 'naranja' :
                                            ''
                            }>
                                <h3 className={
                                    (previousFolio[0] && previousFolio[0].prop_calidad == 1) ? 'verde' :
                                        (previousFolio[0] && previousFolio[0].prop_calidad == 2) ? 'rojo' :
                                            (previousFolio[0] && previousFolio[0].prop_calidad == 3) ? 'naranja' :
                                                ''
                                }>Folio {previousFolio[0] ? previousFolio[0].prop_folio : ''}</h3>
                                {previousFolio[0] && previousFolio[0].prop_calidad == 1 &&
                                    <small>Este es un folio de calidad</small>
                                }
                                {previousFolio[0] && previousFolio[0].prop_calidad == 2 &&
                                    <h6>Este es un folio cerrado</h6>
                                }
                                {previousFolio[0] && previousFolio[0].prop_calidad == 3 &&
                                    <h6>Advertencia</h6>
                                }
                                <div className='col-12'><p><span className='font-bold'>Numero de Control: </span>{preTramiteDetail && preTramiteDetail.tramite && controlInternoBodyTemplate(preTramiteDetail.tramite)}</p></div>
                                {
                                    previousFolio && previousFolio[0] && previousFolio[0].prop_status == 2 &&
                                    <>
                                        <Chip label="Tramite Pendiente" />
                                    </>
                                }
                            </div>
                        </div>
                    }

                    {preTramiteFolio &&
                        <div className='flex col-12 md:col-4'>
                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle verde'></i>
                                    <div className='line'></div>
                                </div>
                            }

                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle rojo'></i>
                                    <div className='lineRed'></div>
                                </div>
                            }

                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle naranja'></i>
                                    <div className='lineOrange'></div>
                                </div>
                            }

                            <div className={
                                (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1) ? 'verde' :
                                    (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2) ? 'rojo' :
                                        (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3) ? 'naranja' :
                                            ''
                            }>
                                <h3 className={
                                    (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1) ? 'verde' :
                                        (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2) ? 'rojo' :
                                            (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3) ? 'naranja' :
                                                ''
                                }>Folio {preTramiteFolio[0] ? preTramiteFolio[0].prop_folio : ''}</h3>
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1 &&
                                    <small>Este es un folio de calidad</small>
                                }
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2 &&
                                    <h6>Este es un folio cerrado</h6>
                                }
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3 &&
                                    <h6>Advertencia</h6>
                                }
                                <div className='col-12'><p><span className='font-bold'>Numero de Control: </span>{preTramiteDetail && preTramiteDetail.tramite && controlInternoBodyTemplate(preTramiteDetail.tramite)}</p></div>
                                {
                                    previousFolio && previousFolio[0] && previousFolio[0].prop_status == 2 &&
                                    <>
                                        <Chip label="Tramite Pendiente" />
                                    </>
                                }
                            </div>
                        </div>
                    }

                    <div className='col-12 md:col-6'>
                        {timeLine &&
                            <TimeLine timeLine={timeLine} />
                        }
                    </div>

                    <div className='flex justify-content-end flex-wrap'>
                    </div>

                </div>
                {
                    (preTramite || responseData) &&
                    <div className='flex justify-content-end flex-wrap'>
                        <div className='position-btns'>
                            <div className='flex justify-content-end flex-wrap'>
                                <div className='pr-3'>
                                    {
                                        timeLine && timeLine.trm_analizado &&
                                        <Button onClick={() => { SavePDFinsc() }} tooltip="Impresión de folio" tooltipOptions={{ position: 'top' }} severity="secondary" icon="pi pi-print" rounded />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Messages ref={msgs} />

                <div className=''>
                    <div className="tabs">
                        <Tabs>
                            <Tab label="Datos Generales">
                                <div>
                                    {previousFolio &&
                                        <ResulGenerales
                                            folioDatos={previousFolio[0]}
                                            notaryList={notaryList}
                                            setNotaryList={setNotaryList}
                                            selectedNotaryList={selectedNotaryList}
                                            setSelectedNotaryList={setSelectedNotaryList}
                                            escrituraNumber={escrituraNumber}
                                            setEscrituraNumber={setEscrituraNumber}
                                            requestName={requestName}
                                            setRequestName={setRequestName}
                                            exist={responseData}
                                            preTramiteDetail={preTramiteDetail}
                                            requestPreTramiteDetail={requestPreTramiteDetail}
                                            fileToBig={fileToBig}
                                            requester={requester}
                                            setRequester={setRequester}
                                            titulares={titularesResp}
                                        />
                                    }{
                                        preTramiteFolio &&
                                        <ResulGenerales
                                            folioDatos={preTramiteFolio[0]}
                                            notaryList={notaryList}
                                            setNotaryList={setNotaryList}
                                            selectedNotaryList={selectedNotaryList}
                                            setSelectedNotaryList={setSelectedNotaryList}
                                            escrituraNumber={escrituraNumber}
                                            setEscrituraNumber={setEscrituraNumber}
                                            requestName={requestName}
                                            setRequestName={setRequestName}
                                            exist={preTramite}
                                            preTramiteDetail={preTramiteDetail}
                                            requestPreTramiteDetail={requestPreTramiteDetail}
                                            fileToBig={fileToBig}
                                            requester={requester}
                                            setRequester={setRequester}
                                            titulares={titularesResp}
                                        />
                                    }
                                    {
                                        !preTramiteFolio && !previousFolio &&
                                        <ResulGenerales
                                            folioDatos={[]}
                                            notaryList={notaryList}
                                            setNotaryList={setNotaryList}
                                            selectedNotaryList={selectedNotaryList}
                                            setSelectedNotaryList={setSelectedNotaryList}
                                            escrituraNumber={escrituraNumber}
                                            setEscrituraNumber={setEscrituraNumber}
                                            requestName={requestName}
                                            setRequestName={setRequestName}
                                            exist={preTramite}
                                            preTramiteDetail={preTramiteDetail}
                                            requestPreTramiteDetail={requestPreTramiteDetail}
                                            fileToBig={fileToBig}
                                            requester={requester}
                                            setRequester={setRequester}
                                            titulares={titularesResp}
                                        />
                                    }
                                </div>
                            </Tab>
                            <Tab label="Actos">
                                <div>
                                    {previousFolio &&
                                        < ResulActos
                                            previousFolio={previousFolio}
                                            previousPayments={previousPayments}
                                            actos={actosArray}
                                            actosList={actosList}
                                            actosFunction={setActosArray}
                                            responseData={responseData}
                                            preTramite={preTramite}
                                            requestActos={getActosInformation}
                                            preTramiteFolio={preTramiteFolio}
                                            total={totalActos}
                                            totalFunction={setTotalActos}
                                            preTramiteDetail={preTramiteDetail}
                                            totalFolios={totalFolios}
                                            setTotalFolios={setTotalFolios}
                                            titulares={titularesResp}
                                            eliminarPagoPorId={eliminarPagoPorId}
                                            selectedNotaryList={selectedNotaryList}
                                        />
                                    }
                                    {preTramiteFolio &&
                                        < ResulActos
                                            previousFolio={previousFolio}
                                            previousPayments={previousPayments}
                                            actos={actosArray}
                                            actosList={actosList}
                                            actosFunction={setActosArray}
                                            responseData={responseData}
                                            preTramite={preTramite}
                                            requestActos={getActosInformation}
                                            total={totalActos}
                                            totalFunction={setTotalActos}
                                            preTramiteDetail={preTramiteDetail}
                                            preTramiteFolio={preTramiteFolio}
                                            totalFolios={totalFolios}
                                            setTotalFolios={setTotalFolios}
                                            titulares={titularesResp}
                                            eliminarPagoPorId={eliminarPagoPorId}
                                            selectedNotaryList={selectedNotaryList}
                                        />
                                    }
                                    {!preTramiteFolio && !previousFolio &&
                                        < ResulActos
                                            previousFolio={previousFolio}
                                            previousPayments={previousPayments}
                                            actos={actosArray}
                                            actosList={actosList}
                                            actosFunction={setActosArray}
                                            responseData={responseData}
                                            preTramite={preTramite}
                                            requestActos={getActosInformation}
                                            total={totalActos}
                                            totalFunction={setTotalActos}
                                            preTramiteDetail={preTramiteDetail}
                                            preTramiteFolio={preTramiteFolio}
                                            totalFolios={totalFolios}
                                            setTotalFolios={setTotalFolios}
                                            titulares={titularesResp}
                                            eliminarPagoPorId={eliminarPagoPorId}
                                        />
                                    }
                                </div>
                            </Tab>
                            <Tab label="Titulares">
                                <div>
                                    <ResulTitulares
                                        previousFolio={previousFolio}
                                        previousPayments={previousPayments}
                                        responseData={responseData}
                                        preTramite={preTramite}
                                        preTramiteDetail={preTramiteDetail}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div >

            <div className='footer-folio flex justify-content-end'>
                <div className='flex col-2'>
                    <Button
                        label="Agregar Pago"
                        onClick={() => setNewPaymentModal(true)}
                    />
                </div>
                <div className='flex col-4'>
                    <ConfirmPopup
                        target={buttonConfirm.current}
                        visible={confirmButton}
                        onHide={() => setConfirmButton(false)}
                        message="¿Estas seguro que los datos ingresados que son correctos?"
                        icon="pi pi-exclamation-triangle"
                        accept={acceptTramite}
                        reject={() => setConfirmButton(false)}
                        acceptLabel={'Si'}
                        rejectLabel={'No'}
                        acceptClassName={'w-3'}
                    />
                    <Button
                        ref={buttonConfirm}
                        label="Enviar a Revisión"
                        onClick={() => setConfirmButton(true)}
                        disabled={!((actosArray && actosArray.length > 0) && (selectedNotaryList || requestName))}
                    />
                </div>
            </div>

            {/* Guardado con éxito  */}
            <Dialog
                header="Tu trámite ha sido guardado con éxito"
                visible={modalBorradorGuardado}
                style={{ width: '30vw' }}
                onHide={() => {
                    setModalBorradorGuardado(false)
                    navigate('/')
                }}
                footer={
                    <div>
                        <Button label="Cerrar" onClick={() => {
                            setModalBorradorGuardado(false)
                            navigate('/')
                        }} autoFocus />
                    </div>
                }
            >
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        {!responseData &&
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" animationDuration=".5s" />
                        }
                        <p>{responseData ? `${responseData.trm_consecutivo}` : ''}</p>
                        <p>{responseData ? `Control Interno` : ''}</p>
                    </div>
                    <div>
                        <p className='m-0'>{obtenerFechaYHoraActualEnFormato(responseData)}</p>
                        <small>Fecha y hora de registro</small>
                    </div>

                    <div style={{ marginTop: 10 }}>
                        <p className='m-0'>Asignado:</p>
                        <p className='m-0'>{responseData ? `${responseData.trm_analista ? `${responseData.usu_nom} ${responseData.usu_pat}` : ''}` : ''}</p>
                        <small>{responseData ? `${responseData.usu_trab_id || ''}` : ''}</small>
                    </div>
                </div>
            </Dialog>

            {/* Agregar PAGO */}
            <Dialog header="Agregar un Pago" visible={newPaymentModal} style={{ width: '50vw' }} onHide={() => setNewPaymentModal(false)}>
                <section className='container-fluid'>
                    <Toast ref={toast} />
                    <div className='flex justify-content-center align-items-center'>
                        <div className='col-12 md:col-10'>
                            <div className='grid'>

                                {hasPayment && !allDone &&
                                    <>
                                        <div className='col-12 md:col-12'>
                                            <p>Ingresa el número de serie y folio de tu comprobante de pago.</p>
                                            <div className='grid'>
                                                <div className='col-12 md:col-4'>
                                                    <span className="p-float-label">
                                                        <Dropdown
                                                            value={selectedSerie}
                                                            onChange={(e) => setSelectedSerie(e.value)}
                                                            options={series}
                                                            optionLabel="name"
                                                            placeholder="Seleccciona una Serie"
                                                            className="p-inputtext-lg w-full"
                                                            virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='col-12 md:col-4'>
                                                    <span className="p-float-label">
                                                        <InputText
                                                            id="folio"
                                                            className="p-inputtext-lg"
                                                            value={folio}
                                                            onChange={(e) => { setFolio(e.target.value) }}
                                                            keyfilter={'int'}
                                                        />
                                                        <label htmlFor="folio">Folio</label>
                                                    </span>
                                                </div>
                                                <div className='col-12 md:col-4'>
                                                    <Button label="Agregar pago" size="large" outlined autoFocus onClick={searchPayment} disabled={!((selectedSerie) && (folio.length > 1))} />
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-12 md:col-12'>
                                            <p>Espera a que tus pagos ingresados sean verificados</p>
                                            <div className=' pb-4'>
                                                <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                                    emptyMessage="No products found." responsiveLayout="scroll">
                                                    <Column field="pago_folio" header="Folio" sortable  ></Column>
                                                    <Column field="pago_serie" header="Serie" sortable  ></Column>
                                                    <Column field="name" style={{ width: '10rem' }} body={(e) => VerificaTemplate(e)} ></Column>
                                                </DataTable>
                                            </div>
                                        </div>

                                        <div className='col-12 md:col-12 flex justify-content-center'>
                                            <div className='col-4'>
                                                <Button label="Continuar" size="large"
                                                    disabled={!(products && products.length > 0)}
                                                    onClick={addPayment} />
                                            </div>
                                        </div>

                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </Dialog>

        </section >
    );

}

export default React.memo(VenPagoFolio);


