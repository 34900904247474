import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';

interface City {
    name: string;
}

const TabNotificador = (props: any) => {
    const dt = useRef<any>(null);
    const [products, setProducts] = useState<any>(null);
    const [expandedRows, setExpandedRows] = useState<any>(null);


    useEffect(() => {
        const productService = new ProductService();
        productService.getProductsWithOrdersSmall().then(data => setProducts(data));
    }, []);


    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.code}
            </>
        );
    }

    const rowExpansionTemplate = (data: any) => {
        return (
            <div>
                <p>Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño. El punto de usar Lorem Ipsum es que tiene una distribución más o menos normal de las letras, al contrario de usar textos como por ejemplo "Contenido aquí, contenido aquí".

                    Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño. El punto de usar Lorem Ipsum es que tiene una distribución más o menos normal de las letras, al contrario de usar textos como por ejemplo "Contenido aquí, contenido aquí".</p>
            </div>
        );
    };

    const allowExpansion = (rowData: any) => {
        return rowData.orders.length > 0;
    };

    function formatFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${dia}-${mes}-${año}`;
        } else {
            return ''
        }
    }

    const fechaAlertBodyTemplate = (payment: any) => {
        return (
            <p>{formatFecha(payment.alinm_fecha)}</p>
        )
    }

    const fechaDuplicateBodyTemplate = (payment: any) => {
        return (
            <p>{formatFecha(payment.aldup_fecha)}</p>
        )
    }

    const descriptionBodyTemplate = (payment: any) => {
        return (
            <p>{payment.aldup_desc === 'undefined' ? '' : payment.aldup_desc}</p>
        )
    }


    return (
        <section>
            <div className='col-12 md:col-12'>
                <h3 className='color-violet'>Notificador</h3>
                <p>Notificador que son parte de este terreno.</p>
            </div>

            <div className='pt-4'>
                <DataTable value={props.notifications.alertas_inm} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id" tableStyle={{ minWidth: '60rem' }}>
                    <Column field="code" header="Tipo" sortable body={<p>Alerta Inmobiliaria</p>} style={{ width: '15%' }} ></Column>
                    <Column field="aldup_desc" header="Descripción" sortable style={{ width: '35%' }} body={descriptionBodyTemplate}></Column>
                    <Column field="alinm_fecha" header="Fecha registro" sortable style={{ width: '15%' }} body={fechaAlertBodyTemplate}></Column>
                    <Column field="alinm_email" header="Registrado por" sortable style={{ width: '35%' }}></Column>
                </DataTable>
                <DataTable value={props.notifications.alertas_dup} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id" tableStyle={{ minWidth: '60rem' }}>
                    <Column field="code" body={<p>Alerta Duplicidad</p>} style={{ width: '15%' }}></Column>
                    <Column field="aldup_desc" style={{ width: '35%' }} body={descriptionBodyTemplate}></Column>
                    <Column field="aldup_fecha" style={{ width: '15%' }} body={fechaDuplicateBodyTemplate}></Column>
                    <Column field="folios" style={{ width: '35%' }}></Column>
                </DataTable>
            </div>

        </section>
    );

}

export default React.memo(TabNotificador);