import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';

const OlvideContraseña = (props: any) => {
    const navigate = useNavigate();
    const toast = useRef<any>();

    const [stage, setStage] = useState(0)
    const [checkedData, setCheckedData] = useState(false)
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [user, setUser] = useState(null)

    function validarEmail(emailString: string) {
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    // Password Recovery /olvideContrasena?email=xxxxx&code=xxxx
    const [queryParameters] = useSearchParams()
    function checkDataLink() {
        setEmail(queryParameters.get("email") ?? '')
        setCode(queryParameters.get("code") ?? '')
        setCheckedData(true)
        if (email && code) {
            let datos
            datos = {
                code: 103,
                usu_email: email,
                codigo: code
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido == 1) {
                    setStage(2)
                    setUser(res.data.addenda.usu_id)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Codigo Invalido', detail: 'El codigo ingresado es incorrecto', life: 4000 });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Codigo Invalido', detail: 'El codigo ingresado es incorrecto', life: 4000 });
            })
        }
    }
    useEffect(checkDataLink, [checkedData])

    const handleRequestCode = () => {
        if (email) {
            if (validarEmail(email)) {
                let datos
                datos = {
                    code: 102,
                    usu_email: email
                }
                datos = EncryptService.encryptData(JSON.stringify(datos));
                ApiServices.processRequestAdd(datos).then(async res => {
                    if (res.data.valido == 1) {
                        toast.current.show({ severity: 'success', summary: 'Codigo Enviado', detail: 'Revisa tu correo para obtener tu codigo', life: 4000 });
                        setStage(1)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Correo Invalido', detail: 'Ingresa un correo valido', life: 4000 });
                    }
                }).catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Correo Invalido', detail: 'Ingresa un correo valido', life: 4000 });
                })
            } else {
                toast.current.show({ severity: 'error', summary: 'Correo Invalido', detail: 'Ingresa un correo valido', life: 4000 });
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Correo Invalido', detail: 'Ingresa un correo valido', life: 4000 });
        }
    }

    const handleValidateCode = () => {
        if (code) {
            let datos
            datos = {
                code: 103,
                usu_email: email,
                codigo: code
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido == 1) {
                    setStage(2)
                    setUser(res.data.addenda.usu_id)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Codigo Invalido', detail: 'El codigo ingresado es incorrecto', life: 4000 });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Codigo Invalido', detail: 'El codigo ingresado es incorrecto', life: 4000 });
            })
        } else {
            toast.current.show({ severity: 'error', summary: 'Codigo Invalido', detail: 'El codigo ingresado es incorrecto', life: 4000 });
        }
    }

    const handleNewPassword = () => {
        if (password && confirmPassword && password === confirmPassword) {
            let datos
            datos = {
                code: 104,
                usu_id: user,
                usu_psw: password
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido == 1) {
                    toast.current.show({ severity: 'success', summary: 'Contraseña Cambiada', detail: 'Tu contraseña fue cambiada con éxito', life: 4000 });
                    navigate('/login')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error al Cambiar', detail: 'Hubo un error al cambiar tu contraseña', life: 4000 });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error al Cambiar', detail: 'Hubo un error al cambiar tu contraseña', life: 4000 });
            })
        } else {
            toast.current.show({ severity: 'error', summary: 'Error al Cambiar', detail: 'Hubo un error al cambiar tu contraseña', life: 4000 });
        }
    }



    return (
        <section className='flex justify-content-center align-items-center'>
            <Toast ref={toast} />
            <div className='col-12 md:col-4 login'>
                <div className='grid'>
                    <div className='col-12 md:col-12 pb-5'>
                        <h1>Olvide contraseña</h1>
                    </div>

                    {stage == 0 &&
                        <>
                            <div className='col-12 md:col-12'>
                                <p>Ingresa tu correo electrónico para enviar un código de seguridad.</p>
                                <span className="p-float-label">
                                    <InputText className='p-inputtext-lg' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                    <label htmlFor="username">Correo electronico*</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-12'>
                                <div className='pb-5'>
                                    <Button label="Atras" className="p-button-link" />
                                </div>
                                <div className='pb-5'>
                                    <Button label='Enviar' onClick={handleRequestCode} />
                                </div>
                            </div>
                        </>
                    }

                    {stage == 1 &&
                        <>
                            <div className='col-12 md:col-12'>
                                <p>{`Ingresa el código que se envió al correo ${email} para iniciar el cambio de contraseña`}</p>
                                <div>
                                    <span className="p-float-label">
                                        <InputText className='p-inputtext-lg' value={code} onChange={(e) => { setCode(e.target.value) }} />
                                        <label htmlFor="username">Codigo*</label>
                                    </span>
                                </div>
                                <Button className='p-button-plain p-button-text' label="Enviar código nuevamente" onClick={handleRequestCode} />
                                <div className='col-12 md:col-12'>
                                    <div className='pb-5'>
                                        <Button label="Atras" className="p-button-link" onClick={() => { setStage(0) }} />
                                    </div>
                                    <div className='pb-5'>
                                        <Button label='Enviar' onClick={handleValidateCode} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {stage == 2 &&
                        <>
                            <div className='col-12 md:col-12'>
                                <p>Ingresa tu nueva contraseña, debe de contener mínimo  8 caracteres.</p>
                                <div className='pb-3'>
                                    <span className="p-float-label">
                                        <Password
                                            value={password}
                                            onChange={(e) => { setPassword(e.target.value) }}
                                            className='p-inputtext-lg'
                                            feedback={false}
                                        />
                                        <label htmlFor="username">Nueva contraseña*</label>
                                    </span>
                                </div>
                                <div className='pb-3'>
                                    <span className="p-float-label">
                                        <Password
                                            value={confirmPassword}
                                            onChange={(e) => { setConfirmPassword(e.target.value) }}
                                            className='p-inputtext-lg' feedback={false}
                                        />
                                        <label htmlFor="username">Confirmar contraseña*</label>
                                    </span>
                                </div>
                            </div>
                            <div className='col-12 md:col-12'>
                                <div className='pb-5'>
                                    <Button label='Enviar' onClick={handleNewPassword} />
                                </div>
                            </div>
                        </>
                    }


                    {/* <div className='col-12 md:col-12'>
                        <div className='pb-5'>
                            {stage != 2 &&
                                <Button label="Atras" className="p-button-link" />
                            }
                        </div>
                        <div className='pb-5'>
                            {stage == 2 ?
                                <Button label='Guardar' onClick={() => {
                                    toast.current.show({ severity: 'error', summary: 'Campos incompletos', detail: 'Ingresa tu correo y tu contraseña', life: 4000 });
                                }} />
                                :
                                <Button label='Enviar' />
                            }
                        </div>
                    </div> */}

                </div>
            </div>
        </section>

    );

}

export default React.memo(OlvideContraseña);