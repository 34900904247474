import React from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from "primereact/button";

const Rpp = (props: any) => {

    const convertLineBreaks = (text: String) => {
        if (text)
            return text.replace(/\r\n|\n|\r/g, '<br />');
        else
            return ''
    };

    return (
        <section>
            <div className="bor-hist">
                <ScrollPanel style={{ width: '100%', height: '600px' }} className="custombar1">
                    {props.list && props.list.length > 0 &&
                        props.list.map(({ concepto, Valor }: any) => {
                            return (
                                <div style={{ display: 'flex' }}>
                                    <p>{concepto}</p>
                                    <>&nbsp;</>
                                    <p dangerouslySetInnerHTML={{ __html: convertLineBreaks(Valor) }} />
                                </div>
                            )
                        })
                    }
                </ScrollPanel>
            </div>
        </section >
    )
};

export default React.memo(Rpp);