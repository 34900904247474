import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';
import { ToggleButton } from 'primereact/togglebutton';
import ApiServices from '../../../../../service/apiServices';
import EncryptService from '../../../../../service/encrypt.service';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

const ListTrabNotario = (props: any) => {

    useEffect(() => {
        setWorkersList(props.listWorkers)
    }, [props.listWorkers])

    useEffect(() => {
        getProfilesList()
    }, [])

    const [workersList, setWorkersList] = useState(props.listWorkers)
    const [profilesList, setProfilesList] = useState([])

    const getProfilesList = () => {
        ApiServices.apiPost('catPerfiles').then(async res => {
            if (res.data.addenda && res.data.addenda.length > 0) {
                setProfilesList(res.data.addenda)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    function obtenerNombrePorCodigo(array: any, codigo: any) {
        if (codigo) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].prf_id === codigo) {
                    return array[i].prf_desc;
                }
            }
            return '-';
        } else {
            return '-'
        }
    }

    const productService = new ProductService();
    const [products, setProducts] = useState([]);
    const [checked1, setChecked1] = useState(false);

    const changeNotStatus = (rowData: any) => {
        let datos
        datos = {
            "code": 107,
            "usu_id": rowData.usu_id,
            "usu_activo": rowData.usu_activo === 0 ? 1 : 0
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                props.getNotaryProfiles(rowData.not_id)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <ToggleButton checked={rowData.usu_activo == 0} onChange={(e) => { changeNotStatus(rowData) }} />
            </div>
        );
    }

    const profileTemplate = (rowData: any) => (
        <p>{obtenerNombrePorCodigo(profilesList, rowData.prf_id)}</p>
    );

    const nameTemplate = (rowData: any) => (
        <p>{`${rowData.usu_nom} ${rowData.usu_pat} ${rowData.usu_mat}`}</p>
    )


    return (
        <DataTable value={workersList} paginator rows={3}
            onSelectionChange={(e) => {
                const value = e.value as Product[];
            }} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
            <Column sortable body={nameTemplate} header="Nombre"></Column>
            <Column sortable body={profileTemplate} header="Perfil"></Column>
            <Column body={actionBodyTemplate} header="Desactivado" style={{ width: '5rem' }}></Column>
        </DataTable>

    );

}

export default React.memo(ListTrabNotario);