import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import ProductService from '../../../serviceExample/ProductService';
import { Tag } from 'primereact/tag';
import { useNavigate } from 'react-router-dom';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

const TraInicio = () => {

    const navigate = useNavigate();
    const user = authGuardService.getUser()

    const [listTramites, setListTramites] = useState([]);

    useEffect(() => {
        getList()
    }, []);


    const getList = () => {
        let datos
        datos = {
            code: 305,
            usu_id: user.usu_id,
            status: 1
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            setListTramites(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const actionBodyTemplate = (rowData: any) => {
        const navigateOptions = {
            state: {
                preTramite: rowData
            }
        };

        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => { navigate('/notarios/pago-folio', navigateOptions) }} />
            </div>
        );
    }

    const statusBodyTemplate = (tramite: any) => {
        const statusType = getStatusType(tramite.ptrm_status)
        return <Tag value={statusType} severity={getSeverity(tramite.ptrm_status)}></Tag>;
    };

    const getStatusType = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'Eliminado';
            case 1:
                return 'Borrador';
            case 2:
                return 'En Revision';
            case 3:
                return 'Rechazado';
            case 4:
                return 'Subsanado';
            case 5:
                return 'Aprobado';
            default:
                return null;
        }
    };

    const getSeverity = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'info'
            case 2:
                return 'warning';
            case 3:
                return 'danger';
            case 4:
                return 'warning';
            case 5:
                return 'success';
            default:
                return null;
        }
    };

    const fechaBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.ptrm_update);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();

        const fechaFormateada = `${day}/${month}/${year}`;

        return (
            <div className="actions">
                <p>{`${fechaFormateada}`}</p>
            </div>
        )
    }

    const [globalFilter, setGlobalFilter] = useState<any>(null);


    return (
        <section className='container-fluid'>
            <div className='grid pb-3'>
                <div className='col-12 md:col-8'>
                    <h3 className='color-violet'>Bienvenido</h3>
                    <p>Gestiona tus borradores para enviarlos a trámites</p>
                </div>
                <div className='col-12 md:col-4'>
                    <Button label="Crear borrador" onClick={() => { navigate('/notarios/not-pagos') }} />
                </div>
            </div>

            <Divider />

            <div className='col-12 md:col-12'>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <h3 className='color-violet'>Borradores</h3>
                    </div>
                    <div className='col-8'>
                        <span className="p-float-label p-input-icon-left w-100">
                            <i className="pi pi-search" />
                            <InputText
                                className='p-inputtext-lg'
                                type="search"
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value)
                                }}
                            />
                            <label htmlFor="username">Buscador general</label>
                        </span>
                    </div>
                </div>

            </div>

            <div className='grid'>
                <div className='col-12 md:col-12'>
                    <DataTable
                        value={listTramites}
                        paginator
                        rows={10}
                        dataKey="id"
                        tableStyle={{ minWidth: '50rem' }}
                        globalFilter={globalFilter}

                    >
                        <Column sortable field="ptrm_consecutivo" header="N° Control Notaria"></Column>
                        <Column sortable field="ptrm_update" header="Fecha" body={fechaBodyTemplate}></Column>
                        <Column sortable field="prop_folio" header="Folio electrónico"></Column>
                        <Column sortable field="prop_clave" header="Clave catastral"></Column>
                        <Column sortable field="actos" header="Actos"></Column>
                        <Column sortable field="ptrm_status" header="Estatus" body={statusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </section>
    );

}

export default React.memo(TraInicio);