import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ResulActos from './PagoResulFolio/actos';
import ResulGenerales from './PagoResulFolio/generales';
import ResulTitulares from './PagoResulFolio/titulares';
import { Dialog } from 'primereact/dialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { useLocation, useNavigate } from "react-router-dom";
import { Messages } from 'primereact/messages';
import { Steps } from 'primereact/steps';
import { InputText } from 'primereact/inputtext';
import Tabs from '../../../Components/Tabs/Tabs';
import Tab from '../../../Components/Tabs/Tab';
import TimeLine from '../../../Components/TimeLine/TimeLine';
import { Password } from 'primereact/password';

const PagoFolio = (props: any) => {

    const location = useLocation();
    const navigate = useNavigate();
    const msgs = useRef<any>(null);

    const previousFolio: any = location.state.datos
    const previousPayments: any = location.state.pagos
    const preTramite: any = location.state.preTramite

    const [preTramiteDetail, setPreTramiteDetail] = useState<any>(null)
    const [preTramiteFolio, setPreTramiteFolio] = useState<any>()
    const [modalRechazar, setModalRechazar] = useState<boolean>(false);
    const [rechazarText, setRechazarText] = useState('')
    const [modalRechazado, setModalRechazado] = useState(false)
    const [modalSentToNotario, setModalSentToNotario] = useState(false)
    const [actosArray, setActosArray] = useState<any>([])
    const [actosList, setActosList] = useState([])
    const [actosListOriginal, setActosListOriginal] = useState([])
    const [escrituraNumber, setEscrituraNumber] = useState(preTramite ? preTramite.ptrm_escritura : '')
    const [modalBorradorGuardado, setModalBorradorGuardado] = useState(false)

    const getActosInformation = async () => {
        const idToSearch = responseData ? responseData.ptrm_id : preTramite ? preTramite.ptrm_id : null
        if (idToSearch) {
            let datos = {
                ptrm_id: idToSearch,
                trm_id: 0
            }
            await ApiServices.apiPost('getFormatosResp', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda.length > 0) {
                    const newArray: any = []

                    res.data.addenda.map(async (singleElement: any) => {
                        if (singleElement.ptrm_id === idToSearch) {
                            const cloned = JSON.parse(JSON.stringify(singleElement))
                            const matched = findByActId(actosListOriginal, cloned.act_id)
                            newArray.push(cloned)
                        }
                    })
                    matchSavedActos(newArray)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    function findByActId(array: any, targetActId: any) {
        return array.find((item: any) => item.act_id == targetActId);
    }

    const [totalActos, setTotalActos] = useState(0)

    const matchSavedActos = (newActos: any) => {
        let newArray = []
        if (newActos && newActos.length > 0) {
            let total = 0;
            const matchedActos = newActos.map((singleActo: any) => {
                const match = findByActId(actosListOriginal, singleActo.act_id)
                if (match) {
                    singleActo.act_umas = match.act_umas
                    singleActo.act_dias = match.act_dias
                    singleActo.cnpg_id = match.cnpg_id
                    singleActo.area_id = match.area_id
                    singleActo.act_status = match.act_status
                    singleActo.act_update = match.act_update
                    singleActo.act_pesos = match.valormx

                    if (match.valormx) {
                        total = total + parseFloat(match.valormx)
                    }
                }
                return singleActo
            })

            if (actosArray.length > 0)
                newArray = mergeArraysByActId(actosArray, matchedActos)
            else
                newArray = matchedActos
            setTotalActos(total)
            setActosArray(newArray)
        }
    }

    function mergeArraysByActId(array1: any, array2: any) {
        const mapArray2 = new Map(array2.map((obj: any) => [obj.act_id, obj]));
        const mergedArray = array1.map((obj1: any) => {
            const obj2 = mapArray2.get(parseInt(obj1.act_id));
            return obj2 && typeof obj1 === 'object' && typeof obj2 === 'object' ? { ...obj1, ...obj2 } : obj1;
        });
        return mergedArray;
    }

    const getActosList = async () => {
        try {
            let datos: any;

            const res1 = await ApiServices.apiPost('catActo', datos);
            setActosListOriginal(res1.data.addenda);

            const res2 = await ApiServices.apiPost('catCategoriaActo', datos);
            let generalInformation: any = [];

            if (res2.data.addenda && res2.data.addenda.length > 0) {
                const clonedArray = JSON.parse(JSON.stringify(res2.data.addenda))
                clonedArray.unshift({ ccatacto_id: 0, ccatacto_desc: 'General', ccatacto_status: 1 });

                for (const singleAct of clonedArray) {
                    let datos = { ccatacto_id: singleAct.ccatacto_id };

                    try {
                        const res3 = await ApiServices.apiPost('catActoCat', datos);

                        if (res3.data.addenda && res3.data.addenda) {
                            const formattedActs = JSON.parse(JSON.stringify(res3.data.addenda))
                            const newArray = formattedActs.map((singleAct: any) => {
                                return {
                                    key: singleAct.act_id,
                                    label: `${singleAct.act_clave} ${singleAct.act_nombre}`,
                                    data: singleAct,
                                    icon: ""
                                };
                            });

                            generalInformation.push({
                                key: singleAct.ccatacto_id,
                                label: singleAct.ccatacto_desc,
                                data: singleAct,
                                icon: "",
                                children: newArray,
                                selectable: false
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                setActosList(generalInformation);
            }
        } catch (error) {
            console.log(error);
        }
    }




    const requestPreTramiteDetail = () => {
        if (preTramite) {
            let datos: { code: number; ptrm_id: any; }
            datos = { code: 303, ptrm_id: preTramite.ptrm_id }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    searchNotaryById(res.data.addenda.tramite.not_id)
                    setEscrituraNumber(res.data.addenda.tramite.ptrm_escritura)
                    setRequestName(res.data.addenda.tramite.ptrm_solicitante)
                }
            }).catch(error => {
                console.log(error)
            })
        } else if (responseData) {
            let datos: { code: number; ptrm_id: any; }
            datos = { code: 303, ptrm_id: responseData.ptrm_id }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    searchNotaryById(res.data.addenda.tramite.not_id)
                    setEscrituraNumber(res.data.addenda.tramite.ptrm_escritura)
                    setRequestName(res.data.addenda.tramite.ptrm_solicitante)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }


    /* OLD */

    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<any>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [modalSoliInter, ModalSoliInter] = useState<boolean>(false);
    const buttonEl = useRef<any>(null);

    const [timeLine, setTimeLine] = useState<any>()

    const events1 = [
        { label: 'Capturado' },
        { label: 'Asignado' },
        { label: 'Analizando' },
        { label: 'A-R-C' },
        { label: 'Firmado' }
    ];

    const events2 = [
        { label: 'Capturado', tooltip: timeLine ? timeLine.capturista : '', time: timeLine ? timeLine.trm_fecha : '' },
        { label: 'Asignado', tooltip: timeLine ? timeLine.analista : '' },
        { label: 'Analizando', tooltip: '', time: timeLine ? timeLine.trm_analizado : '' },
        { label: 'A-R-C', tooltip: timeLine ? timeLine.statusARC : '', time: timeLine ? timeLine.fechahoraARC : '' },
        { label: 'Firmado', tooltip: timeLine ? timeLine.trm_firmador : '', time: timeLine ? timeLine.trm_firmado : '' }
    ];

    const accept = () => {
        ModalSetVisible(true)
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado tu trámite', life: 3000 });
    }

    const showSuccessActo = () => {
        toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado el Acto', life: 3000 });
    }

    const fileToBig = () => {
        toast.current.show({ severity: 'error', summary: 'Archivo muy grande', detail: 'El archivo excede los 5MB', life: 3000 });
    }

    const customizedMarker = (item: any) => {
        return (
            <div>
                {/* circulo vasio: se utilizara cuando no tiene un proceso todavia realizado */}
                <i className='pi pi-circle'></i>
                {/* circulo lleno: aparecera cuando el estatus se valla llenando, en cada punto tiene que aparecer un toolpin con el estatus de quien lo reviso y la hora de movimiento */}
                <Button className='p-button-text tooltip-view' type="button" icon="pi pi-circle-fill" tooltipOptions={{ position: 'bottom' }} />
                <Tooltip target=".tooltip-view" autoHide={false}>
                    <div className="p-3">
                        <h5>Capturado</h5>
                        <p>Juan Montes Torres <br />
                            <small>12 Marz 2022 12:00pm</small></p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const footerContent = (
        <div>
            <Button label="Cerrar" onClick={() => {
                {
                    ModalSetVisible(false)
                    navigate('/')
                }
            }} autoFocus />
        </div>
    );



    const [notaryList, setNotaryList] = useState([])
    const [selectedNotaryList, setSelectedNotaryList] = useState<any>(null)
    const [requestName, setRequestName] = useState('')


    const getNotaryList = async () => {
        let datos: any
        await ApiServices.apiPost('catNotarios', datos).then(async res => {
            setNotaryList(res.data.addenda)
            searchNotary(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const searchNotary = (notarys: any) => {
        if (notarys && notarys.length > 0) {
            notarys.map((element: any) => {
                if (element.not_id == user.not_id) {
                    setSelectedNotaryList(element)
                }
            })
        }
    }

    const searchNotaryById = (id: any) => {
        if (notaryList && notaryList.length > 0) {
            notaryList.map((element: any) => {
                if (element.not_id == id) {
                    setSelectedNotaryList(element)
                }
            })
        }
    }

    const getActosArray = () => {
        if (actosArray && actosArray.length > 0) {
            const ids = actosArray.map((element: any) => {
                return element.act_id
            })
            return ids
        }
    }

    const getPagosArray = () => {
        if (previousPayments && previousPayments.length > 0) {
            const ids = previousPayments.map((element: any) => {
                return element.pago_id
            })
            return ids
        }
    }

    function obtenerFechaYHoraActualEnFormato() {
        const fechaTramite = preTramiteDetail ? preTramiteDetail.tramite.ptrm_fecha : responseData ? responseData.ptrm_fecha : new Date()
        const horaTramite = preTramiteDetail ? preTramiteDetail.tramite.ptrm_hora : responseData ? responseData.ptrm_hora : new Date()

        const mesesEnEspanol = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const fechaActual = new Date(fechaTramite);
        const horaActual = new Date(horaTramite);

        let periodo = "am";

        const dia = fechaActual.getDate() + 1;
        const mes = mesesEnEspanol[fechaActual.getMonth()];
        const anio = fechaActual.getFullYear();
        let hora = horaActual.getUTCHours(); // Usar UTC para la hora
        const minutos = horaActual.getUTCMinutes(); // Usar UTC para los minutos
        const segundos = horaActual.getUTCSeconds();

        // Convertir la hora a formato de 12 horas y ajustar el periodo (am/pm)
        if (hora >= 12) {
            periodo = "pm";
            if (hora > 12) {
                hora -= 12;
            }
        }

        // Agregar un cero al principio si los minutos son menores a 10
        const minutosFormateados = (minutos < 10) ? "0" + minutos : minutos;

        const fechaYHoraFormateada = `${dia} ${mes} ${anio} a las ${hora}:${minutosFormateados} ${periodo}`;
        return fechaYHoraFormateada;
    }
    /* CONFIRMED */

    const getHijosArray = () => {
        if (totalFolios && totalFolios.length > 0) {
            const final = totalFolios.map((singleFolio: any) => {
                console.log(singleFolio)
                return singleFolio.prop_id
            })
            return final
        } else
            return []
    }

    const user = authGuardService.getUser()

    const handleAddPreTramite = () => {
        console.log('Entro')

        const actos = getActosArray()
        const pagos = getPagosArray()
        const hijos = getHijosArray()

        let datos: any = {
            code: 302,
            usu_id: user.usu_id,
            prop_id: previousFolio[0].prop_id,
            ptrm_solicitante: requestName,
            not_id: selectedNotaryList ? selectedNotaryList.not_id : user.not_id,
            ptrm_escritura: escrituraNumber,
            actos: actos || [],
            pagos: pagos || [],
            hijos: hijos || []
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido) {
                setActiveIndex(1)
                setResponseData(res.data.addenda)
                setModalNewScript(false)
                showSuccess()
                if (user.prf_id === 8 && previousFolio) {
                    handleStatusPreTramite(2, res.data.addenda)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleSavePreTramite = (showToast: boolean) => {
        const actos = getActosArray()
        const pagos = getPagosArray()
        const hijos = getHijosArray()

        const ptrmId = preTramite ? preTramite.ptrm_id : responseData ? responseData.ptrm_id : 0
        const propId = preTramiteDetail ? preTramiteDetail.tramite.prop_id : responseData ? responseData.prop_id : 0
        const requestNameFinal = `${selectedNotaryList ? selectedNotaryList.not_nom : ''} ${selectedNotaryList ? selectedNotaryList.not_mat : ''} ${selectedNotaryList ? selectedNotaryList.not_pat : ''}`

        let datos: any = {
            code: 306,
            ptrm_id: ptrmId,
            prop_id: propId,
            ptrm_solicitante: requestName || selectedNotaryList.nombre_notario,
            not_id: selectedNotaryList ? selectedNotaryList.not_id : user.not_id,
            ptrm_escritura: escrituraNumber,
            actos: actos || [],
            pagos: pagos || [],
            hijos: hijos || [],
            usu_id: user.usu_id
        }

        console.log(datos)
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido) {
                setModalBorradorGuardado(false)
                showToast && showSuccess()
                handleAllActosToSave()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    function formatDate(date: any, hours: any) {
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const newDate = new Date(date);
        const newHour = new Date(hours);
        const day = newDate.getUTCDate();
        const month = months[newDate.getUTCMonth()];
        const year = newDate.getUTCFullYear();
        const hour = newHour.getUTCHours();
        const minutes = newHour.getUTCMinutes();

        const fechaFormateada = `${day} ${month} ${year} a las ${hour}:${minutes}`;
        return fechaFormateada;
    }

    const [dataTramite, setDataTramite] = useState<any>()

    const handleStatusPreTramite = async (status: number, dataResp = responseData) => {
        await handleSavePreTramite(false)
        let datos = {}
        let bloqueos = 0
        await ApiServices.apiPost('bloqueoActivo', datos).then((res: any) => {
            bloqueos = res.data.addenda[0].bloqueos
        })

        if (bloqueos == 1) {
            toast.current.show({ severity: 'error', summary: 'Sistema Bloqueado', detail: 'Por el momento no puedes mandar tramites al instituto', life: 3000 });
        } else {
            const data = preTramite || dataResp
            let datos: any = {
                code: 304,
                ptrm_id: data.ptrm_id,
                usu_id: user.usu_id,
                ptrm_status: status,
                ptrm_nota: rechazarText || '',
                usu_efirma_password: user.usu_efirma_password || EncryptService.encryptData(temporalFirma)
            }

            console.log(datos)

            if (status === 3) {
                setModalRechazar(false)
                datos.ptrm_nota = rechazarText;
            }

            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido) {
                    if (status === 3) {
                        setModalRechazado(true)
                    }
                    if (status === 2) {
                        if (user.prf_id !== 8)
                            setModalSentToNotario(true)
                    } else if (status === 5) {
                        setDataTramite(res.data.addenda)
                        ModalSetVisible(true)
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: res.data.mensaje, life: 3000 });
                }
            }).catch(error => {
                console.log(error)
            })
        }


    }

    const [modalNewScript, setModalNewScript] = useState(false)
    const [responseData, setResponseData] = useState<any>()

    const handleAllActosToSave = () => {
        const actos = getActosArray()
        if (actosArray && actosArray.length) {
            actosArray.map((singleActo: any) => {
                if (!(singleActo.frmu_id))
                    handleSave(singleActo)
            })
        }
    }

    const handleSave = (selectedForm: any) => {
        let datos: any
        const ptrmId = preTramite ? preTramite.ptrm_id : responseData ? responseData.ptrm_id : 0

        datos = {
            code: 401,
            usu_id: user.usu_id,
            act_id: selectedForm.act_id,
            campos: [],
            ptrm_id: ptrmId,
            trm_id: 0
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
        }).catch(error => {
            console.log(error)
        })

    }

    const handlePreviousActos = () => {
        const arrayListSaved = preTramite.actos.split(', ')
        const newArray = JSON.parse(JSON.stringify(actosArray))

        if (arrayListSaved && arrayListSaved.length > 0) {
            arrayListSaved.map((singleAct: any) => {
                const foundItem = actosArray.find((item: any) => item.act_id == singleAct);
                if (!foundItem) {
                    const elementToPush = actosListOriginal.find((item: any) => item.act_clave == singleAct);
                    newArray.push(elementToPush)
                }
            })
        }
        matchSavedActos(newArray)
    }

    const searchPreTramiteDetail = async () => {
        if (preTramite) {
            let datos: { code: number; ptrm_id: any; }
            datos = { code: 303, ptrm_id: preTramite.ptrm_id }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            await ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    setEscrituraNumber(res.data.addenda.tramite.ptrm_escritura)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const getTimeLine = (id: any) => {
        if (id) {
            let datos = {
                trm_id: id,
            }
            ApiServices.apiPost('getTimeLine', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                    setTimeLine(res.data.addenda[0])
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const fechaBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.trm_fecha);
        const horaUTC = new Date(rowData.trm_hora);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();
        const hours = horaUTC.getUTCHours().toString().padStart(2, '0');
        const minutes = horaUTC.getUTCMinutes().toString().padStart(2, '0');
        const seconds = horaUTC.getUTCSeconds().toString().padStart(2, '0');

        const fechaFormateada = `${day}/${month}/${year}`;

        return fechaFormateada
    }
    const [requester, setRequester] = useState(true)

    useEffect(() => {
        getNotaryList()
        getActosList()
        if (preTramite && preTramite.trm_id)
            getTimeLine(preTramite.trm_id)
    }, [])

    useEffect(() => {
        getActosInformation()
    }, [actosListOriginal])

    useEffect(() => {
        if (preTramite && preTramite.actos && actosListOriginal && actosListOriginal.length > 0 && actosArray) {
            handlePreviousActos()
        }
    }, [actosListOriginal, preTramite])

    useEffect(() => {
        if (preTramite && notaryList) {
            let datos2: { code: number; ptrm_id: any; }
            datos2 = { code: 303, ptrm_id: preTramite.ptrm_id }
            datos2 = EncryptService.encryptData(JSON.stringify(datos2));
            ApiServices.processRequestAdd(datos2).then(async res => {
                if (res.data.valido === 1) {
                    setPreTramiteDetail(res.data.addenda)
                    searchNotaryById(res.data.addenda.tramite.not_id)
                    setEscrituraNumber(res.data.addenda.tramite.ptrm_escritura)
                    setRequestName(res.data.addenda.tramite.ptrm_solicitante)
                    if ((preTramite.ptrm_status === 3 || preTramite.ptrm_status === 4) && res.data.addenda) {
                        msgs.current.show(
                            {
                                sticky: true,
                                severity: 'error',
                                summary: 'PreTramite Rechazado / ',
                                detail: `Motivo: ${res.data.addenda.tramite.ptrm_nota || 'Sin Motivo'}`,
                                closable: false,
                            }
                        );
                    }
                }
            }).catch(error => {
                console.log(error)
            })

            if (preTramite.prop_folio) {
                let datos = {
                    prop_folio: preTramite.prop_folio,
                    prop_online: 0
                }
                ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                    setPreTramiteFolio(res.data.addenda)
                    setActiveIndex(0)
                }).catch(error => {
                    console.log(error)
                })
            } else {
                let datos = {
                    prop_id: preTramite.prop_id,
                }
                ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
                    let datos = {
                        prop_folio: res.data.addenda[0].prop_folio,
                        prop_online: 0
                    }
                    ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                        setPreTramiteFolio(res.data.addenda)
                        setActiveIndex(0)
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }, [preTramite, notaryList])

    const [modalActive, setModalActive] = useState(false)
    const [temporalFirma, setTemporalFirma] = useState('')

    const handleCheckFirma = (id: any) => {
        if (user && user.usu_efirma_password) {
            if (user.usu_efirma_password != '') {
                handleStatusPreTramite(5)
            } else {
                setModalActive(true)
            }
        } else {
            setModalActive(true)
        }
    }

    const handleCheckNewFirma = () => {
        if (temporalFirma && temporalFirma.length > 0) {
            handleStatusPreTramite(5)
        } else {
            toast.current.show({ severity: 'error', summary: 'Ingresa tu contraseña', detail: 'Ingresa tu contraseña para firmar', life: 3000 });
        }
        setModalActive(false)
    }

    const EFirmafooterContent = (
        <div className=''>
            <Button label="Cancelar" onClick={() => setModalActive(false)} severity="secondary" outlined />
            <Button label="Firmar" onClick={handleCheckNewFirma} autoFocus />
        </div>
    );

    const [titulares, setTitulares] = useState([])

    const getTitulares = () => {
        let datos = {
            prop_id: preTramiteDetail ? preTramiteDetail.tramite.prop_id : responseData ? responseData.prop_id : previousFolio ? previousFolio[0].prop_id : 0
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    function groupObjectsByTrmId(inputArray: any) {
        // Ordenar los objetos por fecha antes de agruparlos
        inputArray.sort((a, b) => {
            return a.trm_fecha.localeCompare(b.trm_fecha);
        });

        const groupedObjects: any = {};

        inputArray.forEach((obj) => {
            const trmFecha = obj.trm_fecha;

            if (!groupedObjects[trmFecha]) {
                groupedObjects[trmFecha] = {
                    trm_fecha: trmFecha,
                    history: []
                };
            }

            groupedObjects[trmFecha].history.push(obj);
        });

        return Object.values(groupedObjects);
    }

    const getActoHistory = () => {
        const previousFolio: any = location && location.state && location.state.datos && location.state.datos[0] && location.state.datos[0].prop_folio
        const preTramite: any = location?.state?.preTramite?.prop_folio

        let datos = {
            prop_folio: previousFolio || preTramite
        }
        ApiServices.apiPost('getHistorial', datos).then(async res => {
            const groupedResult: any = groupObjectsByTrmId(res.data.addenda);
            const reversed = groupedResult.reverse()
            setActoHistory(reversed)
            setActoHistoryClone(reversed)
            setActoHistoryLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }


    useEffect(() => {
        getTitulares()
        getActoHistory()
    }, []);

    const [actoHistory, setActoHistory] = useState()
    const [actoHistoryLoading, setActoHistoryLoading] = useState(true)
    const [actoHistoryClone, setActoHistoryClone] = useState()
    const [totalFolios, setTotalFolios] = useState([])

    return (
        <section>
            <Toast ref={toast} />

            <div className='container-fluid'>
                <div className='flex justify-content-between'>
                    {/* cuando sean folios de calidad aparecera el color verde se mostrara como el div, si no. se negara el small y el div 0.1 siguiente */}
                    {previousFolio &&
                        <div className='flex col-12 md:col-4'>
                            {previousFolio[0] && previousFolio[0].prop_calidad == 1 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle verde'></i>
                                    <div className='line'></div>
                                </div>
                            }

                            {previousFolio[0] && previousFolio[0].prop_calidad == 2 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle rojo'></i>
                                    <div className='lineRed'></div>
                                </div>
                            }

                            {previousFolio[0] && previousFolio[0].prop_calidad == 3 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle naranja'></i>
                                    <div className='lineOrange'></div>
                                </div>
                            }

                            <div className={
                                (previousFolio[0] && previousFolio[0].prop_calidad == 1) ? 'verde' :
                                    (previousFolio[0] && previousFolio[0].prop_calidad == 2) ? 'rojo' :
                                        (previousFolio[0] && previousFolio[0].prop_calidad == 3) ? 'naranja' :
                                            ''
                            }>
                                <h3 className={
                                    (previousFolio[0] && previousFolio[0].prop_calidad == 1) ? 'verde' :
                                        (previousFolio[0] && previousFolio[0].prop_calidad == 2) ? 'rojo' :
                                            (previousFolio[0] && previousFolio[0].prop_calidad == 3) ? 'naranja' :
                                                ''
                                }>Folio {previousFolio[0] ? previousFolio[0].prop_folio : ''}</h3>
                                {previousFolio[0] && previousFolio[0].prop_calidad == 1 &&
                                    <small>Este es un folio de calidad</small>
                                }
                                {previousFolio[0] && previousFolio[0].prop_calidad == 2 &&
                                    <h6>Este es un folio cerrado</h6>
                                }
                                {previousFolio[0] && previousFolio[0].prop_calidad == 3 &&
                                    <h6>Advertencia</h6>
                                }
                                {((preTramite && preTramite.ptrm_consecutivo) || (responseData && responseData.ptrm_consecutivo)) &&
                                    <p className='pt-2 text-center black'>
                                        <small className='font-bold'>{`Control Interno Notaria ${preTramiteDetail ? preTramiteDetail.tramite.ptrm_consecutivo : responseData ? responseData.ptrm_consecutivo : ''}`}</small>
                                        <br />
                                        {preTramiteDetail ?
                                            `${formatDate(preTramiteDetail.tramite.ptrm_fecha, preTramiteDetail.tramite.ptrm_hora)}` :
                                            responseData ? `${formatDate(responseData.ptrm_fecha, responseData.ptrm_hora)}` : ''}
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    {preTramiteFolio &&
                        <div className='flex col-12 md:col-4'>
                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle verde'></i>
                                    <div className='line'></div>
                                </div>
                            }

                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle rojo'></i>
                                    <div className='lineRed'></div>
                                </div>
                            }

                            {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3 &&
                                <div className='text-center'>
                                    <i className='pi pi-check-circle naranja'></i>
                                    <div className='lineOrange'></div>
                                </div>
                            }

                            <div className={
                                (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1) ? 'verde' :
                                    (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2) ? 'rojo' :
                                        (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3) ? 'naranja' :
                                            ''
                            }>
                                <h3 className={
                                    (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1) ? 'verde' :
                                        (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2) ? 'rojo' :
                                            (preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3) ? 'naranja' :
                                                ''
                                }>Folio {preTramiteFolio[0] ? preTramiteFolio[0].prop_folio : ''}</h3>
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 1 &&
                                    <small>Este es un folio de calidad</small>
                                }
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 2 &&
                                    <h6>Este es un folio cerrado</h6>
                                }
                                {preTramiteFolio[0] && preTramiteFolio[0].prop_calidad == 3 &&
                                    <h6>Advertencia</h6>
                                }
                                {((preTramite && preTramite.ptrm_consecutivo) || (responseData && responseData.ptrm_consecutivo)) &&
                                    <p className='pt-2 text-center black'>
                                        <small className='font-bold'>{`Control Interno Notaria ${preTramiteDetail ? preTramiteDetail.tramite.ptrm_consecutivo : responseData ? responseData.ptrm_consecutivo : ''}`}</small>
                                        <br />
                                        {preTramiteDetail ? `${formatDate(preTramiteDetail.tramite.ptrm_fecha, preTramiteDetail.tramite.ptrm_hora)}` :
                                            responseData ? `${formatDate(responseData.ptrm_fecha, responseData.ptrm_hora)}` : ''}
                                    </p>
                                }
                            </div>
                        </div>
                    }
                    <div className='col-12 md:col-6'>
                        {timeLine &&
                            <TimeLine timeLine={timeLine} />
                        }
                    </div>

                </div>

                <Messages ref={msgs} />
                <div className=''>
                    <Tabs activeIndex={activeIndex} onTabChange={(e: { index: React.SetStateAction<number>; }) => setActiveIndex(e.index)}>
                        <Tab label="Datos generales">
                            {previousFolio &&
                                <ResulGenerales
                                    folioDatos={previousFolio[0]}
                                    notaryList={notaryList}
                                    setNotaryList={setNotaryList}
                                    selectedNotaryList={selectedNotaryList}
                                    setSelectedNotaryList={setSelectedNotaryList}
                                    escrituraNumber={escrituraNumber}
                                    setEscrituraNumber={setEscrituraNumber}
                                    requestName={requestName}
                                    setRequestName={setRequestName}
                                    exist={responseData}
                                    preTramiteDetail={preTramiteDetail}
                                    requestPreTramiteDetail={requestPreTramiteDetail}
                                    fileToBig={fileToBig}
                                    requester={requester}
                                    setRequester={setRequester}
                                    titulares={titulares}
                                />
                            }{
                                preTramiteFolio &&
                                <ResulGenerales
                                    folioDatos={preTramiteFolio[0]}
                                    notaryList={notaryList}
                                    setNotaryList={setNotaryList}
                                    selectedNotaryList={selectedNotaryList}
                                    setSelectedNotaryList={setSelectedNotaryList}
                                    escrituraNumber={escrituraNumber}
                                    setEscrituraNumber={setEscrituraNumber}
                                    requestName={requestName}
                                    setRequestName={setRequestName}
                                    exist={preTramite}
                                    preTramiteDetail={preTramiteDetail}
                                    requestPreTramiteDetail={requestPreTramiteDetail}
                                    fileToBig={fileToBig}
                                    requester={requester}
                                    setRequester={setRequester}
                                    titulares={titulares}
                                />
                            }
                        </Tab>
                        {previousFolio &&
                            <Tab label="Actos" disabled={!responseData}>
                                <ResulActos
                                    previousFolio={previousFolio}
                                    previousPayments={previousPayments}
                                    actos={actosArray}
                                    actosList={actosList}
                                    actosFunction={setActosArray}
                                    responseData={responseData}
                                    preTramite={preTramite}
                                    requestActos={getActosInformation}
                                    total={totalActos}
                                    totalFunction={setTotalActos}
                                    preTramiteDetail={preTramiteDetail}
                                    actoSuccess={showSuccessActo}
                                    actoHistory={actoHistory}
                                    setActoHistory={setActoHistory}
                                    actoHistoryClone={actoHistoryClone}
                                    actoHistoryLoading={actoHistoryLoading}
                                    totalFolios={totalFolios}
                                    setTotalFolios={setTotalFolios}
                                />
                            </Tab>
                        }
                        {preTramiteFolio &&
                            <Tab label="Actos">
                                <ResulActos
                                    previousFolio={previousFolio}
                                    previousPayments={previousPayments}
                                    actos={actosArray}
                                    actosList={actosList}
                                    actosFunction={setActosArray}
                                    responseData={responseData}
                                    preTramite={preTramite}
                                    requestActos={getActosInformation}
                                    total={totalActos}
                                    totalFunction={setTotalActos}
                                    preTramiteDetail={preTramiteDetail}
                                    actoSuccess={showSuccessActo}
                                    actoHistory={actoHistory}
                                    setActoHistory={setActoHistory}
                                    actoHistoryClone={actoHistoryClone}
                                    actoHistoryLoading={actoHistoryLoading}
                                    totalFolios={totalFolios}
                                    setTotalFolios={setTotalFolios}
                                />
                            </Tab>
                        }
                        <Tab label="Titulares">
                            <ResulTitulares
                                previousFolio={previousFolio}
                                previousPayments={previousPayments}
                                responseData={responseData}
                                preTramite={preTramite}
                                preTramiteDetail={preTramiteDetail}
                                titulares={titulares}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div >

            <div className='footer-folio flex justify-content-end'>
                {/* Notario Administrador y el Folio No Existe */}
                {user.prf_id === 8 && previousFolio && (
                    <div className='flex col-4'>
                        {
                            responseData ?
                                <Button
                                    label="Guardar Borrador"
                                    className='p-button-outlined mr-4'
                                    onClick={() => { handleSavePreTramite(true) }}
                                />
                                :
                                <Button
                                    label="Crear Borrador"
                                    className='p-button-outlined mr-4'
                                    onClick={handleAddPreTramite}
                                />
                        }
                        {responseData &&
                            <Button
                                label="Enviar al Instituto"
                                onClick={() => handleCheckFirma(5)}
                            />
                        }
                    </div>
                )}
                {/* Notario Administrador y Folio Existente */}
                {user.prf_id === 8 && preTramiteFolio && preTramite && preTramite.usu_id === user.usu_id && (
                    <div className='flex col-4'>
                        <Button
                            label="Guardar Borrador"
                            className='p-button-outlined mr-4'
                            onClick={() => { handleSavePreTramite(true) }}
                        />
                        <Button
                            label="Enviar al Instituto"
                            onClick={() => handleCheckFirma(5)}
                        />
                    </div>
                )}
                {/* Notario Administrador y Folio Existente de Trabajador */}
                {user.prf_id === 8 && preTramite && preTramite.usu_id !== user.usu_id && (
                    <div className='flex col-6'>
                        <Button
                            label="Guardar Borrador"
                            className='p-button-outlined mr-4'
                            onClick={() => { handleSavePreTramite(true) }}
                        />
                        <Button
                            label="Rechazar"
                            className='p-button-outlined mr-4'
                            onClick={() => setModalRechazar(true)}
                        />
                        <Button
                            label="Enviar al Instituto"
                            onClick={() => handleCheckFirma(5)}
                        />
                    </div>
                )}
                {/* Notario Trabajador y Folio No Existente */}
                {user.prf_id === 9 && previousFolio && (
                    <div className='flex col-4'>
                        <Button
                            label="Guardar Borrador"
                            className='p-button-outlined mr-4'
                            onClick={() => { responseData ? handleSavePreTramite(true) : handleAddPreTramite() }}
                        />
                        {responseData &&
                            <Button
                                label="Enviar a revisión"
                                onClick={() => handleStatusPreTramite(2)}
                            />
                        }
                    </div>
                )}
                {/* Notario Trabajador y Folio Existente */}
                {user.prf_id === 9 && preTramite && (
                    <div className='flex col-4'>
                        <Button
                            label="Guardar borrador"
                            className='p-button-outlined mr-4'
                            onClick={() => { handleSavePreTramite(true) }}
                        />
                        <Button
                            label="Enviar a revisión"
                            onClick={() => handleStatusPreTramite(2)}
                        />
                    </div>
                )}
            </div>


            <ConfirmPopup target={buttonEl.current} visible={visible} onHide={() => setVisible(false)}
                message="¿Estas seguro que los datos ingresados que son correctos?" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} acceptLabel='Si' rejectLabel='No' />

            <Dialog header="Tu solicitud ha sido enviada" visible={modalVisible} style={{ width: '30vw' }} onHide={() => {
                ModalSetVisible(false)
                navigate('/')
            }} footer={footerContent}>
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <h2>{dataTramite ? `${dataTramite.trm_consecutivo} - ${fechaBodyTemplate(dataTramite)}` : ''}</h2>
                        <small>Número control interno ISRyC</small>
                    </div>
                    <div>
                        <p className='m-0'>{obtenerFechaYHoraActualEnFormato()}</p>
                        <small>Fecha y hora de registro</small>

                    </div>
                </div>
            </Dialog>

            {/* Borrador Guardado con éxito  */}
            <Dialog
                header="Tu borrador se ha guardado con éxito"
                visible={modalBorradorGuardado}
                style={{ width: '30vw' }}
                onHide={() => setModalBorradorGuardado(false)}
                footer={
                    <div>
                        <Button label="Cerrar" onClick={() => setModalBorradorGuardado(false)} autoFocus />
                    </div>
                }
            >
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <h2>{preTramite ? preTramite.ptrm_id : ''}</h2>
                        <small>Número control interno</small>
                    </div>
                    <div>
                        <p className='m-0'>{obtenerFechaYHoraActualEnFormato()}</p>
                        <small>Fecha y hora de registro</small>

                    </div>
                </div>
            </Dialog>

            {/* Dialog Enviado a Notario */}
            <Dialog
                header="Tu solicitud ha sido enviada"
                visible={modalSentToNotario}
                style={{ width: '40vw' }}
                onHide={() => {
                    setModalSentToNotario(false)
                    navigate('/')
                }}
                footer={
                    <div>
                        <Button label="Cerrar" onClick={() => {
                            setModalSentToNotario(false)
                            navigate('/')
                        }
                        } autoFocus />
                    </div>
                }
            >
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <p>Se envió tu solicitud <h2>{preTramite ? preTramite.ptrm_consecutivo : responseData ? responseData.ptrm_consecutivo : ''}</h2> al notario para su revisión</p>
                    </div>
                    <div>
                        <p className='m-0'>{obtenerFechaYHoraActualEnFormato()}</p>
                        <small>Fecha y hora de registro</small>
                    </div>
                </div>
            </Dialog>

            {/* Modal Rechazado */}
            <Dialog
                header="Se mandó el rechazo a tu trabajador"
                visible={modalRechazado}
                style={{ width: '40vw' }}
                onHide={() => {
                    setModalRechazado(false)
                    navigate(-1)
                }}
                footer={
                    <div>
                        <Button label="Cerrar" onClick={() => {
                            setModalRechazado(false)
                            navigate(-1)
                        }} />
                    </div>
                }>
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <p>Se envió el trámite rechazado al trabajador para su revisión</p>
                    </div>
                </div>
            </Dialog>

            {/* Modal Rechazar  */}
            <Dialog
                header="Rechazar"
                visible={modalRechazar}
                style={{ width: '40vw' }}
                onHide={() => setModalRechazar(false)}
                footer={
                    <div>
                        <Button label="Cancelar" onClick={() => setModalRechazar(false)} severity="secondary" outlined />
                        <Button label="Enviar" onClick={() => handleStatusPreTramite(3)} />
                    </div>
                }
            >
                <div className='grid'>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea
                                rows={5}
                                cols={30}
                                value={rechazarText}
                                onChange={(e) => setRechazarText(e.target.value)}
                            />
                            <label htmlFor="username">Motivo del rechazo</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            {/* Modal Nuevo Borrador Creado */}
            <Dialog
                header="Nuevo borrador creado"
                visible={modalNewScript}
                style={{ width: '40vw' }}
                onHide={() => setModalNewScript(false)}
                footer={
                    <Button label="Cerrar" onClick={() => setModalNewScript(false)} autoFocus />
                }
            >
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <p>Se agregó el borrador del Pre-trámite {responseData && responseData.ptrm_id}</p>
                    </div>
                    <div>
                        <p className='m-0'>{responseData && responseData.ptrm_update && formatDate(responseData.ptrm_update, responseData.ptrm_update)}</p>
                        <small>Fecha y hora de registro</small>

                    </div>
                </div>
            </Dialog>

            <Dialog header="E-Firma" visible={modalActive} maximizable style={{ width: '40vw' }} onHide={() => setModalActive(false)} footer={EFirmafooterContent}>
                <p>Al ingresar la E-firma, estas autorizando que los certificados seleccionados sean firmados.</p>
                <div className='grid'>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Password
                                id="password"
                                inputId='password'
                                className={`p-inputtext-lg`}
                                onChange={(e) => { setTemporalFirma(e.target.value) }}
                                toggleMask
                                feedback={false}
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="username">Contraseña de E-Firma</label>
                        </span>
                    </div>
                </div>
            </Dialog>

        </section >
    );

}

export default React.memo(PagoFolio);


